import { useState } from 'react'

const useDragSort = (data, sort) => {
  const [
    drag,
    setDrag,
  ] = useState(null)

  const [
    ready,
    setDragReady,
  ] = useState(false)

  const handleReadyStatus = (status) => {
    setDragReady(status)
  }

  const handleStart = (row) => (event) => {
    event.stopPropagation()

    if (!ready) {
      event.preventDefault()
    } else {
      const img = document.createElement('img')
      img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      event.dataTransfer.setDragImage(img, 0, 0)
      setDrag(row)
    }
  }

  const handleEnd = (row) => (event) => {
    handleReadyStatus(false)
    setDrag(null)
    event.stopPropagation()
  }

  const handleOver = (row) => (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (row === drag) return
    sort(row, drag)
  }

  return {
    drag,
    handleReadyStatus,
    handleStart,
    handleEnd,
    handleOver,
  }
}

export default useDragSort
