import React, { Fragment } from 'react'

import Row from '_shared/components/layout/Row'

import { Raw as InputTime } from '_shared/components/input/InputTime'
import { Raw as InputDate } from '_shared/components/input/InputDate'
import Tick from '_shared/components/element/Tick'

import DayOfWeek from 'templates/DayOfWeek'
import ArrowRightIcon from 'icons/ArrowRight'
import ListTable from '_shared/components/navigation/ListTable'


const ConditionsTable = ({
  area,
  data,
  updateSingle,
}) => {
  const handleTimeToggle = (index) => {
    const row = data[index]
    const {
      start_time,
      end_time,
    } = row

    if (start_time === null && end_time === null) {
      updateSingle(`conditions.date[${index}]`, {
        ...row,
        start_time: '00:00',
        end_time: '23:59',
      })
    } else {
      updateSingle(`conditions.date[${index}]`, {
        ...row,
        start_time: null,
        end_time: null,
      })
    }
  }

  const handleCreate = () => {
    const state = [...data]

    const row = {
      start_date: new Date().toISOString().split('T')[0],
      end_date: new Date().toISOString().split('T')[0],
      day_of_week: [0, 1, 2, 3, 4, 5, 6],
      start_time: null,
      end_time: null,
    }

    state.push(row)

    updateSingle('conditions.date', state)
  }

  const handleRemove = (row) => {
    const state = [...data]

    state.splice(row, 1)

    updateSingle('conditions.date', state)
  }

  const rows = data.map((row, index) => {
    const {
      day_of_week,
      start_date,
      end_date,
      start_time,
      end_time,
    } = row

    const timeActive = start_time !== null && end_time !== null

    return {
      dateRange: (
        <Row>
          <InputDate
            field={`conditions.date[${index}]start_date`}
            placeholder={'Start'}
            change={updateSingle}
            value={start_date}
            minHeight={'1.5rem'}
          />
          <ArrowRightIcon size={16} />
          <InputDate
            field={`conditions.date[${index}]end_date`}
            placeholder={'End'}
            change={updateSingle}
            value={end_date}
            minHeight={'1.5rem'}
          />
        </Row>
      ),
      days: (
        <DayOfWeek
          value={day_of_week}
          field={`conditions.date[${index}]day_of_week`}
          change={(field, value) => updateSingle(field, value)}
        />
      ),
      timeRange: (
        <Row>
          <Tick active={timeActive} change={() => handleTimeToggle(index)} />
          {timeActive && (
            <Fragment>
              <InputTime
                area={'start'}
                field={`conditions.date[${index}]start_time`}
                value={start_time}
                placeholder={'00:00'}
                change={updateSingle}
                minHeight={'1.5rem'}
                width={'6rem'}
              />
              <InputTime
                area={'end'}
                field={`conditions.date[${index}]end_time`}
                value={end_time}
                placeholder={'23:59'}
                change={updateSingle}
                minHeight={'1.5rem'}
                width={'6rem'}
              />
            </Fragment>
          )}
        </Row>
      ),
    }
  })

  return (
    <ListTable
      area={area}
      columns={[
        {
          key: 'dateRange',
          label: 'Date Range',
        },
        {
          key: 'days',
          label: 'Days',
        },
        {
          key: 'timeRange',
          label: 'Time Range',
        },
      ]}
      data={rows}
      create={handleCreate}
      remove={handleRemove}
    />
  )
}

export default ConditionsTable