import useDependencies from '_shared/hooks/useDependencies'

import * as duration from 'duration-fns'

import useLocale from '_shared/hooks/useLocale'

import {
  getDeepLocale,
  getMaxCapacityFromVenue,
  getCapacityTypesFromLayout,
} from '_shared/libs/nestedDataHelpers'

import {
  translateDuration,
} from 'libs/duration'

const duration_shape = {
  hours: 0,
  minutes: 0,
}

const getDuration = (start, end) => {
  const distance = duration.between(start, end)

  const parsed = {
    ...duration_shape,
    ...distance,
  }

  return `${parsed.hours}h, ${parsed.minutes}m`
}

const useTemplate = (template) => {
  const {
    title,
    description,
    layout,
    segments,
  } = template

  const {
    default_locale,
  } = useLocale()

  const { data: venues } = useDependencies('venues')

  const { data: capacity_types } = useDependencies('capacity_types')

  const { data: locations } = useDependencies('locations', data => data.map(location => {
    return {
      label: location.locales ? getDeepLocale(location.locales, 'title', default_locale) : '',
      value: location.entity_id ? location.entity_id : '',
    }
  }))

  const meta = venues.find(venue => venue.entity_id === template.venue.entity_id)

  if (!meta) return null

  return {
    title,
    description,
    venue_title: getDeepLocale(meta.locales, 'title', default_locale),
    venue_information: getMaxCapacityFromVenue(meta),
    layout_title: getDeepLocale(layout.locales, 'title', default_locale),
    layout_information: getCapacityTypesFromLayout(layout.areas, capacity_types),
    information: getDeepLocale(template.locales, 'important_information', default_locale),
    segments: segments.map(segment => {
      const location = locations.find(location => location.value === segment.location.entity_id)

      return {
        time: translateDuration(segment.end),
        title: location ? location.label : 'undefined',
      }
    }),
  }
}

const useTemplateFunc = () => {
  const {
    default_locale,
  } = useLocale()

  const { data: venues } = useDependencies('venues')

  const { data: capacity_types } = useDependencies('capacity_types')

  const { data: locations } = useDependencies('locations', data => data.map(({ entity_id, locales }) => {
    return {
      label: getDeepLocale(locales, 'title', default_locale),
      value: entity_id,
    }
  }))

  const getDecorated = (template) => {
    const {
      title,
      description,
      layout,
      segments,
    } = template
    const meta = venues.find(venue => venue.entity_id === template.venue?.entity_id)

    // if (!meta) return null
    return {
      title,
      description,
      venue_title: meta ? getDeepLocale(meta.locales, 'title', default_locale) : '', 
      venue_information: meta ? getMaxCapacityFromVenue(meta) : '',
      layout_title: layout?.locales ? getDeepLocale(layout.locales, 'title', default_locale) : '',
      layout_information: layout?.areas ? getCapacityTypesFromLayout(layout.areas, capacity_types) : '',
      information: '', // getDeepLocale(template.locales, 'important_information', default_locale)
      segments: segments?.map(segment => {
        const location = locations.find(location => location.value === segment.location.entity_id)

        return {
          time: translateDuration(segment.end),
          title: location ? location.label : 'undefined',
        }
      }),
    }
  }

  return getDecorated
}

const useAPITemplateFunc = () => {
  const {
    default_locale,
  } = useLocale()

  const { data: capacity_types } = useDependencies('capacity_types')

  const { data: locations } = useDependencies('locations', data => data.map(({ entity_id, locales }) => {
    return {
      label: getDeepLocale(locales, 'title', default_locale),
      value: entity_id,
    }
  }))

  const getDecorated = (template) => {
    const {
      title,
      description,
      layout,
      segments,
    } = template

    return {
      title,
      description,
      layout_title: getDeepLocale(layout.locales, 'title', default_locale),
      layout_information: getCapacityTypesFromLayout(layout.areas, capacity_types),
      information: '', // getDeepLocale(template.locales, 'important_information', default_locale)
      segments: segments.map(segment => {
        const location = locations.find(location => location.value === segment.location.entity_id)

        return {
          time: translateDuration(segment.end),
          title: location ? location.label : 'undefined',
        }
      }),
    }
  }

  return getDecorated
}

const useAPIScheduleFunc = () => {
  const {
    default_locale,
  } = useLocale()

  const { data: capacity_types } = useDependencies('capacity_types')

  const { data: locations } = useDependencies('locations', data => data.map(({ entity_id, locales }) => {
    return {
      label: getDeepLocale(locales, 'title', default_locale),
      value: entity_id,
    }
  }))

  const getDecorated = (template) => {
    const {
      title,
      description,
      layout,
      segments,
    } = template

    return {
      title,
      description,
      layout_title: getDeepLocale(layout.locales, 'title', default_locale),
      layout_information: getCapacityTypesFromLayout(layout.areas, capacity_types),
      information: '', // getDeepLocale(template.locales, 'important_information', default_locale),
      segments: segments.map(segment => {
        const location = locations.find(location => location.value === segment.location.entity_id)

        const [
          hours,
          minutes,
        ] = segment.start.split('T')[1].split(':')

        return {
          time: `${hours}:${minutes}`,
          title: location ? location.label : 'undefined',
        }
      }),
      duration: getDuration(segments[0].start, segments[0].end),
    }
  }

  return getDecorated
}

const useScheduleFunc = () => {
  const {
    default_locale,
  } = useLocale()

  const { data: venues } = useDependencies('venues')

  const { data: capacity_types } = useDependencies('capacity_types')

  const { data: locations } = useDependencies('locations', data => data.map(({ entity_id, locales }) => {
    return {
      label: getDeepLocale(locales, 'title', default_locale),
      value: entity_id,
    }
  }))

  const getDecorated = (template) => {
    const {
      title,
      description,
      layout,
      segments,
    } = template

    const meta = venues.find(venue => venue.entity_id === template.venue.entity_id)

    if (!meta) return null

    return {
      title,
      description,
      venue_title: getDeepLocale(meta.locales, 'title', default_locale),
      venue_information: getMaxCapacityFromVenue(meta),
      layout_title: getDeepLocale(layout.locales, 'title', default_locale),
      layout_information: getCapacityTypesFromLayout(layout.areas, capacity_types),
      information: '', // getDeepLocale(template.locales, 'important_information', default_locale),
      segments: segments.map(segment => {
        const location = locations.find(location => location.value === segment.location.entity_id)

        const [
          hours,
          minutes,
        ] = segment.start.split('T')[1].split(':')

        return {
          time: `${hours}:${minutes}`,
          title: location ? location.label : 'undefined',
        }
      }),
      duration: getDuration(segments[0].start, segments[0].end),
    }
  }

  return getDecorated
}

export default useTemplate

export {
  useTemplateFunc,
  useScheduleFunc,
  useAPITemplateFunc,
  useAPIScheduleFunc,
}
