import React, { useCallback, useState } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

const decorateIn = (value, isNegative) => {
  if (isNegative && value < 0) {
    return Math.abs(value) * 100
  }
  return (value * 100)
}

const decorateOut = (value, isNegative) => {
  return isNegative && value > 0 ? (value / 100) * -1 : (value / 100)
}

const InputPercent = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.25rem',
  controlled = false,
  isNegative = true,
  ...style
}) => {
  const [
    internal,
    setInternal,
  ] = useState(decorateIn(value, isNegative))

  const [
    focused,
    setFocused,
  ] = useState(false)

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
    decorateOut(internal, isNegative) !== value && change(field, decorateOut(internal, isNegative))
  }

  const handleChange = useCallback((event) => {
    let value = isNegative ? event.target.value.replace(/[^0-9.]/g, '') : event.target.value.replace(/[^-0-9.]/g, '')

    setInternal(value)
  }, [isNegative])

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      {isNegative && (
        <InlineTextBox margin={'0 0 0 0.25rem'} color={'text_success'}>{'-'}</InlineTextBox>
      )}
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={focused ? internal : decorateIn(value, isNegative)}
        change={handleChange}
        margin={margin}
        disabled={disabled}
        focus={handleFocus}
        blur={handleBlur}
      />
      <InlineTextBox margin={'0 0.5rem 0 auto'} color={'text_success'}>{'%'}</InlineTextBox>
    </StatefulWrapper>
  )
}

const InputPercentWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputPercent {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputPercent {...props} />
  )
}

export default InputPercentWrapped

export {
  Raw,
}
