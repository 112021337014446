import {
  createStore,
  applyMiddleware,
} from 'redux'

import {
  composeWithDevTools,
} from 'redux-devtools-extension/developmentOnly'

import admin from '../reducers'

import {
  thunk,
} from './logger'

const store = createStore(
  admin,
  composeWithDevTools(
    applyMiddleware(thunk),
  ),
)

export default store
