import React, { useState } from 'react'
import {
  connect,
} from 'react-redux'

import {
  passwordChallenge,
} from 'actions/user'

import {
  createNotification,
} from 'actions/notifications'

import {
  withValidator,
  validate,
  formValid,
} from 'HOC/formValidator'

import Area from '_shared/components/layout/Area'

import { Raw as InputText } from '_shared/components/input/InputText'
import { Raw as InputPassword } from '_shared/components/input/InputPassword'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import Row from '_shared/components/layout/Row'

import ActionCTA from '_shared/components/action/ActionCTA'

import notEmptyOrNull from 'libs/notEmptyOrNull'

const passShape = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/

const validPassword = (record, value) => {
  return passShape.test(value)
}

const Validator = withValidator({
  username: notEmptyOrNull,
  password: validPassword,
})

const ValidTextField = validate(InputText)
const ValidPassField = validate(InputPassword)

const ValidSubmit = formValid((props) => {
  const {
    action,
    status,
  } = props

  return (
    <ActionCTA
      type={status ? 'important' : 'disabled'}
      label={'Set password'}
      change={status ? action : null}
    />
  )
})

const Challenge = ({ location, history, passwordChallenge, notify }) => {
  const [
    state,
    setState,
  ] = useState({
    username: '',
    password: '',
    ...location.state,
  })

  const update = (field, value) => {
    const record = { ...state }

    record[field] = value

    setState(record)
  }

  const submit = async () => {
    try {
      const data = {
        ...state,
        challenge: 'NEW_PASSWORD_REQUIRED',
      }

      await passwordChallenge(data)

      console.log('Successful login')
    } catch (e) {
      console.error(e)

      notify({
        type: 'error',
        message: e.message || 'You have submitted an invalid password.',
      })

      history.push('/login', {
        username: state.username,
      })
    }
  }

  return (
    <Validator
      record={state}
    >
      <form onSubmit={event => event.preventDefault()}>
        <Area
          areas={[
            'heading heading',
            'message .',
            'form .',
          ]}
          columns={4}
          rowgap={1}
        >
          <Heading level={'section'} title={'Update your password'} area={'heading'} />
          <TextBox noMargin color={'text_light'} area={'message'}>{'We have detected that you have a temporary password, please submit a new password to proceed.'}</TextBox>
          <Row type={'start'} area={'form'}>
            <ValidTextField
              field={'username'}
              placeholder={'Email'}
              value={state.username}
              change={update}
              requiredText={'User email required'}
              width={'15rem'}
              noFlex
            />
            <ValidPassField
              field={'password'}
              placeholder={'New Password'}
              value={state.password}
              change={update}
              requiredText={'Password must have one uppercase letter, one digit, one symbol and be 8 characters in length'}
              width={'15rem'}
              noFlex
            />
            <ValidSubmit
              action={submit}
            />
          </Row>
        </Area>
      </form>
    </Validator>
  )
}

Challenge.isPrivate = true

const mapDispatchToProps = (dispatch) => {
  return {
    passwordChallenge: (data) => dispatch(passwordChallenge(data)),
    notify: data => dispatch(createNotification(data)),
  }
}

export default connect(null, mapDispatchToProps)(Challenge)
