import React from 'react'

const SearchRefreshIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size * 0.875}
      viewBox='0 0 24 20.98'
    >
      <path
        fill={fill}
        d='M18,13.2h-1l-.32-.32A7.8,7.8,0,1,0,3,7.8H0l4.61,4.8,5-4.8H5.41a5.4,5.4,0,1,1,5.4,5.4,5.46,5.46,0,0,1-2.18-.46L6.85,14.52a7.79,7.79,0,0,0,9-.8l.32.32v1l6,6L24,19.2Z'
      />
    </svg>
  )
}

export default SearchRefreshIcon
