import React from 'react'

import IconButton from '_shared/components/element/IconButton'

import AddBoxIcon from '_shared/icons/AddBox'

const ActionAddBox = ({ label, change, collapsed, disabled }) => {
  return (
    <IconButton
      icon={AddBoxIcon}
      change={change}
      label={label}
      collapsed={collapsed}
      color={'button'}
      disabled={disabled}
    />
  )
}

export default ActionAddBox
