import React from 'react'

import IconButton from '_shared/components/element/IconButton'

import EditIcon from '_shared/icons/Edit'

const ActionEdit = ({ label, change, collapsed, disabled }) => {
  return (
    <IconButton
      icon={EditIcon}
      change={change}
      label={label}
      collapsed={collapsed}
      color={'button'}
      disabled={disabled}
    />
  )
}

export default ActionEdit
