import React from 'react'

const CalNext = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
    >
      <polygon
        fill={fill}
        points='24 0 24 32 8 16'
      />
    </svg>
  )
}

export default CalNext
