import React from 'react'

import styles from './cardselected.scss'

import TextBox from '_shared/components/layout/TextBox'
import TickIcon from '_shared/icons/Tick'

import Config from 'libs/Config'

import styleCombine from '_shared/libs/styleCombine'

const CardSelected = ({
  selected,
  children,
}) => {
  return (
    <div className={styles.layout}>
      {selected && (
        <div className={styles.icon}>
          <TickIcon fill={Config.theme.text_light} size={24} />
        </div>
      )}
      <div className={styleCombine(styles.inner, selected && styles.selected)}>
        {children}
        {selected && (
          <TextBox color={'text_success'} textAlign={'center'} strong uppercase>{'Selected'}</TextBox>
        )}
      </div>
    </div>
  )
}

export default CardSelected
