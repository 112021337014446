import React from 'react'

const ArrowRightIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
    >
      <path
        fill={fill}
        d='M16,0,13.18,2.82,24.34,14H0v4H24.34L13.18,29.18,16,32,32,16Z'
      />
    </svg>
  )
}

export default ArrowRightIcon
