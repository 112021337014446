import React from 'react'

import styles from './addbutton.scss'

import TextIconRow from 'atoms/TextIconRow'

import PlusIcon from 'icons/PlusNeg'

import colors from 'config/colors'

import styleCombine from 'libs/styleCombine'

const AddButton = (props) => {
  const {
    styling,
    action,
    label,
    reverse = true,
    disabled,
  } = props

  return (
    <TextIconRow
      styling={styleCombine(styles.addBtn, disabled && styles.disabled, styling)}
      icon={PlusIcon}
      iconSize={24}
      iconFill={disabled ? colors.color_text : colors.color_brand}
      text={label}
      textStyling={styles.label}
      reverse={reverse}
      onClick={disabled ? null : action}
    />
  )
}

export default AddButton
