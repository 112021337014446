import React from 'react'

const NextIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path
        fill={fill}
        d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'
      />
    </svg>
  )
}

export default NextIcon
