import React from 'react'
import {
  Provider,
} from 'react-redux'

import store from '../store'

import AppRoot from './AppRoot'

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

// TODO: Kill all of this as it is causing problems
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
    },
  },
})

const App = () => {
  return (
    <Provider
      store={store}
    >
      <QueryClientProvider client={queryClient}>
        <AppRoot />
      </QueryClientProvider>
    </Provider>
  )
}

export default App
