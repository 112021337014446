import React from 'react'

import IconButton from '_shared/components/element/IconButton'

import ArchiveIcon from '_shared/icons/Archive'

const ActionArchive = ({ label, change, collapsed, disabled }) => {
  return (
    <IconButton
      icon={ArchiveIcon}
      change={change}
      label={label}
      collapsed={collapsed}
      color={'text_error'}
      disabled={disabled}
    />
  )
}

export default ActionArchive
