import React from 'react'

import { StateTabbedHeader as TabbedHeader } from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'
import FormTickets from './FormTickets'
import FormTemplate from './FormTemplate'
import FormSchedule from './FormSchedule'

import useGetLocalValue from '_shared/hooks/useGetLocaleValue'

const options = [
  {
    label: 'Description',
    view: FormDescription,
    slug: 'description',
    status: 'DEFAULT',
  },
  {
    label: 'Ticket Types',
    view: FormTickets,
    slug: 'tickets',
    status: 'DEFAULT',
  },
  {
    label: 'Provisional departure templates',
    view: FormTemplate,
    slug: 'template',
    status: 'DEFAULT',
  },
  {
    label: 'Provisional schedule',
    view: FormSchedule,
    slug: 'schedule',
    status: 'DEFAULT',
  },
]

const APIRoute = ({
  cancel,
  current,
  valid,
  updateSingle,
}) => {
  options[0].status = valid.locales ? 'VALID' : 'INVALID'
  options[1].status = valid.ticket_types ? 'VALID' : 'INVALID'
  options[2].status = current.entity_id !== null ? 'DEFAULT' : 'DISABLED'
  options[3].status = current.entity_id !== null ? 'DEFAULT' : 'DISABLED'

  const title = useGetLocalValue(current, 'title')

  return (
    <TabbedHeader
      title={`Service${title !== null ? `: ${title}` : ''}`}
      options={options}
      cancel={cancel}
      passProps={{
        current,
        valid,
        updateSingle,
      }}
    />
  )
}

export default APIRoute
