import React, { Component, Fragment } from 'react'
import {
  connect,
} from 'react-redux'

import {
  editLocalisation,
} from 'actions/localisation'

import {
  createNotification,
} from 'actions/notifications'

import styles from './localisation.scss'

import Grid from 'containers/Grid'
import Container from 'containers/Container'
import InlineTextBox from 'containers/InlineTextBox'

import Heading from 'atoms/Heading'
import Label from 'atoms/Label'

import Select from 'atoms/Select'
import {
  CellRemove,
} from 'HOC/multiRowController/components'

import FloatingActions from 'molecules/FloatingActions'

import locale_codes from 'config/locale_codes.json'
import currencyToSymbolMap from 'currency-symbol-map/map'
import getSymbolFromCurrency from 'currency-symbol-map'

import Row from 'containers/Row'
import ListAddButton from 'molecules/ListAddButton'

import deepCopy from 'libs/deepCopy'
import styleCombine from 'libs/styleCombine'
import get_normal_language from 'libs/getNormalLanguage'

const shallow_is_diff = (A, B) => {
  let result = false

  const keys = Object.keys(A)

  for (let x = 0; x < keys.length; x++) {
    const key = keys[x]

    console.log('Checking equility', A[key], B[key])

    if (Array.isArray(A[key])) {
      result = A[key].length === B[key].length && A[key].every((item, index) => item === B[key][index])

      console.log('Array result', result)
    } else {
      result = A[key] === B[key]
    }

    if (!result) break
  }

  console.log('Return result', result)

  return result
}

const mapConfig = (currencies, locales) => {
  const fallback_locale = locales.find(locale => locale.is_default)
  const fallback_currency = currencies.find(currency => currency.is_default)

  const other_locales = locales
    .filter(locale => !locale.is_default)
    .map(locale => locale.id)

  const other_currency = currencies
    .filter(currency => !currency.is_default)
    .map(currency => currency.id)

  return {
    fallback_locale: fallback_locale ? fallback_locale.id : null,
    fallback_currency: fallback_currency ? fallback_currency.id : null,
    other_locales,
    other_currency,
  }
}

const validity = (fields) => {
  const {
    fallback_locale,
    fallback_currency,
    other_locales,
    other_currency,
  } = fields

  return fallback_locale !== null && fallback_currency !== null && other_locales.every(item => item !== null) && other_currency.every(item => item !== null)
}

class Localisation extends Component {
  constructor (props) {
    super(props)

    const {
      currencies,
      locales,
    } = props

    const initial = mapConfig(currencies, locales)

    this.state = {
      changed: false,
      diff: deepCopy(initial),
      fields: deepCopy(initial),
    }
  }

  update = (field, value) => {
    const fields = deepCopy(this.state.fields)

    if (field === 'fallback_locale') {
      const index = fields.other_locales.findIndex(item => item === value)

      if (index !== -1) {
        fields.other_locales.splice(index, 1)
      }
    }

    if (field === 'fallback_currency') {
      const index = fields.other_currency.findIndex(item => item === value)

      if (index !== -1) {
        fields.other_currency.splice(index, 1)
      }
    }

    fields[field] = value

    const changed = !shallow_is_diff(this.state.diff, fields) && validity(fields)

    this.setState({
      fields,
      changed,
    })
  }

  save = () => {
    const {
      fallback_locale,
      fallback_currency,
      other_locales,
      other_currency,
    } = this.state.fields

    const output = {
      currencies: [
        ...(fallback_currency !== null ? [{ id: fallback_currency, is_default: true }] : []),
        ...other_currency.map(item => {
          return {
            id: item,
            is_default: false,
          }
        }),
      ],
      locales: [
        ...(fallback_locale !== null ? [{ id: fallback_locale, is_default: true }] : []),
        ...other_locales.map(item => {
          return {
            id: item,
            is_default: false,
          }
        }),
      ],
    }

    this.props.saveConfig(output)
      .then(data => {
        const {
          currencies,
          locales,
        } = data

        const initial = mapConfig(currencies, locales)

        this.setState({
          changed: false,
          diff: deepCopy(initial),
          fields: deepCopy(initial),
        })

        this.props.notify({
          type: 'save',
          message: 'Localisation updated',
        })
      })
      .catch(() => {
        this.props.notify({
          type: 'error',
          message: 'Localisation update unsuccessful',
        })
      })
  }

  discard = () => {
    this.setState({
      changed: false,
      fields: deepCopy(this.state.diff),
    })
  }

  render () {
    const {
      fallback_locale,
      fallback_currency,
      other_locales,
      other_currency,
    } = this.state.fields

    const locale_options = locale_codes
      .map(item => {
        return {
          value: item.code,
          title: item.country,
        }
      })

    const currency_options = Object.keys(currencyToSymbolMap)
      .map(key => {
        return {
          value: key,
          title: `${key} - ${currencyToSymbolMap[key]}`,
        }
      })

    return (
      <Fragment>
        <Grid styling={styles.layout}>
          <Grid>
            <Heading
              title={'Language setup'}
              level={2}
            />
            <Label title={'Administrative language'} styling={styles.field}>
              <Select
                placeholder={'Select a language'}
                options={locale_options}
                value={fallback_locale}
                change={value => this.update('fallback_locale', value)}
                required
                requiredText={''}
                status={fallback_locale ? 'validated' : 'error'}
              />
            </Label>
            <Heading
              title={'Languages available to Sales Group and Services'}
              level={3}
            />
            <Label title={'Language'} styling={styleCombine(styles.field, fallback_locale === null && styles.disabled)}>
              <SelectMatrix
                fallback={fallback_locale !== null ? get_normal_language(fallback_locale) : null}
                data={other_locales}
                options={locale_options.filter(item => item.value !== fallback_locale)}
                addPlaceholder={'Add a language'}
                optionPlaceholder={'Select a language'}
                update={(data) => this.update('other_locales', data)}
              />
            </Label>
          </Grid>
          <Grid>
            <Heading
              title={'Currency setup'}
              level={2}
            />
            <Label title={'Administrative currency'} styling={styles.field}>
              <Select
                placeholder={'Select a currency'}
                options={currency_options}
                value={fallback_currency}
                change={value => this.update('fallback_currency', value)}
                required
                requiredText={''}
                status={fallback_currency ? 'validated' : 'error'}
              />
            </Label>
            <Heading
              title={'Currencies available to Sales Group and Services'}
              level={3}
            />
            <Label title={'Currency'} styling={styleCombine(styles.field, fallback_locale === null && styles.disabled)}>
              <SelectMatrix
                fallback={fallback_currency !== null ? `${fallback_currency} - ${getSymbolFromCurrency(fallback_currency)}` : null}
                data={other_currency}
                options={currency_options.filter(item => item.value !== fallback_currency)}
                addPlaceholder={'Add a currency'}
                optionPlaceholder={'Select a currency'}
                update={(data) => this.update('other_currency', data)}
              />
            </Label>
          </Grid>
        </Grid>
        <FloatingActions
          discard={this.discard}
          save={this.save}
          isMounted={this.state.changed}
        />
      </Fragment>
    )
  }
}

class SelectMatrix extends Component {
  update = (index, id) => {
    const data = [...this.props.data]

    data[index] = id

    this.props.update(data)
  }

  add = () => {
    const data = [...this.props.data]

    data.push(null)

    this.props.update(data)
  }

  remove = (index) => {
    const data = [...this.props.data]

    data.splice(index, 1)

    this.props.update(data)
  }

  render () {
    const {
      fallback,
      data,
      options,
      addPlaceholder,
      optionPlaceholder,
    } = this.props

    return (
      <Container styling={styles.selector}>
        {fallback !== null && (
          <Row type={'start'} styling={styles.row_start}>
            <InlineTextBox styling={styles.text}>{fallback}</InlineTextBox>
          </Row>
        )}
        {data.map((item, index) => {
          return (
            <Row type={'spaced'} key={index} styling={styles.row}>
              <Select
                options={options}
                value={item}
                change={value => this.update(index, value)}
                placeholder={optionPlaceholder}
                noSort
                styling={styles.select}
                required
                requiredText={''}
                status={item ? 'validated' : 'error'}
              />
              <CellRemove
                action={() => this.remove(index)}
              />
            </Row>
          )
        })}
        <ListAddButton
          label={addPlaceholder}
          action={this.add}
          centered
        />
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    currencies,
    locales,
  } = state.localisation

  return {
    currencies,
    locales,
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {
    notify: data => dispatch(createNotification(data)),
    saveConfig: (data) => dispatch(editLocalisation(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Localisation)
