import React, { Fragment, useState } from 'react'

import { morphism } from 'morphism'

import cloneDeep from 'lodash/cloneDeep'

import FormView from '_shared/components/layout/FormView'

import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'

import ListTable from '_shared/components/navigation/ListTable'

import ActionCTA from '_shared/components/action/ActionCTA'

import RuleConsumer from '../../PricingRules/form/Consumer'

import useLocale from '_shared/hooks/useLocale'

import useDependencies from '_shared/hooks/useDependencies'

import { getPricingRuleSchema } from '../../PricingRules/form/Context'

const columns = [
  {
    key: 'title',
    label: 'Title',
  },
]

const generator = (channels, markets, tickets) => {
  return tickets
    .reduce((acc, ticket) => {
      markets
        .forEach(market => {
          channels
            .forEach(channel => {
              acc.push({
                channel: channel,
                value_relative: 0,
                market: {
                  entity_id: market,
                },
                ticket_type: {
                  entity_id: ticket,
                },
              })
            })
        })
      return acc
    }, [])
}

const FormPricingRule = ({
  current,
  updateSingle,
}) => {
  const {
    locales,
  } = useLocale()

  const tickets = current.pricing
    .map(({ ticket_type_id }) => {
      return ticket_type_id
    })

  const { data: markets } = useDependencies('markets', data => {
    return data.map(market => {
      return market.entity_id
    })
  })

  const [
    sub,
    setSub,
  ] = useState(false)

  const [
    record,
    setRecord,
  ] = useState({})

  const [
    selected,
    setSelected,
  ] = useState(null)

  const openForm = (index = null) => {
    let reference
    if (index === null) {
      reference = morphism(
        getPricingRuleSchema(locales),
        {
          effects: generator([1, 2, 3, 4, 5, 6, 7], markets, tickets),
        },
      )
    } else {
      reference = current.pricing_rules[index]
    }
    setSelected(index)
    setRecord(reference)
    setSub(true)
  }

  const closeForm = () => {
    setSelected(null)
    setRecord({})

    setSub(false)
  }

  const saveLayout = (selected) => (payload) => {
    const state = cloneDeep(current.pricing_rules)

    if (selected === null) {
      state.push(payload)
    } else {
      state[selected] = payload
    }

    updateSingle('pricing_rules', state)
    setSub(false)
  }

  const pricing_rules = current.pricing_rules
    .map(({ title }, row) => {
      return {
        id: row,
        title: title,
      }
    })

  return (
    <Fragment>
      <RuleConsumer
        mount={sub}
        close={closeForm}
        activeMarkets={current.sales_limitations.markets}
        activeTickets={current.allowed_ticket_types.map(item => item.ticket_type.entity_id)}
        type={current.type}
        routes={current.routes}
        record={record}
        save={saveLayout(selected)}
      />
      <FormView>
        <Area
          areas={[
            'actions/2 . .',
            'list',
          ]}
          columns={4}
          rowgap={1.5}
        >
          <Row type={'start'} area={'actions'}>
            <ActionCTA label={'Add a New Rule'} change={() => openForm()} />
            <ActionCTA label={'Create From Existing Rule'} disabled />
          </Row>
          <ListTable columns={columns} data={pricing_rules} edit={openForm} area={'list'} />
        </Area>
      </FormView>
    </Fragment>
  )
}

export default FormPricingRule