import { useCallback } from 'react'

import {
  useQueryClient,
  useMutation,
} from 'react-query'

import {
  createNotification,
} from '_shared/components/notification/Toasts/actions'

import { useDispatch } from 'react-redux'

import product from 'api/product'

const handlers = {
  'products': product.publishProduct,
  'combos': product.publishCombo,
  'promos': product.publishPromo,
}

const usePublish = (store) => {
  const reduxDispatch = useDispatch()

  const queryClient = useQueryClient()

  const doPublishEntity = useMutation(
    (entity_id) => handlers[store](entity_id),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(store)
      },
    },
  )

  const notify = useCallback(payload => reduxDispatch(createNotification(payload)), [reduxDispatch])

  const setBusyState = useCallback(busy => {
    reduxDispatch({
      type: busy ? 'UI_BUSY' : 'UI_NOT_BUSY',
    })
  }, [reduxDispatch])

  const publishEntity = async (entity_id) => {
    console.log('PUBLISHING', entity_id, store)
    try {
      await doPublishEntity.mutateAsync(entity_id)

      await queryClient.refetchQueries([store])

      notify({
        type: 'new',
        message: 'Publication success',
      })

      setBusyState(false)

      return doPublishEntity.data
    } catch (error) {
      //
    }
  }

  return publishEntity
}

export default usePublish
