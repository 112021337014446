import React from 'react'

import IconButton from '_shared/components/element/IconButton'

import CancelIcon from '_shared/icons/CancelRound'

const ActionRemove = ({ label, change, collapsed, disabled, ...style }) => {
  return (
    <IconButton
      icon={CancelIcon}
      change={change}
      label={label}
      collapsed={collapsed}
      color={'button_stop'}
      disabled={disabled}
      {...style}
    />
  )
}

export default ActionRemove
