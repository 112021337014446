import React from 'react'

import ChunkedTable from 'molecules/ChunkedTable'

const UserAllList = (props) => {
  const {
    data,
    create,
    edit,
    permissions,
  } = props

  return (
    <ChunkedTable
      label={'Add a new user'}
      data={data}
      spec={[
        {
          title: 'Title',
          key: 'name',
          filter: true,
        },
        {
          title: 'Department',
          key: 'department',
          filter: true,
        },
        {
          title: 'Enabled',
          key: 'enabled',
          filter: false,
        },
        {
          title: 'Access',
          key: 'access',
          filter: false,
        },
        {
          title: 'email',
          key: 'email',
          filter: true,
        },
      ]}
      edit={edit}
      create={create}
      permissions={permissions}
    />
  )
}

export default UserAllList
