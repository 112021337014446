import React from 'react'

import styles from './controlledfull.scss'

import Column from 'containers/Column'
import TextBox from 'containers/TextBox'

import LockedBadge from 'atoms/LockedBadge'

import ActionCTA from '_shared/components/action/ActionCTA'

import styleCombine from '_shared/libs/styleCombine'

const Controls = ({
  saveAction,
  cancelAction,
  permissions,
  disabled,
  isNew,
  isMounted,
}) => {
  const permissionSave = isNew ? permissions.includes('create') : permissions.includes('update')

  const readOnly = permissions.length === 1 && permissions[0] === 'read'

  const hasLock = saveAction && !permissionSave

  return (
    <div className={styleCombine(styles.controls, isMounted ? styles.in : styles.out)}>
      {hasLock && (
        <Locked readOnly={readOnly} />
      )}
      {saveAction && permissionSave && disabled && (
        <TextBox styling={styles.required} noMargin>{'* Content required'}</TextBox>
      )}
      <ActionCTA
        label={'Discard Changes'}
        type={'unimportant'}
        change={cancelAction}
      />
      {saveAction && (
        <ActionCTA
          label={'Save Changes'}
          type={disabled ? 'disabled' : 'important'}
          change={!disabled ? saveAction : null}
          locked={!permissionSave}
        />
      )}
    </div>
  )
}

const Locked = (props) => {
  const {
    readOnly,
  } = props

  const message = readOnly ? 'Your user profile has viewing privileges only' : 'Your user profile has limited privileges'

  return (
    <Column>
      <LockedBadge />
      <TextBox styling={styles.lockMessage}>{message}</TextBox>
    </Column>
  )
}

export default Controls
