import React, { Component } from 'react'

const delayMount = (Wrapped, duration = 500) => {
  return class extends Component {
    constructor (props) {
      super(props)

      this.state = {
        shouldRender: props.isMounted,
      }
    }

    componentDidUpdate (prevProps) {
      if (prevProps.isMounted && !this.props.isMounted) {
        setTimeout(
          () => this.unMount(),
          duration,
        )
      } else if (!prevProps.isMounted && this.props.isMounted) {
        this.setState({ shouldRender: true })
      }
    }

    unMount () {
      this.setState({ shouldRender: false })

      this.props.unMountCallBack && this.props.unMountCallBack()
    }

    render () {
      return this.state.shouldRender ? <Wrapped {...this.props} /> : null
    }
  }
}

export default delayMount
