export default {
  'ticket_type:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'ticket_type:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'ticket_type:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'ticket_type:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'venue:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'venue:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'venue:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'venue:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'location:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'location:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'location:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'location:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'route:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'route:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'route:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'route:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'ancillary_item:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'ancillary_item:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'ancillary_item:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'ancillary_item:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'menu:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'menu:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'menu:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'menu:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'service:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'service:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'service:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'service:delete': {
    admin: false,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'season:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'season:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'season:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'season:publish': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'season:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'pricing:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'pricing:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'pricing:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'pricing:publish': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'pricing:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'instance:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'instance:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'instance:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'instance:capping': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'instance:capacity': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'instance:freeze': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'instance:cancel': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'pass:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'pass:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'pass:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'pass:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'promotion:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'promotion:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'promotion:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'promotion:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'ancillary_ticket:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'ancillary_ticket:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'ancillary_ticket:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'ancillary_ticket:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'combo:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'combo:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'combo:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'combo:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'trade_partner:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'trade_partner:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'trade_partner:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'trade_partner:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'user:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: true,
  },
  'user:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'user:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'user:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'system_setting:read': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'system_setting:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'system_setting:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'system_setting:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'reporting:read': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: true,
  },
  'vehicle:read': {
    admin: true,
    owner: true,
    viewer: true,
    editor: true,
    billing: false,
  },
  'vehicle:create': {
    admin: true,
    owner: true,
    viewer: false,
    editor: true,
    billing: false,
  },
  'vehicle:update': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
  'vehicle:delete': {
    admin: true,
    owner: true,
    viewer: false,
    editor: false,
    billing: false,
  },
}
