import React from 'react'

const Logo = () => {
  return (
    <svg
      viewBox='0 0 268.064 50.018'
    >
      <path
        fill='rgb(255,255,255)'
        d='M41.574,25.009a11.811,11.811,0,0,1,0-16.672L33.26,0,0,33.345,16.63,50.017l16.63-5.18,16.63,5.18L66.52,33.344,58.2,25.009a11.738,11.738,0,0,1-16.63,0Z'
      />
      <path
        fill={'rgb(125,226,202)'}
        d='M0,0,11.774.015l.015,11.774L.015,11.774Z'
        transform='translate(33.26 50.016) rotate(-135)'
      />
      <path
        fill={'rgb(61,191,163)'}
        d='M51.634,54.149l8.315,8.336H43.32Z'
        transform='translate(-10.06 -12.469)'
      />
      <path
        fill={'rgb(61,191,163)'}
        d='M29.975,54.149l8.315,8.336H21.66Z'
        transform='translate(-5.03 -12.469)'
      />

      <g
        opacity='0.95'
        transform='translate(82.553 12.531)'
      >
        <path
          fill={'rgb(255,255,255)'}
          d='M107.524,21.365l5.127-.828v5.347h6.16v4.278h-6.162v6.383a5.085,5.085,0,0,0,.568,2.588,2.433,2.433,0,0,0,2.288.966,9.788,9.788,0,0,0,1.7-.154,8.735,8.735,0,0,0,1.6-.431l.722,4a14.484,14.484,0,0,1-2.065.656,12,12,0,0,1-2.787.276,9.154,9.154,0,0,1-3.476-.57,5.452,5.452,0,0,1-2.2-1.587,5.909,5.909,0,0,1-1.152-2.463,14.56,14.56,0,0,1-.327-3.208Z'
          transform='translate(-107.522 -17.259)'
        />
        <path
          fill={'rgb(255,255,255)'}
          d='M131.829,19.764a2.825,2.825,0,0,1-.911,2.225,3.258,3.258,0,0,1-4.3,0,2.823,2.823,0,0,1-.911-2.225,2.825,2.825,0,0,1,.912-2.225,3.259,3.259,0,0,1,4.3,0A2.828,2.828,0,0,1,131.829,19.764Zm-.481,23.391h-5.127V25.008h5.127Z'
          transform='translate(-111.744 -16.383)'
        />
        <path
          fill={'rgb(255,255,255)'}
          d='M136.873,36.413a10.716,10.716,0,0,1,.636-3.709,8.672,8.672,0,0,1,4.766-5.089,9.636,9.636,0,0,1,3.923-.759,12.632,12.632,0,0,1,2.65.259,12.892,12.892,0,0,1,2.34.742l-1.066,4.106a12.963,12.963,0,0,0-1.583-.483,8.257,8.257,0,0,0-1.927-.207,4.057,4.057,0,0,0-3.39,1.415,5.833,5.833,0,0,0-1.119,3.726,6.042,6.042,0,0,0,1.05,3.795q1.05,1.345,3.665,1.345a12.5,12.5,0,0,0,2-.172,8.452,8.452,0,0,0,1.962-.552l.722,4.209a12.017,12.017,0,0,1-2.237.656,14.739,14.739,0,0,1-2.96.276,11.113,11.113,0,0,1-4.267-.742,8.029,8.029,0,0,1-2.943-2.018,7.867,7.867,0,0,1-1.689-3.019,12.827,12.827,0,0,1-.53-3.779Z'
          transform='translate(-114.338 -18.714)'
        />
        <path
          fill={'rgb(255,255,255)'}
          d='M164.316,31.421q.756-.824,1.564-1.715t1.565-1.77q.756-.875,1.427-1.663t1.152-1.372h6.1q-1.824,2.1-3.562,4.01t-3.8,3.942a23.757,23.757,0,0,1,2.134,2.232q1.1,1.3,2.134,2.679t1.893,2.764q.86,1.386,1.445,2.523h-5.9q-.55-.9-1.254-1.988t-1.494-2.178q-.79-1.089-1.665-2.092a14.041,14.041,0,0,0-1.734-1.693v7.953H159.19V17.107l5.127-.828Z'
          transform='translate(-119.521 -16.279)'
        />
        <path fill={'rgb(255,255,255)'}
          d='M184.074,27.98a28.65,28.65,0,0,1,3.372-.707,27.688,27.688,0,0,1,4.336-.328,10.547,10.547,0,0,1,3.839.6,5.771,5.771,0,0,1,2.426,1.708,6.674,6.674,0,0,1,1.274,2.622,14.011,14.011,0,0,1,.378,3.381V45.507h-5.127V35.88a6.948,6.948,0,0,0-.654-3.519,2.665,2.665,0,0,0-2.444-1.035q-.551,0-1.17.052t-1.1.121V45.508h-5.127Z'
          transform='translate(-125.3 -18.734)'
        />
        <path fill={'rgb(255,255,255)'}
          d='M225.874,36.378a11.821,11.821,0,0,1-.62,3.916,8.509,8.509,0,0,1-1.79,3.036,8.051,8.051,0,0,1-2.8,1.949,9.346,9.346,0,0,1-3.665.693,9.248,9.248,0,0,1-3.631-.693,8.04,8.04,0,0,1-2.8-1.949,8.919,8.919,0,0,1-1.823-3.036,11.256,11.256,0,0,1-.654-3.916,10.857,10.857,0,0,1,.671-3.9,8.838,8.838,0,0,1,1.858-3,8.346,8.346,0,0,1,2.822-1.932,9.066,9.066,0,0,1,3.562-.693,9.159,9.159,0,0,1,3.6.693,8.174,8.174,0,0,1,2.8,1.932,8.78,8.78,0,0,1,1.823,3A11.125,11.125,0,0,1,225.874,36.378Zm-5.23,0a6.479,6.479,0,0,0-.947-3.743,3.092,3.092,0,0,0-2.7-1.363,3.135,3.135,0,0,0-2.719,1.363,6.387,6.387,0,0,0-.964,3.743,6.556,6.556,0,0,0,.964,3.778,3.111,3.111,0,0,0,2.719,1.4,3.069,3.069,0,0,0,2.7-1.4A6.646,6.646,0,0,0,220.644,36.378Z'
          transform='translate(-130.875 -18.714)'
        />
        <path fill={'rgb(255,255,255)'}
          d='M238.864,45.632q-1.761-3.449-3.576-8.073t-3.437-10.074h5.437q.346,1.345.813,2.916t.967,3.174q.5,1.6,1,3.122t.986,2.76q.448-1.242.967-2.76t1.019-3.122q.5-1.6.967-3.174t.812-2.916h5.3q-1.623,5.452-3.434,10.074t-3.572,8.073Z'
          transform='translate(-136.395 -18.859)'
        />
        <path fill={'rgb(255,255,255)'}
          d='M263.961,26.856a11.845,11.845,0,0,1,3.785.517,5.885,5.885,0,0,1,2.426,1.483,5.47,5.47,0,0,1,1.291,2.346,11.6,11.6,0,0,1,.378,3.07v10.7q-1.1.242-3.063.57a29.374,29.374,0,0,1-4.749.328,15.012,15.012,0,0,1-3.183-.308,6.893,6.893,0,0,1-2.457-1.018,4.648,4.648,0,0,1-1.583-1.847,6.381,6.381,0,0,1-.551-2.794,5.3,5.3,0,0,1,.637-2.694,4.906,4.906,0,0,1,1.7-1.76,7.691,7.691,0,0,1,2.443-.949A13.7,13.7,0,0,1,263.9,34.2a16.277,16.277,0,0,1,1.773.086,7.938,7.938,0,0,1,1.256.225v-.482a2.852,2.852,0,0,0-.792-2.1,3.832,3.832,0,0,0-2.753-.794,17.532,17.532,0,0,0-2.581.19,11.079,11.079,0,0,0-2.2.535l-.654-4.14q.447-.138,1.119-.293t1.459-.276q.792-.121,1.669-.207T263.961,26.856Zm.413,15.042q.757,0,1.446-.035a9.82,9.82,0,0,0,1.1-.1v-3.9q-.307-.069-.929-.138a10.369,10.369,0,0,0-1.136-.069,10.191,10.191,0,0,0-1.359.086,3.557,3.557,0,0,0-1.119.328,1.921,1.921,0,0,0-.757.656,1.825,1.825,0,0,0-.276,1.035,1.763,1.763,0,0,0,.809,1.673,4.455,4.455,0,0,0,2.219.466Z'
          transform='translate(-142.061 -18.714)'
        />
        <path fill={'rgb(255,255,255)'}
          d='M281.174,21.365l5.127-.828v5.347h6.16v4.278H286.3v6.383a5.085,5.085,0,0,0,.568,2.588,2.433,2.433,0,0,0,2.288.966,9.787,9.787,0,0,0,1.7-.154,8.734,8.734,0,0,0,1.6-.431l.722,4a14.484,14.484,0,0,1-2.065.656,12,12,0,0,1-2.787.276,9.154,9.154,0,0,1-3.476-.57,5.452,5.452,0,0,1-2.2-1.587,5.909,5.909,0,0,1-1.152-2.463,14.56,14.56,0,0,1-.327-3.208Z'
          transform='translate(-147.848 -17.259)'
        />
        <path
          fill={'rgb(255,255,255)'}
          d='M298.513,36.549a11.122,11.122,0,0,1,.739-4.227A9.063,9.063,0,0,1,301.2,29.3a8.083,8.083,0,0,1,2.77-1.828,8.657,8.657,0,0,1,3.218-.621,7.988,7.988,0,0,1,6.092,2.363q2.236,2.364,2.236,6.951,0,.449-.035.983t-.068.949H303.779a3.417,3.417,0,0,0,1.479,2.519,5.935,5.935,0,0,0,3.51.931,14.781,14.781,0,0,0,2.77-.259,10.429,10.429,0,0,0,2.22-.638l.691,4.174a7.082,7.082,0,0,1-1.1.414,14.436,14.436,0,0,1-1.532.363q-.845.154-1.807.259a18,18,0,0,1-1.927.1,11.382,11.382,0,0,1-4.25-.724,8.27,8.27,0,0,1-2.994-1.984,7.877,7.877,0,0,1-1.755-2.984,11.857,11.857,0,0,1-.569-3.726Zm12.043-1.964a5.35,5.35,0,0,0-.224-1.276,3.209,3.209,0,0,0-.585-1.1,3.127,3.127,0,0,0-1-.794,3.245,3.245,0,0,0-1.5-.308,3.439,3.439,0,0,0-1.48.293,3.061,3.061,0,0,0-1.032.777,3.478,3.478,0,0,0-.637,1.122,7.4,7.4,0,0,0-.327,1.294Z'
          transform='translate(-151.875 -18.712)'
        />
      </g>
      <g
        opacity='0.95'
        transform='translate(249.288 14.693)'
      >
        <path
          fill={'rgb(255,255,255)'}
          d='M332.313,19.091v1.671h-2.869v7.451h-1.886V20.762H324.69V19.091Zm10.383,0q.218,1.4.384,3.575t.384,5.547h-1.913q-.028-.684-.055-1.462t-.068-1.562q-.044-.781-.1-1.55t-.109-1.425q-.3.52-.656,1.219t-.7,1.386q-.342.685-.656,1.288t-.478.959H337.4q-.164-.356-.478-.959t-.656-1.288q-.342-.684-.71-1.386l-.642-1.219q-.055.657-.109,1.425t-.1,1.548q-.041.781-.068,1.562t-.055,1.462H332.7q.218-3.369.384-5.547t.384-3.575h1.8q.218.466.547,1.123l.708,1.426L337.3,23.2q.395.792.779,1.534.355-.74.768-1.534t.792-1.562l.71-1.425q.329-.657.547-1.123Z'
          transform='translate(-324.69 -19.089)'
        />
        <path
          fill={'rgb(255,255,255)'}
          d='M332.313,19.091v1.671h-2.869v7.451h-1.886V20.762H324.69V19.091Zm10.383,0q.218,1.4.384,3.575t.384,5.547h-1.913q-.028-.684-.055-1.462t-.068-1.562q-.044-.781-.1-1.55t-.109-1.425q-.3.52-.656,1.219t-.7,1.386q-.342.685-.656,1.288t-.478.959H337.4q-.164-.356-.478-.959t-.656-1.288q-.342-.684-.71-1.386l-.642-1.219q-.055.657-.109,1.425t-.1,1.548q-.041.781-.068,1.562t-.055,1.462H332.7q.218-3.369.384-5.547t.384-3.575h1.8q.218.466.547,1.123l.708,1.426L337.3,23.2q.395.792.779,1.534.355-.74.768-1.534t.792-1.562l.71-1.425q.329-.657.547-1.123Z'
          transform='translate(-324.69 -19.089)'
        />
      </g>
    </svg>
  )
}

export default Logo
