import React from 'react'

import styles from './daybar.scss'

import Row from 'containers/Row'
import Column from 'containers/Column'

import defs from 'config/dates'

const DayBar = (props) => {
  const {
    startsOnMonday,
    styling,
  } = props

  const days = startsOnMonday ? defs.daysAlt : defs.days

  return (
    <Row
      type={'start'}
      styling={[styles.layout, styling].join(' ')}
    >
      {days.map((day, index) => {
        return (
          <Column
            key={index}
            styling={styles.day}
          >
            {day}
          </Column>
        )
      })}
    </Row>
  )
}

export default DayBar
