import storage from 'libs/asyncStorage'

import {
  setBusyState,
} from 'libs/actionCreator'

import loginApi from 'api/login'

import {
  getProfile,
} from './user'

import {
  refreshToken,
  setTokens,
} from './token'

import coreData from '../coreData'

let TIMER = null

const logout = (data) => (dispatch, getState) => {
  clearTimeout(TIMER)

  storage.clear()
    .then(() => {
      dispatch({
        type: 'APP_CLEAR',
      })
    })
}

const setRefreshTimer = () => (dispatch, getState) => {
  storage.get('lastReset')
    .then(lastReset => {
      const now = Date.now()

      const timer = lastReset - now

      if (TIMER) clearTimeout(TIMER)

      TIMER = setTimeout(() => {
        dispatch(refreshToken())
          .then(response => {
            dispatch(setTokens(response))
              .then(response => {
                dispatch(setRefreshTimer())
              })
          })
          .catch(error => {
            console.log('Refresh timer error', error)

            dispatch({
              type: 'UI_REQUEST_AUTH',
            })
          })
      }, timer)
    })
}

const passwordChallenge = (data) => (dispatch, getState) => {
  return loginApi.challenge(data)
    .then(response => {
      if (response.step === 'LOGGED_IN') {
        return dispatch(loginSuccess(response))
      } else {
        throw response
      }
    })
    .then(response => {
      dispatch(setRefreshTimer())

      return response
    })
    .catch(error => {
      if (error.data && error.data.message) {
        throw error.data
      } else {
        throw Error({ type: 'UNKNOWN_ISSUE', message: 'An unknown error occurred' })
      }
    })
}

const forgotStart = (data) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return loginApi.forgot(data)
    .then(response => {
      dispatch(setBusyState(false))

      return response
    })
    .catch(error => {
      dispatch(setBusyState(false))

      if (error.data && error.data.message) {
        throw error.data.message
      } else {
        throw Error({ type: 'UNKNOWN_ISSUE', message: 'An unknown error occurred' })
      }
    })
}

const forgotConfirm = (data) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return loginApi.forgotConfirm(data)
    .then(response => {
      dispatch(setBusyState(false))

      return {
        type: 'RESET_SUCCESS',
        message: 'Password reset successful',
      }
    })
    .catch(error => {
      dispatch(setBusyState(false))

      if (error.data && error.data.type) {
        throw error.data
      } else {
        throw Error({ type: 'UNKNOWN_ISSUE', message: 'An unknown error occurred' })
      }
    })
}

const loginSuccess = (data) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return dispatch(setTokens(data))
    .then(response => {
      return dispatch(getProfile())
        .then(() => {
          dispatch({
            type: 'USER_LOGIN',
          })

          dispatch(setBusyState(false))

          dispatch(coreData())

          return response
        })
    })
    .catch(error => {
      dispatch(setBusyState(false))

      throw error
    })
}

const login = (data) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  const {
    username,
  } = data

  return loginApi.login(data)
    .then(response => {
      dispatch(setBusyState(false))

      if (response.step === 'FORCE_CHANGE_PASSWORD') {
        return {
          type: 'FORCE_CHANGE_PASSWORD',
          username,
        }
      }

      if (response.step === 'CHALLENGE' && response.challenge === 'NEW_PASSWORD_REQUIRED') {
        return {
          type: 'CHALLENGE',
          username,
          session: response.session,
        }
      } else {
        return dispatch(loginSuccess(response))
          .then(() => {
            dispatch(setRefreshTimer())

            return 'LOGGED_IN'
          })
      }
    })
    .catch(err => {
      console.log('Login error', err)

      dispatch(setBusyState(false))

      throw err
    })
}

const reAuth = (data) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return loginApi.login(data)
    .then(response => {
      dispatch(setBusyState(false))

      return dispatch(setTokens(response))
        .then(() => {
          dispatch(setRefreshTimer())

          dispatch({
            type: 'UI_REQUEST_AUTH',
          })
        })
    })
    .catch(error => {
      console.log('Unable to reauth', error)

      dispatch(logout())
    })
}

const checkTokenAndLogin = () => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return storage.get('lastReset')
    .then(reset => {
      if (reset === null) {
        throw new Error('Not logged in')
      }

      const now = Date.now()

      if (now < reset) {
        return dispatch(getProfile())
          .then(() => {
            dispatch(setRefreshTimer())

            return true
          })
      } else {
        return dispatch(refreshToken())
          .then(response => {
            return dispatch(loginSuccess(response))
              .then(() => {
                dispatch(setRefreshTimer())

                return 'LOGGED_IN'
              })
          })
          .catch(error => {
            console.log('Token refresh failed', error)

            throw error
          })
      }
    })
    .catch(error => {
      console.log('Bailing out of token test', error)

      dispatch(setBusyState(false))

      dispatch(logout())

      throw error
    })
}

export {
  login,
  logout,
  reAuth,
  passwordChallenge,
  forgotStart,
  forgotConfirm,
  checkTokenAndLogin,
}
