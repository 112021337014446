import React, { Fragment, useState } from 'react'

import { morphism } from 'morphism'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'
import Container from '_shared/components/layout/Container'

import InputCheckBox from '_shared/components/input/InputCheckBox'
import ActionCTA from '_shared/components/action/ActionCTA'
import ActionEdit from '_shared/components/action/ActionEdit'
import ActionArchive from '_shared/components/action/ActionArchive'
import ActionAddBox from '_shared/components/action/ActionAddBox'
import RouteTemplateConsumer from '../../ScheduleTemplate/form/RouteConsumer'
import EventTemplateConsumer from '../../ScheduleTemplate/form/EventConsumer'

import TemplateHolder from './TemplateHolder'

import LoadIndicator from '_shared/components/notification/LoadIndicator'

import product from 'api/product'

import {
  useQuery,
} from 'react-query'

import { route_segment } from '../../ScheduleTemplate/form/Context'

import { useTemplateFunc, useAPITemplateFunc } from '_shared/hooks/useTemplate'

const fetchTemplates = (service, operator_type) => {
  return product.listInstanceTemplates()
    .then(response => {
      let result = response.filter(item => item.service.entity_id === service)
      if (operator_type !== 'scandlines') {
        result = result.filter(item => item.venue !== null)
      }

      return result
    })
}

const FormTemplate = ({
  current,
  updateSingle,
}) => {
  const decorateTemplate = useTemplateFunc()
  const apiDecorateTemplate = useAPITemplateFunc()
  const [showArchived, setShowArchived] = useState(false)

  const template_list = useQuery(['instance_templates', current.entity_id], () => fetchTemplates(current.entity_id, current.operator_type))

  let templates = []

  if (template_list.data) {
    templates = template_list.data
  }

  const [
    sub,
    setSub,
  ] = useState(false)

  const [
    record,
    setRecord,
  ] = useState(null)

  const openForm = (record = null) => {
    setRecord(record)

    setSub(true)
  }

  const closeForm = () => {
    setRecord(null)

    setSub(false)
  }

  const TemplateConsumer = current.type === 'event' ? EventTemplateConsumer : RouteTemplateConsumer

  const initial = {
    type: current.type,
    service: {
      entity_id: current.entity_id,
    },
    ...(current.type === 'event' && { segments: [ morphism(route_segment, {}) ]}),
  }

  return (
    <Fragment>
      <TemplateConsumer
        mount={sub}
        close={closeForm}
        record={record}
        initial={initial}
        type={current.type}
        operator_type={current.operator_type}
      />
      <FormView>
        <Area
          areas={[
            'layout',
            'templates',
          ]}
          rowgap={1.5}
        >
          <Row type={'spaced'} area={'layout'}>
            <Row>
              <ActionCTA change={() => openForm()} label={'Add Template'} />
              <ActionCTA change={() => { }} label={'Create From Another Template'} type={'disabled'} disabled />
            </Row>
            <InputCheckBox
              title={'Show archived'}
              field={'archived'}
              value={showArchived}
              change={(filed, value) => setShowArchived(value)}
            />
          </Row>
          <Row area={'templates'}>
            {template_list.isLoading && (
              <LoadIndicator />
            )}
            {!template_list.isLoading && (
              <Container>
                {templates.map((template, index) => {
                  const {
                    entity_id,
                  } = template

                  return (
                    <TemplateHolder
                      key={index}
                      type={current.type}
                      decorate={current.operator_type === 'scandlines' ? apiDecorateTemplate : decorateTemplate}
                      template={template}
                      actions={() => {
                        return (
                          <TemplateActions
                            edit={() => openForm(entity_id)}
                            create={() => { }}
                            archive={() => { }}
                          />
                        )
                      }}
                    />
                  )
                })}
              </Container>
            )}
          </Row>
        </Area>
      </FormView>
    </Fragment>
  )
}

const TemplateActions = ({
  edit,
  create,
  archive,
}) => {
  return (
    <Fragment>
      <ActionArchive label={'Archive Template'} change={archive} disabled />
      <ActionEdit label={'Edit Template'} change={edit} />
      <ActionAddBox label={'Create Templates'} change={create} disabled />
    </Fragment>
  )
}

export default FormTemplate
