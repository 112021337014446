import React from 'react'

import EventTemplateCard from './EventTemplateCard'
import RouteTemplateCard from './RouteTemplateCard'

const TemplateHolder = ({
  type,
  template,
  decorate,
  actions,
}) => {
  const value = decorate(template)

  if (!value) return null // YUCK make a proper waiting component for the form

  const TemplateCard = type === 'event' ? EventTemplateCard : RouteTemplateCard

  return (
    <TemplateCard
      value={value}
      actions={actions}
    />
  )
}

export default TemplateHolder
