import React, { useState } from 'react'

import useNavigationView from '_shared/hooks/useNavigationView'

import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'
import Heading from '_shared/components/layout/Heading'
import RoundedTab from '_shared/components/element/RoundedTab'
import ActionBack from '_shared/components/action/ActionBack'

import Row from '_shared/components/layout/Row'

import ShadowWrapper from '_shared/components/layout/ShadowWrapper'

/*
  options = [
    {
      label: 'Description',
      status: 'VALID',
      view: View
      slug: 'description'
    }
  ]
*/

const TabbedHeader = ({ title, options = [], cancel, status, passProps, ...style }) => {
  const {
    selected,
    changeView,
  } = useNavigationView(options)

  const current = options.find(option => option.slug === selected)

  const View = current.view

  return (
    <Area
      areas={[
        'nav',
        'content',
      ]}
      columns={2}
    >
      <Raw
        area={'nav'}
        title={title}
        options={options}
        selected={selected}
        status={status}
        action={changeView}
      />
      <View area={'content'} {...passProps} />
    </Area>
  )
}

const TabbedGroup = ({ options, action, selected, ...style }) => {
  return (
    <Row {...style}>
      {options.map((option, index) => {
        const {
          label,
          status,
          slug,
        } = option

        return (
          <RoundedTab
            key={slug}
            label={label}
            active={selected === slug}
            status={status}
            action={() => action(slug)}
          />
        )
      })}
    </Row>
  )
}

const Raw = ({
  title,
  options = [],
  selected,
  action,
  cancel,
  status,
  ...style
}) => {
  return (
    <ShadowWrapper {...style} background={'background_module'}>
      <Area
        areas={status ? [
          'back title status .',
          'back tabs/2 .',
        ] : [
          'back title/2 .',
          'back tabs/2 .',
        ]}
        colgap={1.5}
        rowgap={1}
        columns={['2rem', '1fr', '1fr', '2rem']}
        rows={['4rem', '1fr']}
      >
        {cancel && (
          <ActionBack area={'back'} change={cancel} />
        )}
        <Heading level={1} title={title} area={'title'} gridAlign={'center'} />
        {status && (
          <Row area={'status'} gridAlign={'center'}>{status && status()}</Row>
        )}
        <TabbedGroup
          area={'tabs'}
          options={options}
          action={action}
          selected={selected}
        />
      </Area>
    </ShadowWrapper>
  )
}

const StateTabbedHeader = ({
  title,
  options = [],
  cancel,
  status,
  passProps,
  ...style
}) => {
  const [
    selected,
    setSelected,
  ] = useState(options[0].slug)

  const changeView = (slug) => {
    setSelected(slug)
  }

  const current = options.find(option => option.slug === selected)

  const View = current.view

  return (
    <Container>
      <Raw
        area={'nav'}
        title={title}
        options={options}
        selected={selected}
        status={status}
        action={changeView}
        cancel={cancel}
        rawStyle={{ position: 'sticky', top: 0, zIndex: 1 }}
      />
      <View area={'content'} {...passProps} />
    </Container>
  )
}

export default TabbedHeader

export {
  Raw,
  TabbedGroup,
  StateTabbedHeader,
}
