import React, { useCallback, useState } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'

import formatCurrency, { smallToLarge, largeToSmall } from '_shared/libs/formatCurrency'

import useLocale from '_shared/hooks/useLocale'

const mask = (event) => {
  let value = event.target.value.replace(/[^0-9.]/g, '')

  let split = value.split('.')

  if (split.length > 2) {
    split = split.slice(0, 2)
  }

  if (split.length > 1) {
    if (split[1].length > 2) {
      value = `${split[0]}.${split[1].slice(0, 2)}`
    } else {
      value = `${split[0]}.${split[1]}`
    }
  }

  return value
}

const InputCurrency = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  currency = 'GBP',
  type,
  ...style
}) => {
  const {
    default_locale,
  } = useLocale()
  const [
    internal,
    setInternal,
  ] = useState(smallToLarge(value)) // '00.00'

  const [
    focused,
    setFocused,
  ] = useState(false)

  const handleChange = useCallback((event) => {
    const value = Number(mask(event) )
    
    setInternal(value)
    change(field, largeToSmall(value))
  }, [change, field])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={focused ? Number(type === 'pricing' ? value / 100 : internal) : formatCurrency(smallToLarge(value), default_locale, currency)}
        change={handleChange}
        margin={margin}
        disabled={disabled}
        focus={handleFocus}
        blur={handleBlur}
      />
    </StatefulWrapper>
  )
}

const InputCurrencyWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputCurrency {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputCurrency {...props} />
  )
}

export default InputCurrencyWrapped

export {
  Raw,
}
