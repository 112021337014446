import React from 'react'

const SearchIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
    >
      <path
        fill={fill}
        d={'M22.87,20.13H21.42l-.51-.5a11.94,11.94,0,1,0-1.28,1.28l.5.51v1.45L29.27,32,32,29.27Zm-11,0a8.24,8.24,0,1,1,8.24-8.24A8.23,8.23,0,0,1,11.89,20.13Z'}
      />
    </svg>
  )
}

export default SearchIcon
