import React from 'react'

import Area from '_shared/components/layout/Area'

import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText, { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import { Localised as InputRTELocalised } from '_shared/components/input/InputRTE'

import InfoBox from '_shared/components/notification/InfoBox'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

const FormDescription = ({
  current,
  valid,
  updateSingle,
}) => {
  return (
    <FormView>
      <Area
        areas={[
          'section',
          'info/2 code_info',
          'code . code_info',
          'title . warning',
          'link . warning',
          'description . .',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Description'} />
        </SectionWrapper>
        <TextBox strong area={'info'}>{'Describe the basic details of this vehicle.'}</TextBox>
        <InfoBox
          area={'warning'}
          type={'warning'}
          title={'Warning'}
          content={'Changing details here will change it throughout the services'}
        />
        <InfoBox
          area={'code_info'}
          type={'info'}
          title={'External ID'}
          content={'Used for external API calls.'}
        />
        <InputText
          area={'code'}
          title={'External ID'}
          field={'external_id'}
          value={current.external_id}
          placeholder={'Unique External ID'}
          change={updateSingle}
          status={parseValidity(valid.external_id)}
          required
          requiredText={'Must be unique'}
        />
        <InputTextLocalised
          area={'title'}
          title={'Title'}
          field={'title'}
          value={current.locales}
          placeholder={'Title'}
          change={updateSingle}
          valid={valid.locales}
          required
        />
        <InputText
          area={'link'}
          title={'Link'}
          field={'link'}
          value={current.link}
          placeholder={'Link'}
          change={updateSingle}
        />
        <InputRTELocalised
          area={'description'}
          title={'Description'}
          field={'description'}
          value={current.locales}
          placeholder={'Vehicle description'}
          change={updateSingle}
        />
      </Area>
    </FormView>
  )
}

export default FormDescription
