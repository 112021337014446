// import { getPricingTicketSchema } from './Context'

// import mapToSchema from '_shared/libs/mapToSchema'

const filterTickets = (filtered_types, markets, pricing) => {
  const ticket_pricing = [...pricing]
    .filter(effect => filtered_types.includes(effect.ticket_type_id))

  const output = [
    ...ticket_pricing,
    ...filtered_types
      .reduce((acc, type) => {
        const exists = ticket_pricing.find(effect => effect.ticket_type_id === type)

        if (!exists) {
          markets.forEach(market => {
            acc.push({
              type: 'set_fixed',
              value: 0,
              market_id: market,
              channel: null,
              ticket_type_id: type,
              location_id: null,
              end_location_id: null,
            })
          })
        }

        return acc
      }, []),
  ]

  return output
}

const generator = (channels, markets, ticket) => {
  const acc = []

  markets
    .forEach(market => {
      channels
        .forEach(channel => {
          acc.push({
            channel: channel,
            value_relative: 0,
            market: {
              entity_id: market,
            },
            ticket_type: {
              entity_id: ticket,
            },
          })
        })
    })

  return acc
}

const filterRules = (filtered_types, rules, markets) => {
  if (rules.length === 0) {
    return rules
  } else {
    return rules
      .map(rule => {
        const filtered_effects = [...rule.price_adjustment]
          .filter(effect => filtered_types.includes(effect.ticket_type_id))

        // AAAHHHAHAHAH I hate this data model 🤬
        const effects = [
          ...filtered_effects,
          ...filtered_types
            .reduce((acc, type) => {
              const exists = filtered_effects.find(item => item.ticket_type_id === type)

              if (!exists) {
                acc = [
                  ...acc,
                  ...generator([1, 2, 3, 4, 5, 6], markets, type),
                ]
              }

              return acc
            }, []),
        ]

        return {
          ...rule,
          effects,
        }
      })
  }
}

export default filterTickets

export {
  filterRules,
}
