import {
  combineReducers,
} from 'redux'

import notifications from '_shared/components/notification/Toasts/reducer'
import user from './user'
import salesGroups from './salesGroups'
import localisation from './localisation'

const uiSpec = {
  waiting: false,
  busy: false,
  requestAuth: false,
  lockGroup: null,
  collapsed: false,
}

const ui = (state = uiSpec, action) => {
  switch (action.type) {
    case 'UI_SET_GROUP_LOCK':
      return {
        ...state,
        lockGroup: action.data,
      }
    case 'UI_BUSY':
      return Object.assign({}, state, {
        busy: true,
      })
    case 'UI_NOT_BUSY':
      return Object.assign({}, state, {
        busy: false,
      })
    case 'UI_REQUEST_AUTH':
      if (state.requestAuth) {
        return {
          ...state,
          requestAuth: false,
        }
      } else {
        return {
          ...state,
          requestAuth: true,
        }
      }
    case 'UI_SET_COLLAPSED':
      return {
        ...state,
        collapsed: action.collapsed,
      }
    default: return state
  }
}

const admin = combineReducers({
  ui,
  user,
  // profiles,
  notifications,
  salesGroups,
  localisation,
})

const rootReducer = (state, action) => {
  if (action.type === 'APP_REFRESH') {
    return {
      ...state,
      ...action.data,
    }
  }

  if (action.type === 'APP_CLEAR') {
    state = {}
  }

  return admin(state, action)
}

export default rootReducer
