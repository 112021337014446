import React from 'react'

import Row from '_shared/components/layout/Row'

import ActionTagCheck from '_shared/components/action/ActionTagCheck'

const InputTagSelector = ({
  value,
  field,
  options,
  change,
  ...style
}) => {
  const handleChange = (day) => {
    const state = [...value]
    const index = state.indexOf(day)

    if (index === -1) {
      state.push(day)
    } else {
      state.splice(index, 1)
    }

    change(field, state)
  }

  return (
    <Row spread={false} {...style}>
      {options.map(option => {
        return (
          <ActionTagCheck
            key={option.value}
            label={option.label}
            active={value.includes(option.value)}
            change={() => handleChange(option.value)}
            margin={'0 0.25rem 0.25rem 0'}
          />
        )
      })}
    </Row>
  )
}

export default InputTagSelector
