import React from 'react'

import styles from './floatingactions.scss'

import Row from 'containers/Row'
import ModalButton from 'atoms/ModalButton'

import delayMount from 'HOC/delayMount'

const FloatingActions = (props) => {
  const {
    discard,
    save,
    isMounted,
  } = props

  return (
    <Row styling={[styles.layout, isMounted ? styles.in : styles.out].join(' ')} spread>
      <ModalButton
        color={'cancel'}
        content={'Discard Changes'}
        onClick={discard}
      />
      <ModalButton
        color={'save'}
        content={'Save Changes'}
        onClick={save}
      />
    </Row>
  )
}

export default delayMount(FloatingActions)
