import useLocale from '_shared/hooks/useLocale'

const useGetLocalValue = (record, field) => {
  const {
    default_locale,
  } = useLocale()

  const found = record.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

  return found && found[field] ? found[field] : null
}

const useGetLocalValueFunc = (field) => {
  const {
    default_locale,
  } = useLocale()

  return (record) => {
    const found = record.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

    return found && found[field] ? found[field] : null
  }
}

export default useGetLocalValue

export {
  useGetLocalValueFunc,
}
