import React from 'react'

import {
  getSpec,
  valid,
  handlers,
} from './Context'

import { StateTabbedHeader as TabbedHeader }  from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'
// import FormExtension from './FormExtension'

import SlideForm from 'templates/SlideForm'

import { useGetLocalValueFunc } from '_shared/hooks/useGetLocaleValue'

const options = [
  {
    label: 'Description',
    view: FormDescription,
    slug: 'description',
  },
]

const Consumer = () => {
  const getTitle = useGetLocalValueFunc('title')

  return (
    <SlideForm
      options={options}
      data_spec={getSpec}
      valid_spec={valid}
      handlers={handlers}
      mountKey={['subview', 'record']}
      match={{
        subview: '-group',
      }}
      permissionsKey={'ticket-type-groups'}
      render={({
        current,
        valid,
        update,
        updateSingle,
      }) => {
        const title = getTitle(current)

        return (
          <TabbedHeader
            title={`Ticket Type Group${title !== null ? `: ${title}` : ''}`}
            options={options}
            passProps={{
              current,
              valid,
              updateSingle,
            }}
          />
        )
      }}
    />
  )
}

export default Consumer
