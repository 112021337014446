import React, { Fragment } from 'react'

import FlexButton from '_shared/components/element/FlexButton'
import UpIcon from '_shared/icons/Up'
import DownIcon from '_shared/icons/Down'

import Config from 'libs/Config'

const ActionExpand = ({
  change,
  active = false,
  ...style
}) => {
  return (
    <FlexButton {...style} change={change} background={active ? 'button' : 'button_inactive'}>
      {active && (
        <Fragment>
          <DownIcon
            size={12}
            fill={Config.theme.text_light}
          />
          <UpIcon
            size={12}
            fill={Config.theme.text_light}
          />
        </Fragment>
      )}
      {!active && (
        <Fragment>
          <UpIcon
            size={12}
            fill={Config.theme.text}
          />
          <DownIcon
            size={12}
            fill={Config.theme.text}
          />
        </Fragment>
      )}
    </FlexButton>
  )
}

ActionExpand.styleable = true

export default ActionExpand
