import React from 'react'

import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'

import ChooserProduct from '../choosers/ChooserProduct'
import ChooserService from '../choosers/ChooserService'
import ChooserServiceTypes from '../choosers/ChooserServiceTypes'

const ProductFilter = ({
  data,
  change,
}) => {
  return (
    <Area
      areas={[
        'title',
        'product service type',
      ]}
      columns={3}
      rowgap={1}
    >
      <Heading
        area={'title'}
        level={3}
        title={'Products and services'}
      />
      <ChooserProduct
        area={'product'}
        data={data}
        change={change}
      />
      <ChooserService
        area={'service'}
        data={data}
        change={change}
      />
      <ChooserServiceTypes
        area={'type'}
        data={data}
        change={change}
      />
    </Area>
  )
}

export default ProductFilter
