import React from 'react'

import { StateTabbedHeader as TabbedHeader } from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'
import FormServices from './FormServices'
import FormAvailability from './FormAvailability'
import FormValidity from './FormValidity'
import FormPricing from './FormPricing'
import FormPricingRules from './FormPricingRules'
import FormExtension from './FormExtension'
import FormInfo from './FormInfo'

import useGetLocalValue from '_shared/hooks/useGetLocaleValue'

const options = [
  {
    label: 'Description',
    view: FormDescription,
    slug: 'description',
    status: 'DEFAULT',
  },
  {
    label: 'Services',
    view: FormServices,
    slug: 'services',
    status: 'DEFAULT',
  },
  {
    label: 'Availability & Channels',
    view: FormAvailability,
    slug: 'availability',
    status: 'DISABLED',
  },
  {
    label: 'Validity',
    view: FormValidity,
    slug: 'validity',
    status: 'DISABLED',
  },
  {
    label: 'Pricing',
    view: FormPricing,
    slug: 'pricing',
    status: 'DISABLED',
  },
  {
    label: 'Pricing Rules',
    view: FormPricingRules,
    slug: 'pricing_rules',
    status: 'DEFAULT',
  },
  {
    label: 'Integrations',
    view: FormExtension,
    slug: 'integrations',
    status: 'DEFAULT',
  },
  {
    label: 'Important Info',
    view: FormInfo,
    slug: 'info',
    status: 'DEFAULT',
  },
]

const serviceValid = (services) => {
  return services.length > 0 && services.every(({ entity_id }) => entity_id !== null)
}

const Route = ({
  cancel,
  current,
  valid,
  update,
  updateSingle,
}) => {
  options[1].label = current.type === 'route' ? 'Services & Routes' : 'Services'
  options[2].status = serviceValid(current.services) ? 'DEFAULT' : 'DISABLED'
  options[3].status = current.allowed_ticket_types.length > 0 ? 'DEFAULT' : 'DISABLED'
  options[4].status = current.allowed_ticket_types.length > 0 && current.sales_limitations.markets.length > 0 ? 'DEFAULT' : 'DISABLED'
  options[5].status = current.allowed_ticket_types.length > 0 && current.sales_limitations.markets.length > 0 ? 'DEFAULT' : 'DISABLED'

  if (current.type !== 'route') {
    options.filter(option => option.slug === 'info')
  }

  const title = useGetLocalValue(current, 'title')

  return (
    <TabbedHeader
      title={`Product${title !== null ? `: ${title}` : ''}`}
      cancel={cancel}
      options={options}
      passProps={{
        current,
        valid,
        update,
        updateSingle,
      }}
    />
  )
}

export default Route
