import TicketingApi from './base'

const endcodeQuery = (shape) => {
  const query = Object.keys(shape)
    .map(key => {
      const val = Array.isArray(shape[key]) ? shape[key].join(',') : shape[key]

      return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
    })
    .join('&')

  return Object.keys(shape).length > 0 ? `?${query}` : ''
}

class ReportsApi extends TicketingApi {
  getYield = (query) => this.callApi(`services/instances/yield${endcodeQuery(query)}`)
  getReport = (query, format, type, lang) => this.downloadFile(`reports/${type}${endcodeQuery(query)}`, {
    headers: {
      accept: format,
      'Accept-Language': lang,
    },
  })
}

export default new ReportsApi()
