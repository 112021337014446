import api from '../api/localisation'

import {
  createActionGet,
  setBusyState,
  editStore,
} from 'libs/actionCreator'

const getLocalisation = createActionGet('localisation', api.getSystemConfig)

const editLocalisation = (data) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return api.editSystemConfig(data)
    .then(data => {
      dispatch(editStore('localisation', data))

      dispatch(setBusyState(false))

      return data
    })
    .catch(err => {
      console.error('Caught error in edit', err)

      dispatch(setBusyState(false))

      throw err
    })
}

export {
  getLocalisation,
  editLocalisation,
}
