import React from 'react'

import Card from '_shared/components/layout/Card'
import Area from '_shared/components/layout/Area'

import { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import InputSelect from '_shared/components/input/InputSelect'
import InputInteger from '_shared/components/input/InputInteger'
import ActionRemove from '_shared/components/action/ActionRemove'

import useDependencies from '_shared/hooks/useDependencies'

const CapacityCard = ({
  value = {},
  change,
  remove,
}) => {
  const { data: capacity_types } = useDependencies('capacity_types',
    data => data.map(item => ({
      label: item.title,
      value: item.entity_id,
    })))

  return (
    <Card padding={'1rem'}>
      <Area
        areas={[
          'title type capacity remove',
        ]}
        columns={'1fr 1fr 1fr 10rem'}
        colgap={1}
      >
        <InputTextLocalised
          area={'title'}
          gridAlign={'end'}
          title={'Layout name'}
          field={'title'}
          value={value.locales}
          placeholder={'E.g Full Capacity'}
          change={change}
        />
        <InputSelect
          area={'type'}
          gridAlign={'end'}
          minWidth={'10rem'}
          title={'Area Contains'}
          field={'capacity.type.entity_id'}
          options={capacity_types}
          value={value.capacity.type.entity_id}
          placeholder={'Type'}
          change={change}
        />
        <InputInteger
          area={'capacity'}
          gridAlign={'end'}
          minWidth={'10rem'}
          title={'Capacity'}
          field={'capacity.units'}
          value={value.capacity.units}
          placeholder={'Capacity'}
          change={change}
        />
        <ActionRemove area={'remove'} label={'Remove'} change={remove} />
      </Area>
    </Card>
  )
}

export default CapacityCard
