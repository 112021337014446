import { createSchema, morphism } from 'morphism'

import { mapDefaults } from '_shared/libs/mapToSchema'

const description = {
  locale: 'locale',
  boarding_time: 'boarding_time',
  important_information: 'important_information',
  whats_onboard: 'whats_onboard',
}

const defaults = {
  notes: [],
}

const cleanTemplate = createSchema(
  {
    title: 'title',
    description: 'description',
    type: 'type',
    locales: {
      path: 'locales',
      fn: (value, source) => {
        if (!value) return []
        return value
          .map(record => morphism(description, record))
      },
    },
    compatible_ticket_type_groups: {
      path: 'compatible_ticket_type_groups',
      fn: (value, source) => {
        if (!value) return []
        return value
          .map(record => morphism({
            entity_id: 'entity_id',
          }, record))
      },
    },
    layout: {
      areas: {
        path: 'layout.areas',
        fn: (value, source) => {
          if (!value) return []
          return value
            .map(record => morphism({
              locales: {
                path: 'locales',
                fn: (value, source) => {
                  if (!value) return []
                  return value
                    .map(record => morphism({
                      locale: 'locale',
                      title: 'title',
                    }, record))
                },
              },
              seating: {
                path: 'seating',
                fn: (value, record) => {
                  return []
                },
              },
              capacity: {
                units: 'capacity.units',
                type: {
                  entity_id: 'capacity.type.entity_id',
                },
              },
            }, record))
        },
      },
      locales: {
        path: 'layout.locales',
        fn: (value, source) => {
          if (!value) return []
          return value
            .map(record => morphism({
              locale: 'locale',
              title: 'title',
              description: 'description',
            }, record))
        },
      },
    },
    notes: 'notes',
    segments: {
      path: 'segments',
      fn: (value, source) => {
        if (!value) return []
        return value
          .map(record => morphism({
            start: 'start',
            end: 'end',
            location: {
              entity_id: 'location.entity_id',
            },
          }, record))
      },
    },
    service: {
      entity_id: 'service.entity_id',
    },
    venue: {
      entity_id: 'venue.entity_id',
    },
  },
  mapDefaults(defaults),
)

export default cleanTemplate
