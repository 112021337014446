import {
  connect,
} from 'react-redux'

import getSymbolFromCurrency from 'currency-symbol-map'

const localisationWrapper = (Component) => {
  return connect(mapStateToProps)(Component)
}

const mapStateToProps = (state) => {
  const {
    currency,
    locale,
  } = state.ui

  return {
    currency,
    locale,
    currency_symbol: getSymbolFromCurrency(currency),
  }
}

export default localisationWrapper
