import React from 'react'

import { Raw as InputSelect } from '_shared/components/input/InputSelect'
import ListTable from '_shared/components/navigation/ListTable'

import mapToSchema from '_shared/libs/mapToSchema'

import useLocale from '_shared/hooks/useLocale'

import { update } from '_shared/libs/mapToSchema'

const fieldStyle = {
  flex: '0 0 auto',
  minHeight: '1.5rem',
}

const Selector = ({
  outer_field,
  inner_field,
  data,
  options,
  columns,
  change,
  placeholder,
  schema_generator,
  allowCreate = true,
}) => {
  const {
    locales,
    currencies,
  } = useLocale()

  const create = () => {
    const state = [...data]
    const row = mapToSchema(locales, currencies, schema_generator)({})

    state.push(row)

    change(outer_field, state)
  }

  const remove = (row) => {
    const state = [...data]

    state.splice(row, 1)

    change(outer_field, state)
  }

  const handleChange = (row) => (field, value) => {
    const state = [...data]

    update(state, `[${row}]${field}`, value)

    change(outer_field, state)
  }

  const mutatedData = data.map((record, row) => {
    return {
      id: row,
      [inner_field]: (
        <InputSelect
          field={`${inner_field}.entity_id`}
          placeholder={placeholder}
          options={options}
          value={record[inner_field].entity_id}
          change={handleChange(row)}
          {...fieldStyle}
        />
      ),
    }
  })

  return (
    <ListTable
      columns={columns}
      data={mutatedData}
      create={allowCreate && create}
      remove={remove}
    />
  )
}

export default Selector
