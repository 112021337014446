import React from 'react'
import {
  connect,
} from 'react-redux'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import styles from './auth.scss'

import Container from 'containers/Container'

import Logo from 'icons/Logo'

import AppStatus from 'containers/AppStatus'

import Routes from './Routes'

import Notifications from '../Notifications'

const Auth = (props) => {
  return (
    <Router>
      <AppStatus>
        <Container
          styling={styles.layout}
        >
          <Container
            styling={styles.view_content}
          >
            <Container
              styling={styles.logo}
            >
              <Logo />
            </Container>
            <Routes />
          </Container>
        </Container>
        <Notifications />
      </AppStatus>
    </Router>
  )
}

const mapStateToProps = (state) => {
  const {
    loginState,
  } = state.ui

  return {
    loginState,
  }
}

export default connect(mapStateToProps)(Auth)
