import React from 'react'

import InputFilter from '_shared/components/input/InputFilter'

// pos,b2c
const options = [
  {
    label: 'POS',
    value: 'pos',
  },
  {
    label: 'B2C',
    value: 'b2c',
  },
]

const ChooserStatus = ({
  data,
  change,
}) => {
  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  return (
    <InputFilter
      field={'sources'}
      title={'Filter sources'}
      placeholder={'Filter sources'}
      value={data['sources']}
      change={handleChange}
      options={options}
    />
  )
}

export default ChooserStatus
