import React from 'react'

import {
  getSpec,
  handlers,
  valid as valid_spec,
} from './Context'

import { StateTabbedHeader as TabbedHeader } from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'
import FormLocation from './FormLocation'
import FormVenue from './FormVenue'
import FormInformation from './FormInformation'

import SubSlideForm from 'templates/SubSlideForm'

const options = [
  {
    label: 'Description & Notes',
    view: FormDescription,
    slug: 'description',
  },
  {
    label: 'Location',
    view: FormLocation,
    slug: 'location',
  },
  {
    label: 'Venue, Layout & Capacity',
    view: FormVenue,
    slug: 'venue_layout_capacity',
  },
  {
    label: 'Information',
    view: FormInformation,
    slug: 'information',
  },
]

const TemplatesConsumer = ({
  record,
  initial,
  // save,
  close,
  mount,
  type,
}) => {
  return (
    <SubSlideForm
      data_spec={getSpec}
      valid_spec={valid_spec}
      handlers={handlers}
      mount={mount}
      permissionsKey={'user'}
      initial={initial}
      record={record}
      close={close}
      render={({
        current,
        update,
        updateSingle,
      }) => {
        return (
          <TabbedHeader
            title={'Template'}
            options={options}
            passProps={{
              current,
              update,
              updateSingle,
              type,
            }}
          />
        )
      }}
    />
  )
}

export default TemplatesConsumer
