import React from 'react'

import styles from './texticonrow.scss'

import Row from '../../containers/Row'

/**
  * Displays a row containing an Icon next to a text label
  * @param {String} text Text to display with icon
  * @param {ReactElement} icon SVG icon component
  * @param {Number} iconSize size in pixels for the icon
  * @param {String} iconFill colour string for icon ('rgb(255,255,255)')
  * @param {Boolean} reverse whether to show the icon before the text
  * @param {String} textStyling pass through className for the text
*/

const TextIconRow = (props) => {
  const {
    text,
    icon,
    iconSize,
    iconFill,
    reverse,
    textStyling,
    styling,
    ...rest
  } = props

  const size = iconSize || 16

  const Tag = icon

  const fill = iconFill || 'rgb(0,0,0)'

  const Icon = <Tag size={size} fill={fill} />

  if (reverse) {
    return (
      <Row
        type={'start'}
        styling={[styles.layout, styling].join(' ')}
        {...rest}
      >
        {Icon}
        <span className={[styles.textReverse, textStyling].join(' ')}>{text}</span>
      </Row>
    )
  } else {
    return (
      <Row
        type={'spaced'}
        styling={[styles.layout, styling].join(' ')}
        {...rest}
      >
        <span className={[styles.text, textStyling].join(' ')}>{text}</span>
        {Icon}
      </Row>
    )
  }
}

export default TextIconRow
