import useLocale from './useLocale'
import useDependencies from './useDependencies'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

const useMergedTickets = () => {
  const {
    default_locale,
  } = useLocale()

  const { isFetching: ticketFetching, data: ticket_types } = useDependencies('ticket_types')
  const { isFetching: groupFetching, data: ticket_groups } = useDependencies('ticket_groups')

  if (ticket_types.length === 0 || ticket_groups.length === 0) {
    return {
      isFetching: ticketFetching || groupFetching,
      data: [],
    }
  } else {
    return {
      isFetching: ticketFetching || groupFetching,
      data: ticket_types
        .map(ticket => {
          const ticket_label = getDeepLocale(ticket.locales, 'title', default_locale)

          const ticket_group = ticket_groups.find(group => group.entity_id === ticket.ticket_type_group.entity_id)

          const group_title = ticket_group ? getDeepLocale(ticket_group.locales, 'title', default_locale) : 'undefined'

          return {
            label: `${group_title}: ${ticket_label}`,
            value: ticket.entity_id,
          }
        }),
    }
  }
}

export default useMergedTickets
