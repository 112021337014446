import Section from './Section'
import Info from './Info'
import Radio from './Radio'
import Switch from './Switch'
import Integer from './Integer'
import Text from './Text'

const components = {
  section: Section,
  info: Info,
  radio: Radio,
  switch: Switch,
  integer: Integer,
  text: Text,
}

export default components
