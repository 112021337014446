import React from 'react'

import StyleWrapper from '_shared/style/StyleWrapper'

import Container from '_shared/components/layout/Container'
import Table from '_shared/components/element/Table'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import ActionAdd from '_shared/components/action/ActionAdd'
import ActionRemove from '_shared/components/action/ActionRemove'
import DragHandleIcon from '_shared/icons/DragHandle'

import useDragSort from '_shared/hooks/useDragSort'

import Config from 'libs/Config'

const handleStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 0,
  flexShrink: 0,
  outline: 0,
  borderWidth: 0,
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
}

const SortListTable = ({
  columns,
  data = [],
  change,
  create,
  remove,
  sort,
  finish,
  locked = false,
  ...style
}) => {
  const {
    drag,
    handleStart,
    handleEnd,
    handleOver,
    handleReadyStatus,
  } = useDragSort(data, sort)

  const wrappedHandleEnd = (row) => (event) => {
    handleReadyStatus(false)
    handleEnd()(event)
    finish()
  }

  const handleDown = () => {
    handleReadyStatus(true)
  }

  const handleUp = () => {
    handleReadyStatus(false)
  }

  const headRowStyle = {
    backgroundColor: Config.theme.row_header,
  }

  const headStyle = {
    color: Config.theme.text_mid,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
  }

  const bodyRowStyle = {
    height: '2.5rem',
  }

  let mutatedData

  const styledColumns = [
    {
      key: 'list_row_handle',
      label: 'Reorder',
      headStyle: {
        ...headStyle,
        width: '3rem',
      },
    },
    ...columns
      .map(column => ({
        ...column,
        headStyle,
      })),
  ]

  mutatedData = data
    .map(row => ({
      ...row,
      'list_row_handle': (
        <button onMouseDown={handleDown} onMouseUp={handleUp} style={handleStyle}>
          <DragHandleIcon fill={Config.theme.button} size={24} />
        </button>
      ),
    }))

  if (remove) {
    styledColumns.push({
      key: 'list_row_remove',
      label: '',
      headStyle: {
        ...headStyle,
        width: '3rem',
      },
    })

    mutatedData = mutatedData.map(row => ({
      ...row,
      'list_row_remove': (<ActionRemove collapsed label={'remove'} change={() => remove(row.id)} />),
    }))
  }

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <Container background={'background_module'} style={styling}>
            {locked && (
              <Table
                columns={styledColumns}
                data={mutatedData}
                shadeAlternateRows={false}
                headRowStyle={headRowStyle}
                bodyRowStyle={bodyRowStyle}
              />
            )}
            {!locked && (
              <Table
                columns={styledColumns}
                data={mutatedData}
                shadeAlternateRows={false}
                headRowStyle={headRowStyle}
                bodyRowStyle={bodyRowStyle}
                draggable={{
                  active: true,
                  drag,
                  onDragOver: handleOver,
                  onDragStart: handleStart,
                  onDragEnd: wrappedHandleEnd,
                }}
              />
            )}
            {mutatedData.length === 0 && (
              <Empty />
            )}
            {create && (
              <AddBar create={create} label={'Add a new record'} />
            )}
          </Container>
        )
      }}
    />
  )
}

const Empty = () => {
  const style = {
    minHeight: '2.5rem',
    borderBottom: `solid 1px ${Config.theme.border_thin}`,
  }

  return (
    <Container flexCenter rawStyle={style}>
      <InlineTextBox strong>{'No records to display'}</InlineTextBox>
    </Container>
  )
}

const AddBar = ({ create, label }) => {
  const style = {
    minHeight: '2.5rem',
    backgroundColor: Config.theme.row_highlight,
    borderBottom: `solid 1px ${Config.theme.border_boundary}`,
  }

  return (
    <Container flexCenter background={'table_body_highlight'} rawStyle={style}>
      <ActionAdd change={create} label={label} />
    </Container>
  )
}

export default SortListTable