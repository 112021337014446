import React from 'react'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'

import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import TextBox from '_shared/components/layout/TextBox'

import ProductCard from 'templates/PromoProductCard'

const FormProduct = ({
  current,
  update,
  updateSingle,
}) => {
  const handleProductChange = (field, value) => {
    updateSingle(`product_selection.${field}`, value)
  }

  const handleProductChangeMulti = (payload) => {
    update(payload.map(({ field, value }) => {
      return {
        field: `product_selection.${field}`,
        value,
      }
    }))
  }

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'info',
          'products',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Promotion Items'} />
        </SectionWrapper>
        <TextBox strong area={'info'}>{'Add the product for the promotion to apply to. Add limits on the products date and time. Define the promotional discount in percentage.'}</TextBox>
        <Container area={'products'} padding={'0 0 15rem 0'}>
          <ProductCard
            row={0}
            data={current.product_selection}
            change={handleProductChange}
            changeMulti={handleProductChangeMulti}
          />
        </Container>
      </Area>
    </FormView>
  )
}

export default FormProduct
