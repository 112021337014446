const storage = window.localStorage

const parseValue = value => JSON.parse(value)

const createPromise = func => {
  return new Promise((resolve, reject) => {
    let result = func()

    result || result === null ? resolve(parseValue(result)) : reject(new Error('empty store'))
  })
}

const APP = 'TICKNOVATE'

const store = {
  get: (key) => {
    return storeMethods.get(APP)
      .then(data => {
        return data && data[key] ? data[key] : null
      })
  },

  set: (key, value) => {
    return storeMethods.get(APP)
      .then(data => {
        const state = {...data}

        state[key] = value

        return storeMethods.set(APP, state)
          .then(data => data)
      })
  },

  all: () => {
    return storeMethods.get(APP)
      .then(data => {
        return data
      })
  },

  fill: (data) => {
    return storeMethods.set(APP, data)
      .then(data => data)
  },

  clear: () => {
    return storeMethods.empty()
  },
}

const storeMethods = {
  get: function (key) {
    return createPromise(() => storage.getItem(key))
  },

  set: function (key, value) {
    return createPromise(() => {
      storage.setItem(key, JSON.stringify(value))

      return storage.getItem(key)
    })
  },

  update: function (key, value) {
    return store.get(key).then(item => {
      // value = typeof value === 'string' ? value : Object.assign({}, item, value)
      return storage.setItem(key, JSON.stringify(value))
    })
  },

  delete: function (key) {
    return createPromise(() => {
      storage.removeItem(key)

      return JSON.stringify({})
    })
  },

  empty: function () {
    return createPromise(() => {
      storage.clear()

      return JSON.stringify('cleared')
    })
  },
}

export default store
