import React from 'react'

import View from 'containers/View'
import Heading from 'atoms/Heading'

const Home = () => {
  return (
    <View>
      <Heading
        level='section'
        title='Home'
      />
    </View>
  )
}

export default Home
