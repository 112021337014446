import React from 'react'

import Row from '_shared/components/layout/Row'

import Config from 'libs/Config'

const SectionWrapper = ({
  label,
  children,
  ...style
}) => {
  return (
    <Row type={'start'} {...style}>
      <Badge label={label} />
      {children}
    </Row>
  )
}

const badge = {
  position: 'absolute',
  left: '-4rem',
}

const Badge = () => {
  return (
    <svg
      width={32}
      height={64}
      viewBox={'0 0 16 32'}
      style={badge}
    >
      <polygon
        fill={Config.theme.text}
        points={'0 0 16 16 0 32'}
      />
    </svg>
  )
}

export default SectionWrapper
