import React from 'react'

import InputTime from '_shared/components/input/InputTime'

const TimeServiceStart = ({
  value,
  change,
}) => {
  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  return (
    <InputTime
      field={'service-time-start'}
      title={'Service Time Starts'}
      placeholder={'Service Time Starts'}
      value={value}
      change={handleChange}
    />
  )
}

export default TimeServiceStart
