import React, { useState, useEffect } from 'react'

import styles from './controlledslide.scss'

import ReactDOM from 'react-dom'

import Container from 'containers/Container'

import SlideContent from './Content'

import Controls from './Controls'

// const defaultCapability = [
//   'read',
//   'create',
//   'update',
//   'delete'
// ]

const SubSlide = ({
  children,
  cancel,
  save,
  remove,
  valid = true,
  permissions = [],
  isNew,
  mount = false,
  controls,
}) => {
  const [
    shouldRender,
    setRender,
  ] = useState(mount)

  useEffect(() => {
    if (mount) setRender(true)
  }, [mount])

  if (!document.getElementById('slide')) return null

  const onAnimationEnd = () => {
    if (!mount) setRender(false)
  }

  return ReactDOM.createPortal(
    (
      shouldRender &&
      <Container
        styling={styles.layout}
      >
        <SlideContent
          permissions={permissions}
          isMounted={mount}
          onAnimationEnd={onAnimationEnd}
        >
          <Container styling={styles.scroller}>
            {children}
          </Container>
          {controls && controls()}
        </SlideContent>
      </Container>
    ),
    document.getElementById('slide'),
  )
}

export default SubSlide

export {
  Controls,
}
