import React, { useState } from 'react'
import {
  connect,
} from 'react-redux'

import {
  forgotStart,
} from 'actions/user'

import {
  createNotification,
} from 'actions/notifications'

import {
  withValidator,
  validate,
  formValid,
} from 'HOC/formValidator'

import Area from '_shared/components/layout/Area'
import { Raw as InputText } from '_shared/components/input/InputText'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import Button from '_shared/components/element/Button'

import Row from '_shared/components/layout/Row'

import ActionCTA from '_shared/components/action/ActionCTA'

import PlusIcon from 'icons/Plus'

import notEmptyOrNull from 'libs/notEmptyOrNull'

import Config from 'libs/Config'

const Validator = withValidator({
  username: notEmptyOrNull,
})

const ValidTextField = validate(InputText)

const ValidSubmit = formValid((props) => {
  const {
    action,
    status,
  } = props

  return (
    <ActionCTA
      type={status ? 'important' : 'disabled'}
      label={'Request Reset'}
      change={status ? action : null}
    />
  )
})

const ForgotStart = ({ location, history, forgotStart, notify }) => {
  const [
    state,
    setState,
  ] = useState({
    username: '',
    ...location.state,
  })

  const update = (field, value) => {
    const record = { ...state }

    record[field] = value

    setState(record)
  }

  const submit = async () => {
    try {
      await forgotStart(state)

      history.push('/forgot_confirm', state.record)
    } catch (e) {
      console.error(e)

      notify({
        type: 'error',
        message: 'An unknown error occured.',
      })
    }
  }

  const back = () => {
    history.push('/login')
  }

  return (
    <Validator
      record={state}
    >
      <Area
        areas={[
          'heading heading',
          'message .',
          'form .',
          'back .',
        ]}
        columns={4}
        rowgap={1}
      >
        <Heading level={'section'} title={'Forgotten password'} area={'heading'} />
        <TextBox noMargin color={'text_light'} area={'message'}>{'Please enter your email to reset your password'}</TextBox>
        <Row type={'start'} area={'form'}>
          <ValidTextField
            field={'username'}
            placeholder={'Email'}
            value={state.username}
            change={update}
            requiredText={'User email required'}
            width={'15rem'}
            noFlex
          />
          <ValidSubmit
            action={submit}
          />
        </Row>
        <Button change={back} margin={'1rem 0 0 0'} area={'back'}>
          <PlusIcon size={10} fill={Config.theme.text_light} />
          <TextBox margin={'0 0 0 0.5rem'} color={'text_light'}>{'Back to login'}</TextBox>
        </Button>
      </Area>
    </Validator>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotStart: (data) => dispatch(forgotStart(data)),
    notify: data => dispatch(createNotification(data)),
  }
}

export default connect(null, mapDispatchToProps)(ForgotStart)
