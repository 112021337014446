import React from 'react'

import Label from '_shared/components/layout/Label'
import InputRadio from '_shared/components/input/InputRadio'

const Switch = ({
  field,
  value,
  title,
  options,
  current,
  cancel,
}) => {
  return (
    <Label title={title} area={field}>
      <InputRadio
        field={field}
        value={current !== null}
        change={cancel}
        options={options}
        type={'horizontal'}
        margin={'1.25rem 0 0 0'}
      />
    </Label>
  )
}

export default Switch
