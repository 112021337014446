import React from 'react'
import styles from './textinput.scss'

const Input = (props) => {
  const {
    name,
    type,
    placeholder,
    value,
    change,
    styling,
    getRef,
    ...rest
  } = props

  return (
    <input
      className={[styles.input, styling].join(' ')}
      id={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={change}
      ref={getRef}
      {...rest}
    />
  )
}

export default Input
