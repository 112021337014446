import React from 'react'

const AddRoundIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 28 28'
    >
      <path
        fill={fill}
        d='M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0Zm7,15.75H15.75V21h-3.5V15.75H7v-3.5h5.25V7h3.5v5.25H21Z'
      />
    </svg>
  )
}

export default AddRoundIcon
