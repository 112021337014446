import React from 'react'

import AnimatedLogo from 'atoms/AnimatedLogo'

const Splash = () => {
  return (
    <div id={'splash'}>
      <AnimatedLogo
        size={150}
      />
    </div>
  )
}

export default Splash
