import locale_codes from 'config/locale_codes.json'
import langs from 'config/langs.json'

const get_normal_language = (code) => {
  const exists_country = locale_codes.find(item => item.code === code)
  const exists_short = langs.find(item => item['1'] === code.split('-')[0])

  return exists_country && exists_short ? { country: exists_country.country, short: exists_short['2'].toUpperCase() } : { country: 'undefined', short: 'undefined' }
}

export default get_normal_language
