import admin from './admin'
import pos from './pos'
import reservations from './reservations'
import trade from './trade'

export default {
  admin,
  pos,
  reservations,
  trade,
  scanner: {},
}
