import React, { useEffect, useState } from 'react'
import {
  connect,
} from 'react-redux'

import {
  checkTokenAndLogin,
} from 'actions/user'

import storeFromServer from 'actions/coreData'

import './app.scss'

import Splash from './Splash'
import Auth from './Auth'
import Main from './Main'

const AppRoot = ({
  validate,
  setCore,
  authenticated,
}) => {
  const [
    complete,
    setComplete,
  ] = useState(false)

  useEffect(() => {
    validate()
      .then(response => {
        return setCore()
      })
      .then(() => {
        setComplete(true)
      })
      .catch(error => {
        console.log('Token test failed', error)

        setComplete(true)
      })
  }, [setCore, validate])

  if (!complete) {
    return (
      <Splash />
    )
  } else {
    if (!authenticated) {
      return (
        <Auth />
      )
    }

    return (
      <Main />
    )
  }
}

const mapStateToProps = ({
  user,
}) => {
  return {
    authenticated: user.authenticated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCore: (group) => dispatch(storeFromServer()),
    validate: () => dispatch(checkTokenAndLogin()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoot)
