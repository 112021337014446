import React from 'react'

import InputFilter from '_shared/components/input/InputFilter'

import useDependencies from '_shared/hooks/useDependencies'

import useLocale from '_shared/hooks/useLocale'

const Chooser = ({
  field,
  label,
  data,
  change,
  dependency,
}) => {
  const {
    default_locale,
  } = useLocale()

  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  const { data: options } = useDependencies(dependency, data => {
    return data.map(record => {
      const found = record.title ? record : record.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

      return {
        label: found ? found.title : record.external_id,
        value: record.external_id,
      }
    })
  })

  return (
    <InputFilter
      field={field}
      title={label}
      placeholder={label}
      value={data[field]}
      change={handleChange}
      options={options}
    />
  )
}

export default Chooser
