import deepCopy from 'libs/deepCopy'

const reducer = 'localisation'

const base = {
  'currencies': [
    {
      'id': 'EUR',
      'is_default': false,
    },
    {
      'id': 'GBP',
      'is_default': true,
    },
    {
      'id': 'SEK',
      'is_default': false,
    },
  ],
  'locales': [
    {
      'id': 'da',
      'is_default': false,
    },
    {
      'id': 'de',
      'is_default': false,
    },
    {
      'id': 'en-gb',
      'is_default': true,
    },
    {
      'id': 'sv',
      'is_default': false,
    },
  ],
}


const localisation = (state = base, action) => {
  const store = deepCopy(state)

  switch (action.type) {
    case `${reducer.toUpperCase()}_LOADED`: {
      return action.data
    }
    case `${reducer.toUpperCase()}_EDIT`: {
      return action.data
    }
    default: {
      return store
    }
  }
}

export default localisation
