import React from 'react'

import Area from '_shared/components/layout/Area'

import FormView from 'containers/FormView'

import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'

import Label from '_shared/components/layout/Label'

import TemporaryTags from '_shared/components/element/TemporaryTags'

import InputInteger from '_shared/components/input/InputInteger'
import useMergedTickets from '_shared/hooks/useMergedTickets'

const FormMandatory = ({
  current,
  valid,
  updateSingle,
}) => {
  const { data: ticket_types} = useMergedTickets()

  const changeInnerTags = (field, value) => {
    const tags = value.map(tag => {
      return {
        ticket_type: {
          entity_id: tag,
        },
      }
    })

    updateSingle(field, tags)
  }

  const getInnerTags = () => current.mandatory_companions.map(tag => tag.ticket_type.entity_id)

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'max companions/2 .',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Mandatory Companions'} />
        </SectionWrapper>
        <InputInteger
          area={'max'}
          title={'Maximum free'}
          field={'mandatory_companions_max_free_selection'}
          value={current.mandatory_companions_max_free_selection}
          placeholder={'Maximum free'}
          change={updateSingle}
        />
        <Label title={'Mandatory Companions'} area={'companions'}>
          <TemporaryTags
            field={'mandatory_companions'}
            value={getInnerTags()}
            change={changeInnerTags}
            options={ticket_types}
          />
        </Label>
      </Area>
    </FormView>
  )
}

export default FormMandatory
