import { useCallback } from 'react'

import {
  createNotification,
} from '_shared/components/notification/Toasts/actions'

import { useDispatch } from 'react-redux'

const useToast = () => {
  const reduxDispatch = useDispatch()

  const send = useCallback((payload) => {
    reduxDispatch(createNotification(payload))
  }, [reduxDispatch])

  return send
}

export default useToast
