import React from 'react'

import {
  Redirect,
  Route,
} from 'react-router-dom'

const PUBLIC_ROOT = '/login'

const AuthRoute = ({component, ...props}) => {
  const { isPrivate } = component

  const session = props.location.state && props.location.state.session

  if (isPrivate) {
    if (!session) {
      return <Redirect to={PUBLIC_ROOT} />
    } else {
      return <Route {...props} component={component} />
    }
  } else {
    return <Route {...props} component={component} />
  }
}

export default AuthRoute
