import TicketingApi from './base'

// const mock = [
//   {
//     'id': 1,
//     'title': 'London',
//     'slug': 'london',
//     'currency': 'GBP',
//     'locales': [
//       {
//         'locale': 'en',
//         'is_default': true
//       }
//     ]
//   },
//   {
//     'id': 2,
//     'title': 'York',
//     'slug': 'york',
//     'currency': null,
//     'locales': []
//   }
// ]

class SalesGroupApi extends TicketingApi {
  getSalesGroups = () => this.callApi('markets')

  getSalesGroup = id => this.callApi(`markets/${id}`)

  createSalesGroup = data => this.callApi('markets', 'POST', data)

  updateSalesGroup = (data, id) => this.callApi(`markets/${id}`, 'PUT', data)

  deleteSalesGroup = id => this.callApi(`markets/${id}`, 'DELETE')
}

export default new SalesGroupApi()

// let count = 14

// const SalesGroupApi = {
//   getSalesGroups: () => Promise.resolve(mock),
//   createSalesGroup: (data) => Promise.resolve(data)
//     .then(data => {
//       count += 1

//       return {
//         ...data,
//         id: count
//       }
//     }),
//   editSalesGroup: (data, id) => Promise.resolve(data),
//   deleteSalesGroup: (id) => Promise.resolve(id)
// }

// export default SalesGroupApi
