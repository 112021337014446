import React from 'react'

import Container from '_shared/components/layout/Container'
import style from './block.scss'
import Heading from '_shared/components/layout/Heading'

const Block = ({ area, title, title_level, content, field }) => {
  return (
    <Container area={area}>
      <Heading
        level={title_level}
        title={title}
        margin={'0 0 1rem 0'}
      />
      <div
        className={style.layout}
        dangerouslySetInnerHTML={{__html: content}}
      />
    </Container>
  )
}

export default Block
