import React from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import LocaleWrapper, { LocaleWrapperHorizontal } from '_shared/components/layout/LocaleWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'

import ValidityIndicator from '_shared/components/element/ValidityIndicator'

const InputText = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  ...style
}) => {

  const handleChange = (event) => change(field, event.target.value)

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      indicator={state => (<ValidityIndicator status={status} margin={'0 0.25rem'} />)}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={value}
        change={handleChange}
        margin={margin}
        disabled={disabled}
      />
    </StatefulWrapper>
  )
}

const InputTextWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputText {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputText {...props} />
  )
}

const Localised = ({
  title,
  domain,
  field,
  value,
  valid,
  required = false,
  change,
  ...rest }) => {
  return (
    <Label title={title}>
      <LocaleWrapper
        domain={domain}
        value={value}
        field={field}
        change={change}
        valid={valid}
        required={required}
      >
        <InputText field={field} {...rest} />
      </LocaleWrapper>
    </Label>
  )
}

const LocalisedHorizontal = ({
  title,
  domain,
  field,
  value,
  valid,
  required = false,
  change,
  ...rest }) => {
  return (
    <Label title={title}>
      <LocaleWrapperHorizontal
        domain={domain}
        value={value}
        field={field}
        change={change}
        valid={valid}
        required={required}
      >
        <InputText field={field} {...rest} />
      </LocaleWrapperHorizontal>
    </Label>
  )
}

export default InputTextWrapped

export {
  Raw,
  Localised,
  LocalisedHorizontal,
}
