import { createSchema, morphism } from 'morphism'
import api from 'api/product'

import { mapLocales, mapDefaults } from '_shared/libs/mapToSchema'

import { getSpec as getLayout } from '../../Layouts/form/Context'

import {
  checkValidID,
  isUnique,
} from '_shared/libs/validityChecks'

const spec = {
  entity_id: null,
  external_id: '',
  link: '',
  assets: null,
  location: {
    entity_id: null,
  },
  capacity_templates: [],
  type: 'vehicle',
  locales: {
    title: '',
    description: '',
    instructions: '',
  },
}

const defaults = {
  'external_id': '',
  'type': 'vehicle',
  'capacity_templates': [],
  'link': '',
}

const getSpec = (locales) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      link: 'link',
      assets: 'assets',
      location: {
        entity_id: 'location.entity_id',
      },
      capacity_templates: {
        path: 'capacity_templates',
        fn: (value, source) => {
          if (!value) return []
          return value.map(record => morphism(getLayout(locales), record))
        },
      },
      type: 'type',
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          title: '',
          description: '',
          instructions: '',
        }, value, locales),
      },
    },
    mapDefaults(defaults),
  )
}

const valid = {
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },

  locales: (record, value) => {
    return value
      .map(locale => locale.title)
      .some(locale => locale !== '')
  },
}

const handlers = {
  store: 'vehicles',
  unique_key: 'external_id',
  get: api.getVenue,
  new: api.createVenue,
  edit: (payload) => api.updateVenue(payload.entity_id, payload),
  remove: (payload) => {},
  cancel: null,
}

export {
  getSpec,
  spec,
  valid,
  handlers,
}
