import React, { Component } from 'react'

import styles from './calendar.scss'

import Container from 'containers/Container'
import Row from 'containers/Row'
import InlineTextBox from 'containers/InlineTextBox'

import differenceInDays from 'date-fns/difference_in_days'

import {
  generateMonthBlock,
} from 'libs/generateCalendarRange'

import {
  isBetween,
} from 'libs/date'

const groupSequentialDates = (dates) => {
  return dates.reduce((range, date, i) => {
    if (i === 0) {
      range.push([date])

      return range
    }

    if (differenceInDays(date, dates[i - 1]) > 1) range.push([])

    range[range.length - 1].push(date)

    return range
  }, [])
}

const nullFunction = () => {
  console.log('OUT OF RANGE DATE')
}

class Calendar extends Component {
  getDays = () => {
    const {
      selected,
      date,
      action,
      disabled,
    } = this.props

    const ranges = groupSequentialDates(selected)

    return generateMonthBlock(date).map(item => {
      if (disabled.some(dates => isBetween(dates.min, dates.max, item.ISO))) {
        item.disabled = true
      }

      const active = selected.includes(item.ISO)

      const inRange = ranges.find(range => range.includes(item.ISO))

      let position = null

      if (inRange && inRange.length > 1) {
        const index = inRange.findIndex(instance => instance === item.ISO)

        position = index === 0 ? 'start' : index === (inRange.length - 1) ? 'end' : 'current'
      }

      return (
        <Day
          key={item.ISO}
          label={item.string.dateShort}
          date={item.ISO}
          active={active}
          position={position}
          ghosted={item.ghosted}
          disabled={item.disabled}
          action={item.disabled ? nullFunction : action}
        />
      )
    })
  }

  render () {
    return (
      <Container
        styling={styles.layout}
      >
        {this.getDays()}
      </Container>
    )
  }
}

const Day = (props) => {
  const {
    label,
    date,
    ghosted,
    disabled,
    active,
    action,
    position,
  } = props

  const styling = [styles.day]

  if (!active && ghosted) styling.push(styles.ghosted)
  if (disabled) styling.push(styles.disabled)

  return (
    <Row
      styling={styling.join(' ')}
      onMouseDown={(event) => {
        event.preventDefault()

        action(date)
      }}
    >
      {active && <Selected position={position} />}
      <InlineTextBox
        styling={active ? styles.active : styles.label}
      >
        {label}
      </InlineTextBox>
    </Row>
  )
}

const Selected = (props) => {
  const {
    position,
  } = props

  const styling = [styles.selected]

  if (position) styling.push(styles[`pos_${position}`])

  return (
    <Container
      styling={styling.join(' ')}
    />
  )
}

export default Calendar
