import React from 'react'

import ErrorBoundary from 'containers/ErrorBoundary'
import styles from './view.scss'

const View = (props) => {
  return (
    <ErrorBoundary>
      <div className={styles.layout} data-type={'form'}>
        {props.children}
      </div>
    </ErrorBoundary>
  )
}

export default View
