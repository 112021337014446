import React, { useCallback } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'

const mask = (event) => {
  let value = event.target.value.replace(/[^0-9:]/g, '')

  let split = value.split(':')

  if (split.length > 2) split = split.slice(0, 2)

  if (split.length > 1) {
    split = split.map(item => item.slice(0, 2))

    value = split.join(':')
  } else {
    if (value.length > 2) {
      value = value.match(/[0-9]{1,2}/g).join(':')
    }
  }

  return value
}

const InputTime = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  min,
  max,
  ...style
}) => {
  const handleChange = useCallback((event) => {
    const value = mask(event)

    change(field, value)
  }, [change, field])

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={value}
        change={handleChange}
        margin={margin}
        disabled={disabled}
      />
    </StatefulWrapper>
  )
}

const InputIntegerWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputTime {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputTime {...props} />
  )
}

export default InputIntegerWrapped

export {
  Raw,
}
