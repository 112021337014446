import storage from 'libs/asyncStorage'
import Config from 'libs/Config'

const fetch = window.fetch

const errorCodes = [
  400,
  403,
  404,
  500,
  '400',
  '403',
  '404',
  '500',
]

const defaultOptions = (method = 'GET', token, data) => {
  return {
    method,
    headers: {
      'content-type': 'application/json',
      'accept': 'application/json',
      'Authorization': token,
    },
    body: data ? JSON.stringify(data) : undefined,
  }
}

export default class TicketingApi {
  // Call API with expected empty response
  triggerApi(endpoint, method = 'GET', data) {
    const BASE_URL = process.env.REACT_APP_API_AUTH_URL || Config.app.gatewayURL
    return storage.get('token')
      .then(token => {
        return fetch(`${BASE_URL}/${endpoint}`, defaultOptions(method, token, data))
          .then(response => {
            if (response.ok) {
              return true
            } else {
              throw response.json()
            }
          })
          .catch(err => {
            console.error('Generic fetch error', err)
            throw err
          })
      })
  }

  // Call API with expected JSON resource as return
  callApi(endpoint, method = 'GET', data) {
    const BASE_URL = process.env.REACT_APP_API_AUTH_URL || Config.app.gatewayURL
    return storage.get('token')
      .then(token => {
        return fetch(`${BASE_URL}/${endpoint}`, defaultOptions(method, token, data))
          .then(this.handleErrors)
          .then(response => response.json())
          .then(data => {
            if (data === null) {
              throw new Error('API returned null')
            }

            if (data.hasOwnProperty('code') && errorCodes.includes(data.code)) {
              console.log('Got error throwing', data)
              throw data
            }

            return data
          })
          .catch(err => {
            // console.error('Generic fetch error', err)
            throw err
          })
      })
  }

  // Call API with expected JSON resource as return
  callApiNoToken(endpoint, method = 'GET', data) {
    const BASE_URL = process.env.REACT_APP_API_AUTH_URL || Config.app.gatewayURL
    return fetch(
      `${BASE_URL}/${endpoint}`, {
        method,
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
        body: data ? JSON.stringify(data) : undefined,
      })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(data => {
        if (data === null) {
          throw new Error('API returned null')
        }

        if (data.hasOwnProperty('code') && errorCodes.includes(data.code)) {
          console.log('Got error throwing', data)
          throw data
        }

        return data
      })
      .catch(err => {
        // console.error('Generic fetch error', err)
        throw err
      })
  }

  // Fetch top level error handler
  handleErrors = response => !response.ok
    ? response.json().then(error => {
      throw error
    })
    : response
}
