import React from 'react'

import styles from './view.scss'

const View = ({ children }) => {
  return (
    <div className={styles.layout} data-type={'form'}>
      {children}
    </div>
  )
}

export default View
