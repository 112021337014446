import Base from './base'

class ProductApi extends Base {
  /* Capacity Types */
  listCapacities = () => this.callApiFlatten('capacity-types')
  createCapacity = data => this.callApi('capacity-types', 'POST', data)
  getCapacity = id => this.callApiFlatten(`capacity-types/${id}`)
  updateCapacity = (id, data) => this.callApi(`capacity-types/${id}`, 'PUT', data)
  /* Locations */
  listLocations = () => this.callApiFlatten('locations')
  createLocation = data => this.callApi('locations', 'POST', data)
  getLocation = id => this.callApiFlatten(`locations/${id}`)
  updateLocation = (id, data) => this.callApi(`locations/${id}`, 'PUT', data)
  /* Ticket Types */
  listTicketTypes = () => this.callApiFlatten('ticket-types')
  createTicketType = data => this.callApi('ticket-types', 'POST', data)
  getTicketType = id => this.callApiFlatten(`ticket-types/${id}`)
  updateTicketType = (id, data) => this.callApi(`ticket-types/${id}`, 'PUT', data)
  /* Ticket Type Groups */
  listTicketTypeGroups = () => this.callApiFlatten('ticket-type-groups')
  createTicketTypeGroup = data => this.callApi('ticket-type-groups', 'POST', data)
  getTicketTypeGroup = id => this.callApiFlatten(`ticket-type-groups/${id}`)
  updateTicketTypeGroup = (id, data) => this.callApi(`ticket-type-groups/${id}`, 'PUT', data)
  /* Services */
  listServices = () => this.callApiFlatten('services')
  createService = data => this.callApi('services', 'POST', data)
  getService = id => this.callApiFlatten(`services/${id}`)
  updateService = (id, data) => this.callApi(`services/${id}`, 'PUT', data)
  /* Products */
  listProducts = () => this.callApiMergePublish('products')
  listProductsExpanded = (expand) => this.callApiMergePublishExpand('products', expand)
  createProduct = data => this.callApi('products', 'POST', data)
  getProduct = id => this.callApiFlatten(`products/${id}`)
  updateProduct = (id, data) => this.callApi(`products/${id}`, 'PUT', data)
  publishProduct = (id) => this.callApi(`products/${id}/publications`, 'POST', {})
  /* Combos */
  listCombos = () => this.callApiMergePublish('combos')
  createCombo = data => this.callApi('combos', 'POST', data)
  getCombo = id => this.callApiFlatten(`combos/${id}`)
  updateCombo = (id, data) => this.callApi(`combos/${id}`, 'PUT', data)
  publishCombo = id => this.callApi(`combos/${id}/publications`, 'POST', {})
  /* Promos */
  listPromos = () => this.callApiMergePublish('promotions')
  createPromo = data => this.callApi('promotions', 'POST', data)
  getPromo = id => this.callApiFlatten(`promotions/${id}`)
  updatePromo = (id, data) => this.callApi(`promotions/${id}`, 'PUT', data)
  publishPromo = id => this.callApi(`promotions/${id}/publications`, 'POST', {})
  /* Venues */
  listVenues = () => this.callApiFlatten('venues')
  createVenue = data => this.callApi('venues', 'POST', data)
  getVenue = id => this.callApiFlatten(`venues/${id}`)
  updateVenue = (id, data) => this.callApi(`venues/${id}`, 'PUT', data)
  /* Markets */
  listMarkets = () => this.callApiFlatten('markets')
  createMarket = data => this.callApi('markets', 'POST', data)
  getMarket = id => this.callApiFlatten(`markets/${id}`)
  updateMarket = (id, data) => this.callApi(`markets/${id}`, 'PUT', data)
  /* Terms */
  listTerms = () => this.callApiFlatten('terms')
  createTerm = data => this.callApi('terms', 'POST', data)
  getTerm = id => this.callApiFlatten(`terms/${id}`)
  updateTerm = (id, data) => this.callApi(`terms/${id}`, 'PUT', data)
  /* System Config */
  listSystemConfig = () => this.callApi('system-config')
  updateSystemConfig = (id, data) => this.callApi('system-config', 'PUT', data)
  /* Instance Templates */
  listInstanceTemplates = () => this.callApiFlatten('service-instance-templates')
  createInstanceTemplate = (data) => this.callApiFlatten('service-instance-templates', 'POST', data)
  getInstanceTemplate = (id) => this.callApiFlatten(`service-instance-templates/${id}`)
  updateInstanceTemplate = (id, data) => this.callApiFlatten(`service-instance-templates/${id}`, 'PUT', data)
  /* Instance Generation */
  generateDraftInstances = (data) => this.callApiFlatten('service-instances/generate', 'POST', data)
  generateActiveInstances = (data) => this.callApiFlatten('service-instances/generate/publications', 'POST', data)
  /* Instance Manipulation */
  listInstances = () => this.callApiFlatten('service-instances')
  createInstance = (data) => this.callApiFlatten('service-instances', 'POST', data)
  getInstance = (id) => this.callApiFlatten(`service-instances/${id}?expand=published_revision`)
  updateInstance = (id, data) => this.callApiFlatten(`service-instances/${id}`, 'PUT', data)
    .then(() => {
      return this.callApiFlatten(`service-instances/${id}/publications`, 'POST')
    })
  publishInstance = (id) => this.callApiFlatten(`service-instances/${id}/publications`, 'POST')
  /* Instance Listing */
  listServiceInstances = (service, options) => this.callApi(`services/${service}/instances?page=${options.page}&dates=${options.dates.join(',')}`, 'GET') // {{AdminGateway}}/services/{{ServiceEntityId}}/instances?page=1&dates=2021-03-01,2021-03-02&start_time=10:00&end_time=12:00&state=draft,published
  listServiceLocations = () => this.callApi('service-locations')
}

export default new ProductApi()
