import React, { Component } from 'react'

const FormContext = React.createContext({
  allValid: true,
  valid: {},
})

const withValidator = (spec) => {
  class Validator extends Component {
    constructor (props) {
      super(props)

      this.state = {
        override: false,
      }
    }

    checkCurrentValidity = () => {
      const keys = Object.keys(this.props.record)

      const valid = {}

      keys.forEach(key => {
        if (spec.hasOwnProperty(key)) {
          valid[key] = spec[key](this.props.record, this.props.record[key])
        } else {
          valid[key] = true
        }
      })

      return valid
    }

    allValid = () => {
      const keys = Object.keys(spec)

      const valid = keys.map(key => {
        if (this.props.record.hasOwnProperty(key)) {
          return spec[key](this.props.record, this.props.record[key])
        } else {
          return true
        }
      }).every(item => item === true)

      return valid
    }

    setOverride = (override) => {
      console.log('Setting override', override)
      this.setState({
        override,
      })
    }

    render () {
      const valid = this.checkCurrentValidity()
      const allValid = this.state.override ? false : this.allValid()

      return (
        <FormContext.Provider
          value={{
            valid,
            allValid,
            setOverride: this.setOverride,
          }}
        >
          {this.props.children}
        </FormContext.Provider>
      )
    }
  }

  return Validator
}

const validate = (Wrapped) => {
  const ValidateField = (props) => {
    return (
      <FormContext.Consumer>
        {validity => {
          const {
            valid,
          } = validity

          let status = null

          if (valid.hasOwnProperty(props.field)) {
            const statusValid = valid && props.field && valid[props.field]

            status = statusValid ? 'VALID' : 'INVALID'
          }

          return (
            <Wrapped
              required
              requiredText={'Field is required'}
              {...props}
              status={status}
            />
          )
        }}
      </FormContext.Consumer>
    )
  }

  return ValidateField
}

const formOverride = (Wrapped) => {
  const OverrideValidate = (props) => {
    return (
      <FormContext.Consumer>
        {validity => {
          const {
            setOverride,
          } = validity

          return (
            <Wrapped
              {...props}
              setOverride={setOverride}
            />
          )
        }}
      </FormContext.Consumer>
    )
  }

  return OverrideValidate
}

const formValid = (Wrapped) => {
  const ValidateFull = (props) => {
    return (
      <FormContext.Consumer>
        {validity => {
          const {
            allValid,
          } = validity

          return (
            <Wrapped
              {...props}
              status={allValid}
              disabled={!allValid}
            />
          )
        }}
      </FormContext.Consumer>
    )
  }

  return ValidateFull
}

export {
  withValidator,
  validate,
  formValid,
  formOverride,
}
