import React, { useState } from 'react'

import NavIcon from './NavIcon'

import View from '_shared/components/layout/View'
import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'

import { Raw as SideNavigation } from '_shared/components/navigation/SideNavigation'

import reports from './form/layout'

import Form from './form/index'

const options = reports
  .map(report => ({
    title: report.title,
    disabled: report.disabled,
    slug: report.type,
  }))

const getInitial = () => {
  const available = options.filter(option => !option.disabled)

  return available.length > 0 ? available[0].slug : null
}

const Reports = () => {
  const [
    selected,
    setSelected,
  ] = useState(getInitial())

  const current = reports.find(({ type }) => type === selected)

  return (
    <View>
      <Heading
        level={'section'}
        title={'Reports'}
      />
      <Area
        areas={[
          'nav content/3',
        ]}
      >
        <SideNavigation
          area={'nav'}
          options={options}
          selected={selected}
          action={setSelected}
        />
        {current && (
          <Form
            key={current.type}
            area={'content'}
            type={current.type}
            current={current}
          />
        )}
      </Area>
    </View>
  )
}

export default Reports

export {
  NavIcon,
}
