import React from 'react'

import Chooser from '../Chooser'

const ChooserProduct = ({
  data,
  change,
}) => {
  return (
    <Chooser
      field={'products'}
      label={'Filter products'}
      data={data}
      change={change}
      dependency={'products'}
    />
  )
}

export default ChooserProduct
