import React, { Component } from 'react'

import styles from './textbox.scss'

import styleCombine from 'libs/styleCombine'
import clamp from 'libs/clamp'

class TextBox extends Component {
  constructor (props) {
    super(props)
    this.container = React.createRef()
  }

  componentDidMount () {
    if (this.props.truncate) {
      clamp(this.container.current, 3, true)
    }
  }

  componentDidUpdate () {
    if (this.props.truncate) {
      clamp(this.container.current, 3, true)
    }
  }

  render () {
    const {
      children,
      styling,
      bold,
      noMargin = false,
      ...rest
    } = this.props

    delete rest.truncate

    return (
      <p
        ref={this.container}
        className={styleCombine(styles.layout, bold && styles.bold, noMargin && styles.noMargin, styling)}
        {...rest}
      >
        {children}
      </p>
    )
  }
}

export default TextBox
