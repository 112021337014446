import React, { useState } from 'react'

import useNavigationView from '_shared/hooks/useNavigationView'

import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'
import Heading from '_shared/components/layout/Heading'
import StepTab from '_shared/components/element/StepTab'
import ActionCTA from '_shared/components/action/ActionCTA'

import Row from '_shared/components/layout/Row'

import ShadowWrapper from '_shared/components/layout/ShadowWrapper'

/*
  options = [
    {
      label: 'Description',
      status: 'VALID',
      view: View
      slug: 'description'
    }
  ]
*/

const SteppedHeader = ({
  title,
  options = [], cancel, status, passProps, ...style }) => {
  const {
    selected,
    changeView,
  } = useNavigationView(options)

  const current = options.find(option => option.slug === selected)

  const View = current.view

  return (
    <Area
      areas={[
        'nav',
        'content',
      ]}
      columns={2}
    >
      <Raw
        area={'nav'}
        title={title}
        options={options}
        selected={selected}
        status={status}
        action={changeView}
      />
      <View area={'content'} {...passProps} />
    </Area>
  )
}

const SteppedGroup = ({
  options,
  selected,
  ...style
}) => {
  return (
    <Row {...style}>
      {options.map((option, index) => {
        const {
          label,
          slug,
        } = option

        return (
          <StepTab
            key={slug}
            title={`Step ${index + 1}:`}
            label={label}
            active={selected === slug}
          />
        )
      })}
    </Row>
  )
}

const Raw = ({
  title,
  options = [],
  selected,
  action,
  cancel,
  status,
  change,
  ...style
}) => {
  const current = options.findIndex(option => option.slug === selected)

  const disabled = options[current].hasOwnProperty('validKey') && status[options[current].validKey] ? !status[options[current].validKey] : true

  return (
    <ShadowWrapper {...style} background={'background_module'}>
      <Area
        areas={[
          '. title/2 .',
          '. tabs/2 action',
        ]}
        colgap={1.5}
        rowgap={1}
        columns={['2rem', '1fr', '1fr', '1fr']}
        rows={['4rem', '1fr']}
      >
        <Heading level={'1'} title={title} area={'title'} gridAlign={'center'} color={'heading_section'} />
        <SteppedGroup
          area={'tabs'}
          options={options}
          action={action}
          selected={selected}
        />
        {options[current + 1] && (
          <ActionCTA area={'action'} label={`Next: ${options[current + 1].label}`} change={change} disabled={disabled}/>
        )}
      </Area>
    </ShadowWrapper>
  )
}

const StateSteppedHeader = ({
  title,
  options = [],
  valid,
  cancel,
  status,
  passProps,
  ...style
}) => {
  const [
    selected,
    setSelected,
  ] = useState(options[0].slug)

  const current = options.findIndex(option => option.slug === selected)

  const changeView = () => {
    setSelected(options[current + 1].slug)
  }

  const View = options[current].view

  return (
    <Container>
      <Raw
        area={'nav'}
        title={title}
        options={options}
        selected={selected}
        status={valid}
        change={changeView}
        rawStyle={{ position: 'sticky', top: 0, zIndex: 1 }}
      />
      <View area={'content'} {...passProps} />
    </Container>
  )
}

export default SteppedHeader

export {
  Raw,
  SteppedGroup,
  StateSteppedHeader,
}
