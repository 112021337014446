import React from 'react'

import Container from '_shared/components/layout/Container'
import InputText from '_shared/components/input/InputText'

const Text = (props) => {
  const {
    title,
    area,
    field,
    placeholder,
    value,
    update,
  } = props

  return (
    <Container area={area}>
      <InputText
        title={title}
        field={field}
        change={update}
        value={value}
        placeholder={placeholder}
      />
    </Container>
  )
}

export default Text
