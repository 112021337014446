import { createSchema } from 'morphism'

import {
  mapDefaults,
} from '_shared/libs/mapToSchema'

const getPriceAdjustmentSchema = () => {
  return createSchema(
    {
      type: 'type',
      value: 'value',
      market_id: 'market_id',
      channel: 'channel',
      ticket_type_id: {
        path: 'ticket_type_id',
        fn: (value) => {
          if (value === null || !value) {
            return 'all'
          } else {
            return value
          }
        },
      },
      location_id: 'location_id',
      end_location_id: 'end_location_id',
      target: 'target',
      all_ticket_types: 'all_ticket_types',
    },
    mapDefaults({
      'type': 'increment_percent',
      'all_ticket_types': false,
      'target': 'price',
      'value': 0,
    }),
  )
}

export default getPriceAdjustmentSchema
