import React, { useState, useEffect } from 'react'

import styles from './modal.scss'

import ReactDOM from 'react-dom'

import styleCombine from '_shared/libs/styleCombine'

const ModalWrapper = ({
  children,
  isMounted = false,
  style = {},
}) => {
  const [
    shouldRender,
    setRender,
  ] = useState(isMounted)

  useEffect(() => {
    if (isMounted) setRender(true)
  }, [isMounted])

  if (!document.getElementById('portal')) return null

  const onAnimationEnd = () => {
    if (!isMounted) setRender(false)
  }

  return ReactDOM.createPortal(
    (
      shouldRender &&
      <div className={styles.layout}>
        <div style={style} className={styleCombine(styles.content, isMounted ? styles.in : styles.out)} onAnimationEnd={onAnimationEnd}>
          {children}
        </div>
      </div>
    ),
    document.getElementById('portal'),
  )
}

export default ModalWrapper
