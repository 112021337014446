import React, { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import FormView from '_shared/components/layout/FormView'
import Container from '_shared/components/layout/Container'
import ActionCTA from '_shared/components/action/ActionCTA'

import api from 'api/product'

import {
  createNotification,
} from '_shared/components/notification/Toasts/actions'

import {
  useQueryClient,
} from 'react-query'

const FormPublish = ({
  service,
  save,
  current,
  updateSingle,
}) => {
  const queryClient = useQueryClient()

  const reduxDispatch = useDispatch()

  const setBusyState = useCallback(busy => {
    reduxDispatch({
      type: busy ? 'UI_BUSY' : 'UI_NOT_BUSY',
    })
  }, [reduxDispatch])

  const notify = payload => reduxDispatch(createNotification(payload))

  const publish = async (active = false) => {
    setBusyState(true)

    let response

    try {
      if (active) {
        response = await api.generateActiveInstances(current)
      } else {
        response = await api.generateDraftInstances(current)
      }

      await queryClient.refetchQueries([`instance_list_${service}`])

      console.log('INSTANCE CREATION STATUS', response)
      notify({
        type: 'new',
        message: 'Schedule generation successful',
      })
    } catch (error) {
      console.log(error)

      notify({
        type: 'error',
        message: 'Schedule generation failed',
      })
    }

    setBusyState(false)

    save()
  }

  return (
    <FormView>
      <Area
        areas={[
          'now/2 later/2 . .',
          'action_now/2 action_later/2 . .',
        ]}
        rowgap={1}
      >
        <Container area={'now'}>
          <Heading level={3} title={'Publish now'} />
          <TextBox>{'Make sure the departure content is correct and no sign-off is required before publishing.'}</TextBox>
        </Container>
        <Container area={'later'}>
          <Heading level={3} title={'Publish later'} />
          <TextBox>{'If you need to do adjustments, add additional departures, get sign-off or check the instances in the reservations preview mode, publish later.'}</TextBox>
        </Container>
        <ActionCTA label={'Publish Now'} area={'action_now'} change={() => publish(true)}/>
        <ActionCTA label={'Publish Later'} area={'action_later'} change={() => publish()}/>
      </Area>
    </FormView>
  )
}

export default FormPublish
