import React from 'react'

import styles from './formview.scss'

import Container from 'containers/Container'

import styleCombine from 'libs/styleCombine'

const FormView = (props) => {
  const {
    noScroll,
    compact = false,
    styling,
    children,
  } = props

  return (
    <Container
      styling={styleCombine(styles.layout, noScroll && styles.no_scroll, styling)}
      data-type={'form'}
    >
      <Container styling={compact ? styles.wrapper_compact : styles.wrapper}>
        {children}
      </Container>
    </Container>
  )
}

export default FormView
