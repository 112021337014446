import TicketingApi from './user'

class LoginApi extends TicketingApi {
  login = (data) => this.callApiNoToken('users/login', 'POST', data)

  challenge = (data) => this.callApiNoToken('users/challenge', 'POST', data)

  forgot = (data) => this.triggerApi('users/forgot/start', 'POST', data)

  forgotConfirm = (data) => this.callApiNoToken('users/forgot/confirm', 'POST', data)

  getProfiles = () => {
    let users = []

    const getUntil = (token) => {
      if (token) {
        return this.callApi(`users/profiles?paginationToken=${encodeURIComponent(token)}`)
          .then(response => {
            if (response.users) {
              users = [...users, ...response.users]
            }

            if (response.paginationToken) {
              return getUntil(response.paginationToken)
            } else {
              return users
            }
          })
          .catch(error => {
            console.log(error)

            return users
          })
      } else {
        return this.callApi('users/profiles')
          .then(response => {
            if (response.users) {
              users = [...users, ...response.users]
            }

            if (response.paginationToken) {
              return getUntil(response.paginationToken)
            } else {
              return users
            }
          })
          .catch(error => {
            console.log(error)

            return users
          })
      }
    }

    return getUntil()
      .then(response => {
        return response
      })
  }

  getProfile = () => {
    return this.callApi('users/profiles/me')
      .then(response => {
        return response
      })
      .catch(error => {
        console.log('Error in auth', error)

        throw error
      })
  }

  getUserProfile = (id) => this.callApi(`users/profiles/${id}`, 'GET')
  createProfile = (data) => this.callApi('users/profiles', 'POST', data)
  editProfile = (id, data) => this.callApi(`users/profiles/${id}`, 'PUT', data)
  resetUser = (id) => this.triggerApi(`users/profiles/${id}/resend`, 'POST', {})
}

export default new LoginApi()
