import React from 'react'

const UserAdminIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
    >
      <path
        fill={fill}
        d='M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm5.78,10.14a3.09,3.09,0,1,1-3.09,3.09A3.08,3.08,0,0,1,21.78,10.14Zm-9.6-2.52A3.78,3.78,0,1,1,8.4,11.39,3.77,3.77,0,0,1,12.18,7.62Zm0,14.6v6A12.84,12.84,0,0,1,4,20.29c1.68-1.79,5.87-2.71,8.23-2.71a15.93,15.93,0,0,1,3,.36C12.59,19.33,12.18,21.17,12.18,22.22ZM16,28.8q-.64,0-1.26-.06V22.22c0-2.27,4.7-3.4,7-3.4,1.71,0,4.67.62,6.14,1.84A12.83,12.83,0,0,1,16,28.8Z'
      />
    </svg>
  )
}

export default UserAdminIcon