import { useRef } from 'react'

import {
  useQuery,
  useQueries,
} from 'react-query'

import useToast from '_shared/hooks/useToast'

import product from 'api/product'
import login from 'api/login'

const handlers = {
  'capacity_types': product.listCapacities,
  'locations': product.listLocations,
  'ticket_types': product.listTicketTypes,
  'ticket_groups': product.listTicketTypeGroups,
  'services': product.listServices,
  'products': product.listProducts,
  'products_expand_market': () => product.listProductsExpanded('last_revision.sales_limitations.markets'),
  'combos': product.listCombos,
  'promos': product.listPromos,
  'venues': product.listVenues,
  'vehicles': product.listVenues,
  'markets': product.listMarkets,
  'config': product.listSystemConfig,
  'terms': product.listTerms,
  'instance_templates': product.listInstanceTemplates,
  'instances': product.listInstances, // TODO: Update this with the correct path once we have a filter
  'profiles': login.getProfiles,
}

const callMultipleDependencies = (dependencies) => {
  const list = dependencies.map(dependency => {
    return handlers[dependency]
  })

  return () => Promise.all(list.map(apiCall => {
    return apiCall()
  }))
    .then(responses => {
      let output = {}

      responses.forEach((response, index) => {
        output[dependencies[index]] = response
      })

      return output
    })
    .catch(err => {
      console.log('error', err)

      throw err
    })
}

const useDependencies = (key, decorator) => {
  const errorSent = useRef(false)
  const send = useToast()

  const queryKey = key
  let apiCall = handlers[key]
  let defaultResponse = []

  if (Array.isArray(key)) {
    apiCall = callMultipleDependencies(key)

    defaultResponse = key
      .reduce((response, key) => {
        response[key] = []

        return response
      }, {})
  }

  const {
    isFetching,
    isSuccess,
    isError,
    data,
  } = useQuery(queryKey, apiCall, {
    retry: 0,
    staleTime: 1000 * 60 * 2,
  })

  if (isSuccess) return decorator ? { isFetching, isLoaded: true, data: decorator(data) } : { isFetching, isLoaded: true, data }

  if (isError && errorSent.current === false) {
    send({
      type: 'error',
      message: 'Failed to load',
    })
    console.log('Error in fetch')
    errorSent.current = true
    return {
      isFetching,
      isLoaded: true,
      data: defaultResponse,
    }
  }

  return {
    isFetching,
    isLoaded: isSuccess,
    data: defaultResponse,
  }
}

export default useDependencies