import React from 'react'
import StyleWrapper from '_shared/style/StyleWrapper'

const Container = ({ children, ...style }) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div style={styling}>
            {children}
          </div>
        )
      }}
    />
  )
}

Container.styleable = true

export default Container
