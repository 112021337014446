import React from 'react'

import Row from '_shared/components/layout/Row'
import Waiting from '_shared/components/element/Waiting'

const LoadIndicator = () => {
  return (
    <Row type={'centered'} cover padding={'1rem 0 0 0'}>
      <Waiting color={'nav_icon'} />
    </Row>
  )
}

export default LoadIndicator
