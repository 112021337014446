import { createSchema } from 'morphism'
import api from 'api/product'

import {
  mapLocales,
  mapDefaults,
  mapToArraySubSchema,
} from '_shared/libs/mapToSchema'

import {
  checkValidID,
  isUnique,
} from '_shared/libs/validityChecks'

import {
  filterOnSave,
  getPriceAdjustmentSchema,
} from 'templates/PriceAdjustMatrix'

const sanitizeTime = (time) => {
  if (!time) return null

  const [
    hours,
    minutes,
  ] = time.split(':')

  return `${hours}:${minutes}`
}

const getDateConditionSchema = () => {
  return createSchema(
    {
      start_date: 'start_date',
      end_date: 'end_date',
      start_time: {
        path: 'start_time',
        fn: sanitizeTime,
      },
      end_time: {
        path: 'end_time',
        fn: sanitizeTime,
      },
      before_offset_from_now: 'before_offset_from_now',
      after_offset_from_now: 'after_offset_from_now',
      day_of_week: 'day_of_week',
    },
    mapDefaults({
      'start_date': new Date().toISOString().split('T')[0],
      'end_date': new Date().toISOString().split('T')[0],
      'day_of_week': [0, 1, 2, 3, 4, 5, 6],
    }),
  )
}

const getProductSelectionSchema = (locales) => {
  return createSchema(
    {
      conditions: {
        date: {
          path: 'conditions.date',
          fn: mapToArraySubSchema(getDateConditionSchema),
        },
      },
      locales: {
        path: 'locales',
        fn: (value) => mapLocales({
          discount_description: '',
        }, value, locales),
      },
      price_adjustment: {
        path: 'price_adjustment',
        fn: mapToArraySubSchema(getPriceAdjustmentSchema, locales),
      },
      product_options: {
        path: 'product_options',
        fn: mapToArraySubSchema(getEntitySchema, locales),
      },
    },
    mapDefaults({
      'conditions.date': [],
    }),
  )
}

const getEntitySchema = () => {
  return createSchema(
    {
      entity_id: 'entity_id',
    },
  )
}

const getSchema = (locales) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      auto_apply: 'auto_apply',
      promo_code: 'promo_code',
      required_ticket_qty: 'required_ticket_qty',
      searchable: 'searchable',
      combo_limitations: {
        fixed_order: 'combo_limitations.fixed_order',
        max_total_interval: 'combo_limitations.max_total_interval',
        return: 'combo_limitations.return',
        same_day: 'combo_limitations.same_day',
      },
      locales: {
        path: 'locales',
        fn: (value) => mapLocales({
          description: '',
          terms: '',
          terms_url: '',
          title: '',
          short_title: '',
          short_description: '',
        }, value, locales),
      },
      product_selection: {
        path: 'product_selection',
        fn: mapToArraySubSchema(getProductSelectionSchema, locales),
      },
      sales_limitations: {
        channels: {
          path: 'sales_limitations.channels',
          fn: (value) => {
            if (!value) return []
            return value
          },
        },
        date: {
          start_date: 'sales_limitations.date.start_date',
          end_date: 'sales_limitations.date.end_date',
          start_time: 'sales_limitations.date.start_time',
          end_time: 'sales_limitations.date.end_date',
          before_offset_from_now: 'sales_limitations.date.before_offset_from_now',
          after_offset_from_now: 'sales_limitations.date.after_offset_from_now',
          day_of_week: {
            path: 'sales_limitations.date.day_of_week',
            fn: (value) => {
              if (!value) return []
              return value
            },
          },
        },
        markets: {
          path: 'sales_limitations.markets',
          fn: mapToArraySubSchema(getEntitySchema, locales),
        },
      },
      extensions: 'extensions',
      max_sale_period: 'max_sale_period',
      max_sale_qty: 'max_sale_qty',
    },
    mapDefaults({
      'external_id': '',
      'auto_apply': true,
      'required_ticket_qty': 1,
      'searchable': true,
      'combo_limitations.fixed_order': false,
      'combo_limitations.max_total_interval': 'P10M',
      'combo_limitations.return': true,
      'combo_limitations.same_day': false,
      'extensions': {},
    }),
  )
}

const valid = {
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },
  locales: (record, value) => {
    return value
      .map(locale => locale.title)
      .some(locale => locale !== '')
  },
}

// Filter price adjustment effects to remove empty and unused values
const filterPriceAdjustment = (payload) => {
  const {
    product_selection,
    ...rest
  } = payload

  const cleaned = {
    ...rest,
    product_selection: product_selection
      .map(selection => {
        const {
          price_adjustment,
          ...rest
        } = selection

        return {
          ...rest,
          price_adjustment: filterOnSave(price_adjustment),
        }
      }),
  }

  return cleaned
}

const handlers = {
  store: 'combos',
  unique_key: 'external_id',
  get: api.getCombo,
  new: (payload) => {
    const cleaned = filterPriceAdjustment(payload)

    return api.createCombo(cleaned)
  },
  edit: (payload) => {
    const cleaned = filterPriceAdjustment(payload)

    return api.updateCombo(payload.entity_id, cleaned)
  },
  remove: () => {},
  cancel: null,
}

export {
  getSchema,
  getProductSelectionSchema,
  getEntitySchema,
  valid,
  handlers,
}
