import React from 'react'

import IconButton from '_shared/components/element/IconButton'

import AddIcon from '_shared/icons/AddRound'

const ActionAdd = ({ label, change, collapsed, disabled, ...style }) => {
  return (
    <IconButton
      icon={AddIcon}
      change={change}
      label={label}
      collapsed={collapsed}
      color={'button'}
      disabled={disabled}
      {...style}
    />
  )
}

export default ActionAdd
