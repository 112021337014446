import React from 'react'

import sortBy from 'lodash/sortBy'

import FormView from '_shared/components/layout/FormView'

import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import ListTable from '_shared/components/navigation/ListTable'

import Tick from '_shared/components/element/Tick'
import InputDuration, { Raw as InputDurationSmall } from '_shared/components/input/InputDuration'
import { LocalisedHorizontal as InputTextLocalised } from '_shared/components/input/InputText'
import InputCheckBox from '_shared/components/input/InputCheckBox'

import TransferIcon from '_shared/icons/Transfer'

import useDependencies from '_shared/hooks/useDependencies'
import useLocale from '_shared/hooks/useLocale'
import { useGetLocalValueFunc } from '_shared/hooks/useGetLocaleValue'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import mapToSchema from '_shared/libs/mapToSchema'

import {
  getTransferSpec,
} from './Context'

import Config from 'libs/Config'

const FormTransfer = ({
  current,
  updateSingle,
}) => {
  const {
    locales,
    currencies,
    default_locale,
  } = useLocale()

  const title = useGetLocalValueFunc('title')(current)

  const { data: locations } = useDependencies('locations', data => data
    .filter(location => location.entity_id !== current.entity_id)
    .map(location => ({
      label: getDeepLocale(location.locales, 'title', default_locale),
      value: location.entity_id,
    })),
  )

  const transfers = sortBy(locations
    .map(location => {
      const exists = current.transfers.find(transfer => transfer.location.entity_id === location.value)

      const {
        duration,
        locales: internalLocales,
      } = mapToSchema(locales, currencies, getTransferSpec)(exists || {})

      return {
        id: location.value,
        transfer: location.label,
        enabled: Boolean(exists),
        duration,
        instruction: internalLocales,
      }
    }), ['transfer'])

  const handleToggle = (entity_id) => {
    const state = [...current.transfers]
    const exists = state.findIndex(transfer => transfer.location.entity_id === entity_id)

    if (exists !== -1) {
      state.splice(exists, 1)
    } else {
      const record = mapToSchema(locales, currencies, getTransferSpec)({
        location: {
          entity_id,
        },
      })

      state.push(record)
    }

    updateSingle('transfers', state)
  }

  const handleChange = (entity_id) => (field, value) => {
    const state = [...current.transfers]
    const row = state.findIndex(transfer => transfer.location.entity_id === entity_id)

    updateSingle(`transfers[${row}]${field}`, value)
  }

  return (
    <FormView>
      <Area columns={1}>
        <Area
          areas={[
            'internal',
            'external',
          ]}
          rowgap={3}
        >
          <Area
            area={'internal'}
            areas={[
              'section',
              'info/2 .',
              'chooser',
            ]}
            rowgap={1.5}
          >
            <SectionWrapper area={'section'}>
              <Heading level={1} title={'Transfer at this station'} />
            </SectionWrapper>
            <TextBox area={'info'} noMargin>{'Allow customers to use this station for transferring between routes for multi leg travel.'}</TextBox>
            <ChooserInternal
              area={'chooser'}
              current={current}
              change={handleChange(current.entity_id)}
              toggle={handleToggle}
            />
          </Area>
          <Area
            area={'external'}
            areas={[
              'section',
              'info/2 .',
              'chooser',
            ]}
            rowgap={1.5}
          >
            <SectionWrapper area={'section'}>
              <Heading level={1} title={'Transfer via another station'} />
            </SectionWrapper>
            <TextBox area={'info'} noMargin>{'Allow customers to travel to other locations via alternative means like walking, driving or public transport as a transfer between routes.'}</TextBox>
            <ChooserExternal
              area={'chooser'}
              title={title}
              value={transfers}
              change={handleChange}
              toggle={handleToggle}
            />
          </Area>
        </Area>
      </Area>
    </FormView>
  )
}

const ChooserInternal = ({
  current,
  change,
  toggle,
}) => {
  const selected = current.transfers.find(({ location }) => location.entity_id === current.entity_id)

  return (
    <Area
      areas={[
        'toggle',
        '. form/2 . .',
      ]}
      rowgap={1.5}
      colgap={0}
      columns={['2rem', '1fr', '1fr', '1fr', '1fr']}
    >
      <InputCheckBox
        area={'toggle'}
        title={'Enable internal transferring at this location'}
        value={Boolean(selected)}
        change={() => toggle(current.entity_id)}
      />
      <Area
        area={'form'}
        areas={[
          'chooser .',
          'info',
        ]}
        rowgap={1}
      >
        <InputDuration
          area={'chooser'}
          title={'Mininum transfer between legs'}
          field={'duration'}
          change={change}
          disabled={!selected}
          value={selected ? selected.duration : 'P0D'}
        />
        <TextBox area={'info'} noMargin>
          {'This is used as a mandatory time gap between legs in the timetable to make sure the customer can make their ongoing journey. Make sure to include checkin and passport time in the value above.'}
        </TextBox>
      </Area>
    </Area>
  )
}

const columns = [
  {
    key: 'transfer',
    label: 'transfer',
  },
  {
    key: 'enabled',
    label: 'enable',
  },
  {
    key: 'duration',
    label: 'time gap',
  },
  {
    key: 'instruction',
    label: 'instructions',
  },
]

const ChooserExternal = ({
  title,
  value: collection,
  change,
  toggle,
}) => {
  const mutatedData = collection.map(({
    id,
    transfer,
    enabled,
    duration,
    instruction,
  }) => {
    return {
      id,
      transfer: (
        <Row type={'start'}>
          <InlineTextBox color={'text_mid'}>{title}</InlineTextBox>
          <TransferIcon size={16} fill={Config.theme.text} />
          <InlineTextBox>{transfer}</InlineTextBox>
        </Row>
      ),
      enabled: (
        <Tick active={enabled} change={() => toggle(id)} width={'2rem'} />
      ),
      duration: (
        <InputDurationSmall
          field={'duration'}
          value={duration}
          change={change(id)}
          disabled={!enabled}
          width={'6rem'}
          minHeight={'2rem'}
        />
      ),
      instruction: (
        <InputTextLocalised value={instruction} field={'description'} change={change(id)} disabled={!enabled} width={'10rem'} minHeight={'2rem'} />
      ),
    }
  })

  return (
    <ListTable
      columns={columns}
      data={mutatedData}
    />
  )
}

export default FormTransfer
