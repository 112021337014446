import React, { Fragment } from 'react'

import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'
import Row from '_shared/components/layout/Row'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import ActionCTA from '_shared/components/action/ActionCTA'

import ListTable from '_shared/components/navigation/ListTable'

import deepSort from 'libs/deepSort'

import useLocale from '_shared/hooks/useLocale'

import useDependencies from '_shared/hooks/useDependencies'

import useNav from '_shared/hooks/useNav'

import Consumer from './form/Consumer'

import {
  handlers,
} from './form/Context'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

const columns = [
  {
    key: 'title',
    label: 'title',
    sortable: true,
  },
  {
    key: 'external',
    label: 'ID',
    sortable: true,
  },
]

const LocationsList = () => {
  const {
    default_locale,
  } = useLocale()

  const {
    open,
  } = useNav('subview')

  const { isFetching, data } = useDependencies('locations', data => {
    return data.map(record => {
      return {
        id: record.entity_id,
        external: record.external_id,
        title: getDeepLocale(record.locales, 'title', default_locale),
      }
    })
  })

  return (
    <Area
      areas={[
        'description',
        'nav',
        'list',
      ]}
      rowgap={1.5}
    >
      <ListTable
        area={'list'}
        columns={columns}
        data={deepSort(data, 'title')}
        edit={open}
        loading={isFetching}
      />
      <Container area={'description'}>
        <Heading level={2} title={'Locations & Stations'} />
        <TextBox strong>{'Locations can be stations, piers and stops. They are used for stops along routes.'}</TextBox>
      </Container>
      <Row type={'spaced'} area={'nav'}>
        <ActionCTA change={() => open(`new_${handlers.store}`)} label={'Add a New Location or Station'} />
      </Row>
    </Area>
  )
}

const ListWrapper = () => {
  return (
    <Fragment>
      <Consumer />
      <LocationsList />
    </Fragment>
  )
}

export default ListWrapper
