import React, { useState } from 'react'

import { morphism } from 'morphism'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'
import Heading from '_shared/components/layout/Heading'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import { Raw as InputDuration } from '_shared/components/input/InputDuration'
import { Raw as InputSelect } from '_shared/components/input/InputSelect'

import SortListTable from '_shared/components/navigation/SortListTable'
import InputTime from '_shared/components/input/InputTime'

import useDependencies from '_shared/hooks/useDependencies'
import useLocale from '_shared/hooks/useLocale'
import * as duration from 'duration-fns'
import { format } from 'date-fns'

import { route_segment } from './Context'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import {
  update,
} from '_shared/libs/mapToSchema'

import cloneDeep from 'lodash/cloneDeep'
import take from 'lodash/take'

import swap from '_shared/libs/arraySwap'
import TextBox from '_shared/components/layout/TextBox'

const styles = {
  headingStyle: {
    color: '#5b6372',
  },
  fontStyle: {
    whiteSpace: 'pre-line',
    color: 'grey',
    marginTop: '2rem',
  },
  durationInput: {
    top: '20px',
    zIndex: 9,
  },
  input: {
    flex: '0 0 auto',
    minHeight: '1.75rem',
  },
}

const FormRoute = ({
  current,
  updateSingle,
}) => {
  const {
    default_locale,
  } = useLocale()

  const { data: location_options } = useDependencies('locations', data => data
    .map(location => ({
      label: getDeepLocale(location.locales, 'title', default_locale),
      value: location.entity_id,
    })))

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'selector',
        ]}
        columns={2}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Define stops and travel time'} />
        </SectionWrapper>
        <Area
          area="selector"
          areas={[
            'table label',
          ]}
          columns={['3fr', '1fr']}
          colgap={3}
        >
          <Selector
            area={'table'}
            field={'segments'}
            options={location_options}
            value={current.segments}
            change={updateSingle}
          />
          <Heading rawStyle={styles.headingStyle} area={'label'} level={3} title={'The Example Schedule'} />
          <TextBox
            rawStyle={styles.fontStyle}
            area={'label'}
            // level={6}
            // title={''}
          >
            The Example Schedule is not used in the final schedule. It is to help define the duration and departure gaps to make sure they match your real schedule
            <br /><br />
            Start times will be defined when building a schedule using this template
          </TextBox>
        </Area>
      </Area>
    </FormView>
  )
}

const columns = [
  {
    key: 'stage',
    label: '',
  },
  {
    key: 'location',
    label: 'Stop Stations',
  },
  {
    key: 'start',
    label: 'Duration',
  },
  {
    key: 'end',
    label: 'Departure Interval',
  },
  {
    key: 'arrive',
    label: 'Arrive',
  },
  {
    key: 'depart',
    label: 'Depart',
  },
]

const addTempID = (item, index) => ({
  ...item,
  temp_id: index + 1,
})

const removeTempID = ({ ...rest }) => ({ ...rest })

const Selector = ({
  field: outer_field,
  value: collection,
  options,
  change,
}) => {
  const [startTime, setStartTime] = useState('09:00')
  const [
    internal,
    setInternal,
  ] = useState(collection.map(addTempID))

  const sync = (state) => {
    setInternal(state)
    change(outer_field, cloneDeep(state).map(removeTempID))
  }

  const create = () => {
    const state = [...internal]
    const row = morphism(route_segment, { position: state.length, start: (state.length > 0 ) && state[0].end})

    state.push({
      ...row,
      temp_id: state.length + 1,
    })
    sync(state)
  }

  const remove = (row) => {
    const state = [...internal].filter(
      el => el.position != row - 1,
    )
    sync(state)
  }

  const handleChange = (row) => (field, value) => {
    const state = [...internal]
    update(state, `[${row}]${field}`, value)
    sync(state)
  }

  const sort = (row, drag) => {
    const over = internal.findIndex(item => item.temp_id === row)
    const dragging = internal.findIndex(item => item.temp_id === drag)

    if (over === -1 || dragging === -1) return

    const mutated = swap(cloneDeep(internal), over, dragging)

    mutated.map((item, index) => {
      return {
        ...item,
        position: index,
      }
    })

    setInternal(mutated)
  }

  const finish = () => {
    const state = cloneDeep(internal)

    const end = state[0].end
    state[0].start = 'PT0M'

    for (let x = 1; x < state.length; x++) {
      update(state, `[${x}]start`, end)
    }

    setInternal(state)

    change(outer_field, state.map(removeTempID))
  }

  const getStage = (index) => {
    let output = index + 1
    if (index === 0) output = 'START'
    if (index === internal.length - 1) output = 'END'

    return (
      <InlineTextBox textAlign={'center'} width={'100%'} block>{output}</InlineTextBox>
    )
  }

  const getDurationField = (index, field, value, change, middle = false) => {
    return (
      <InputDuration
        field={field}
        placeholder={'00h 00m'}
        value={value}
        change={change}
        {...styles.input}
        width={'6rem'}
        rawStyle={middle && styles.durationInput}
      />
    )
  }


  const mutatedData = internal.map(({
    temp_id,
    start,
    end,
    location,
  }, row) => {
    // here are getting all the rows before the current one to allow us to add up all the previous durations
    const takeArray = take(internal, row)
    // get all -1 for start for duration gap
    const arrivalArray = take(internal, row - 1).map((el) => duration.parse(el.end))

    // get all travel time and duration gap variables for travel time and gap
    const sumArray = takeArray.map((el) => duration.parse(el.start))
    const departureArray = takeArray.map((el) => duration.parse(el.end))
    // Apply the added up times to the start time set by the user. There is a date here to accomodate multi-day stops in the future
    const arrivalTime = row > 0 && duration.apply(
      `2021-01-01T${startTime}`,
      duration.sum(...sumArray, ...arrivalArray),
    )
    const departureTime = row > 0 && duration.apply(
      duration.apply(
        `2021-01-01T${startTime}`,
        duration.sum(...sumArray, ...departureArray),
        duration.sum(duration.parse(end), ...departureArray),
      ),
    )
    return {
      id: temp_id,
      stage: getStage(row),
      start: row !== internal.length - 1 && getDurationField(row, 'start', start, handleChange(row), true),
      end: (
        row !== internal.length - 1
      ) && getDurationField(row, 'end', end, handleChange(row), true),
      location: (
        <InputSelect
          field={'location.entity_id'}
          placeholder={'Choose a location'}
          options={options}
          value={location.entity_id}
          change={handleChange(row)}
          {...styles.input}
        />
      ),
      arrive: row !== 0 && <>{
        format(arrivalTime, 'HH:mm') || '00:00'
      }</>,
      depart: row !== internal.length - 1 && (
        row === 0 ? (
          <InputTime
            field={'time'}
            value={startTime}
            styles={{ display: 'none' }}
            placeholder={'00:00'}
            change={(field, value) => {
              setStartTime(value)
            }}
            {...styles.input}
            width={'6rem'}
          />
        ) : format(departureTime, 'HH:mm') || '00:00'
      ),
    }
  })

  return (
    <SortListTable
      columns={columns}
      data={mutatedData}
      create={create}
      remove={remove}
      sort={sort}
      finish={finish}
    />
  )
}

export default FormRoute
