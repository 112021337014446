import React from 'react'

import InputDate from '_shared/components/input/InputDate'

const DateServiceStart = ({
  value,
  change,
}) => {
  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  return (
    <InputDate
      field={'service-date-start'}
      title={'Service Start Date'}
      placeholder={'Service Start Date'}
      value={value}
      change={handleChange}
    />
  )
}

export default DateServiceStart
