import { createSchema, morphism } from 'morphism'
import api from 'api/product'

import { mapLocales, mapDefaults } from '_shared/libs/mapToSchema'

const defaults = {
  'areas': [],
  'locales': [],
  'capacity.units': 0,
  'seating': [],
}

const getAreaSpec = (locales) => {
  return createSchema(
    {
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          title: '',
        }, value, locales),
      },
      seating: 'seating',
      capacity: {
        type: {
          entity_id: 'capacity.type.entity_id',
        },
        units: 'capacity.units',
      },
    },
    mapDefaults(defaults),
  )
}

const getCapacitySpec = (locales) => {
  return createSchema(
    {
      areas: {
        path: 'areas',
        fn: (value, source) => {
          if (!value) return []
          return value.map(record => morphism(getAreaSpec(locales), record))
        },
      },
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          title: '',
          description: '',
        }, value, locales),
      },
    },
    mapDefaults(defaults),
  )
}

const getSpec = (locales) => {
  return createSchema(
    {
      capacity_layout: {
        areas: {
          path: 'capacity_layout.areas',
          fn: (value, source) => {
            if (!value) return []
            return value.map(record => morphism(getAreaSpec(locales), record))
          },
        },
        locales: {
          path: 'capacity_layout.locales',
          fn: (value, source) => mapLocales({
            title: '',
            description: '',
          }, value, locales),
        },
      },
    },
    mapDefaults(defaults),
  )
}

const valid = {
  capacity_layout: (record, value) => {
    const locales = value.locales
      .map(locale => locale.title)
      .some(locale => locale !== '')

    const areas = value.areas.length > 0 && value.areas
      .every(area => {
        return area.capacity.units > 0 && area.capacity.type.entity_id !== null
      })

    return areas && locales
  },
}

const handlers = {
  store: 'layouts',
  get: api.getVenue,
  new: api.createVenue,
  edit: (payload) => api.updateVenue(payload.entity_id, payload),
  remove: (payload) => { },
  cancel: null,
}

export {
  getSpec,
  getAreaSpec,
  getCapacitySpec,
  valid,
  handlers,
}