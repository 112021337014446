import React from 'react'

const TicketIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size * 0.8}
      viewBox='0 0 32 25.6'
    >
      <path
        fill={fill}
        d='M28.8,12.8A3.21,3.21,0,0,1,32,9.6V3.2A3.21,3.21,0,0,0,28.8,0H3.2A3.19,3.19,0,0,0,0,3.2V9.6A3.19,3.19,0,0,1,3.2,12.8,3.2,3.2,0,0,1,0,16v6.4a3.21,3.21,0,0,0,3.2,3.2H28.8A3.21,3.21,0,0,0,32,22.4V16A3.21,3.21,0,0,1,28.8,12.8Zm-7.07,7.68L16,16.8l-5.73,3.68L12,13.89,6.74,9.58l6.78-.4L16,2.88,18.46,9.2l6.79.4L20,13.9Z'
      />
    </svg>
  )
}

export default TicketIcon
