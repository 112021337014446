let counter = 0

const notifications = (state = [], action) => {
  const store = [...state]

  switch (action.type) {
    case 'NOTIFICATIONS_CREATE': {
      counter += 1

      store.push({
        id: counter,
        data: action.data,
        read: false,
        hidden: false,
        persistent: action.persistent,
      })

      return store
    }
    case 'NOTIFICATIONS_DELETE': {
      const index = store.findIndex(item => {
        return item.id === action.id
      })

      if (index !== -1) {
        store.splice(index, 1)
      }

      return store
    }
    case 'NOTIFICATIONS_READ': {
      const index = store.findIndex(item => {
        return item.id === action.id
      })

      if (store[index]) {
        store[index].read = true
      }

      return store
    }
    case 'NOTIFICATIONS_HIDDEN': {
      const index = store.findIndex(item => {
        return item.id === action.id
      })

      if (store[index]) {
        store[index].hidden = true
      }

      return store
    }
    default: {
      return store
    }
  }
}

export default notifications
