import React, { useState } from 'react'
import {
  connect,
} from 'react-redux'

import {
  login,
} from 'actions/user'

import {
  createNotification,
} from 'actions/notifications'

import {
  withValidator,
  validate,
  formValid,
} from 'HOC/formValidator'

import Area from '_shared/components/layout/Area'

import { Raw as InputText } from '_shared/components/input/InputText'
import { Raw as InputPassword } from '_shared/components/input/InputPassword'
import Button from '_shared/components/element/Button'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import Row from '_shared/components/layout/Row'

import ActionCTA from '_shared/components/action/ActionCTA'

import PlusIcon from 'icons/Plus'

import Config from 'libs/Config'

import {
  login_action_map,
} from '../Routes'

import notEmptyOrNull from 'libs/notEmptyOrNull'

const Validator = withValidator({
  username: notEmptyOrNull,
  password: notEmptyOrNull,
})

const ValidTextField = validate(InputText)
const ValidPassField = validate(InputPassword)

const ValidSubmit = formValid((props) => {
  const {
    action,
    status,
  } = props

  return (
    <ActionCTA
      type={status ? 'important' : 'disabled'}
      label={'Login'}
      change={status ? action : null}
    />
  )
})

const Login = ({ location, history, login, notify }) => {
  const [
    state,
    setState,
  ] = useState({
    username: '',
    password: '',
    ...location.state,
  })

  const update = (field, value) => {
    const record = { ...state}

    record[field] = value

    setState(record)
  }

  const submit = async () => {
    try {
      const response = await login(state)

      const {
        type,
        ...rest
      } = response

      if (login_action_map[type]) {
        history.push(login_action_map[type], rest)
      }
    } catch (e) {
      console.error(e)

      notify({
        type: 'error',
        message: 'Incorrect username or password.',
      })

      setState({
        ...state,
        password: '',
      })
    }
  }

  const forgotten = () => {
    history.push('/forgot')
  }

  return (
    <Validator
      record={state}
    >
      <form onSubmit={event => event.preventDefault()}>
        <Area
          areas={[
            'heading heading',
            'message .',
            'form .',
            'reset .',
          ]}
          columns={4}
          rowgap={1}
        >
          <Heading level={'section'} title={'Login'} area={'heading'} />
          <TextBox noMargin color={'text_light'} area={'message'}>{'Welcome to Ticknovate.'}</TextBox>
          <Row type={'start'} area={'form'}>
            <ValidTextField
              field={'username'}
              placeholder={'Email'}
              value={state.username}
              change={update}
              requiredText={'User email required'}
              width={'15rem'}
              noFlex
            />
            <ValidPassField
              field={'password'}
              placeholder={'Password'}
              value={state.password}
              change={update}
              requiredText={'Password required'}
              width={'15rem'}
              noFlex
            />
            <ValidSubmit
              action={submit}
            />
          </Row>
          <Button change={forgotten} margin={'1rem 0 0 0'} area={'reset'}>
            <PlusIcon size={10} fill={Config.theme.text_light} />
            <TextBox margin={'0 0 0 0.5rem'} color={'text_light'}>{'Forgotten password'}</TextBox>
          </Button>
        </Area>
      </form>
    </Validator>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
    notify: data => dispatch(createNotification(data)),
  }
}

export default connect(null, mapDispatchToProps)(Login)
