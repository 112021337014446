import React from 'react'

const EditIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
    >
      <polygon
        fill={fill}
        points='0 25.33 0 32 6.67 32 26.32 12.34 19.66 5.67 0 25.33'
      />
      <path
        fill={fill}
        d='M31.48,7.19a1.78,1.78,0,0,0,0-2.51L27.32.52a1.78,1.78,0,0,0-2.51,0L21.56,3.77l6.67,6.67Z'
      />
    </svg>
  )
}

export default EditIcon
