import React from 'react'

import { morphism } from 'morphism'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'
import Card from '_shared/components/layout/Card'

import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import TextBox from '_shared/components/layout/TextBox'

import ActionCTA from '_shared/components/action/ActionCTA'
import ActionRemove from '_shared/components/action/ActionRemove'

import {
  getProductSelectionSchema,
} from './Context'

import ProductCard from 'templates/PromoProductCard'

const FormProduct = ({
  current,
  update,
  updateSingle,
}) => {
  const handleAdd = () => {
    const state = [...current.product_selection]
    state.push(morphism(getProductSelectionSchema(), {}))
    updateSingle('product_selection', state)
  }

  const handleProductChange = (row) => (field, value) => {
    updateSingle(`product_selection[${row}]${field}`, value)
  }

  const handleProductChangeMulti = (row) => (payload) => {
    update(payload.map(({ field, value }) => {
      return {
        field: `product_selection[${row}]${field}`,
        value,
      }
    }))
  }

  const handleProductRemove = (row) => () => {
    const state = [...current.product_selection]

    state.splice(row, 1)

    updateSingle('product_selection', state)
  }

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'info/3 action',
          'products',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Combo Items'} />
        </SectionWrapper>
        <TextBox strong area={'info'}>{'Limit when this product to be purchased through certain channels.'}</TextBox>
        <ActionCTA change={() => handleAdd()} label={'Add Combo Item'} area={'action'} margin={'0 0 0 auto'} />
        <Container area={'products'} padding={'0 0 10rem 0'}>
          {current.product_selection.map((product, row) => {
            return (
              <Card padding={'2rem'} margin={'0 0 1rem 0'} key={row}>
                <Area
                  areas={[
                    'title/2 . remove',
                    'actions',
                  ]}
                  rowgap={1}
                >
                  <Heading level={2} title={`Item option ${row + 1}`} area={'title'} />
                  <ActionRemove area={'remove'} label={'Remove'} change={handleProductRemove(row)} margin={'0 0 0 auto'} />
                  <ProductCard
                    row={row}
                    area={'actions'}
                    data={product}
                    change={handleProductChange(row)}
                    changeMulti={handleProductChangeMulti(row)}
                  />
                </Area>
              </Card>
            )
          })}
        </Container>
      </Area>
    </FormView>
  )
}

export default FormProduct
