import React, { useState } from 'react'

import * as duration from 'duration-fns'

import FormView from '_shared/components/layout/FormView'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import Area from '_shared/components/layout/Area'
import InputText from '_shared/components/input/InputText'
import InputTime from '_shared/components/input/InputTime'

import differenceInMinutes from 'date-fns/difference_in_minutes'

import addZero from 'libs/addZero'

const getActualTime = (stamp) => {
  const [
    hours,
    minutes,
  ] = stamp.split('T')[1].split(':')

  return `${hours}:${minutes}`
}

const getDuration = (start, end) => {
  const difference = differenceInMinutes(end, start)
  const hours = Math.floor(difference / 60)
  const minutes = difference % 60

  return duration.toString({
    hours,
    minutes,
  })
}

const sanitizeTime = (time) => {
  let [
    hours = '00',
    minutes = '00',
  ] = time.split(':')

  return `${addZero(hours)}:${addZero(minutes)}`
}

const FormLocation = ({
  current,
  update,
}) => {
  const base = current.segments[0]

  const [
    state,
    setState,
  ] = useState({
    date: base.start.split('T')[0],
    time: getActualTime(base.start),
    duration: getDuration(base.start, base.end),
  })

  const handleChange = (field, value) => {
    const record = {...state}

    record[field] = value

    setState(record)

    try {
      const start = `${record.date}T${record.time}:00.000Z`
      const end = duration.apply(`${record.date}T${sanitizeTime(record.time)}:00.000Z`, record.duration).toISOString()
      console.log('Updating end', start, end, state)
      update([
        {
          field: 'segments[0].start',
          value: start,
        },
        {
          field: 'segments[0].end',
          value: end,
        },
      ])
    } catch (error) {
      console.log('End invalid')
    }
  }

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'location',
          'duration',
        ]}
        rowgap={1}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Adjust date time and duration'} />
        </SectionWrapper>
        <Area
          area={'location'}
          areas={[
            'info',
            'date time duration .',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'Adjust the start date, time and duration.'}</TextBox>
          <InputText
            area={'date'}
            title={'Date'}
            field={'date'}
            value={state.date}
            placeholder={'Location'}
            change={handleChange}
          />
          <InputTime
            area={'time'}
            title={'Schedule starts'}
            field={'time'}
            value={state.times}
            styles={{ display: 'none'}}
            placeholder={'00:00'}
            change={handleChange}
          />
          <InputText
            area={'duration'}
            title={'Duration'}
            field={'duration'}
            value={state.duration}
            placeholder={'Duration'}
            change={handleChange}
          />
        </Area>
      </Area>
    </FormView>
  )
}

export default FormLocation
