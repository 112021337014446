import { createSchema, morphism } from 'morphism'
import api from 'api/product'

import { mapLocales, mapDefaults } from '_shared/libs/mapToSchema'

import { getAreaSpec } from '../../BuildingBlocks/subviews/Layouts/form/Context'

const defaults = {
  title: '',
  description: '',
  duration: '',
  segments: [],
  type: '',
  notes: [],
}

const ticket_type_group = {
  entity_id: 'entity_id',
}

const route_segment = createSchema(
  {
    start: 'start',
    end: 'end',
    location: {
      entity_id: 'location.entity_id',
    },
  },
  mapDefaults({
    start: 'PT0M',
    end: 'PT0M',
  }),
)

const getSchema = (locales) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      type: 'type',
      status: 'status',
      compatible_ticket_type_groups: {
        path: 'compatible_ticket_type_groups',
        fn: (value, source) => {
          if (!value) return []
          return value.map(record => morphism(ticket_type_group, record))
        },
      },
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          boarding_time: '',
          whats_onboard: '',
        }, value, locales),
      },
      venue: {
        entity_id: 'venue.entity_id',
      },
      service: {
        entity_id: 'service.entity_id',
      },
      notes: 'notes',
      layout: {
        areas: {
          path: 'layout.areas',
          fn: (value, source) => {
            if (!value) return []
            return value.map(record => morphism(getAreaSpec(locales), record))
          },
        },
      },
      segments: {
        path: 'segments',
        fn: (value, source) => {
          if (!value) return []
          return value.map(record => morphism(route_segment, record))
        },
      },
    },
    mapDefaults(defaults),
  )
}

const valid = {
  segments: (record, value) => {
    return value.length > 0 && value
      .every(({ location }) => location.entity_id !== null)
  },
}

const handlers = {
  store: 'instances',
  get: api.getInstance,
  new: api.createInstance,
  edit: (payload) => api.updateInstance(payload.entity_id, payload),
  remove: (payload) => { },
  cancel: null,
}

export {
  getSchema,
  valid,
  handlers,
  route_segment,
}