import React, { useState } from 'react'

import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'
import Container from '_shared/components/layout/Container'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import Heading from '_shared/components/layout/Heading'

import ActionAdd from '_shared/components/action/ActionAdd'
import ActionDelete from '_shared/components/action/ActionDelete'

import { Raw as InputSelect } from '_shared/components/input/InputSelect'

import ListTable from '_shared/components/navigation/ListTable'

import mapToSchema, { update } from '_shared/libs/mapToSchema'

import { getLegSchema } from './Context'

import useLocale from '_shared/hooks/useLocale'

import RouteIcon from '_shared/icons/Route'

import Config from 'libs/Config'

const getCurrentRecords = (current) => {
  return current.routes
    .filter(route => route.legs.length > 1)
    .map(({ legs }) => {
      return {
        legs: legs
          .map(({ departure_location, arrival_location }) => {
            return {
              depart: departure_location.entity_id,
              arrival: arrival_location.entity_id,
            }
          }),
      }
    })
}

const generateInsert = (generateLeg) => (state) => {
  return state
    .filter(({ legs }) => legs.length > 1)
    .reduce((routes, { legs }) => {
      const valid = legs.every(({ depart, arrival }) => depart !== null && arrival !== null)

      if (valid) {
        routes.push({
          legs: legs
            .map(({ depart, arrival }) => generateLeg({
              departure_location: {
                entity_id: depart.replace('transfer_', ''),
              },
              arrival_location: {
                entity_id: arrival.replace('transfer_', ''),
              },
              locales: [],
            })),
        })
      }

      return routes
    }, [])
}

const TransferRouteSelector = ({
  loading,
  loaded,
  data,
  current,
  change,
  disabled,
}) => {
  const {
    locales,
    currencies,
  } = useLocale()

  const [
    rows,
    setRows,
  ] = useState(getCurrentRecords(current))

  const generateLeg = mapToSchema(locales, currencies, getLegSchema)

  const handleCreate = () => {
    const state = [...rows]

    state.push({
      legs: [
        {
          depart: null,
          arrival: null,
        },
      ],
    })

    setRows(state)

    const insert = generateInsert(generateLeg)(state)

    const payload = [
      ...current.routes.filter(route => route.legs.length === 1),
      ...insert,
    ]

    change([
      {
        field: 'routes',
        value: payload,
      },
    ])
  }

  const handleRemove = (index) => {
    const state = [...rows]

    state.splice(index, 1)

    setRows(state)

    const insert = generateInsert(generateLeg)(state)

    const payload = [
      ...current.routes.filter(route => route.legs.length === 1),
      ...insert,
    ]

    change([
      {
        field: 'routes',
        value: payload,
      },
    ])
  }

  const handleChange = (row) => (value) => {
    const state = [...rows]

    update(state, `[${row}]legs`, value)

    setRows(state)

    const insert = generateInsert(generateLeg)(state)

    const payload = [
      ...current.routes.filter(route => route.legs.length === 1),
      ...insert,
    ]

    change([
      {
        field: 'routes',
        value: payload,
      },
    ])
  }

  const mutatedData = !loaded ? [] : rows.map((record, row) => {
    return {
      id: row,
      transfer: (
        <TransferRow
          data={record.legs}
          destinations={data.transfers}
          change={handleChange(row)}
        />
      ),
    }
  })

  return (
    <Area columns={1} rowgap={1} margin={'0 0 4rem 0'}>
      <Heading title={'Define routes that require a transfer'} level={2} />
      <ListTable
        loading={loading}
        columns={[
          {
            key: 'transfer',
            label: 'Transfers',
          },
        ]}
        data={mutatedData}
        create={disabled ? null : handleCreate}
        remove={handleRemove}
      />
    </Area>
  )
}

const getCurrentOptions = (options, previous = null) => {
  if (previous === null) {
    return Object.keys(options)
      .map(entity_id => {
        const {
          title,
        } = options[entity_id]

        return {
          label: title,
          value: entity_id,
        }
      })
  } else {
    previous = previous.replace('transfer_', '')

    return options[previous].destinations
      .map(entity => {
        return {
          label: entity.isTransfer ? `${entity.title} (indirect transfer)` : entity.title,
          value: entity.isTransfer ? `transfer_${entity.entity_id}` : entity.entity_id,
        }
      })
  }
}

const fieldStyle = {
  flex: '0 0 auto',
  width: '10rem',
  minHeight: '1.5rem',
}

const TransferRow = ({
  data,
  destinations,
  change,
}) => {
  const handleSetLeg = (index) => (value) => {
    const state = [...data]

    state[index] = value

    const remaining = (state.length - 1) - index

    if (remaining > 0) {
      state.length = index + 1

      state.push({
        depart: null,
        arrival: null,
      })
    }

    change(state)
  }

  const handleCreate = () => {
    const state = [...data]

    state.push({
      depart: null,
      arrival: null,
    })

    change(state)
  }

  const handleRemove = (index) => () => {
    const state = [...data]

    state.length = index

    change(state)
  }

  const [
    initial,
    ...legs
  ] = data

  return (
    <Container>
      <Row padding={'0.25rem 0 0.25rem 0'}>
        <InlineTextBox>{'Add and remove stops along the route'}</InlineTextBox>
        {data.length < 2 && (
          <InlineTextBox color={'text_error'}>{'* Add more legs to make this route valid'}</InlineTextBox>
        )}
      </Row>
      <Row flexWrap>
        <InputInitial
          value={initial}
          destinations={destinations}
          change={handleSetLeg(0)}
          create={data.length > 1 ? null : handleCreate}
        />
        {legs.map((leg, index) => {
          return (
            <InputLeg
              key={index}
              value={leg}
              previous={index === 0 ? initial : legs[index - 1]}
              destinations={destinations}
              change={handleSetLeg(index + 1)}
              create={legs[index + 1] ? null : handleCreate}
              remove={handleRemove(index + 1)}
            />
          )
        })}
      </Row>
    </Container>
  )
}

const InputLeg = ({
  value,
  previous,
  destinations,
  change,
  create,
  remove,
}) => {
  const options = getCurrentOptions(destinations, previous.arrival)

  const handleChange = (field, value) => {
    change({
      depart: previous.arrival,
      arrival: value,
    })
  }

  return (
    <Row noFlex spread={false} padding={'0.25rem 0'}>
      <RouteIcon fill={Config.theme.text} size={20} />
      <InputSelect
        field={'leg'}
        placeholder={'Select'}
        options={options}
        value={value.arrival}
        change={handleChange}
        {...fieldStyle}
        margin={'0 0.25rem'}
      />
      <ActionDelete collapsed label={'Remove leg'} change={remove} />
      {create !== null && (
        <ActionAdd collapsed label={'New leg'} change={create} disabled={value.arrival === null} margin={'0 0 0 0.25rem'}/>
      )}
    </Row>
  )
}

const InputInitial = ({
  value,
  destinations,
  change,
  create,
}) => {
  const {
    depart,
    arrival,
  } = value

  const departOptions = getCurrentOptions(destinations)
  const arrivalOptions = getCurrentOptions(destinations, depart)

  const handleChange = (field, location) => {
    const state = {...value}

    state[field] = location

    if (field === 'depart') state.arrival = null

    change(state)
  }

  return (
    <Row noFlex spread={false} padding={'0.25rem 0'}>
      <InputSelect
        field={'depart'}
        placeholder={'Select'}
        options={departOptions}
        value={depart}
        change={handleChange}
        {...fieldStyle}
        margin={'0 0.25rem 0 0'}
      />
      <RouteIcon fill={Config.theme.text} size={20} />
      <InputSelect
        field={'arrival'}
        placeholder={'Select'}
        options={arrivalOptions}
        value={arrival}
        change={handleChange}
        disabled={depart === null}
        {...fieldStyle}
        margin={'0 0.25rem'}
      />
      <ActionAdd collapsed label={'New leg'} change={create} disabled={create === null || arrival === null} />
    </Row>
  )
}

export default TransferRouteSelector
