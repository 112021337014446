import React from 'react'

import DateRange from '../DateRange'

const DateService = ({
  data,
  change,
}) => {
  return (
    <DateRange
      datePrefix={'service-date'}
      timePrefix={'service-time'}
      label={'Service'}
      data={data}
      change={change}
      showTime
    />
  )
}

export default DateService
