import React, { Component } from 'react'
import {
  connect,
} from 'react-redux'

import styles from './cache.scss'

import {
  clearCache,
} from 'actions/cache'

import {
  createNotification,
} from 'actions/notifications'

import Container from 'containers/Container'

import Heading from 'atoms/Heading'
import TextBox from 'containers/TextBox'

class Cache extends Component {
  constructor (props) {
    super(props)

    this.state = {
      clearing: false,
    }
  }

  clear = () => {
    if (!this.state.clearing) {
      this.setState({
        clearing: true,
      })

      this.props.clearCache()
        .then(response => {
          console.log(response)

          this.setState({
            clearing: false,
          })

          this.props.notify({
            type: 'save',
            message: 'Cache cleared',
          })
        })
        .catch(() => {
          this.setState({
            clearing: false,
          })

          this.props.notify({
            type: 'remove',
            message: 'Cache clear failed',
          })
        })
    }
  }

  render () {
    return (
      <Container
        spread
        styling={styles.layout}
      >
        <Heading
          level={2}
          title={'Clear all caches'}
        />
        <TextBox>{'Warning! Pressing this button will remove all caches, responses after pressing this button may be slow. Only press if you know what this means'}</TextBox>
        <Container styling={styles.holder}>
          <Container
            styling={styles.button}
            onClick={this.clear}
          >
            {'CLEAR'}
          </Container>
        </Container>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearCache: () => dispatch(clearCache()),
    notify: data => dispatch(createNotification(data)),
  }
}

export default connect(null, mapDispatchToProps)(Cache)
