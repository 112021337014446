import React from 'react'

import styles from './textbox.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

const TextBox = (props) => {
  const {
    children,
    ...style
  } = props

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <p className={styles.layout} style={styling}>
            {children}
          </p>
        )
      }}
    />
  )
}

export default TextBox
