import React from 'react'

import Area from '_shared/components/layout/Area'

import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText, { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import { Localised as InputRTELocalised } from '_shared/components/input/InputRTE'
import InputSelect from '_shared/components/input/InputSelect'

import InfoBox from '_shared/components/notification/InfoBox'

import useDependencies from '_shared/hooks/useDependencies'
import useLocale from '_shared/hooks/useLocale'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

const FormDescription = ({
  current,
  valid,
  updateSingle,
}) => {
  const {
    default_locale,
  } = useLocale()

  const { data: locations } = useDependencies('locations', data => data
    .map(({ entity_id, locales }) => {
      const title = locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

      return {
        label: title ? title.title : 'undefined',
        value: entity_id,
      }
    }))

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'info/2 code_info',
          'code . code_info',
          'title . warning',
          'link . warning',
          'description . .',
          'section_b',
          'info_b/2 .',
          'location . .',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Description'} />
        </SectionWrapper>
        <TextBox strong area={'info'}>{'Describe the basic details of this venue.'}</TextBox>
        <InfoBox
          area={'warning'}
          type={'warning'}
          title={'Warning'}
          content={'Changing details here will change it throughout the services'}
        />
        <InfoBox
          area={'code_info'}
          type={'info'}
          title={'External ID'}
          content={'Used for external API calls.'}
        />
        <InputText
          area={'code'}
          title={'External ID'}
          field={'external_id'}
          value={current.external_id}
          placeholder={'Unique External ID'}
          change={updateSingle}
          status={parseValidity(valid.external_id)}
          required
          requiredText={'Must be unique'}
        />
        <InputTextLocalised
          area={'title'}
          title={'Title'}
          field={'title'}
          value={current.locales}
          placeholder={'Title'}
          change={updateSingle}
          valid={valid.locales}
          required
        />
        <InputText
          area={'link'}
          title={'Link'}
          field={'link'}
          value={current.link}
          placeholder={'Link'}
          change={updateSingle}
        />
        <InputRTELocalised
          area={'description'}
          title={'Description'}
          field={'description'}
          value={current.locales}
          placeholder={'Vehicle description'}
          change={updateSingle}
        />
        <SectionWrapper area={'section_b'}>
          <Heading level={1} title={'Location'} />
        </SectionWrapper>
        <TextBox strong area={'info_b'}>{'Give this venue a location.'}</TextBox>
        <InputSelect
          area={'location'}
          title={'Location'}
          field={'location.entity_id'}
          value={current.location.entity_id}
          placeholder={'Location'}
          options={locations}
          change={updateSingle}
          status={parseValidity(valid.location)}
          required
          requiredText={'Field is required'}
        />
      </Area>
    </FormView>
  )
}

export default FormDescription
