import coreData from 'api/refresh'

import {
  setBusyState,
} from 'libs/actionCreator'

const storeFromServer = () => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return coreData()
    .then(data => {
      dispatch({
        type: 'APP_REFRESH',
        data,
      })

      dispatch(setBusyState(false))

      return data
    })
}

export default storeFromServer
