import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import TicketBox from './TicketBox'

import cloneDeep from 'lodash/cloneDeep'

const FormTickets = ({
  current,
  updateSingle,
}) => {
  const create = (payload) => {
    const state = cloneDeep(current.ticket_types)

    state.push(payload)

    updateSingle('ticket_types', state)
  }

  const remove = (row) => {
    const state = cloneDeep(current.ticket_types)

    state.splice(row, 1)

    updateSingle('ticket_types', state)
  }

  return (
    <FormView>
      <Area
        areas={[
          'title/3 .',
          'info/3 .',
          'box',
        ]}
        columns={4}
        rowgap={1.5}
      >
        <Heading level={1} title={'Select what can travel on this service'} area={'title'} />
        <TextBox strong area={'info'}>{'Add the ticket types. The tickets default settings will be applied, adjust if necessary'}</TextBox>
        <TicketBox 
          area={'box'}
          field={'ticket_types'}
          value={current.ticket_types}
          change={updateSingle}
          create={create}
          remove={remove}
          withoutCapacity={current.operator_type === 'scandlines'}
        />
      </Area>
    </FormView>
  )
}

export default FormTickets
