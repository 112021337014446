import React, { useState } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'

import VisibleIndicator from '_shared/components/element/VisibleIndicator'

const InputPassword = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  ...style
}) => {
  const [
    visible,
    setVisible,
  ] = useState(false)

  const handleChange = (event) => change(field, event.target.value)

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={visible ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        change={handleChange}
        margin={margin}
        disabled={disabled}
      />
      {!disabled && (
        <VisibleIndicator active={visible} change={() => setVisible(!visible)} margin={'0 0.25rem'} />
      )}
    </StatefulWrapper>
  )
}

const InputPasswordWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputPassword {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputPassword {...props} />
  )
}

export default InputPasswordWrapped

export {
  Raw,
}
