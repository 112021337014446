import React from 'react'

import {
  getSchema,
  valid as valid_spec,
  handlers,
} from './Context'

import Chooser from '../../../templates/Chooser'

import FullForm from 'templates/FullForm'

import Route from './Route'

const text = {
  title: 'Select a Product Type',
  create: 'Add a new product',
  warning: 'This cannot be changed later. Make sure you are selecting the correct one for the type of product you are trying to sell.',
  journeyTitle: 'Journey Product',
  journeyInfo: ['This product supports journey services that have a route.'],
  eventTitle: 'Event Product',
  eventInfo: ['This product supports event services'],
}

const Consumer = (props) => {
  return (
    <FullForm
      data_spec={getSchema}
      valid_spec={valid_spec}
      handlers={handlers}
      mountKey={'record'}
      permissionsKey={'products'}
      render={({
        cancel,
        current,
        valid,
        reset,
        update,
        updateSingle,
      }) => {
        if (current.type === '') return (
          <Chooser
            cancel={cancel}
            update={updateSingle}
            current={current}
            config={text}
            reset={reset}
          />
        )

        if (current.type === 'route' || current.type === 'event') return (
          <Route
            cancel={cancel}
            current={current}
            valid={valid}
            update={update}
            updateSingle={updateSingle}
          />
        )
      }}
    />
  )
}

export default Consumer
