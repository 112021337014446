import { createSchema } from 'morphism'
import api from 'api/product'

import { mapDefaults } from '_shared/libs/mapToSchema'

const defaults = {
  template: {},
  schedule: [],
}

const getSpec = (locales) => {
  return createSchema(
    {
      template: 'template',
      schedule: 'schedule',
    },
    mapDefaults(defaults),
  )
}

const valid = {
  template: (record, value) => {
    return value.hasOwnProperty('title')
  },
  schedule: (record, value) => {
    return value.length > 0 && value.every(item => item.times.length > 0)
  },
}

const handlers = {
  store: 'instances',
  get: api.getVenue,
  new: api.createVenue,
  edit: (payload) => api.updateVenue(payload.entity_id, payload),
  remove: (payload) => { },
  cancel: null,
}

export {
  getSpec,
  valid,
  handlers,
}