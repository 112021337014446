import { useSelector } from 'react-redux'

const useLocale = () => {
  const localisation = useSelector(state => state.localisation)
  const default_locale = localisation.locales.length > 0 ? localisation.locales.filter(locale => locale.is_default)[0].id : null

  const locales_list = localisation.locales.map(locale => locale.id)

  const default_currency = localisation.currencies.length > 0 ? localisation.currencies.filter(locale => locale.is_default)[0].id : null

  const currency_list = localisation.currencies.map(locale => locale.id)

  return {
    locales: locales_list,
    default_locale,
    currencies: currency_list,
    default_currency,
  }
}

export default useLocale
