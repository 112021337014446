import React from 'react'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'

import Extension from 'templates/Extension'

import Integration from 'templates/Integration'

const FormExtension = ({
  current,
  updateSingle,
}) => {
  return (
    <FormView>
      <Area
        columns={1}
      >
        <Extension
          config={'ticket_type'}
          value={current.extensions}
          update={updateSingle}
        />
        <Integration
          config={'ticket_type'}
          current={current}
          update={updateSingle}
        />
      </Area>
    </FormView>
  )
}

export default FormExtension
