import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText from '_shared/components/input/InputText'

import InfoBox from '_shared/components/notification/InfoBox'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

const FormDescription = ({
  current,
  valid,
  updateSingle,
}) => {
  return (
    <FormView>
      <Area
        areas={[
          'section',
          'info/2 code_info',
          'code . code_info',
          'label . .',
          'description . .',
        ]}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Description'} />
        </SectionWrapper>
        <TextBox strong area={'info'}>{'Describe the basic details of this capacity area type.'}</TextBox>
        <InputText
          area={'code'}
          title={'External ID'}
          field={'external_id'}
          value={current.external_id}
          placeholder={'Unique External ID'}
          change={updateSingle}
          status={parseValidity(valid.external_id)}
          required
          requiredText={'Must be unique'}
        />
        <InfoBox
          area={'code_info'}
          type={'info'}
          title={'External ID'}
          content={'Used for external API calls.'}
        />
        <InputText
          area={'label'}
          title={'Label'}
          field={'title'}
          value={current.title}
          status={parseValidity(valid.title)}
          placeholder={'Label'}
          change={updateSingle}
          required
          requiredText={'Field is required'}
        />
        <InputText
          area={'description'}
          title={'Description - Internal administration'}
          field={'description'}
          value={current.description}
          placeholder={'Description'}
          change={updateSingle}
        />
      </Area>
    </FormView>
  )
}

export default FormDescription
