import React, { Fragment } from 'react'

import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'
import Row from '_shared/components/layout/Row'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import ActionCTA from '_shared/components/action/ActionCTA'

import ListTable from '_shared/components/navigation/ListTable'

import deepSort from 'libs/deepSort'

import useLocale from '_shared/hooks/useLocale'

import useDependencies from '_shared/hooks/useDependencies'

import useNav from '_shared/hooks/useNav'

import VehicleConsumer from './form/Consumer'

import {
  handlers,
} from './form/Context'

const columns = [
  {
    key: 'title',
    label: 'title',
    sortable: true,
  },
  {
    key: 'external',
    label: 'ID',
    sortable: true,
  },
]

const VenuesList = () => {
  const {
    open,
  } = useNav('subview')

  const {
    default_locale,
  } = useLocale()

  const { isFetching, data } = useDependencies(handlers.store, data => {
    return data
      .filter(record => record.type === 'venue')
      .map(record => {
        const title = record.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

        return {
          id: record.entity_id,
          external: record.external_id,
          title: title ? title.title : 'undefined',
        }
      })
  })

  return (
    <Area
      areas={[
        'description',
        'nav',
        'list',
      ]}
      rowgap={1.5}
    >
      <ListTable
        area={'list'}
        columns={columns}
        data={deepSort(data, 'title')}
        edit={open}
        loading={isFetching}
      />
      <Container area={'description'}>
        <Heading level={2} title={'Venues'} />
        <TextBox strong>{'Venues are used for stationary events.'}</TextBox>
        <TextBox strong>{'Venue examples are: Galleries, Museums, Theatres...'}</TextBox>
      </Container>
      <Row type={'spaced'} area={'nav'}>
        <ActionCTA change={() => open(`new_${handlers.store}`)} label={'Add a New Venue'} />
      </Row>
    </Area>
  )
}

const ListWrapper = () => {
  return (
    <Fragment>
      <VehicleConsumer />
      <VenuesList />
    </Fragment>
  )
}

export default ListWrapper
