import React from 'react'

import {
  getSpec,
  valid as valid_spec,
} from './Context'

import { StateSteppedHeader as SteppedHeader } from '_shared/components/navigation/SteppedHeader'

import FormTemplate from './FormTemplate'
import FormRanges from './FormRanges'
import FormPublish from './FormPublish'

import SubSlide, { Controls } from 'containers/SubSlide'

import useForm from '_shared/hooks/useForm'

const options = [
  {
    label: 'Select Template To Use',
    view: FormTemplate,
    validKey: 'template',
    slug: 'template',
  },
  {
    label: 'Define Start Times',
    view: FormRanges,
    validKey: 'schedule',
    slug: 'ranges',
  },
  {
    label: 'Publish (Optional)',
    view: FormPublish,
    slug: 'publish',
  },
]

const permissions = [
  'read',
  'create',
  'update',
  'delete',
]

const ScheduleWizardConsumer = ({
  close,
  mount,
  service,
  type,
  operator_type,
}) => {
  const {
    current,
    valid,
    reset,
    update,
  } = useForm(getSpec, valid_spec, {}, {})

  const updateSingle = (field, value) => {
    return update([{ field, value }])
  }

  const wrappedCancel = () => {
    reset({})
    close()
  }

  return (
    <SubSlide
      mount={mount}
      cancel={close}
      controls={() => {
        return (
          <Controls
            cancelAction={wrappedCancel}
            permissions={permissions}
            disabled={false}
          />
        )
      }}
    >
      <SteppedHeader
        title={'Add To The Schedule'}
        options={options}
        valid={valid}
        passProps={{
          service,
          type,
          current,
          update,
          updateSingle,
          save: wrappedCancel,
          operator_type,
        }}
      />
    </SubSlide>
  )
}

export default ScheduleWizardConsumer
