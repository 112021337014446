import React from 'react'

import styles from './list.scss'

import InlineTextBox from 'containers/InlineTextBox'

import EditViewButton from 'molecules/EditViewButton'

// spec = [
//   {
//     title: 'Title',
//     key: 'title'
//   },
//   {
//     title: 'Type',
//     key: 'title'
//   },
//   {
//     title: 'Usage',
//     key: 'usage'
//   },
//   {
//     title: 'Expires',
//     key: 'expires'
//   }
// ]

const List = (props) => {
  const {
    spec,
    rows,
    edit,
    sorted,
    sort,
    canEdit,
  } = props

  return (
    <table className={styles.layout}>
      <thead>
        <tr>
          {spec.map((item, index) => {
            const selected = index === sorted

            return (
              <th key={index}>
                <InlineTextBox styling={[styles.head_item, selected ? styles.selected : null].join(' ')} onClick={() => sort(index)}>
                  {item.title}
                </InlineTextBox>
              </th>
            )
          })}
          <th />
        </tr>
      </thead>
      <tbody>
        {rows.map(row => {
          return (
            <ListRow
              key={row.id}
              spec={spec}
              data={row}
              edit={() => edit(row.id)}
              canEdit={canEdit}
            />
          )
        })}
      </tbody>
    </table>
  )
}

const ListRow = (props) => {
  const {
    spec,
    data,
    edit,
    canEdit,
  } = props

  return (
    <tr onClick={edit}>
      {spec.map((item, index) => {
        return (
          <td key={index}>
            <InlineTextBox styling={styles.body_item}>
              {data[item.key]}
            </InlineTextBox>
          </td>
        )
      })}
      <td>
        <EditViewButton styling={styles.edit} view={!canEdit} />
      </td>
    </tr>
  )
}

export default List
