import React from 'react'

const RightIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20.01 32'
    >
      <polygon
        fill={fill}
        points='0.01 28 4.01 32 20.01 16 4.01 0 0 4 12.01 16 0.01 28'
      />
    </svg>
  )
}

export default RightIcon
