import React, { Fragment } from 'react'

import ListTable from '_shared/components/navigation/ListTable'

import { Raw as InputInteger } from '_shared/components/input/InputInteger'
import { Raw as InputSelect } from '_shared/components/input/InputSelect'

import useLocale from '_shared/hooks/useLocale'
import useDependencies from '_shared/hooks/useDependencies'
import useMergedTickets from '_shared/hooks/useMergedTickets'

import cloneDeep from 'lodash/cloneDeep'

const defaultColumns = [
  {
    key: 'ticket_type',
    label: 'select ticket type',
  },
  {
    key: 'capacity_type',
    label: 'capacity type',
  },
  {
    key: 'capacity_units',
    label: 'capacity',
  },
]

const spec = {
  ticket_type: {
    entity_id: null,
  },
  capacity_type: {
    entity_id: null,
  },
  capacity_units: 0,
}

const fieldStyle = {
  flex: '0 0 auto',
  width: '6rem',
  minHeight: '1.5rem',
}

const generateNested = (spec, localisation) => {
  return {
    ...spec,
    locales: localisation.map(code => {
      return {
        locale: code,
        ...spec.locales,
      }
    }),
  }
}

const TicketBox = ({
  field: outer_field,
  value: collection,
  change,
  create,
  remove,
  withoutCapacity,
}) => {
  const {
    locales,
  } = useLocale()
  const { isFetching, data: ticket_types } = useMergedTickets()

  const { isFetching: capacityFetching, data: capacity_types } = useDependencies('capacity_types', data => data
    .map(({ entity_id, title }) => {
      return {
        label: title,
        value: entity_id,
      }
    }))

  const innerCreate = () => {
    create(generateNested(cloneDeep(spec), locales))
  }

  // const localeIndex = locales.findIndex(locale => locale === current_locale)

  // May have to take into account ID if BE sends back a record ID, use row index for now
  const mutatedData = collection.map(({
    ticket_type,
    capacity_type,
    capacity_units,
  }, row) => {
    const rowView = {
      id: row,
      ticket_type: (
        <InputSelect
          placeholder={'Ticket Type'}
          options={ticket_types}
          field={`ticket_types[${row}]ticket_type.entity_id`}
          value={ticket_type.entity_id}
          {...fieldStyle}
          width={withoutCapacity ? '33%' : '100%'}
          change={change}
        />
      ),
    }

    if (!withoutCapacity) {
      rowView.capacity_type = (
        <InputSelect
          placeholder={'Capacity Type'}
          options={capacity_types}
          field={`ticket_types[${row}]capacity_type.entity_id`}
          value={capacity_type.entity_id}
          {...fieldStyle}
          width={'100%'}
          change={change}
        />
      )

      rowView.capacity_units = (
        <InputInteger
          placeholder={'Capacity'}
          field={`ticket_types[${row}]capacity_units`}
          value={capacity_units}
          {...fieldStyle}
          width={'50%'}
          change={change}
        />
      )
    }

    return rowView
  })

  const columns = withoutCapacity ? defaultColumns.filter(
    (el) => el.key === 'ticket_type',
  ) : defaultColumns

  return (
    <ListTable
      columns={columns}
      data={mutatedData}
      create={innerCreate}
      remove={remove}
      loading={isFetching || capacityFetching}
    />
  )
}

export default TicketBox
