import React from 'react'

import InputDate from '_shared/components/input/InputDate'

const DateServiceEnd = ({
  value,
  change,
}) => {
  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  return (
    <InputDate
      field={'service-date-end'}
      title={'Service End Date'}
      placeholder={'Service End Date'}
      value={value}
      change={handleChange}
    />
  )
}

export default DateServiceEnd
