import React, { useState } from 'react'

import cloneDeep from 'lodash/cloneDeep'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'
import Heading from '_shared/components/layout/Heading'
// import TextBox from '_shared/components/layout/TextBox'
// import InputText from '_shared/components/input/InputText'
import InputDate from '_shared/components/input/InputDate'
import ActionTagCheck from '_shared/components/action/ActionTagCheck'
import InputTimeList from '_shared/components/input/InputTimeList'

import InputTagSelector from '_shared/components/input/InputTagSelector'

import eachDay from 'date-fns/each_day'
import format from 'date-fns/format'

import {
  getStamp,
  stampToDateISO,
} from 'libs/date'

const isValidDate = (date) => {
  return !isNaN(getStamp(date))
}

const generateSchedule = (dateFrom, dateTo, days, times) => {
  const dates = []

  if (!isValidDate(dateFrom) || !isValidDate(dateTo)) return dates

  dateFrom = getStamp(dateFrom)
  dateTo = getStamp(dateTo)

  if (dateTo < dateFrom) return dates

  const schedule = eachDay(dateFrom, dateTo)
    .filter(date => days.includes(Number(format(date, 'd'))))
    .reduce((acc, cur) => {
      acc.push({
        date: stampToDateISO(cur),
        times: [...times],
      })
      return acc
    }, [])

  return schedule
}

const options = [
  {
    label: 'Mon',
    value: 1,
  },
  {
    label: 'Tue',
    value: 2,
  },
  {
    label: 'Wed',
    value: 3,
  },
  {
    label: 'Thu',
    value: 4,
  },
  {
    label: 'Fri',
    value: 5,
  },
  {
    label: 'Sat',
    value: 6,
  },
  {
    label: 'Sun',
    value: 0,
  },
]

const FormRanges = ({
  // update 
  updateSingle,
}) => {
  const [
    state,
    setState,
  ] = useState({
    start_date: new Date().toISOString().split('T')[0],
    end_date: new Date().toISOString().split('T')[0],
    days_of_week: [0, 1, 2, 3, 4, 5, 6],
    start_times: [],
  })

  const handleUpdate = (field, value) => {
    const current = cloneDeep(state)

    current[field] = value

    setState(current)

    updateSingle('schedule', generateSchedule(current.start_date, current.end_date, current.days_of_week, current.start_times))
  }

  return (
    <FormView>
      <Area
        areas={[
          'range',
          'days',
          'times',
        ]}
        rowgap={1.5}
      >
        <Area
          area={'range'}
          areas={[
            'info',
            'start . end .',
          ]}
          rowgap={1}
          colgap={1}
          columns={['10rem', '2rem', '10rem', '1fr']}
        >
          <Heading level={3} title={'Date range:'} area={'info'} />
          <InputDate
            area={'start'}
            title={'Start date'}
            field={'start_date'}
            value={state.start_date}
            placeholder={'Start'}
            change={handleUpdate}
          />
          <InputDate
            area={'end'}
            title={'End date'}
            field={'end_date'}
            value={state.end_date}
            placeholder={'End'}
            change={handleUpdate}
          />
        </Area>
        <Area
          area={'days'}
          areas={[
            'info',
            'field',
          ]}
          rowgap={1}
        >
          <Heading level={3} title={'Days of the week:'} area={'info'} />
          <InputTagSelector
            area={'field'}
            field={'days_of_week'}
            value={state.days_of_week}
            options={options}
            change={handleUpdate}
          />
        </Area>
        <Area
          area={'times'}
          areas={[
            'info',
            'field . . .',
          ]}
          rowgap={1}
        >
          <Heading level={3} title={'Departure route start times:'} area={'info'} />
          <InputTimeList
            area={'field'}
            field={'start_times'}
            value={state.start_times}
            change={handleUpdate}
          />
        </Area>
      </Area>
    </FormView>
  )
}

// TODO: When we implement locales, add these in
const day_strings = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
]

const days = [1, 2, 3, 4, 5, 6, 0]

const DaySelector = ({
  value,
  field,
  change,
}) => {
  const handleChange = (day) => {
    const state = [...value]
    const index = state.indexOf(day)

    if (index === -1) {
      state.push(day)
    } else {
      state.splice(index, 1)
    }

    change(field, state)
  }

  return (
    <Row spread={false}>
      {days.map(day => {
        return (
          <ActionTagCheck
            key={day_strings[day]}
            label={day_strings[day]}
            active={value.includes(day)}
            change={() => handleChange(day)}
            margin={'0 0.5rem 0 0'}
          />
        )
      })}
    </Row>
  )
}

export default FormRanges
