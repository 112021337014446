import React from 'react'

import Chooser from '../Chooser'

const ChooserMarket = ({
  data,
  change,
}) => {
  return (
    <Chooser
      field={'markets'}
      label={'Filter markets'}
      data={data}
      change={change}
      dependency={'markets'}
    />
  )
}

export default ChooserMarket
