import React from 'react'

import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'

import ChooserMarket from '../choosers/ChooserMarket'
import ChooserChannel from '../choosers/ChooserChannel'
import ChooserSource from '../choosers/ChooserSource'

const MetaFilter = ({
  data,
  change,
}) => {
  return (
    <Area
      areas={[
        'title',
        'market channel source',
      ]}
      columns={3}
      rowgap={1}
    >
      <Heading
        area={'title'}
        level={3}
        title={'Markets and channels'}
      />
      <ChooserMarket
        area={'market'}
        data={data}
        change={change}
      />
      <ChooserChannel
        area={'channel'}
        data={data}
        change={change}
      />
      <ChooserSource
        area={'source'}
        data={data}
        change={change}
      />
    </Area>
  )
}

export default MetaFilter
