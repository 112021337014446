import React from 'react'

const DragHandleIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M20,9H4v2h16V9z M4,15h16v-2H4V15z' fill={fill} />
    </svg>
  )
}

export default DragHandleIcon
