import React, { Fragment, useState } from 'react'

import cloneDeep from 'lodash/cloneDeep'

import Area from '_shared/components/layout/Area'

import FormView from '_shared/components/layout/FormView'
import Row from '_shared/components/layout/Row'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'

import InfoBox from '_shared/components/notification/InfoBox'

import ActionCTA from '_shared/components/action/ActionCTA'

import LayoutConsumer from '../../Layouts/form/Consumer'

import Card from '_shared/components/layout/Card'
import CardSelected from '_shared/components/notification/CardSelected'

import Button from '_shared/components/element/Button'

import useLocale from '_shared/hooks/useLocale'

const buttonStyle = {
  flexDirection: 'column',
  alignSelf: 'stretch',
  flexGrow: 1,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}

const FormCapacity = ({
  current,
  updateSingle,
}) => {
  const {
    default_locale,
  } = useLocale()

  const [
    sub,
    setSub,
  ] = useState(false)

  const [
    record,
    setRecord,
  ] = useState({})

  const [
    selected,
    setSelected,
  ] = useState(null)

  const openForm = (index = null) => {
    const reference = index !== null ? current.capacity_templates[index] : {}
    setSelected(index)
    setRecord(reference)

    setSub(true)
  }

  const closeForm = () => {
    setSelected(null)
    setRecord({})

    setSub(false)
  }

  const saveLayout = (selected) => (payload) => {
    const state = cloneDeep(current.capacity_templates)

    if (selected === null) {
      state.push(payload)
    } else {
      state[selected] = payload
    }

    updateSingle('capacity_templates', state)

    setSub(false)
  }

  return (
    <Fragment>
      <LayoutConsumer mount={sub} close={closeForm} record={record} save={saveLayout(selected)} />
      <FormView>
        <Area
          areas={[
            'section/2 layout_info',
            'info/2 layout_info',
            'layout',
            'templates',
          ]}
          rowgap={1.5}
        >
          <SectionWrapper area={'section'}>
            <Heading level={1} title={'Vehicle Layout Templates'} />
          </SectionWrapper>
          <TextBox strong area={'info'}>{'Layout templates are used for creating instances. Once you have added them to a service they are not connected. Editing these templates will not impact the services.'}</TextBox>
          <InfoBox
            area={'layout_info'}
            type={'info'}
            title={'Layouts'}
            content={'Adjusting the layouts does not impact any departures.'}
          />
          <Row area={'layout'}>
            <ActionCTA change={() => openForm()} label={'Add a New Layout'} />
          </Row>
          <Row area={'templates'}>
            {current.capacity_templates.map((template, index) => {
              const {
                locales,
                areas,
              } = template.capacity_layout

              const meta = locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

              const areaMeta = areas.map(area => {
                const meta = area.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

                return {
                  title: meta ? meta.title : 'undefined',
                  capacity: area.capacity ? area.capacity.units : 0,
                }
              })

              return (
                <Template
                  key={template.id || index}
                  value={{
                    title: meta ? meta.title : 'undefined',
                    description: meta ? meta.description : 'undefined',
                    areas: areaMeta,
                  }}
                  change={() => openForm(index)}
                />
              )
            })}
          </Row>
        </Area>
      </FormView>
    </Fragment>
  )
}

const infoStyle = {
  maxHeight: '9rem',
  overflow: 'hidden',
  textAlign: 'left',
}

const Template = ({ value, change }) => {
  return (
    <Card>
      <CardSelected>
        <Button change={change} rawStyle={buttonStyle}>
          <Heading level={3} title={value.title} />
          <InlineTextBox rawStyle={infoStyle}>{value.description}</InlineTextBox>
          <Heading level={4} title={'Capacities'} rawStyle={{ marginTop: 'auto' }} />
          {value.areas.map((area, index) => {
            return (
              <Row type={'spaced'} key={index} rawStyle={{ flex: '0 0 auto' }}>
                <InlineTextBox>{area.title}</InlineTextBox>
                <InlineTextBox>{area.capacity}</InlineTextBox>
              </Row>
            )
          })}
        </Button>
      </CardSelected>
    </Card>
  )
}

export default FormCapacity
