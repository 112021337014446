import React, { Fragment } from 'react'

const ShowHideWrapper = (props) => {
  const {
    children,
    visible,
  } = props

  return (
    <Fragment>
      {visible && children}
    </Fragment>
  )
}

export default ShowHideWrapper
