import { createSchema, morphism } from 'morphism'
import api from 'api/product'

import {
  mapLocales,
  mapDefaults,
} from '_shared/libs/mapToSchema'

import {
  checkValidID,
  isUnique,
} from '_shared/libs/validityChecks'

const defaults = {
  'external_id': '',
  'type': 'cancellation',
  'min_tickets': 0,
  'max_tickets': 0,
  'rule.channels': [],
  'rule.date.after_offset_from_now': 'P1D',
  'rules': [],
}

const getRuleSpec = (locales) => {
  return createSchema(
    {
      min_tickets: 'min_tickets',
      max_tickets: 'max_tickets',
      type: 'type',
      rule: {
        markets: 'rule.markets',
        channels: 'rule.channels',
        date: {
          after_offset_from_now: 'rule.date.after_offset_from_now',
        },
      },
      fee: {
        path: 'fee',
        fn: (value, source) => {
          // Default fee value when one isn't available:
          // [{ channel: null, market_id: null, ticket_type_id: null, type: 'increment_percent', value: 0 }]
          if (!value || value.length === 0) {
            return [
              morphism(getPriceAdjustmentSchema(locales, []), {}),
            ]
          }

          return value.map(record => morphism(getPriceAdjustmentSchema(locales, []), record))
        },
      },
    },
    mapDefaults(defaults),
  )
}

const getPriceAdjustmentSchema = (locales) => {
  return createSchema(
    {
      channel: 'channel',
      market_id: 'market_id',
      ticket_type_id: 'ticket_type_id',
      type: 'type',
      value: 'value',
    },
    mapDefaults({
      'type': 'increment_percent',
      'value': 0,
    }),
  )
}

const getSpec = (locales) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          title: '',
          description: '',
        }, value, locales),
      },
      rules: {
        path: 'rules',
        fn: (value, source) => {
          if (!value) return []
          return value.map(record => morphism(getRuleSpec(), record))
        },
      },
    },
    mapDefaults(defaults),
  )
}

const valid = {
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },

  locales: (record, value) => {
    return value
      .map(locale => locale.title)
      .some(locale => locale !== '')
  },
  rules: (record, value) => {
    return value.length > 0
  },
}

const handlers = {
  store: 'terms',
  unique_key: 'external_id',
  get: api.getTerm,
  new: api.createTerm,
  edit: (payload) => api.updateTerm(payload.entity_id, payload),
  remove: (payload) => { },
  cancel: null,
}

export {
  getSpec,
  getRuleSpec,
  valid,
  handlers,
}