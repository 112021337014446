import React from 'react'

import InputFilter from '_shared/components/input/InputFilter'

// ordered,cancelled,amended
const options = [
  {
    label: 'Ordered',
    value: 'ordered',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
  {
    label: 'Amended',
    value: 'amended',
  },
]

const ChooserStatus = ({
  data,
  change,
}) => {
  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  return (
    <InputFilter
      field={'statuses'}
      title={'Filter status'}
      placeholder={'Filter status'}
      value={data['statuses']}
      change={handleChange}
      options={options}
    />
  )
}

export default ChooserStatus
