import React from 'react'

import StyleWrapper from '_shared/style/StyleWrapper'

import UpIcon from '_shared/icons/Up'
import DownIcon from '_shared/icons/Down'

const VerticalArrowIndicator = (props) => {
  const {
    open,
    size = 12,
    ...style
  } = props

  const Icon = open ? UpIcon : DownIcon

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div style={styling}>
            <Icon size={size} />
          </div>
        )
      }}
    />
  )
}

export default VerticalArrowIndicator
