import React, { useRef, useCallback } from 'react'

import styles from './utilitydropdown.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

import styleCombine from '_shared/libs/styleCombine'

import useListNavigation from '_shared/hooks/useListNavigation'

import TickIcon from '_shared/icons/Tick'

import Config from 'libs/Config'

const getIndex = (options, value) => {
  return value ? options.findIndex(item => item.value === value) : 0
}

const UtilityDropDown = (props) => {
  const {
    options,
    change,
    value,
    cancel,
    focusOnMount = true,
    ...style
  } = props

  const container = useRef(null)

  const keyChange = useCallback((focused) => {
    change(options[focused].value)
  }, [options, change])

  const {
    focused,
    handleKey,
  } = useListNavigation(container, getIndex(options, value), options, keyChange, cancel, focusOnMount)

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.holder}>
            <div className={styles.arrow} />
            <div
              ref={container}
              className={styles.drop}
              style={styling}
              onKeyDown={handleKey}
              tabIndex={0}
            >
              {options.map((option, index) => {
                const isFocused = index === focused
                const isSelected = option.value === value

                const mod = isSelected ? styles.selected : isFocused ? styles.focused : null

                return (
                  <div
                    key={option.value}
                    className={styleCombine(styles.row, mod)}
                    onMouseDown={event => {
                      event.stopPropagation()

                      change(option.value)
                    }}
                  >
                    <span>{option.label}</span>
                    {isSelected && (
                      <TickIcon fill={Config.theme.text_success} size={24} />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )
      }}
    />
  )
}

export default UtilityDropDown
