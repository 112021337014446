import React, { Fragment } from 'react'

import styles from './nav.scss'

import Row from 'containers/Row'

import ModalButton from 'atoms/ModalButton'
import TextFieldIcon from 'molecules/TextFieldIcon'
import IconButton from 'molecules/IconButton'

import SearchIcon from 'icons/Search'
import SearchRefreshIcon from 'icons/SearchRefresh'

import colors from 'config/colors'

const Nav = (props) => {
  const {
    label,
    create,
    value,
    update,
    sorted,
    options,
    canCreate,
  } = props

  return (
    <Row
      type={'spaced'}
      styling={styles.layout}
    >
      <ModalButton
        styling={styles.action}
        color={canCreate ? 'save' : 'disabled'}
        content={label}
        onClick={canCreate ? create : null}
        locked={!canCreate}
      />
      {options[sorted].filter && (
        <Fragment>
          <IconButton
            label={`Search by ${sorted !== null ? options[sorted].title : ''}`}
            icon={SearchRefreshIcon}
          />
          <TextFieldIcon
            styling={styles.search}
            placeholder={'Search'}
            value={value}
            change={(field, value) => update(value)}
            icon={(
              <SearchIcon
                size={20}
                fill={colors.color_text}
              />
            )}
          />
        </Fragment>
      )}
    </Row>
  )
}

export default Nav
