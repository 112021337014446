import React from 'react'

import styles from './listaddbutton.scss'

import Row from 'containers/Row'

import AddButton from 'molecules/AddButton'

import LockedBadge from 'atoms/LockedBadge'

import styleCombine from 'libs/styleCombine'

const ListAddButton = (props) => {
  const {
    action,
    label,
    styling,
    centered = false,
    disabled = false,
  } = props

  return (
    <Row type={centered ? 'centered' : 'start'} styling={styleCombine(styles.layout, disabled ? styles.disabled : styles.enabled, styling)} onClick={!disabled ? action : null}>
      <AddButton
        label={label}
        disabled={disabled}
      />
      {disabled && (
        <LockedBadge styling={styles.locked} />
      )}
    </Row>
  )
}

export default ListAddButton
