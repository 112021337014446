import { useReducer } from 'react'

import createHookReducer from '_shared/libs/createHookReducer'

const getInitialState = (modify) => ({
  status: 'idle', // idle || new || edit || error || confirm || waiting
})

// const loadRecord = (state, action) => {
//   const {
//     status
//   } = action

//   return Object.assign({}, state, {
//     status
//   })
// }

// const cancelRecord = (state, action) => {
//   return getInitialState(state.modals)
// }

const setStatus = (state, action) => {
  const {
    status,
  } = action

  return Object.assign({}, state, {
    status,
  })
}

const reducer = createHookReducer({
  // LOAD: loadRecord,
  // CANCEL: cancelRecord,
  SET_STATUS: setStatus,
})

/*
  Provides the cache layer for the form
  Holds the current working record (either empty or fetched)
*/
const useStatus = () => {
  return useReducer(reducer, getInitialState())
}

export default useStatus
