import React, { useCallback, useState } from 'react'

import * as duration from 'duration-fns'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'

const mask = (event) => {
  let value = event.target.value.replace(/[^0-9:]/g, '')

  let split = value.split(':')

  if (split.length > 2) split = split.slice(0, 2)

  if (split.length > 1) {
    split = split.map(item => item.slice(0, 2))

    value = split.join(':')
  } else {
    if (value.length > 2) {
      value = value.match(/[0-9]{1,2}/g).join(':')
    }
  }

  return value
}

const addZero = (number) => {
  number = parseInt(number)

  if (number < 10) number = '0' + number

  return number
}

const defaultStamp = {
  minutes: 0,
  hours: 0,
}

const getTimeString = (stamp) => {
  const output = duration.parse(stamp)

  const cleaned = {
    ...defaultStamp,
    ...output,
  }

  return `${addZero(cleaned.hours)}:${addZero(cleaned.minutes)}`
}

const getDurationString = (time) => {
  const [
    hours = 0,
    minutes = 0,
  ] = time.split(':')

  return duration.toString({
    hours: Number(hours),
    minutes: Number(minutes),
  })
}

const decorateDuration = (stamp) => {
  const output = duration.parse(stamp)

  const cleaned = {
    ...defaultStamp,
    ...output,
  }

  return `${cleaned.hours}h ${cleaned.minutes}m`
}

const InputDuration = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  min,
  max,
  ...style
}) => {
  const [
    internal,
    setInternal,
  ] = useState(getTimeString(value)) // '00:00'

  const [
    focused,
    setFocused,
  ] = useState(false)

  const handleChange = useCallback((event) => {
    const value = mask(event)

    setInternal(value)
    change(field, getDurationString(value))
  }, [change, field])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={focused ? internal : decorateDuration(value)}
        change={handleChange}
        margin={margin}
        disabled={disabled}
        focus={handleFocus}
        blur={handleBlur}
      />
    </StatefulWrapper>
  )
}

const InputDurationWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputDuration {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputDuration {...props} />
  )
}

export default InputDurationWrapped

export {
  Raw,
}
