import React, { Component } from 'react'

import Row from 'containers/Row'
import InlineTextBox from 'containers/InlineTextBox'
import Container from 'containers/Container'

import styles from './statefulcontainer.scss'

import TickIcon from 'icons/Tick'

import colors from 'config/colors.json'

const allowed = [
  'interacting',
  'validated',
  'warning',
  'error',
  'disabled',
]

class StatefulContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      focused: false,
      touched: false,
    }
  }

  handleFocus = () => {
    this.setState({
      focused: true,
      touched: true,
    })
  }

  handleClick = () => {
    this.setState({
      touched: true,
    })
  }

  handleBlur = () => {
    this.setState({
      focused: false,
    })
  }

  render () {
    const {
      children,
      status,
      styling,
      required,
      showRequiredMessage = true,
      requiredText,
      getRef,
    } = this.props

    let alteredStatus = status

    if (!this.state.touched) {
      if (status !== 'validated' && status !== 'disabled') {
        alteredStatus = null
      }
    }

    if (this.state.focused) {
      alteredStatus = 'interacting'
    }

    const style = allowed.includes(alteredStatus) ? styles[alteredStatus] : styles.default

    const showRequired = alteredStatus === 'error' && !this.state.focused && this.state.touched && showRequiredMessage

    return (
      <Row
        type={'start'}
        styling={[styles.layout, style, styling].join(' ')}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
        getRef={getRef}
      >
        {children}
        {showRequired && <RequiredMessage label={requiredText} status={alteredStatus} />}
        {required && <RequiredIndicator status={alteredStatus} />}
      </Row>
    )
  }
}

const RequiredIndicator = (props) => {
  const {
    status,
  } = props

  const styling = [styles.indicator]

  styling.push(allowed.includes(status) ? styles[status] : styles.default)

  const Icon = status === 'validated' ? <TickIcon size={10} fill={colors.color_text_light} /> : '*'

  return (
    <Container
      styling={styling.join(' ')}
    >
      {Icon}
    </Container>
  )
}

const RequiredMessage = (props) => {
  const {
    label,
    status,
  } = props

  const styling = status === 'error' ? styles.error : null

  return (
    <InlineTextBox
      styling={[styles.message, styling].join(' ')}
    >
      {label}
    </InlineTextBox>
  )
}

export default StatefulContainer
