import { useParams, useHistory } from 'react-router-dom'

const mergeKeys = (params, param_key) => param_key.reduce((acc, cur) => {
  if (params[cur]) acc.push(params[cur])

  return acc
}, []).join('/')

const useNav = (param_key) => {
  const history = useHistory()
  const params = useParams()

  const open = (slug) => {
    const location = history.location.pathname.replace(/\/$/, '')

    history.push(`${location}${slug.startsWith('-') ? '' : '/'}${slug}`)
  }

  const close = () => {
    const slug = Array.isArray(param_key) ? mergeKeys(params, param_key) : params[param_key]

    console.log('CLOSING', params, param_key, slug)

    const location = history.location.pathname.replace(slug, '').replace(/\/$/, '')

    history.push(location)
  }

  return {
    open,
    close,
  }
}

export default useNav
