// import login from './login'
import salesGroups from './salesGroups'
import localisation from './localisation'

const coreData = () => {
  const endpoints = [
    // login.getProfiles,
    salesGroups.getSalesGroups,
    localisation.getSystemConfig,
  ].filter(item => item)

  const storeMap = [
    // 'profiles',
    'salesGroups',
    'localisation',
  ]

  return Promise.all(endpoints.map(endpoint => {
    return endpoint().catch(err => {
      console.log(err)

      return null
    })
  }))
    .then(data => {
      let output = {}

      data.forEach((item, index) => {
        if (item !== null) output[storeMap[index]] = item
      })

      return output
    })
    .catch(err => console.log('error', err))
}

export default coreData
