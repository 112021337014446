import React from 'react'

import useFormController from '_shared/hooks/useFormController'

import Slide, { Controls } from 'containers/HistorySlide'

const SlideForm = ({
  options,
  data_spec,
  valid_spec,
  handlers,
  mountKey,
  match = null,
  permissionsKey,
  render,
  unique_key = null,
}) => {
  const {
    current,
    status,
    valid,
    allValid,
    permissions,
    cancel,
    save,
    update,
  } = useFormController(data_spec, valid_spec, handlers, permissionsKey, mountKey, match, unique_key)

  const updateSingle = (field, value) => update([{ field, value }])

  // TODO: Find a cleaner way of doing this...
  if (status === 'waiting') return null

  return (
    <Slide
      mountKey={mountKey}
      match={match}
      controls={() => {
        return (
          <Controls
            saveAction={save}
            cancelAction={cancel}
            permissions={permissions}
            disabled={!allValid}
          />
        )
      }}
    >
      {render({
        status,
        current,
        valid,
        update,
        updateSingle,
      })}
    </Slide>
  )
}

export default SlideForm
