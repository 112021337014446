import upperCaseFirst from 'libs/upperCaseFirst'

const getFilteredProfiles = (profiles) => {
  const filtered = profiles
    .filter(profile => !profile.trade_partner_id)
    .reduce((list, profile) => {
      let access = []

      if (profile.roles) {
        access = profile.roles
          .split(',')
          .reduce((acc, cur) => {
            const [
              portal,
            ] = cur.split(':')

            acc.push(upperCaseFirst(portal))

            return acc
          }, [])
      }

      list.ALL.push({
        ...profile,
        title: profile.name,
        department: profile.department || '',
        id: profile.username,
        access: access.join(','),
        enabled: profile.enabled ? 'enabled' : 'disabled',
      })

      access.forEach(key => {
        list[key.toUpperCase()].push({
          ...profile,
          title: profile.name,
          department: profile.department || '',
          id: profile.username,
          access: access.join(','),
          enabled: profile.enabled ? 'enabled' : 'disabled',
        })
      })

      return list
    }, {
      ALL: [],
      ADMIN: [],
      POS: [],
      RESERVATIONS: [],
      SCANNER: [],
    })

  return filtered
}

export default getFilteredProfiles
