import React from 'react'

const HiddenIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size * 0.87}
      viewBox='0 0 30 25.92'
    >
      <path
        fill={fill}
        d={'M15,5.46a6.82,6.82,0,0,1,6.82,6.82,6.62,6.62,0,0,1-.49,2.49l4,4A16.15,16.15,0,0,0,30,12.28,16.1,16.1,0,0,0,9.56,3l2.95,3A6.52,6.52,0,0,1,15,5.46ZM1.36,1.73,4.47,4.84l.63.63A16.12,16.12,0,0,0,0,12.28,16.14,16.14,0,0,0,15,22.51a16,16,0,0,0,6-1.15l.58.58,4,4,1.74-1.73L3.1,0ZM8.91,9.28,11,11.39a3.8,3.8,0,0,0-.11.89A4.09,4.09,0,0,0,15,16.37a3.66,3.66,0,0,0,.88-.11L18,18.38a6.78,6.78,0,0,1-9.1-9.1Zm5.88-1.07,4.3,4.3,0-.22A4.08,4.08,0,0,0,15,8.2Z'}
      />
    </svg>
  )
}

export default HiddenIcon
