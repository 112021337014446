import React from 'react'

const ListAddIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path
        fill={fill}
        d='M14,10H2v2H14Zm0-4H2V8H14ZM2,16h8V14H2Zm19.5-4.5L23,13l-7,7L11.5,15.5,13,14l3,3Z'
      />
    </svg>
  )
}

export default ListAddIcon
