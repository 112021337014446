import React from 'react'
import styles from './tag.scss'

import StyleWrapper from '_shared/style/StyleWrapper'
import Tick from '_shared/components/element/Tick'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import styleCombine from '_shared/libs/styleCombine'

const ActionTagCheck = ({
  change,
  label,
  active,
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <button
            style={styling}
            className={styleCombine(styles.tag, active && styles.active)}
            onClick={change}
          >
            <Tick active={active} />
            <InlineTextBox color={'text_light'} margin={'0 0 0 0.25rem'} size={0.75}>{label}</InlineTextBox>
          </button>
        )
      }}
    />
  )
}

export default ActionTagCheck
