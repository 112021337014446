import { useState, useEffect, useCallback } from 'react'

const moveDown = (length, current) => {
  const next = current + 1 < length ? current + 1 : current

  return next
}

const moveUp = (length, current) => {
  const next = current - 1 > -1 ? current - 1 : current

  return next
}

const useKeyNavigation = (containerRef, initial, options, callback, cancel, focusOnMount = true) => {
  const [
    focused,
    setFocus,
  ] = useState(initial !== -1 ? initial : 0)

  const scrollTo = useCallback(() => {
    const node = containerRef.current

    if (node.scrollHeight > node.clientHeight) {
      const element = node.childNodes[focused]

      const scrollBottom = node.clientHeight + node.scrollTop
      const elementBottom = element.offsetTop + element.offsetHeight

      if (elementBottom > scrollBottom) {
        node.scrollTop = elementBottom - node.clientHeight
      } else if (element.offsetTop < node.scrollTop) {
        node.scrollTop = element.offsetTop
      }
    }
  }, [focused, containerRef])

  useEffect(() => {
    focusOnMount && containerRef.current.focus()

    scrollTo()
  })

  useEffect(() => {
    scrollTo()
  }, [focused, scrollTo])

  const handleKey = (event) => {
    const key = event.key

    switch (key) {
      case 'Down':
        event.preventDefault()

        setFocus(moveDown(options.length, focused))

        break
      case 'ArrowDown':
        event.preventDefault()

        setFocus(moveDown(options.length, focused))

        break
      case 'Up':
        event.preventDefault()

        setFocus(moveUp(options.length, focused))

        break
      case 'ArrowUp':
        event.preventDefault()

        setFocus(moveUp(options.length, focused))

        break
      case 'Enter':
        event.preventDefault()

        callback(focused)
        break
      case 'SpaceBar':
        event.preventDefault()

        callback(focused)
        break
      case 'Escape':
        cancel()

        break
      case ' ':
        event.preventDefault()

        callback(focused)
        break
      default:
        break
    }
  }

  return {
    focused,
    handleKey,
  }
}

export default useKeyNavigation
