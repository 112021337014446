import React from 'react'
import styles from './gradientbutton.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

const GradientButton = (props) => {
  const {
    change,
    children,
    focus,
    blur,
    ...style
  } = props

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <button
            style={styling}
            className={styles.button}
            onFocus={focus}
            onBlur={blur}
            onClick={change}
          >
            {children}
          </button>
        )
      }}
    />
  )
}

export default GradientButton
