import deepCopy from 'libs/deepCopy'

const spec = {
  authenticated: false,
  session: null,
  user: {},
}

const user = (state = spec, action = {}) => {
  switch (action.type) {
    case 'USER_LOGIN':
      return {
        ...state,
        session: null,
        authenticated: true,
      }
    case 'USER_SET_SESSION':
      return {
        ...state,
        authenticated: false,
        session: action.session,
        user: action.user,
      }
    case 'USER_SET_USER':
      return {
        ...state,
        user: action.user,
        authenticated: true,
      }
    case 'USER_SET_PERMISSION': {
      const {
        key,
        value,
      } = action

      const permissions = deepCopy(state.user.permissions)

      if (!permissions[key]) {
        console.log('KEY DOESNT EXIST', key)
        permissions[key] = []
      }

      if (permissions[key].includes(value)) {
        console.log('VALUE EXISTS REMOVING', key, value)
        const index = permissions[key].indexOf(value)

        permissions[key].splice(index, 1)
      } else {
        console.log('VALUE DOESNT EXIST ADDING', key, value)
        permissions[key].push(value)
      }

      if (permissions[key].length === 0) {
        console.log('KEY IS NOW EMPTY REMOVING', key, value)
        delete permissions[key]
      }

      console.log('UPDATED PERMISSIONS OBJECT', permissions)

      return {
        ...state,
        user: {
          ...state.user,
          permissions,
        },
      }
    }
    default: return state
  }
}

export default user

export {
  spec,
}
