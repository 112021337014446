import React, { useState } from 'react'

import api from 'api/reports'

import Form from './modules/Form'

import deepCopy from 'libs/deepCopy'

import {
  getToday,
  stampToDateISO,
} from 'libs/date'

import useLocale from '_shared/hooks/useLocale'

const formFields = {
  type: null,
  markets: [],
  services: [],
  products: [],
  channels: [],
  statuses: [],
  sources: [],
  location_id: null,
  'service-types': [],
  'service-date-start': stampToDateISO(getToday()),
  'service-date-end': stampToDateISO(getToday()),
  'booking-date-start': stampToDateISO(getToday()),
  'booking-date-end': stampToDateISO(getToday()),
  'service-time-start': null,
  'service-time-end': null,
  format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const Reports = ({
  type,
  current,
}) => {
  const {
    default_locale,
  } = useLocale()

  const [
    record,
    setRecord,
  ] = useState({
    ...deepCopy({
      ...formFields,
      type,
    }),
  })

  const handleChange = (payload) => {
    const state = { ...record }

    payload.forEach(({ field, value }) => {
      state[field] = value
    })

    setRecord(state)
  }

  const handleSubmit = async () => {
    const {
      format,
      type,
      ...rest
    } = record

    const output = Object.keys(rest)
      .reduce((acc, cur) => {
        if (Array.isArray(rest[cur])) {
          if (rest[cur].length > 0) acc[cur] = rest[cur]
        } else {
          if (rest[cur] !== null && rest[cur] !== '') acc[cur] = rest[cur]
        }

        return acc
      }, {})

    try {
      await api.getReport(output, format, type, default_locale)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Form
      key={type}
      title={current.title}
      description={current.description}
      fields={current.form}
      layout={current.layout}
      data={record}
      change={handleChange}
      submit={handleSubmit}
    />
  )
}

export default Reports
