import React, { useEffect } from 'react'

import sortedUniq from 'lodash/sortedUniq'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'

import { Raw as InputSelect } from '_shared/components/input/InputSelect'
import ListTable from '_shared/components/navigation/ListTable'

import DirectRouteSelector from './DirectRouteSelector'
import TransferRouteSelector from './TransferRouteSelector'

import { getServiceSchema } from './Context'

import mapToSchema from '_shared/libs/mapToSchema'

import useDependencies from '_shared/hooks/useDependencies'

import useLocale from '_shared/hooks/useLocale'

import useServiceLocations from '_shared/hooks/useServiceLocations'

import { update } from '_shared/libs/mapToSchema'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import filterTickets, { filterRules } from './filterTickets'

const FormServices = ({
  valid,
  current,
  update,
}) => {
  const {
    locales,
    currencies,
    default_locale,
  } = useLocale()

  const { data: markets } = useDependencies('markets', data => {
    return data.map(market => {
      return market.entity_id
    })
  })

  const { isFetching, data: services } = useDependencies(
    'services',
    data => data
      .filter(record => record.type === current.type),
  )

  const service_options = services
    .map(record => {
      return {
        value: record.entity_id,
        label: getDeepLocale(record.locales, 'title', default_locale),
      }
    })

  const handleServiceChange = (field, value) => {
    const service_ticket_types = value
      .filter(item => item.entity_id !== null)
      .map(item => item.entity_id)
      .reduce((acc, entity_id) => {
        const outer = services.find(item => item.entity_id === entity_id)

        if (outer) {
          const tickets = outer.ticket_types
            .map(type => type.ticket_type.entity_id)
            .filter(type => !acc.includes(type))

          acc.push(...tickets)
        }

        return sortedUniq(acc)
      }, [])

    const output = filterTickets(service_ticket_types, markets, current.pricing)
    const rules = filterRules(service_ticket_types, current.pricing_rules, markets)

    const vat_rates = markets
      .map(market => {
        return {
          amount: 0,
          market: {
            entity_id: market,
          },
        }
      })

    update([
      {
        field,
        value,
      },
      {
        field: 'pricing',
        value: output,
      },
      {
        field: 'pricing_rules',
        value: rules,
      },
      {
        field: 'vat_rate',
        value: vat_rates,
      },
    ])
  }

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'services/2 . .',
          'routes',
        ]}
        columns={4}
        rowgap={2}
      >
        <Heading level={1} title={current.type === 'route' ? 'Add services & select routes' : 'Add services'} area={'section'} />
        <ServiceSelector
          area={'services'}
          loading={isFetching}
          outer_field={'services'}
          inner_field={'service'}
          data={current.services}
          options={service_options}
          change={handleServiceChange}
          placeholder={'Choose a service'}
          schema_generator={getServiceSchema}
        />
        {current.type === 'route' && (
          <Routes
            area={'routes'}
            valid={valid}
            current={current}
            update={update}
          />
        )}
      </Area>
    </FormView>
  )
}

const fieldStyle = {
  flex: '0 0 auto',
  minHeight: '1.5rem',
}

const ServiceSelector = ({
  loading,
  outer_field,
  inner_field,
  data,
  options,
  change,
  placeholder,
  schema_generator,
  allowCreate = true,
}) => {
  const {
    locales,
    currencies,
  } = useLocale()

  const create = () => {
    const state = [...data]
    const row = mapToSchema(locales, currencies, schema_generator)({})

    state.push(row)

    change(outer_field, state)
  }

  const remove = (row) => {
    const state = [...data]

    state.splice(row, 1)

    change(outer_field, state)
  }

  const handleChange = (row) => (field, value) => {
    const state = [...data]

    update(state, `[${row}]${field}`, value)

    change(outer_field, state)
  }

  const mutatedData = data.map((record, row) => {
    return {
      id: row,
      service: (
        <InputSelect
          field={'entity_id'}
          placeholder={placeholder}
          options={options}
          value={record.entity_id}
          change={handleChange(row)}
          {...fieldStyle}
        />
      ),
    }
  })

  return (
    <Area columns={1} rowgap={1}>
      <Heading title={'Add Services'} level={2} />
      <ListTable
        loading={loading}
        columns={[
          {
            key: 'service',
            label: 'services',
          },
        ]}
        data={mutatedData}
        create={allowCreate && create}
        remove={remove}
      />
    </Area>
  )
}

const Routes = ({
  valid,
  current,
  update,
  area,
}) => {
  const {
    isFetching,
    isLoaded,
    data,
  } = useServiceLocations({
    service_ids: current.services.map(({ entity_id }) => entity_id),
  })

  useEffect(() => {
    const payload = current.routes
      .reduce((state, route) => {
        const route_valid = route.legs
          .flatMap(({ departure_location, arrival_location }) => [departure_location.entity_id, arrival_location.entity_id])
          .every(location => data.allowed.includes(location))

        if (route_valid) {
          state.push(route)
        }

        return state
      }, [])

    if (payload.length !== current.routes.length && isLoaded) {
      update([
        {
          field: 'routes',
          value: payload,
        },
      ])
    }
  }, [data])

  return (
    <Area
      area={area}
      areas={[
        'direct/2 . .',
        'transfer',
      ]}
      columns={4}
      rowgap={2}
    >
      <DirectRouteSelector
        area={'direct'}
        loading={isFetching}
        loaded={isLoaded}
        current={current}
        data={data}
        change={update}
      />
      <TransferRouteSelector
        area={'transfer'}
        loading={isFetching}
        loaded={isLoaded}
        current={current}
        data={data}
        change={update}
        disabled={!valid.services}
      />
    </Area>
  )
}

Routes.styleable = true

export default FormServices
