import React, { useCallback, useState, useRef } from 'react'

import UtilityDropDown from '_shared/components/element/UtilityDropDown'
import ActionOption from '_shared/components/action/ActionOption'

import useOnClickOutside from '_shared/hooks/useOnClickOutside'
import useParentDistance from '_shared/hooks/useParentDistance'

const ActionOptionUtility = ({
  field,
  value,
  change,
  options,
  label,
}) => {
  const container = useRef(null)

  const [
    open,
    setOpen,
  ] = useState(false)

  const forceClose = useCallback(event => {
    setOpen(false)
  }, [])

  useOnClickOutside(container, forceClose)

  const {
    maxHeight,
    calcMaxHeight,
  } = useParentDistance(container)

  const handleChange = useCallback((value) => {
    setOpen(false)

    change(field, value)
  }, [change, field])

  const handleOpen = useCallback((event) => {
    event.preventDefault()

    calcMaxHeight()

    setOpen(!open)
  }, [open, calcMaxHeight])

  return (
    <div
      ref={container}
    >
      <ActionOption change={handleOpen} label={label} />
      {open && (
        <UtilityDropDown
          options={options}
          change={handleChange}
          value={value}
          cancel={forceClose}
          maxHeight={maxHeight}
        />
      )}
    </div>
  )
}

export default ActionOptionUtility
