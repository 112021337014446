import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText, { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import InputGeo from '_shared/components/input/InputGeo'
import { Localised as InputRTELocalised } from '_shared/components/input/InputRTE'
import InputSelect from '_shared/components/input/InputSelect'

import InfoBox from '_shared/components/notification/InfoBox'

import timeZones from 'timezones.json'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

const zoneOptions = timeZones
  .reduce((options, zone) => {
    const {
      utc,
    } = zone

    utc.forEach(text => {
      if (!options.includes(text)) options.push(text)
    })

    return options
  }, [])
  .sort()
  .map(zone => {
    return {
      label: zone,
      value: zone,
    }
  })

const FormDescription = ({
  current,
  valid,
  update,
  updateSingle,
  ...style
}) => {
  return (
    <FormView {...style}>
      <Area
        areas={[
          'section_a',
          'info_a/2 code_info',
          'code . code_info',
          'title . warning',
          'locode . locode_info',
          'description . locode_info',
          'section_b',
          'info_b/2 .',
          'country . .',
          'address . .',
          'location . .',
          'zone . .',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section_a'}>
          <Heading level={1} title={'Description'} />
        </SectionWrapper>
        <TextBox strong area={'info_a'}>{'Describe the basic details of this location or station.'}</TextBox>
        <InfoBox
          area={'warning'}
          type={'warning'}
          title={'Warning'}
          content={'Changing details here will change it throughout the services'}
        />
        <InputText
          area={'code'}
          title={'External ID'}
          field={'external_id'}
          value={current.external_id}
          placeholder={'Unique External ID'}
          change={updateSingle}
          status={parseValidity(valid.external_id)}
          required
          requiredText={'Must be unique'}
        />
        <InfoBox
          area={'code_info'}
          type={'info'}
          title={'External ID'}
          content={'Used for external API calls.'}
        />
        <InputTextLocalised
          area={'title'}
          title={'Title'}
          field={'title'}
          value={current.locales}
          placeholder={'Location title'}
          change={updateSingle}
          valid={valid.locales_title}
          required
        />
        <InputText
          area={'locode'}
          title={'Location Code'}
          field={'code'}
          value={current.code}
          placeholder={'Code'}
          change={updateSingle}
        />
        <InfoBox
          area={'locode_info'}
          type={'note'}
          title={'Location Code'}
          content={'Location codes are used by the messaging system and LED boards.'}
        />
        <InputRTELocalised
          area={'description'}
          title={'Description'}
          field={'description'}
          value={current.locales}
          placeholder={'Location description'}
          change={updateSingle}
        />
        <SectionWrapper area={'section_b'}>
          <Heading level={1} title={'Address and location'} />
        </SectionWrapper>
        <TextBox strong area={'info_b'}>{'Help customers navigate to this location.'}</TextBox>
        <InputTextLocalised
          area={'country'}
          title={'Country'}
          field={'country'}
          value={current.locales}
          placeholder={'Country'}
          change={updateSingle}
          valid={valid.locales_country}
          required
        />
        <InputText
          area={'address'}
          title={'Address'}
          field={'address'}
          value={current.address}
          placeholder={'Code'}
          change={updateSingle}
        />
        <InputGeo
          area={'location'}
          title={'GPS location - latitude and longitude'}
          field={'geo_position'}
          value={current.geo_position}
          placeholder={'E.g 0.282816263,1.82939271'}
          change={updateSingle}
        />
        <InputSelect
          area={'zone'}
          title={'Time Zone'}
          field={'timezone'}
          options={zoneOptions}
          value={current.timezone}
          placeholder={'TimeZone'}
          change={updateSingle}
          status={parseValidity(valid.timezone)}
          required
          requiredText={'Field is required'}
        />
      </Area>
    </FormView>
  )
}

FormDescription.styleable = true

export default FormDescription
