import storage from 'libs/asyncStorage'

import {
  setBusyState,
} from 'libs/actionCreator'

import loginApi from 'api/login'

// const permissions = [
//   'ancillary tickets:create',
//   'ancillary tickets:delete',
//   'ancillary tickets:read',
//   'ancillary tickets:update',
//   'ancillary items:create',
//   'ancillary items:delete',
//   'ancillary items:read',
//   'ancillary items:update',
//   'combos:create',
//   'combos:delete',
//   'combos:read',
//   'combos:update',
//   'instances:cancel',
//   'instances:capacity',
//   'instances:capping',
//   'instances:create',
//   'instances:freeze',
//   'instances:read',
//   'menus:create',
//   'menus:delete',
//   'menus:read',
//   'menus:update',
//   'pass:create',
//   'pass:delete',
//   'pass:read',
//   'pass:update',
//   'pricing:create',
//   'pricing:delete',
//   'pricing:publish',
//   'pricing:read',
//   'pricing:update',
//   'promotions:create',
//   'promotions:delete',
//   'promotions:read',
//   'promotions:update',
//   'reporting:',
//   'routes:create',
//   'routes:delete',
//   'routes:read',
//   'routes:update',
//   'schedule:create',
//   'schedule:delete',
//   'schedule:publish',
//   'schedule:read',
//   'schedule:update',
//   'season:create',
//   'season:delete',
//   'season:publish',
//   'season:read',
//   'season:update',
//   'service:create',
//   'service:read',
//   'service:update',
//   'stations:create',
//   'stations:delete',
//   'stations:read',
//   'stations:update',
//   'system settings:read',
//   'ticket type:create',
//   'ticket type:delete',
//   'ticket type:read',
//   'ticket type:update',
//   'trade partners:create',
//   'trade partners:delete',
//   'trade partners:read',
//   'trade partners:update',
//   'user admin:read',
//   'vehicles:create',
//   'vehicles:delete',
//   'vehicles:read',
//   'vehicles:update',
//   'venues:create',
//   'venues:delete',
//   'venues:read',
//   'venues:update',
//   'vouchers:create',
//   'vouchers:delete',
//   'vouchers:read',
//   'vouchers:update'
// ]

const createMatrix = (permissions) => {
  return permissions.reduce((store, ability) => {
    const [
      key,
      action,
    ] = ability.replace(/\s/, '_').split(':')

    if (action.length === 0) return store

    if (store[key]) {
      store[key].push(action)
    } else {
      store[key] = [action]
    }

    return store
  }, {})
}

const getProfile = () => (dispatch, getState) => {
  // const permissionsMatrix = permissions.reduce((store, ability) => {
  //   const [
  //     key,
  //     action
  //   ] = ability.replace(/\s/, '_').split(':')

  //   if (action.length === 0) return store

  //   if (store[key]) {
  //     store[key].push(action)
  //   } else {
  //     store[key] = [action]
  //   }

  //   return store
  // }, {})

  return loginApi.getProfile()
    .then(response => {
      return storage.set('username', response.username)
        .then(() => {
          const {
            userPermissions,
            ...rest
          } = response

          console.log(createMatrix(userPermissions.split(',')))

          dispatch({
            type: 'USER_SET_USER',
            user: {
              ...rest,
              permissions: createMatrix(userPermissions.split(',')),
            },
          })

          return 'USER_SET'
        })
    })
    .catch(err => {
      dispatch(setBusyState(false))

      throw err
    })
}

export {
  getProfile,
}
