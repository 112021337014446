import React from 'react'

import {
  getSpec,
  valid as valid_spec,
  handlers,
} from './Context'

import Chooser from '../../../templates/Chooser'

import SlideForm from 'templates/FullForm'

import Event from './Event'
import Route from './Route'
import APIRoute from './APIRoute'

const Consumer = (props) => {
  return (
    <SlideForm
      data_spec={getSpec}
      valid_spec={valid_spec}
      handlers={handlers}
      mountKey={'record'}
      permissionsKey={'services'}
      render={({
        cancel,
        current,
        valid,
        reset,
        update,
        updateSingle,
      }) => {
        if (current.type === '') return (
          <Chooser
            cancel={cancel}
            update={updateSingle}
            current={current}
            reset={reset}
          />
        )

        if (current.type === 'route' && current.operator_type === 'internal') return (
          <Route
            cancel={cancel}
            current={current}
            valid={valid}
            updateSingle={updateSingle}
          />
        )

        if (current.type === 'route' && current.operator_type === 'scandlines') return (
          <APIRoute
            cancel={cancel}
            current={current}
            valid={valid}
            updateSingle={updateSingle}
          />
        )

        if (current.type === 'event' && current.operator_type === 'internal') return (
          <Event
            cancel={cancel}
            current={current}
            valid={valid}
            updateSingle={updateSingle}
          />
        )
      }}
    />
  )
}

export default Consumer
