import React from 'react'

import FormView from '_shared/components/layout/FormView'

import Extension from 'templates/Extension'

const FormExtension = ({
  current,
  updateSingle,
}) => {
  return (
    <FormView>
      <Extension
        config={'product'}
        value={current.extensions}
        update={updateSingle}
      />
    </FormView>
  )
}

export default FormExtension
