import React, { Component } from 'react'

import styles from './currencyfield.scss'

import Container from 'containers/Container'
import StatefulContainer from 'containers/StatefulContainer'
import Input from 'atoms/Input'

import {
  smallToLarge,
  largeToSmall,
  formatCurrency,
} from 'libs/cashConversion'

import localisationWrapper from 'HOC/wrapLocalisation'

class CurrencyField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: '',
      focused: false,
    }

    this.input = React.createRef()
  }

  onChange = (event) => {
    let value = event.target.value.replace(/[^0-9.]/g, '')

    let split = value.split('.')

    if (split.length > 2) {
      split = split.slice(0, 2)
    }

    if (split.length > 1) {
      if (split[1].length > 2) {
        value = `${split[0]}.${split[1].slice(0, 2)}`
      } else {
        value = `${split[0]}.${split[1]}`
      }
    }

    this.setState({
      value,
    })

    if (this.props.allowEmpty && value === '') {
      this.props.change(this.props.field, null)
    } else {
      !this.props.sendOnBlur && this.props.change(this.props.field, largeToSmall(value === '' ? 0 : value))
    }
  }

  onFocus = (event) => {
    let value

    if (this.props.value === null || this.props.value === '' || this.props.value === 0) {
      value = ''
    } else {
      value = smallToLarge(this.props.value)
    }

    this.setState({
      value,
      focused: true,
    })
  }

  onBlur = (event) => {
    const {
      value,
    } = this.state

    this.setState({
      focused: false,
    })

    if (this.props.allowEmpty && value === '') {
      this.props.change(this.props.field, null)
    } else {
      this.props.change(this.props.field, largeToSmall(value === '' ? 0 : value))
    }
  }

  handleKey = (event) => {
    const key = event.key

    if (key === 'Enter') this.input.current.blur()
  }

  render () {
    const {
      field,
      status,
      styling,
      value,
      required,
      requiredText,
      derived,
      currency,
      locale,
      placeholder = formatCurrency(0, locale, currency),
      display = null,
    } = this.props

    let altValue = value === null || value === '' ? '' : `${formatCurrency(value, locale, currency)}`

    if (display !== null) altValue = display

    return (
      <Container
        styling={[styles.layout, styling].join(' ')}
      >
        <StatefulContainer
          status={status}
          styling={derived ? styles.derived : styles.field}
          required={required}
          requiredText={requiredText}
        >
          <Input
            name={field}
            type={'text'}
            placeholder={this.state.focused ? '00.00' : placeholder}
            value={this.state.focused ? this.state.value : altValue}
            change={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onKeyDown={this.handleKey}
            styling={styles.input}
            getRef={this.input}
          />
        </StatefulContainer>
      </Container>
    )
  }
}

export default localisationWrapper(CurrencyField)
