import { createSchema } from 'morphism'
import api from 'api/product'

import {
  mapLocales,
  mapDefaults,
  mapToArraySubSchema,
} from '_shared/libs/mapToSchema'

import {
  checkValidID,
  isUnique,
  notEmptyOrNull,
} from '_shared/libs/validityChecks'

const spec = {
  entity_id: null,
  external_id: '',
  country: '',
  address: '',
  code: '',
  geo_position: {
    latitude: 0,
    longitude: 0,
  },
  map_image: null,
  timezone: '',
  locales: {
    title: '',
    description: '',
    travel_instructions: '',
  },
}

const getTransferSpec = (locales) => {
  return createSchema(
    {
      location: {
        entity_id: 'location.entity_id',
      },
      duration: 'duration',
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          description: '',
        }, value, locales),
      },
    },
    mapDefaults({
      'duration': 'PT0H30M',
    }),
  )
}

const defaults = {
  'external_id': '',
  'code': '',
  'country': '',
  'address': '',
  'geo_position.latitude': 0,
  'geo_position.longitude': 0,
  'extensions': {},
  'transfers': [],
}

const getSpec = (locales) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      address: 'address',
      code: 'code',
      geo_position: {
        latitude: 'geo_position.latitude',
        longitude: 'geo_position.longitude',
      },
      timezone: 'timezone',
      extensions: 'extensions',
      integration_scandlines: 'integration_scandlines',
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          title: '',
          description: '',
          travel_instructions: '',
          country: '',
        }, value, locales),
      },
      transfers: {
        path: 'transfers',
        fn: mapToArraySubSchema(getTransferSpec, locales),
      },
    },
    mapDefaults(defaults),
  )
}

const valid = {
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },
  locales_title: (record, value) => {
    return value
      .map(locale => locale.title)
      .some(locale => locale !== '')
  },
  locales_country: (record, value) => {
    return value
      .map(locale => locale.country)
      .some(locale => locale !== '')
  },
  country: notEmptyOrNull,
  timezone: notEmptyOrNull,
}

const handlers = {
  store: 'locations',
  unique_key: 'external_id',
  get: api.getLocation,
  new: api.createLocation,
  edit: (payload) => api.updateLocation(payload.entity_id, payload),
  remove: (payload) => {},
  cancel: null,
}

export {
  getSpec,
  getTransferSpec,
  spec,
  valid,
  handlers,
}
