import Config from './Config'

const smallToLarge = (pence) => {
  return `${(Number(pence) / 100).toFixed(2)}`
}

const largeToSmall = (pounds) => {
  return isNaN(Number(pounds)) ? 0 : Math.round(Number(pounds) * 100)
}

const formatCurrency = (value, region = null, currency = null) => {
  const {
    locale,
    currency_code,
  } = Config.app

  region = region || locale
  currency = currency || currency_code

  return new Intl.NumberFormat(region, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  }).format(smallToLarge(value))
}

export {
  smallToLarge,
  largeToSmall,
  formatCurrency,
}
