import storage from 'libs/asyncStorage'
import Config from 'libs/Config'

// const BASE_URL = process.env.REACT_APP_API_GATEWAY_URL

const fetch = window.fetch
const Blob = window.Blob

const errorCodes = [
  400,
  403,
  404,
  500,
  '400',
  '403',
  '404',
  '500',
]

const defaultOptions = (method = 'GET', token, data) => {
  return {
    method,
    headers: {
      'content-type': 'application/json',
      'accept': 'application/json',
      'Authorization': token,
    },
    body: data ? JSON.stringify(data) : undefined,
  }
}
// const isDraft = !!(
//   published_revision &&
//   published_revision.entity_revision_id !== last_revision.entity_revision_id
// )

const mergePublishState = (data) => {
  if (Array.isArray(data)) {
    return data.map(({
      entity_id,
      last_revision,
      published_revision,
    }) => {
      return {
        entity_id,
        published: (published_revision != null && published_revision.entity_revision_id === last_revision.entity_revision_id),
        ...last_revision,
      }
    })
  } else {
    return (({
      entity_id,
      last_revision,
      published_revision,
    }) => ({
      entity_id,
      published: (published_revision !== null && published_revision.entity_revision_id === last_revision.entity_revision_id),
      ...last_revision,
    }))(data)
  }
}

export default class TicketingApi {
  // Call API with expected CSV file as response
  downloadFile (endpoint, { headers, ...optionsRest } = {}) {
    return storage.get('token')
      .then(token => {
        return fetch(
          `${Config.app.gatewayURL}/${endpoint}`,
          {
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              'Authorization': token,
              ...headers,
            },
            responseType: 'blob',
          },
        )
          .then(response => {
            if (!response.ok) {
              throw new Error('Server error')
            } else {
              return response.blob()
                .then(blob => {
                  // Yuck 🤢🤮 thanks internet
                  const url = URL.createObjectURL(new Blob([blob]))
                  const link = document.createElement('a')
                  // Get the filename from the Content-Disposition header. That header's value looks like this:
                  // "attachment; filename=foobar.json"
                  const contentDisposition = response.headers.get('Content-Disposition')

                  let fileName = contentDisposition
                    .split(';')
                    .map(str => str.trim())
                    .find(str => str.startsWith('filename=')) || 'untitled.txt'

                  // Remove quotes and the initial 'filename=' part
                  fileName = fileName.replace(/(filename=|'|")/g, '')

                  link.setAttribute('download', fileName)
                  link.href = url

                  document.body.appendChild(link)
                  link.click()
                  link.parentNode.removeChild(link)
                })
            }
          })
      })
  }

  // Call API with expected empty response
  triggerApi (endpoint, method = 'GET', data) {
    return storage.get('token')
      .then(token => {
        return fetch(`${Config.app.gatewayURL}/${endpoint}`, defaultOptions(method, token, data))
          .then(response => {
            if (response.ok) {
              return true
            } else {
              throw response.json()
            }
          })
          .catch(err => {
            console.error('Generic fetch error', err)
            throw err
          })
      })
  }

  // Call API with expected JSON resource as return
  callApi (endpoint, method = 'GET', data) {
    return storage.get('token')
      .then(token => {
        return fetch(`${Config.app.gatewayURL}/${endpoint}`, defaultOptions(method, token, data))
          .then(this.handleErrors)
          .then(response => response.json())
          .then(data => {
            if (data === null) {
              throw new Error('API returned null')
            }

            if (data.hasOwnProperty('code') && errorCodes.includes(data.code)) {
              console.log('Got error throwing', data)
              throw data
            }

            return data
          })
          .catch(err => {
            // console.error('Generic fetch error', err)
            throw err
          })
      })
  }

  // Call API with expected JSON resource as return
  callApiFlatten(endpoint, method = 'GET', data) {
    return storage.get('token')
      .then(token => {
        return fetch(`${Config.app.gatewayURL}/${endpoint}`, defaultOptions(method, token, data))
          .then(this.handleErrors)
          .then(response => response.json())
          .then(data => {
            if (data === null) {
              throw new Error('API returned null')
            }

            if (data.hasOwnProperty('code') && errorCodes.includes(data.code)) {
              console.log('Got error throwing', data)
              throw data
            }

            return Array.isArray(data) ? data.map(({ entity_id, last_revision }) => ({ entity_id, ...last_revision })) : (({ entity_id, last_revision }) => ({ entity_id, ...last_revision }))(data)
          })
          .catch(err => {
            // console.error('Generic fetch error', err)
            throw err
          })
      })
  }

  callApiMergePublish(endpoint, method = 'GET', data) {
    return storage.get('token')
      .then(token => {
        return fetch(`${Config.app.gatewayURL}/${endpoint}?expand=published_revision`, defaultOptions(method, token, data))
          .then(this.handleErrors)
          .then(response => response.json())
          .then(data => {
            if (data === null) {
              throw new Error('API returned null')
            }

            if (data.hasOwnProperty('code') && errorCodes.includes(data.code)) {
              console.log('Got error throwing', data)
              throw data
            }

            return mergePublishState(data)
          })
          .catch(err => {
            // console.error('Generic fetch error', err)
            throw err
          })
      })
  }

  callApiMergePublishExpand(endpoint, expand, method = 'GET', data) {
    return storage.get('token')
      .then(token => {
        return fetch(`${Config.app.gatewayURL}/${endpoint}?expand=published_revision,${expand}`, defaultOptions(method, token, data))
          .then(this.handleErrors)
          .then(response => response.json())
          .then(data => {
            if (data === null) {
              throw new Error('API returned null')
            }

            if (data.hasOwnProperty('code') && errorCodes.includes(data.code)) {
              console.log('Got error throwing', data)
              throw data
            }

            return mergePublishState(data)
          })
          .catch(err => {
            // console.error('Generic fetch error', err)
            throw err
          })
      })
  }

  // Call API with expected JSON resource as return
  callApiNoToken (endpoint, method = 'GET', data) {
    return fetch(
      `${Config.app.gatewayURL}/${endpoint}`, {
        method,
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
        body: data ? JSON.stringify(data) : undefined,
      })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(data => {
        if (data === null) {
          throw new Error('API returned null')
        }

        if (data.hasOwnProperty('code') && errorCodes.includes(data.code)) {
          console.log('Got error throwing', data)
          throw data
        }

        return data
      })
      .catch(err => {
        // console.error('Generic fetch error', err)
        throw err
      })
  }

  // Retrieve resource from asset API
  getResource (id) {
    return storage.get('token')
      .then(token => {
        return fetch(`${Config.app.gatewayURL}/assets/${id}`, defaultOptions('GET', token))
          .then(this.handleErrors)
          .then(response => response.json())
          .then(data => {
            if (data.hasOwnProperty('code') && errorCodes.includes(data.code)) {
              throw data
            }

            return data
          })
          .catch(err => {
            console.error('Error getting resource', err)
            throw err
          })
      })
  }

  // Asset API endpoint
  callAssetApi (endpoint, method = 'GET', data, filetype) {
    return storage.get('token')
      .then(token => {
        return fetch(`${Config.app.gatewayURL}/${endpoint}`, {
          method,
          body: data,
          headers: {
            'content-type': filetype,
            'Authorization': token,
          },
        })
          .then(this.handleErrors)
          .then(response => response.json())
          .then(data => {
            if (data.hasOwnProperty('code') && errorCodes.includes(data.code)) {
              throw data
            }

            return data
          })
          .catch(err => {
            console.error('Error setting resource', err)
            throw err
          })
      })
  }

  // Fetch top level error handler
  handleErrors = response => !response.ok
    ? response.json().then(error => {
      throw error
    })
    : response
}
