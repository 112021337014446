import React from 'react'

const SettingsIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 31.11 32'
    >
      <path
        fill={fill}
        d='M27.46,17.56a13.05,13.05,0,0,0,.1-1.56,9.86,9.86,0,0,0-.12-1.56l3.38-2.64a.83.83,0,0,0,.2-1l-3.2-5.54a.81.81,0,0,0-1-.36l-4,1.6a11.72,11.72,0,0,0-2.7-1.56L19.56.68a.8.8,0,0,0-.8-.68h-6.4a.78.78,0,0,0-.78.68L11,4.92a12,12,0,0,0-2.7,1.56l-4-1.6a.79.79,0,0,0-1,.36L.12,10.78a.78.78,0,0,0,.2,1L3.7,14.44a9.43,9.43,0,0,0,0,3.12L.3,20.2a.83.83,0,0,0-.2,1l3.2,5.54a.81.81,0,0,0,1,.36l4-1.6A11.72,11.72,0,0,0,11,27.08l.6,4.24a.81.81,0,0,0,.8.68h6.4a.76.76,0,0,0,.78-.68l.6-4.24a12,12,0,0,0,2.7-1.56l4,1.6a.79.79,0,0,0,1-.36L31,21.22a.78.78,0,0,0-.2-1ZM15.56,22a6,6,0,1,1,6-6A6,6,0,0,1,15.56,22Z'
      />
    </svg>
  )
}

export default SettingsIcon
