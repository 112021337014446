import React from 'react'

import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'
import Row from '_shared/components/layout/Row'
import InputCheckBox from '_shared/components/input/InputCheckBox'

import useDependencies from '_shared/hooks/useDependencies'

import channels from 'config/channels'

import Config from 'libs/Config'

const ChannelMatrixActual = ({
  current,
  change,
}) => {
  const { data: market_options } = useDependencies(
    'markets',
    data => data
      .map(item => {
        return {
          label: item.title,
          value: item.entity_id,
        }
      }),
  )

  const channel_options = Object
    .keys(channels)
    .map(channel => {
      return {
        label: channels[channel],
        value: Number(channel),
      }
    })

  const handleChannelChange = (value) => {
    const state = [...current.sales_limitations.channels]

    if (state.includes(value)) {
      state.splice(state.indexOf(value), 1)
    } else {
      state.push(value)
    }

    change('sales_limitations.channels', state)
  }

  const handleChannelChangeAll = () => {
    let state

    if (current.sales_limitations.channels.length !== channel_options.length) {
      state = channel_options
        .map(item => item.value)
    } else {
      state = []
    }

    change('sales_limitations.channels', state)
  }

  const handleMarketChange = (value) => {
    const index = current.sales_limitations.markets.findIndex(item => item.entity_id === value)

    let state = [...current.sales_limitations.markets]

    if (index < 0) {
      state.push({
        entity_id: value,
      })
    } else {
      state.splice(index, 1)
    }

    change('sales_limitations.markets', state)
  }

  const handleMarketChangeAll = () => {
    let state

    if (current.sales_limitations.markets.length !== market_options.length) {
      state = market_options
        .map(item => ({
          entity_id: item.value,
        }))
    } else {
      state = []
    }

    change('sales_limitations.markets', state)
  }

  return (
    <Area
      columns={4}
      baseColUnit={1}
    >
      <SelectorCard
        title={'Select all channels'}
        options={channel_options}
        field={'sales_limitations.channels'}
        value={current.sales_limitations.channels}
        changeAll={handleChannelChangeAll}
        change={handleChannelChange}
      />
      <SelectorCard
        title={'Select all markets'}
        options={market_options}
        field={'sales_limitations.markets'}
        value={current.sales_limitations.markets.map(item => item.entity_id)}
        changeAll={handleMarketChangeAll}
        change={handleMarketChange}
      />
    </Area>
  )
}

const SelectorCard = ({
  title,
  options,
  field,
  value,
  changeAll,
  change,
}) => {
  const channelBoxStyle = {
    borderTop: `1px solid ${Config.theme.border_thick}`,
  }

  const channelRowStyle = {
    padding: '0.5rem',
    borderBottom: `1px solid ${Config.theme.border_thin}`,
    backgroundColor: Config.theme.background_module,
  }

  return (
    <Container>
      <InputCheckBox
        title={title}
        value={options.every(option => value.includes(option.value))}
        change={changeAll}
        margin={'0 0 1rem 0'}
      />
      <Container rawStyle={channelBoxStyle}>
        {options.map(option => {
          return (
            <Row rawStyle={channelRowStyle} background={'background_module'} key={option.value}>
              <InputCheckBox
                title={option.label}
                value={value.includes(option.value)}
                change={() => change(option.value)}
              />
            </Row>
          )
        })}
      </Container>
    </Container>
  )
}

export default ChannelMatrixActual
