import React from 'react'

import DateRange from '../DateRange'

const DateBooking = ({
  data,
  change,
}) => {
  return (
    <DateRange
      datePrefix={'booking-date'}
      timePrefix={'booking-time'}
      label={'Booking'}
      data={data}
      change={change}
    />
  )
}

export default DateBooking
