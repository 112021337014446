import TicketingApi from './base'

// const mock_locales = [
//   {
//     'id': 'en-gb',
//     'is_default': true
//   },
//   {
//     'id': 'sv',
//     'is_default': false
//   },
//   {
//     'id': 'da',
//     'is_default': false
//   }
// ]

// const mock_currency = [
//   {
//     'id': 'EUR',
//     'is_default': true
//   },
//   {
//     'id': 'GBP',
//     'is_default': false
//   },
//   {
//     'id': 'SEK',
//     'is_default': false
//   },
//   {
//     'id': 'DKK',
//     'is_default': false
//   }
// ]

class LocalisationApi extends TicketingApi {
  getSystemConfig = () => this.callApi('system-config')

  editSystemConfig = (data) => this.callApi('system-config', 'PUT', data)
}

// export default new SalesGroupApi()

// const LocalisationApi = {
//   getSystemConfig: () => Promise.resolve({
//     currencies: mock_currency,
//     locales: mock_locales
//   }),
//   editSystemConfig: (data) => Promise.resolve(data)
// }

export default new LocalisationApi()
