import React from 'react'

import styles from './dropdown.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

const DropDown = (props) => {
  const {
    children,
    change,
    value,
    cancel,
    ...style
  } = props

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div
            className={styles.drop}
            style={styling}
            tabIndex={0}
          >
            {children}
          </div>
        )
      }}
    />
  )
}

export default DropDown
