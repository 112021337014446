import React, { Fragment } from 'react'

import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'
import Row from '_shared/components/layout/Row'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import ActionCTA from '_shared/components/action/ActionCTA'

import ExpandListTable from '_shared/components/navigation/ExpandListTable'

import useLocale from '_shared/hooks/useLocale'

import useDependencies from '_shared/hooks/useDependencies'

import Consumer from './form/Consumer'
import GroupConsumer from '../TicketTypeGroups/form/Consumer'

import useNav from '_shared/hooks/useNav'

const columns = [
  {
    key: 'title',
    label: 'title',
    sortable: true,
  },
  {
    key: 'type',
    label: 'type',
  },
  {
    key: 'external',
    label: 'ID',
    sortable: true,
  },
]

const TicketTypesList = () => {
  const {
    default_locale,
  } = useLocale()

  const {
    open,
  } = useNav('subview')

  const { isFetching: ticketFetching, data: tickets } = useDependencies('ticket_types', data => {
    return data.map(record => {
      const title = record.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

      return {
        id: record.entity_id,
        external: record.external_id,
        title: title ? title.title : 'undefined',
        type: 'ticket',
        parent: record.ticket_type_group.entity_id,
      }
    })
  })

  const { isFetching: groupFetching, data: groups } = useDependencies('ticket_groups', data => {
    return data.map(record => {
      const title = record.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

      return {
        id: record.entity_id,
        external: record.external_id,
        title: title ? title.title : 'undefined',
        type: 'group',
      }
    })
  })

  const data = groups.map(group => {
    const __children = tickets.filter(ticket => ticket.parent === group.id)

    return {
      ...group,
      __children,
    }
  })

  const wrappedOpen = (id) => {
    const found = [...groups, ...tickets].find(item => item.id === id)

    if (found) {
      open(`-${found.type}/${id}`)
    }
  }

  return (
    <Area
      areas={[
        'description',
        'nav',
        'list',
      ]}
      rowgap={1.5}
    >
      <ExpandListTable
        area={'list'}
        columns={columns}
        data={data}
        edit={wrappedOpen}
        loading={ticketFetching || groupFetching}
      />
      <Container area={'description'}>
        <Heading level={2} title={'Ticket Types'} />
        <TextBox strong>{'Define ticket types to sell. Example: Adult, Child, Family, Car etc.'}</TextBox>
        <TextBox strong>{'Ticket groups are used for customer purchase searches and managing what can access a vehicle or venue.'}</TextBox>
      </Container>
      <Row type={'start'} area={'nav'}>
        <ActionCTA disabled={groups.length === 0} change={() => open('-ticket/new_ticket_types')} label={'Add a New Ticket Type'} />
        <ActionCTA change={() => open('-group/new_ticket_groups')} label={'Add a New Ticket Group'} />
      </Row>
    </Area>
  )
}

const ListWrapper = () => {
  return (
    <Fragment>
      <Consumer />
      <GroupConsumer />
      <TicketTypesList />
    </Fragment>
  )
}

export default ListWrapper
