import Text from './Text'
import Checkbox from './Checkbox'
import Block from './Block'
import Select from './Select'

const components = {
  text: Text,
  checkbox: Checkbox,
  block: Block,
  select: Select,
}

export default components
