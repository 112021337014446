import React from 'react'

import ListTable from '_shared/components/navigation/ListTable'
import Row from '_shared/components/layout/Row'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import { Raw as InputInteger } from '_shared/components/input/InputInteger'
import { Raw as InputPriceAdjust } from '_shared/components/input/InputPriceAdjust'
import { Raw as InputSelectMulti } from '_shared/components/input/InputSelectMulti'
import { Raw as InputISOInterval } from '_shared/components/input/InputISOInterval'

import channels from 'config/channels'

const columns = [
  {
    key: 'channel',
    label: 'channel',
  },
  {
    key: 'quantity',
    label: 'ticket quantity',
  },
  {
    key: 'limit',
    label: 'date/time limits',
  },
  {
    key: 'fee',
    label: 'fee',
  },
]

const fieldStyle = {
  flex: '0 0 auto',
  width: '6rem',
  minHeight: '1.5rem',
}

const FeeBox = ({
  type,
  field: outer_field,
  value: collection,
  change,
  create,
  remove,
}) => {
  const handleChange = (row) => (field, value) => {
    change(`rules[${row}]${field}`, value)
  }

  const channel_options = Object
    .keys(channels)
    .map(channel => {
      return {
        label: channels[channel],
        value: Number(channel),
      }
    })

  const mutatedData = collection.map(({
    id,
    row,
  }) => {
    const {
      min_tickets,
      max_tickets,
      rule,
      fee,
    } = row
    return {
      id,
      channel: (
        <InputSelectMulti
          placeholder={'channels'}
          options={channel_options}
          field={'rule.channels'}
          value={rule.channels}
          {...fieldStyle}
          width={'10rem'}
          change={handleChange(id)}
        />
      ),
      quantity: (
        <Quantity
          min_tickets={min_tickets}
          max_tickets={max_tickets}
          change={handleChange(id)}
        />
      ),
      limit: (
        <InputISOInterval
          field={'rule.date.after_offset_from_now'}
          value={rule.date.after_offset_from_now}
          {...fieldStyle}
          change={handleChange(id)}
        />
      ),
      fee: (
        <Fees
          value={fee}
          change={handleChange(id)}
        />
      ),
    }
  })

  return (
    <ListTable
      columns={columns}
      data={mutatedData}
      create={create}
      remove={remove}
    />
  )
}

const Quantity = ({
  min_tickets,
  max_tickets,
  change,
}) => {
  return (
    <Row type={'start'}>
      <InputInteger field={'min_tickets'} value={min_tickets} {...fieldStyle} change={change} />
      <InlineTextBox>{'to'}</InlineTextBox>
      <InputInteger field={'max_tickets'} value={max_tickets} {...fieldStyle} change={change} />
    </Row>
  )
}

const Fees = ({
  value,
  change,
}) => {
  return (
    <Row type={'start'}>
      <InputPriceAdjust
        value={value[0].value}
        field={'fee[0]value'}
        type_field={'fee[0]type'}
        type={value[0].type}
        change={change}
        allowedAdjustments={['NA', 'increment_percent']}
        {...fieldStyle}
      />
    </Row>
  )
}

export default FeeBox
