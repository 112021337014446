import React from 'react'

import {
  Switch,
  Redirect,
} from 'react-router-dom'

import Route from './AuthRoute'

import LoginForm from './forms/Login'
import ForgotStartForm from './forms/ForgotStart'
import ForgotConfirmForm from './forms/ForgotConfirm'
import PasswordChallengeForm from './forms/PasswordChallenge'

const Routes = () => {
  return (
    <Switch>
      <Route
        path={'/login'}
        component={LoginForm}
      />
      <Route
        path={'/forgot'}
        component={ForgotStartForm}
      />
      <Route
        path={'/forgot_confirm'}
        component={ForgotConfirmForm}
      />
      <Route
        path={'/change'}
        component={PasswordChallengeForm}
      />
      <Redirect from='/' to='/login' />
    </Switch>
  )
}

const login_action_map = {
  CHALLENGE: '/change',
  FORCE_CHANGE_PASSWORD: '/forgot_confirm',
  CODE_EXPIRED: '/forgot',
}

export {
  login_action_map,
}

export default Routes
