import React, { useCallback, useState, useRef } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import UtilityDropDown from '_shared/components/element/UtilityDropDown'

import formatCurrency, { smallToLarge, largeToSmall, fixRoundingError } from '_shared/libs/formatCurrency'

import useLocale from '_shared/hooks/useLocale'
import useParentDistance from '_shared/hooks/useParentDistance'

const maskCurrency = (value) => {
  value = value.replace(/[^0-9.]/g, '')

  let split = value.split('.')

  if (split.length > 2) {
    split = split.slice(0, 2)
  }

  if (split.length > 1) {
    if (split[1].length > 2) {
      value = `${split[0]}.${split[1].slice(0, 2)}`
    } else {
      value = `${split[0]}.${split[1]}`
    }
  }

  return value
}

const input_types = {
  NA: {
    key: 'NA',
    negative: false,
    label: 'No change ( NA )',
    display: 'NA',
    in: () => 0,
    mask: () => 0,
    out: () => null,
    blurred: () => 'None',
  },
  set_fixed: {
    key: 'set_fixed',
    negative: false,
    label: 'Set price ( = $ )',
    display: '= $',
    in: smallToLarge,
    mask: maskCurrency,
    out: largeToSmall,
    blurred: (value, locale, currency) => formatCurrency(smallToLarge(value), locale, currency),
  },
  increment_fixed: {
    key: 'increment_fixed',
    negative: true,
    label: 'Reduce price ( - $ )',
    display: '- $',
    in: value => smallToLarge(Math.abs(value)),
    mask: maskCurrency,
    out: value => largeToSmall(value) * -1,
    blurred: (value, locale, currency) => formatCurrency(smallToLarge(value), locale, currency),
  },
  increment_fixed_positive: {
    key: 'increment_fixed',
    negative: false,
    label: 'Increase price ( + $ )',
    display: '+ $',
    in: value => smallToLarge(Math.abs(value)),
    mask: maskCurrency,
    out: value => largeToSmall(value),
    blurred: (value, locale, currency) => formatCurrency(smallToLarge(value), locale, currency),
  },
  increment_percent: {
    key: 'increment_percent',
    negative: true,
    label: 'Reduce by percent ( - % )',
    display: '- %',
    in: value => fixRoundingError(Math.abs(value) * 100),
    mask: value => value.replace(/[^0-9.]/g, ''),
    out: value => (value / 100) * -1,
    blurred: value => `-${fixRoundingError(Math.abs(value) * 100)}%`,
  },
  increment_percent_positive: {
    key: 'increment_percent',
    negative: false,
    label: 'Increase by percent ( + % )',
    display: '+ %',
    in: value => fixRoundingError(Math.abs(value) * 100),
    mask: value => value.replace(/[^0-9.]/g, ''),
    out: value => (value / 100),
    blurred: value => `${fixRoundingError(Math.abs(value) * 100)}%`,
  },
}

/*
  No Change - Remove but indicate 0 with mid gray on blur
  Set price
  Reduce price
  Increase price
  Reduce by percent
  Increase by percent
*/
const options = Object
  .entries(input_types)
  .map(([value, { label }]) => ({
    label,
    value,
  }))

const getSelected = (type, negative) => {
  const exists = Object
    .entries(input_types)
    .filter((option) => option[1].key === type)
    .map((option) => ({
      selection: option[0],
      ...option[1],
    }))

  if (!negative && exists.length > 1) {
    const actual = exists.find(option => !option.negative)

    return actual
  } else {
    return exists[0]
  }
}

const InputPriceAdjust = ({
  placeholder,
  name,
  field,
  value,
  type_field,
  type = 'increment_percent',
  status,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.25rem',
  controlled = false,
  currency = 'GBP',
  allowedAdjustments,
  ...style
}) => {
  const container = useRef(null)

  const {
    default_locale,
  } = useLocale()

  const [
    negative,
    setNegative,
  ] = useState((value <= 0))

  const [
    internal,
    setInternal,
  ] = useState(input_types[type].in(value))

  const [
    focused,
    setFocused,
  ] = useState(false)

  const {
    maxHeight,
    calcMaxHeight,
  } = useParentDistance(container)

  const selected = getSelected(type, negative)

  const handleChange = useCallback((event) => {
    const value = selected.mask(event.target.value)

    setInternal(value)
    change(field, selected.out(value))
  }, [change, field, type])

  const handleTypeChange = (value) => {
    const type = input_types[value]

    setNegative(type.negative)

    setInternal(type.in(0))
    change(field, 0)
    change(type_field, type.key)
  }

  const forceClose = useCallback(() => {
    setFocused(false)
  }, [])

  const handleFocus = () => {
    calcMaxHeight()

    setInternal(selected.in(value))

    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  const dropdownOptions = allowedAdjustments ?
    options.filter(option => allowedAdjustments.includes(option.value)) : options

  // TODO: Remap drop down to open when you click (or tap) on the type display box - SO MUCH MORE SIMPLE!!

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      getRef={container}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <InlineTextBox margin={'0 0 0 0.25rem'} color={'text_success'} noFlex>{selected.display}</InlineTextBox>
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={focused ? internal : selected.blurred(value, default_locale, currency)}
        change={handleChange}
        margin={margin}
        disabled={disabled}
        focus={handleFocus}
        blur={handleBlur}
        textAlign={'right'}
      />
      {focused && (
        <UtilityDropDown
          options={dropdownOptions}
          value={selected.selection}
          change={handleTypeChange}
          cancel={forceClose}
          focusOnMount={false}
          maxHeight={maxHeight}
        />
      )}
    </StatefulWrapper>
  )
}

const InputPriceAdjustWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputPriceAdjust {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputPriceAdjust {...props} />
  )
}

export default InputPriceAdjustWrapped

export {
  Raw,
  options,
}