const smallToLarge = (pence) => {
  return `${(Number(pence) / 100).toFixed(2)}`
}

const largeToSmall = (pounds) => {
  return isNaN(Number(pounds)) ? 0 : Math.round(Number(pounds) * 100)
}

const formatCurrency = (value, locale, currency = null) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  }).format(value)
}

/**
 * Due to getting stored as floats on the BE rather than integer,
 * discounts such as `-0.544`(54.4%) have to be transformed to be presented on the FE.
 * In doing so, we run into rounding errors such as `Math.abs(-0.544) * 100` outputting 54.400000000000006.
 * To remedy this, set the number precision to 12 digits.
 */
const fixRoundingError = (number) => {
  return Number(number.toPrecision(12))
}

export default formatCurrency

export {
  smallToLarge,
  largeToSmall,
  fixRoundingError,
}