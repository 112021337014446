import React from 'react'

import InlineTextBox from '_shared/components/layout/InlineTextBox'

import StyleWrapper from '_shared/style/StyleWrapper'

const Label = ({ title, children, ...style}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <label onClick={event => event.preventDefault()} style={{...styling, display: 'block'}}>
            <InlineTextBox block strong margin={'0 0 0.5rem 0'}>{title}</InlineTextBox>
            {children}
          </label>
        )
      }}
    />
  )
}

export default Label
