import React from 'react'

import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'

import ChooserLocation from '../choosers/ChooserLocation'

const LocationFilter = ({
  data,
  change,
}) => {
  return (
    <Area
      areas={[
        'title',
        'location . .',
      ]}
      columns={3}
      rowgap={1}
    >
      <Heading
        area={'title'}
        level={3}
        title={'Departure location'}
      />
      <ChooserLocation
        area={'location'}
        data={data}
        change={change}
      />
    </Area>
  )
}

export default LocationFilter
