import React from 'react'

import Area from '_shared/components/layout/Area'

import FormView from 'containers/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText, { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import InputSelect from '_shared/components/input/InputSelect'

import InfoBox from '_shared/components/notification/InfoBox'

import useLocale from '_shared/hooks/useLocale'

import useDependencies from '_shared/hooks/useDependencies'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

const FormDescription = ({
  current,
  valid,
  updateSingle,
}) => {
  const {
    default_locale,
  } = useLocale()

  const { data: groups } = useDependencies('ticket_groups', data => {
    return data.map(record => {
      const title = record.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

      return {
        value: record.entity_id,
        label: title ? title.title : 'undefined',
      }
    })
  })

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'info/2 code_info',
          'code . code_info',
          'title . warning',
          'short . short_info',
          'group . group_info',
          '. . .',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Description'} />
        </SectionWrapper>
        <TextBox strong area={'info'}>{'Set the default settings for the ticket type here. These can be changed on a per service basis.'}</TextBox>
        <InfoBox
          area={'warning'}
          type={'warning'}
          title={'Warning'}
          content={'Changing details here will change it throughout the services'}
        />
        <InfoBox
          area={'code_info'}
          type={'info'}
          title={'External ID'}
          content={'Used for external API calls.'}
        />
        <InputText
          area={'code'}
          title={'External ID'}
          field={'external_id'}
          value={current.external_id}
          placeholder={'Unique External ID'}
          change={updateSingle}
          status={parseValidity(valid.external_id)}
          required
          requiredText={'Must be unique'}
        />
        <InputTextLocalised
          area={'title'}
          title={'Title'}
          field={'title'}
          value={current.locales}
          placeholder={'Ticket Type'}
          change={updateSingle}
          valid={valid.locales}
          required
        />
        <InputTextLocalised
          area={'short'}
          title={'Short Title'}
          field={'short_title'}
          value={current.locales}
          placeholder={'Short Title'}
          change={updateSingle}
        />
        <InputSelect
          area={'group'}
          title={'Ticket group'}
          field={'ticket_type_group.entity_id'}
          options={groups}
          value={current.ticket_type_group.entity_id}
          placeholder={'Ticket group'}
          change={updateSingle}
          status={parseValidity(valid.ticket_type_group)}
          required
          requiredText={'Field is required'}
        />
        <InfoBox
          area={'short_info'}
          type={'note'}
          title={'Short Title'}
          content={'This is a small description of the ticket'}
        />
        <InfoBox
          area={'group_info'}
          type={'note'}
          title={'Ticket groups'}
          content={'Ticket groups are used for customer purchase searches and managing what can access a vehicle or venue'}
        />
      </Area>
    </FormView>
  )
}

export default FormDescription
