import React from 'react'

import styles from './card.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

const Card = ({ children, ...style }) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.layout} style={styling}>
            {children}
          </div>
        )
      }}
    />
  )
}

export default Card
