import React from 'react'

import styles from './lockedbadge.scss'

import Container from 'containers/Container'

import LockedIcon from 'icons/Locked'

import colors from 'config/colors'

import styleCombine from 'libs/styleCombine'

const LockedBadge = ({ styling }) => {
  return (
    <Container styling={styleCombine(styles.layout, styling)}>
      <LockedIcon
        fill={colors.color_text_light}
        size={16}
      />
    </Container>
  )
}

export default LockedBadge
