import React from 'react'

import IconButton from '_shared/components/element/IconButton'

import CalculateIcon from '_shared/icons/Calculate'

const ActionAdd = ({ label, change, collapsed, disabled }) => {
  return (
    <IconButton
      icon={CalculateIcon}
      change={change}
      label={label}
      collapsed={collapsed}
      color={'button'}
      disabled={disabled}
      reversed
    />
  )
}

export default ActionAdd
