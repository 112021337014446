import React from 'react'

import Area from '_shared/components/layout/Area'

import InputTime from '_shared/components/input/InputTime'

const TimeRange = ({
  fieldFrom,
  fieldTo,
  label,
  data,
  change,
}) => {
  const handleChange = (field, value) => {
    if (field === fieldTo && value < data[fieldFrom]) {
      change([
        {
          field: fieldFrom,
          value,
        },
        {
          field,
          value,
        },
      ])
    } else {
      change([{
        field,
        value,
      }])
    }
  }

  return (
    <Area
      areas={[
        'from to',
      ]}
      columns={2}
    >
      <InputTime
        field={fieldFrom}
        title={`${label} Time Starts`}
        placeholder={`${label} Time Starts`}
        value={data[fieldFrom]}
        change={handleChange}
      />
      <InputTime
        field={fieldTo}
        title={`${label} Time Ends`}
        placeholder={`${label} Time Ends`}
        value={data[fieldTo]}
        change={handleChange}
      />
    </Area>
  )
}

export default TimeRange
