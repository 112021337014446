import React, { useState } from 'react'

import Area from '_shared/components/layout/Area'

import Heading from '_shared/components/layout/Heading'

import useDependencies from '_shared/hooks/useDependencies'
import useLocale from '_shared/hooks/useLocale'

import { Localised as InputTextLocalised } from '_shared/components/input/InputText'

import InputSelect, { Raw as RawInputSelect } from '_shared/components/input/InputSelect'
import ListTable from '_shared/components/navigation/ListTable'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import {
  getEntitySchema,
} from '../views/Combo/form/Context'

import mapToSchema from '_shared/libs/mapToSchema'

import { update } from '_shared/libs/mapToSchema'

import PriceAdjustMatrix from 'templates/PriceAdjustMatrix'
import ConditionsTable from './ConditionsTable'

import uniq from 'lodash/uniq'

const columns = [
  {
    key: 'product',
    label: 'product',
  },
]

const ProductCard = ({
  data,
  change,
  changeMulti,
}) => {
  const {
    default_locale,
  } = useLocale()

  const { data: product_options } = useDependencies(
    'products_expand_market',
    data => data
      .map(record => {
        return {
          value: record.entity_id,
          label: `${getDeepLocale(record.locales, 'title', default_locale)} (${record.external_id})`,
          meta: {
            ...record,
          },
        }
      }),
  )

  return (
    <Area
      areas={[
        'product',
        'date',
        'adjust',
      ]}
    >
      <Area
        area={'product'}
        areas={[
          'info',
          'selector',
        ]}
        columns={1}
        rowgap={1}
      >
        <Heading level={3} area={'info'} title={'Item product options'} />
        <Selector
          area={'selector'}
          outer_field={'product_options'}
          inner_field={'product'}
          data={data.product_options}
          options={product_options}
          columns={columns}
          change={change}
          placeholder={'Choose a service'}
          schema_generator={getEntitySchema}
        />
      </Area>
      <Area
        area={'date'}
        areas={[
          'info',
          'selector',
        ]}
        columns={1}
        rowgap={1}
      >
        <Heading level={3} area={'info'} title={'Limit date & time'} />
        <ConditionsTable
          area={'selector'}
          data={data.conditions.date}
          update={changeMulti}
          updateSingle={change}
        />
      </Area>
      <Area
        area={'adjust'}
        areas={[
          'info',
          'selector',
        ]}
        columns={1}
        rowgap={1}
      >
        <Heading level={3} area={'info'} title={'Item price adjustment'} />
        <Adjustment
          data={data}
          change={change}
          products={product_options.map(({ meta }) => ({ ...meta }))}
        />
      </Area>
    </Area>
  )
}

const getInitialMode = (effects) => {
  const hasAllTickets = effects.some(effect => effect.ticket_type_id === 'all')

  return hasAllTickets ? 'all' : 'per_ticket'
}

const Adjustment = ({
  data,
  change,
  products,
}) => {
  const [mode, setMode] = useState(getInitialMode(data.price_adjustment))

  const { data: overview, isLoaded } = useDependencies(['markets', 'ticket_types', 'services'])

  if (!isLoaded) return null

  const {
    markets,
    ticket_types,
    services,
  } = overview

  const service_tickets = services
    .reduce((acc, cur) => {
      acc[cur.entity_id] = cur.ticket_types
        .map(type => type.ticket_type.entity_id)

      return acc
    }, {})

  const product_map = products
    .reduce((acc, cur) => {
      acc[cur.entity_id] = cur.services
        .map(service => service.entity_id)

      return acc
    }, {})

  let ticket_filter = []

  if (data.product_options.length > 0 && Object.keys(service_tickets).length > 0 && Object.keys(product_map).length > 0) {
    ticket_filter = data.product_options
      .reduce((acc, product) => {
        if (product.entity_id) {
          const services = product_map[product.entity_id]

          services
            .forEach(service => {
              service_tickets[service]
                .forEach(ticket => {
                  if (!acc.includes(ticket)) acc.push(ticket)
                })
            })
        }

        return acc
      }, [])
  }

  let market_options = markets

  if (data.product_options.length > 0) {
    const allowed_markets = uniq(data.product_options
      .reduce((acc, product) => {
        const exists = products.find(({ entity_id }) => entity_id === product.entity_id)

        if (exists) {
          acc = [...acc, ...exists.sales_limitations.markets.map(({ entity_id }) => entity_id)]
        }

        return acc
      }, []))

    market_options = markets.filter(({ entity_id }) => allowed_markets.includes(entity_id))
  }

  return (
    <Area
      areas={[
        'mode description . .',
        'adjust',
      ]}
      columns={4}
      colgap={1.5}
    >
      <InputSelect
        area={'mode'}
        title={'Adjust by'}
        field={'mode'}
        value={mode}
        options={[
          {
            label: 'All tickets',
            value: 'all',
          },
          {
            label: 'Price per ticket',
            value: 'per_ticket',
          },
        ]}
        change={(field, value) => setMode(value)}
      />
      <InputTextLocalised
        area={'description'}
        title={'Discount description'}
        field={'discount_description'}
        value={data.locales}
        placeholder={'Discount description'}
        change={change}
      />
      <PriceAdjustMatrix
        area={'adjust'}
        field={'price_adjustment'}
        value={data.price_adjustment}
        change={change}
        ticketMode={mode}
        tickets={ticket_filter.map(ticket_id => ticket_types.find(ticket => ticket.entity_id === ticket_id))}
        markets={market_options}
      />
    </Area>
  )
}

const fieldStyle = {
  flex: '0 0 auto',
  minHeight: '1.5rem',
}

const Selector = ({
  outer_field,
  data,
  options,
  columns,
  change,
  placeholder,
  schema_generator,
  allowCreate = true,
}) => {
  const {
    locales,
    currencies,
  } = useLocale()

  const create = () => {
    const state = [...data]
    const row = mapToSchema(locales, currencies, schema_generator)({})

    state.push(row)

    change(outer_field, state)
  }

  const remove = (row) => {
    const state = [...data]

    state.splice(row, 1)

    change(outer_field, state)
  }

  const handleChange = (row) => (field, value) => {
    const state = [...data]

    update(state, `[${row}]${field}`, value)

    change(outer_field, state)
  }
  const mutatedData = data.map((record, row) => {
    return {
      id: row,
      product: (
        <RawInputSelect
          field={'entity_id'}
          placeholder={placeholder}
          options={options}
          value={record.entity_id}
          change={handleChange(row)}
          lockToWindow
          {...fieldStyle}
        />
      ),
    }
  })

  return (
    <ListTable
      columns={columns}
      data={mutatedData}
      create={allowCreate && create}
      remove={remove}
    />
  )
}

export default ProductCard
