import React, { useRef, useCallback } from 'react'

import styles from './utilitydropdownmulti.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

import styleCombine from '_shared/libs/styleCombine'

import useListNavigation from '_shared/hooks/useListNavigation'

import AddIcon from '_shared/icons/AddRound'
import RemoveIcon from '_shared/icons/RemoveRound'

import Config from 'libs/Config'

const manageCollection = (collection, value) => {
  const temp = [...collection]

  temp.includes(value) ? temp.splice(temp.indexOf(value), 1) : temp.push(value)

  return temp
}

const UtilityDropDownMulti = (props) => {
  const {
    options,
    change,
    value,
    cancel,
    focusOnMount = true,
    ...style
  } = props

  const container = useRef(null)

  const keyChange = useCallback((focused) => {
    change(manageCollection(value, options[focused].value))
  }, [options, change, value])

  const {
    focused,
    handleKey,
  } = useListNavigation(container, 0, options, keyChange, cancel, focusOnMount)

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.holder}>
            <div className={styles.arrow} />
            <div
              ref={container}
              className={styles.drop}
              style={styling}
              onKeyDown={handleKey}
              tabIndex={0}
            >
              {options.map((option, index) => {
                const isFocused = index === focused
                const isSelected = value.includes(option.value)

                const mod = isSelected ? styles.selected : isFocused ? styles.focused : null

                return (
                  <div
                    key={option.value}
                    className={styleCombine(styles.row, mod)}
                    onMouseDown={event => {
                      event.stopPropagation()

                      change(manageCollection(value, option.value))
                    }}
                  >
                    <span>{option.label}</span>
                    {isSelected && (
                      <AddIcon fill={Config.theme.text_success} size={24} />
                    )}
                    {!isSelected && (
                      <RemoveIcon fill={Config.theme.text_warning} size={24} />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )
      }}
    />
  )
}

export default UtilityDropDownMulti
