import { useReducer, useCallback, useMemo } from 'react'

import useLocale from '_shared/hooks/useLocale'

import cloneDeep from 'lodash/cloneDeep'

import createHookReducer from '_shared/libs/createHookReducer'

import mapToSchema, { update } from '_shared/libs/mapToSchema'

const resetValidity = (record, spec, unique, unique_filter) => {
  if (record === null) return {}

  const unique_map = Object.keys(unique)
    .reduce((filter, key) => {
      filter[key] = unique[key].filter(value => value !== unique_filter)

      return filter
    }, {})

  const valid = Object.keys(spec)
    .reduce((validity, key) => {
      if (key.startsWith('locales')) {
        validity[key] = spec[key](record, record.locales, unique_map)
      } else {
        validity[key] = record.hasOwnProperty(key) ? spec[key](record, record[key], unique_map) : true
      }


      return validity
    }, {})

  return valid
}

const initialValid = (valid_spec, unique) => {
  return Object.keys(valid_spec)
    .reduce((valid, key) => {
      valid[key] = false

      return valid
    }, {})
}

const getInitialState = (refreshData, valid_spec, data, unique) => ({
  current: {
    ...refreshData(data),
  }, // Holds the current form values
  valid: initialValid(valid_spec, unique),
  allValid: false,
  forceInvalid: false,
  changed: false,
  unique_filter: '',
})

const resetCurrent = (refreshData, valid_spec, unique) => (state, action) => {
  const {
    record,
  } = action

  const current = {
    ...refreshData(record),
  }

  const unique_filter = Object.keys(unique)
    .reduce((filter, key) => {
      if (record[key] && (record[key] !== null || record[key] !== '')) filter = record[key]

      return filter
    }, '')

  const valid = resetValidity(current, valid_spec, unique, unique_filter)
  const allValid = Object.keys(valid).every(key => valid[key] === true)

  return Object.assign(
    {},
    state,
    {
      current,
      valid,
      allValid,
      changed: false,
      unique_filter,
    },
  )
}

const setValidOverride = (state, action) => {
  const {
    validity,
  } = action

  return Object.assign({}, state, {
    forceInvalid: validity,
  })
}

const updateRecord = (valid_spec, unique) => (state, action) => {
  const {
    changes,
  } = action

  const current = cloneDeep(state.current)
  changes
    .forEach(change => update(current, change.field, change.value))
  const valid = resetValidity(current, valid_spec, unique, state.unique_filter)
  const allValid = Object
    .keys(valid)
    .every(key => valid[key] === true)

  return Object.assign(
    {},
    state,
    {
      current,
      valid,
      allValid,
      changed: true,
    },
  )
}

const reducer = (refreshData, valid_spec, unique) => createHookReducer({
  RESET_CURRENT: resetCurrent(refreshData, valid_spec, unique),
  OVERRIDE_VALID: setValidOverride,
  UPDATE: updateRecord(valid_spec, unique),
})

const useForm = (getSpec, valid_spec, initial = {}, unique) => {
  const {
    locales,
    currencies,
  } = useLocale()

  const valid_model = valid_spec

  const refreshData = mapToSchema(locales, currencies, getSpec)

  const [
    formState,
    dispatch,
  ] = useReducer(
    reducer(refreshData, valid_model, unique),
    getInitialState(refreshData, valid_model, initial, unique),
  )

  const setOverride = useCallback((value) => dispatch({
    type: 'OVERRIDE_VALID',
    validity: Boolean(value),
  }), [])

  const reset = useCallback((record) => dispatch({
    type: 'RESET_CURRENT',
    record,
  }), [])

  const update = useCallback((changes) => dispatch({
    type: 'UPDATE',
    locales,
    changes,
  }), [locales])

  return useMemo(() => {
    return {
      current: formState.current,
      valid: formState.valid,
      allValid: formState.allValid,
      changed: formState.changed,
      reset,
      update,
      setOverride,
    }
  }, [formState, reset, setOverride, update])
}

export default useForm