import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText from '_shared/components/input/InputText'
import InputSelect from '_shared/components/input/InputSelect'
import InputSelectMulti from '_shared/components/input/InputSelectMulti'

import useLocale from '_shared/hooks/useLocale'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

import getSymbolFromCurrency from 'currency-symbol-map'
import get_normal_language from 'libs/getNormalLanguage'

const FormDescription = ({
  current,
  valid,
  updateSingle,
}) => {
  const {
    currencies,
    locales,
  } = useLocale()

  const currency_options = currencies
    .map(id => ({
      label: `${id}-${getSymbolFromCurrency(id)}`,
      value: id,
    }))

  const locale_options = locales
    .map(id => ({
      label: get_normal_language(id),
      value: id,
    }))

  const handleCurrencyChange = (field, value) => {
    updateSingle(field, [{ id: value }])
  }

  const handleLocaleChange = (field, value) => {
    updateSingle(field, value.map(id => ({ id })))
  }

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'info/2 .',
          'label . .',
          'description . .',
          'currency locales .',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Description'} />
        </SectionWrapper>
        <TextBox strong area={'info'}>{'Describe the basic details of this market.'}</TextBox>
        <InputText
          area={'label'}
          title={'Label'}
          field={'title'}
          value={current.title}
          status={parseValidity(valid.title)}
          placeholder={'Label'}
          change={updateSingle}
          required
          requiredText={'Field is required'}
        />
        <InputText
          area={'description'}
          title={'External ID'}
          field={'external_id'}
          value={current.external_id}
          placeholder={'External'}
          change={updateSingle}
          status={parseValidity(valid.external_id)}
          required
          requiredText={'Must be unique'}
        />
        <InputSelect
          area={'currency'}
          title={'Currency'}
          field={'currencies'}
          value={current.currencies.map(({ id }) => id)[0]}
          options={currency_options}
          placeholder={'Choose Currency'}
          change={handleCurrencyChange}
        />
        <InputSelectMulti
          area={'locales'}
          title={'Locales'}
          field={'locale_map'}
          value={current.locale_map.map(({ id }) => id)}
          options={locale_options}
          placeholder={'Choose Locales'}
          change={handleLocaleChange}
        />
      </Area>
    </FormView>
  )
}

export default FormDescription
