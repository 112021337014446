import React from 'react'

const Calendar = (props) => {
  const {
    size,
    fill,
  } = props

  const width = props.size * 0.9

  return (
    <svg
      width={width}
      height={size}
      viewBox={'0 0 28.8 32'}
    >
      <path
        fill={fill}
        d='M9.6,14.4H6.4v3.2H9.6Zm6.4,0H12.8v3.2H16Zm6.4,0H19.2v3.2h3.2ZM25.6,3.2H24V0H20.8V3.2H8V0H4.8V3.2H3.2A3.18,3.18,0,0,0,0,6.4L0,28.8A3.2,3.2,0,0,0,3.2,32H25.6a3.21,3.21,0,0,0,3.2-3.2V6.4A3.21,3.21,0,0,0,25.6,3.2Zm0,25.6H3.2V11.2H25.6Z'
      />
    </svg>
  )
}

export default Calendar
