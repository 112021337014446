import React from 'react'

import InputFilter from '_shared/components/input/InputFilter'

const options = [
  {
    label: 'Event',
    value: 'event',
  },
  {
    label: 'Journey',
    value: 'route',
  },
]

const ChooserStatus = ({
  data,
  change,
}) => {
  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  return (
    <InputFilter
      field={'service-types'}
      title={'Filter service type'}
      placeholder={'Filter service type'}
      value={data['service-types']}
      change={handleChange}
      options={options}
    />
  )
}

export default ChooserStatus
