import React from 'react'

import styles from './waiting.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

const Waiting = ({
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.dot} style={styling}>
            <div className={styles.anim} />
          </div>
        )
      }}
    />
  )
}

export default Waiting
