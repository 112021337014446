import React from 'react'
import styles from './button.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

const Button = (props) => {
  const {
    change,
    disabled,
    children,
    ...style
  } = props

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <button
            style={styling}
            className={styles.button}
            onClick={change}
            disabled={disabled}
          >
            {children}
          </button>
        )
      }}
    />
  )
}

export default Button
