import React from 'react'

import styles from './heading.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

const Heading = (props) => {
  const {
    level = 1,
    title,
    align,
    ...style
  } = props

  const Tag = level === 'section' ? 'h1' : `h${level}`

  const className = level === 'section' ? styles.section : styles.heading

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <Tag className={className} style={{ ...styling, textAlign: align || 'none'}}>
            {title}
          </Tag>
        )
      }}
    />
  )
}

Heading.styleable = true

export default Heading
