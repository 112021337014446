import React from 'react'

import styles from './iconbutton.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

import styleCombine from '_shared/libs/styleCombine'

import Config from 'libs/Config'

const IconButton = ({
  icon: Icon,
  label,
  change,
  reversed = false,
  collapsed,
  disabled,
  ...style
}) => {
  if (disabled && style.color) delete style.color

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <button
            className={styleCombine(styles.layout, collapsed && styles.collapsed, disabled && styles.disabled)}
            style={styling}
            onClick={change}
            disabled={disabled}
          >
            {reversed && !collapsed && label}
            {Icon && (
              <Icon size={24} fill={disabled ? Config.theme.text_disabled : styling.color || Config.theme.text} />
            )}
            {!reversed && !collapsed && label}
          </button>
        )
      }}
    />
  )
}

export default IconButton
