import React from 'react'

const AlertIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size * 0.85}
      height={size}
      viewBox='0 0 26.26 32'
    >
      <path
        fill={fill}
        d='M23,22.15V14c0-5-2.69-9.26-7.38-10.37V2.46a2.46,2.46,0,1,0-4.92,0V3.58C6,4.69,3.28,8.89,3.28,14v8.2L0,25.44v1.64H26.26V25.44Zm-8.2,0H11.49V18.87h3.28Zm0-6.56H11.49V9h3.28ZM13.13,32a3.29,3.29,0,0,0,3.28-3.28H9.85A3.28,3.28,0,0,0,13.13,32Z'
      />
    </svg>
  )
}

export default AlertIcon
