import React from 'react'
import styles from './chip.scss'

import Config from 'libs/Config'
import StyleWrapper from '_shared/style/StyleWrapper'
import RemoveIcon from '_shared/icons/Remove'

const RemoveButton = ({ change }) => (
  <button className={styles.button} onClick={change}>
    <RemoveIcon size={16} fill={Config.theme.text_light} />
  </button>
)

const Chip = props => {
  const {
    change,
    label,
    removable = false,
    ...style
  } = props

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div style={styling} className={styles.chip}>
            {removable && <RemoveButton change={change} />}
            <span className={styles.label}>{label}</span>
          </div>
        )
      }}
    />
  )
}

export default Chip
