import React from 'react'

import styles from './steptab.scss'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import styleCombine from '_shared/libs/styleCombine'

const StepTab = ({
  title,
  label,
  active,
}) => {
  const color = active ? 'button' : 'text'

  return (
    <div className={styleCombine(styles.tab, active && styles.active)}>
      <Heading level={2} title={title} color={color} />
      <TextBox strong margin={'0.25rem 0 0 0'} color={color}>{label}</TextBox>
    </div>
  )
}

export default StepTab
