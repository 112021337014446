const unit = 3
const base = (3 * unit)

const parentDistance = (element, offset = 0) => {
  let maxHeight = base - offset

  let parent = element

  let found = false

  while (parent.offsetParent !== window.document.body && !found) {
    parent = parent.offsetParent

    if (parent.dataset.type && parent.dataset.type === 'form') found = true
  }

  if (!found) {
    return maxHeight
  }

  const outer = parent.getBoundingClientRect()

  const distance = (outer.bottom - element.getBoundingClientRect().bottom) - 16

  if (Math.floor(distance / 16) > base - offset) {
    const rems = Math.floor(distance / 16)
    const fit = rems - (rems % 3)

    maxHeight = fit - offset
  }

  return maxHeight
}

export default parentDistance
