import React from 'react'

const VenueIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z" fill={fill} />
    </svg>
  )
}

export default VenueIcon
