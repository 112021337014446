import React, { Component } from 'react'

import styles from './textfieldicon.scss'

import Container from 'containers/Container'
import StatefulContainer from 'containers/StatefulContainer'
import Input from 'atoms/Input'

/**
 * @param {String} value Value of the textfield
 * @param {String} field Name of this field
 * @param {String} placeholder Text for field when empty
 * @param {String} status Status of field
 * @param {Function} change Method to call when input changes the field
 * @param {String} styling CSS classname for styling overrides
*/

class TextFieldIcon extends Component {
  _onChange = (event) => {
    const value = event.target.value

    this.props.change(this.props.field, value)
  }

  iconAction = () => {
    this.props.action && this.props.action()
  }

  render () {
    const {
      placeholder,
      field,
      status,
      styling,
      value,
      required,
      requiredText,
      type = 'text',
      icon,
      getInputRef,
    } = this.props

    return (
      <Container
        styling={[styles.layout, styling].join(' ')}
      >
        <StatefulContainer
          status={status}
          styling={styles.field}
          required={required}
          requiredText={requiredText}
        >
          <Input
            name={field}
            type={type}
            placeholder={placeholder}
            value={value}
            change={this._onChange}
            styling={styles.input}
            getRef={getInputRef}
          />
          <Container
            onClick={this.iconAction}
            styling={styles.icon}
          >
            {icon}
          </Container>
        </StatefulContainer>
      </Container>
    )
  }
}

export default TextFieldIcon
