import { createSchema } from 'morphism'
import api from 'api/product'

import { mapDefaults } from '_shared/libs/mapToSchema'

import {
  checkValidID,
  isUnique,
  notEmptyOrNull,
} from '_shared/libs/validityChecks'

const getSchema = () => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      title: 'title',
      currencies: 'currencies',
      locale_map: 'locales',
      extensions: 'extensions',
    },
    mapDefaults({
      'external_id': '',
      'title': '',
      'currencies': [],
      'locale_map': [],
      'extensions': {},
    }),
  )
}

const valid = {
  title: notEmptyOrNull,
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },
}

const handlers = {
  store: 'markets',
  unique_key: 'external_id',
  get: api.getMarket,
  new: api.createMarket,
  edit: (payload) => api.updateMarket(payload.entity_id, payload),
  remove: (payload) => { },
  cancel: null,
  decorateSave: ({ locale_map, ...rest }) => ({
    locales: locale_map,
    ...rest,
  }),
}

export {
  valid,
  handlers,
  getSchema,
}
