import React from 'react'

import FormView from '_shared/components/layout/FormView'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import Area from '_shared/components/layout/Area'
import InputSelect from '_shared/components/input/InputSelect'
import InputDuration from '_shared/components/input/InputDuration'

import useLocale from '_shared/hooks/useLocale'
import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import useDependencies from '_shared/hooks/useDependencies'

const FormLocation = ({
  current,
  updateSingle,
}) => {
  const {
    default_locale,
  } = useLocale()

  const { data: locations } = useDependencies('locations', data => data.map(({ entity_id, locales }) => {
    return {
      label: getDeepLocale(locales, 'title', default_locale),
      value: entity_id,
    }
  }))

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'location',
          'duration',
        ]}
        rowgap={1}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Define the event location'} />
        </SectionWrapper>
        <Area
          area={'location'}
          areas={[
            'info',
            'field . .',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'Choose the location for this Event'}</TextBox>
          <InputSelect
            area={'field'}
            title={'Location'}
            field={'segments[0]location.entity_id'}
            value={current.segments[0].location.entity_id}
            placeholder={'Location'}
            options={locations}
            change={updateSingle}
          />
        </Area>
        <Area
          area={'duration'}
          areas={[
            'info',
            'field . .',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'Set the duration for this Event'}</TextBox>
          <InputDuration
            area={'field'}
            title={'Duration'}
            field={'segments[0]end'}
            value={current.segments[0].end}
            placeholder={'Duration'}
            change={updateSingle}
          />
        </Area>
      </Area>
    </FormView>
  )
}

export default FormLocation
