import React, { Component } from 'react'

import styles from './alert.scss'

import Container from 'containers/Container'
import Row from 'containers/Row'
import TextBox from 'containers/TextBox'

import delayMount from 'HOC/delayMount'

import TickIcon from 'icons/Tick'
import RemoveIcon from 'icons/Remove'
import AlertIcon from 'icons/Alert'

import colors from 'config/colors'

class Alert extends Component {
  render () {
    const {
      id,
      data,
      pause,
      dismiss,
      isMounted,
      position,
    } = this.props

    const styling = [styles.layout]

    styling.push(isMounted ? null : styles.out)

    const style = {
      transform: `translateY(-${position * 4}rem)`,
    }

    let Content

    switch (data.type) {
      case 'edit': {
        Content = Edit

        break
      }
      case 'remove': {
        Content = Remove

        break
      }
      case 'error': {
        Content = Error

        break
      }
      default: {
        Content = Save
      }
    }

    return (
      <Container
        type={'start'}
        styling={styling.join(' ')}
        style={isMounted ? style : null}
        onMouseOver={() => pause(true)}
        onMouseOut={() => pause(false)}
        onClick={() => dismiss(id)}
      >
        <Content
          data={data}
        />
      </Container>
    )
  }
}

const Save = (props) => {
  const {
    data,
  } = props

  return (
    <Row
      type={'start'}
      styling={[styles.content, styles.save].join(' ')}
      spread
    >
      <TickIcon
        size={20}
        fill={colors.color_text_light}
      />
      <TextBox
        styling={styles.message}
      >
        {data.message}
      </TextBox>
      <RemoveIcon
        size={20}
        fill={colors.color_text_light}
      />
    </Row>
  )
}

const Edit = (props) => {
  const {
    data,
  } = props

  return (
    <Row
      type={'start'}
      styling={[styles.content, styles.edit].join(' ')}
      spread
    >
      <TickIcon
        size={20}
        fill={colors.color_text_light}
      />
      <TextBox
        styling={styles.message}
      >
        {data.message}
      </TextBox>
      <RemoveIcon
        size={20}
        fill={colors.color_text_light}
      />
    </Row>
  )
}

const Remove = (props) => {
  const {
    data,
  } = props

  return (
    <Row
      type={'start'}
      styling={[styles.content, styles.remove].join(' ')}
      spread
    >
      <TickIcon
        size={20}
        fill={colors.color_text_light}
      />
      <TextBox
        styling={styles.message}
      >
        {data.message}
      </TextBox>
      <RemoveIcon
        size={20}
        fill={colors.color_text_light}
      />
    </Row>
  )
}

const Error = (props) => {
  const {
    data,
  } = props

  return (
    <Row
      type={'start'}
      styling={[styles.content, styles.remove].join(' ')}
      spread
    >
      <AlertIcon
        size={20}
        fill={colors.color_text_light}
      />
      <TextBox
        styling={styles.message}
      >
        {data.message}
      </TextBox>
      <RemoveIcon
        size={20}
        fill={colors.color_text_light}
      />
    </Row>
  )
}

export default delayMount(Alert)
