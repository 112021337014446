import { createSchema, morphism } from 'morphism'
import api from 'api/product'

import { mapLocales, mapDefaults } from '_shared/libs/mapToSchema'

import {
  checkValidID,
  isUnique,
} from '_shared/libs/validityChecks'

const ticket_type = {
  entity_id: 'ticket_type.entity_id',
}

const getSpec = (locales) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      extensions: 'extensions',
      integration_scandlines: 'integration_scandlines',
      ticket_type_group: {
        entity_id: 'ticket_type_group.entity_id',
      },
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          title: '',
          short_title: '',
          description: '',
        }, value, locales),
      },
      mandatory_companions: {
        path: 'mandatory_companions',
        fn: (value, source) => {
          if (!value) return
          return value.map(record => morphism({ ticket_type }, record))
        },
      },
      mandatory_companions_max_free_selection: 'mandatory_companions_max_free_selection',
    },
    mapDefaults({
      'external_id': '',
      'capacity.units': 0,
      'mandatory_companions': [],
      'mandatory_companions_max_free_selection': 0,
      'extensions': {},
    }),
  )
}

const valid = {
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },

  locales: (record, value) => {
    return value
      .map(locale => locale.title)
      .some(locale => locale !== '')
  },
  ticket_type_group: (record, value) => {
    return value.entity_id !== null
  },
}

const handlers = {
  store: 'ticket_types',
  unique_key: 'external_id',
  get: api.getTicketType,
  new: api.createTicketType,
  edit: (payload) => api.updateTicketType(payload.entity_id, payload),
  remove: (payload) => {},
  cancel: null,
}

export {
  getSpec,
  valid,
  handlers,
}
