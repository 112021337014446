import api from '../api/login'

import {
  createActionCreate,
  createActionGet,
  setBusyState,
} from 'libs/actionCreator'

const getProfiles = createActionGet('profiles', api.getProfiles)
const createProfiles = createActionCreate('profiles', api.createProfile)

const editProfile = (data) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  const {
    username,
  } = data

  return api.editProfile(data, username)
    .then(data => {
      return dispatch(getProfiles())
    })
    .then(data => {
      return data
    })
    .catch(err => {
      console.error('Caught error in edit', err)

      dispatch(setBusyState(false))

      throw err
    })
}

const resetProfile = (id) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return api.resetUser(id)
    .then(response => {
      dispatch(setBusyState(false))

      return true
    })
    .catch(error => {
      dispatch(setBusyState(false))
      console.log(error)

      return false
    })
}

export {
  getProfiles,
  createProfiles,
  editProfile,
  resetProfile,
}
