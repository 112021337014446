import React from 'react'

import InputFilter from '_shared/components/input/InputFilter'

import channel_map from 'config/channels'

const channels = [1, 2, 3, 4]

const ChooserChannel = ({
  data,
  change,
}) => {
  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  const options = channels
    .map(channel => {
      return {
        label: channel_map[channel],
        value: channel,
      }
    })

  return (
    <InputFilter
      field={'channels'}
      title={'Filter channels'}
      placeholder={'Filter channels'}
      value={data.channels}
      change={handleChange}
      options={options}
    />
  )
}

export default ChooserChannel
