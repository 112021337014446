import React, { useState } from 'react'

import styles from './inputtimelist.scss'

import Row from '_shared/components/layout/Row'
import { Raw as InputTime } from '_shared/components/input/InputTime'
import Chip from '_shared/components/element/Chip'

const timeMatch = /^[0-9]{1,2}:[0-9]{1,2}$/gm

const InputTimeList = ({
  field,
  value,
  change,
  minHeight = '2.5rem',
}) => {
  const [
    holder,
    setHolder,
  ] = useState('')

  const exists = value.includes(holder)

  const status = !exists && holder.match(timeMatch) ? 'VALID' : 'INVALID'

  const handleInputChange = (field, value) => {
    setHolder(value)
  }

  const handleKey = (event) => {
    const key = event.key

    if (key === 'Enter' && status !== 'INVALID') {
      handleOuterChange(holder)
      setHolder('')
    }
  }

  const handleOuterChange = (day) => {
    const state = [...value]
    const index = state.indexOf(day)

    if (index === -1) {
      state.push(day)
    } else {
      state.splice(index, 1)
    }

    state.sort()

    change(field, state)
  }

  return (
    <div
      onKeyDown={handleKey}
    >
      <InputTime
        value={holder}
        change={handleInputChange}
        minHeight={minHeight}
        placeholder={'00:00'}
        controlled
        status={status}
      />
      <div className={styles.values}>
        <Row>
          {value.map(time => {
            return (
              <Chip key={time} label={time} removable change={() => handleOuterChange(time)} />
            )
          })}
        </Row>
      </div>
    </div>
  )
}

export default InputTimeList
