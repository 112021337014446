import React from 'react'

import styles from './roundedtab.scss'

import styleCombine from '_shared/libs/styleCombine'

import StyleWrapper from '_shared/style/StyleWrapper'

/*
  selected (active === true):
    default
    success
    error
  unselected (active === false):
    default
    success
    error
    disabled
*/

const states = [
  'DEFAULT',
  'VALID',
  'INVALID',
  'DISABLED',
]

const RoundedTab = ({ small = false, label, active, action, status = 'DEFAULT', ...style }) => {
  const styleState = []

  const type = states.includes(status) ? status : 'DEFAULT'

  if (type === 'DISABLED') {
    styleState.push(styles.disabled)
  } else {
    if (active) styleState.push(styles.active)

    if (type === 'VALID') styleState.push(styles.valid)

    if (type === 'INVALID') styleState.push(styles.invalid)
  }

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <button
            style={styling}
            onClick={action}
            className={styleCombine(
              styles.tab,
              small && styles.small,
              ...styleState,
            )}
            disabled={type === 'DISABLED'}
          >
            {label}
          </button>
        )
      }}
    />
  )
}

export default RoundedTab