import React from 'react'

import InputSelect from '_shared/components/input/InputSelect'

import useDependencies from '_shared/hooks/useDependencies'

import useLocale from '_shared/hooks/useLocale'

const Selector = ({
  field,
  label,
  data,
  change,
  dependency,
  valueKey = 'external_id',
}) => {
  const {
    default_locale,
  } = useLocale()

  const handleChange = (field, value) => {
    const output = value === data[field] ? null : value

    change([{
      field,
      value: output,
    }])
  }

  const { data: options } = useDependencies(dependency, data => {
    return data.map(record => {
      const found = record.title ? record : record.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

      return {
        label: found ? found.title : record[valueKey],
        value: record[valueKey],
      }
    })
  })

  return (
    <InputSelect
      field={field}
      title={label}
      placeholder={label}
      value={data[field]}
      change={handleChange}
      options={options}
    />
  )
}

export default Selector
