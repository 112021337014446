import React from 'react'

const OutboundIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill={fill}
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M11.59 7.41L15.17 11H1v2h14.17l-3.59 3.59L13 18l6-6-6-6-1.41 1.41zM20 6v12h2V6h-2z' />
    </svg>
  )
}

export default OutboundIcon
