import React from 'react'

import sortedUniq from 'lodash/sortedUniq'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'

import SectionWrapper from '_shared/components/layout/SectionWrapper'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InputDate from '_shared/components/input/InputDate'
import InfoBox from '_shared/components/notification/InfoBox'

import InputCheckBox from '_shared/components/input/InputCheckBox'

import ChannelMatrix from './ChannelMatrixActual'

import useDependencies from '_shared/hooks/useDependencies'

import Selector from 'templates/Selector'

import filterTickets from './filterTickets'
import useMergedTickets from '_shared/hooks/useMergedTickets'

const FormAvailability = ({
  current,
  update,
  updateSingle,
}) => {

  const { data: markets } = useDependencies('markets', data => {
    return data.map(market => {
      return market.entity_id
    })
  })

  const { data: services } = useDependencies(
    'services',
    data => data
      .filter(record => record.type === current.type),
  )

  const { data: ticket_types} = useMergedTickets()
  if (ticket_types.length === 0) return null

  const ticket_options = current.services
    .filter(item => item.entity_id !== null)
    .map(item => item.entity_id)
    .reduce((acc, entity_id) => {
      const outer = services.find(item => item.entity_id === entity_id)

      if (outer) {
        const tickets = outer.ticket_types
          .map(type => type.ticket_type.entity_id)
          .filter(type => !acc.includes(type))

        acc.push(...tickets)
      }
      return sortedUniq(acc)
    }, [])
    .map(type => {
      const { value, label} = ticket_types.find(item => item.value === type)
      return { value, label }
    })

  const handleTicketChange = (field, value) => {
    const types = value
      .map(type => type.ticket_type.entity_id)
      .filter(Boolean)

    const state = filterTickets(types, markets, current.pricing)

    updateSingle('allowed_ticket_types', value)
    updateSingle('pricing', state)
  }

  const handleDateTypeChange = (field) => {
    if (field === 'fill') {
      update([
        {
          field: 'sales_limitations.date.start_date',
          value: new Date().toISOString().split('T')[0],
        },
        {
          field: 'sales_limitations.date.end_date',
          value: new Date().toISOString().split('T')[0],
        },
      ])
    } else {
      update([
        {
          field: 'sales_limitations.date.start_date',
          value: null,
        },
        {
          field: 'sales_limitations.date.end_date',
          value: null,
        },
      ])
    }
  }

  const dates_empty = current.sales_limitations.date.start_date === null && current.sales_limitations.date.end_date === null

  return (
    <FormView>
      <Area
        areas={[
          'section_purchase',
          'available',
          'section_tickets',
          'tickets',
          'section_channels',
          'channels',
        ]}
        rowgap={2}
      >
        <SectionWrapper area={'section_purchase'}>
          <Heading level={1} title={'Available to purchase'} />
        </SectionWrapper>
        <Area
          area={'available'}
          areas={[
            'info',
            'type/2 . .',
            'dates/2 . .',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Limit when this product will be available for purchase.'}</TextBox>
          <Row area={'type'}>
            <InputCheckBox
              field={'empty'}
              value={dates_empty === true}
              title={'Available as long as the product is live'}
              change={handleDateTypeChange}
            />
            <InputCheckBox
              field={'fill'}
              value={dates_empty === false}
              title={'Limit availability to dates'}
              change={handleDateTypeChange}
            />
          </Row>
          {!dates_empty && (
            <Availability
              area={'dates'}
              current={current}
              updateSingle={updateSingle}
            />
          )}
        </Area>
        <SectionWrapper area={'section_tickets'}>
          <Heading level={1} title={'Available for tickets'} />
        </SectionWrapper>
        <Area
          area={'tickets'}
          areas={[
            'info',
            'selector/2 . . warning/2',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Limit what tickets are available for purchase.'}</TextBox>
          <Selector
            area={'selector'}
            outer_field={'tickets'}
            inner_field={'ticket_type'}
            data={current.allowed_ticket_types}
            options={ticket_options}
            columns={[
              {
                key: 'ticket_type',
                label: 'Ticket Type',
              },
            ]}
            change={handleTicketChange}
            placeholder={'Choose a ticket'}
            schema_generator={() => ({
              ticket_type: {
                entity_id: 'ticket_type.entity_id',
              },
            })}
            allowCreate={ticket_options.filter(({ disabled }) => !disabled).length > 0}
          />
          <InfoBox type={'warning'} title={'warning'} content={'Making changes here will impact pricing and pricing rules and may result in loss of data with existing products.'} area={'warning'} />
        </Area>
        <SectionWrapper area={'section_channels'}>
          <Heading level={1} title={'Markets and channels'} />
        </SectionWrapper>
        <Area
          area={'channels'}
          areas={[
            'info',
            'selector',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Limit when this product to be purchased through certain channels.'}</TextBox>
          <ChannelMatrix
            area={'selector'}
            current={current}
            change={updateSingle}
          />
        </Area>
      </Area>
    </FormView>
  )
}

const Availability = ({
  area,
  current,
  updateSingle,
}) => {
  return (
    <Area
      area={area}
      areas={[
        'start . end .',
      ]}
      rowgap={1}
      colgap={1}
      columns={['10rem', '2rem', '10rem', '1fr']}
    >
      <InputDate
        area={'start'}
        title={'Start date'}
        field={'sales_limitations.date.start_date'}
        value={current.sales_limitations.date.start_date}
        placeholder={'Start'}
        change={updateSingle}
      />
      <InputDate
        area={'end'}
        title={'End date'}
        field={'sales_limitations.date.end_date'}
        value={current.sales_limitations.date.end_date}
        placeholder={'End'}
        change={updateSingle}
      />
    </Area>
  )
}

export default FormAvailability
