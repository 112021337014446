import React from 'react'

const DurationIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" fill={fill} />
    </svg>
  )
}

export default DurationIcon
