import React, { useState } from 'react'

import get from 'lodash/get'

import Row from '_shared/components/layout/Row'
import Container from '_shared/components/layout/Container'
import RoundedTab from '_shared/components/element/RoundedTab'

import getNormalLanguage from '_shared/libs/getNormalLanguage'

import LanguageIcon from '_shared/icons/Language'

import useLocale from '_shared/hooks/useLocale'

import Config from 'libs/Config'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

const findLocaleIndex = (collection, locale) => {
  const index = collection.findIndex(record => record.locale === locale)

  return index === -1 ? null : index
}

const LocaleWrapper = ({
  domain = 'locales',
  field,
  value,
  valid,
  required = false,
  change,
  children,
}) => {
  const {
    default_locale,
    locales,
  } = useLocale()

  const [
    current_locale,
    setLocale,
  ] = useState(default_locale)

  const handleChange = (field, value) => {
    change(`${domain}[${current_locale}]${field}`, value)
  }

  return (
    <Container>
      <Row type={'start'} spread={false}>
        <LanguageIcon size={24} fill={Config.theme.text_placeholder}/>
        {locales.map(locale => {
          const label = getNormalLanguage(locale).short

          const status = required && current_locale === locale ? parseValidity(valid) : 'DEFAULT'

          return (
            <RoundedTab
              key={locale}
              small
              label={label}
              status={status}
              active={current_locale === locale}
              action={() => setLocale(locale)}
              margin={'0 0 0 0.25rem'}
            />
          )
        })}
      </Row>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          change: handleChange,
          value: get(value, `[${findLocaleIndex(value, current_locale)}].${field}`),
          required,
          ...(required && { status: parseValidity(valid), requiredText: 'Field is required' }),
        })
      })}
    </Container>
  )
}

const LocaleWrapperHorizontal = ({
  domain = 'locales',
  field,
  value,
  valid,
  required = false,
  change,
  children,
}) => {
  const {
    default_locale,
    locales,
  } = useLocale()

  const [
    current_locale,
    setLocale,
  ] = useState(default_locale)

  const handleChange = (field, value) => {
    change(`${domain}[${current_locale}]${field}`, value)
  }

  return (
    <Container>
      <Row type={'start'} spread={false}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            change: handleChange,
            value: get(value, `[${findLocaleIndex(value, current_locale)}].${field}`),
            required,
            ...(required && { status: parseValidity(valid), requiredText: 'Field is required' }),
          })
        })}
        {locales.map(locale => {
          const label = getNormalLanguage(locale).short

          const status = required && current_locale === locale ? parseValidity(valid) : 'DEFAULT'

          return (
            <RoundedTab
              key={locale}
              small
              label={label}
              status={status}
              active={current_locale === locale}
              action={() => setLocale(locale)}
              margin={'0 0 0 0.25rem'}
            />
          )
        })}
      </Row>
    </Container>
  )
}

const Controlled = ({
  locales,
  required,
  valid,
  change,
  field,
  selected,
}) => {
  return (
    <Row type={'start'} spread={false}>
      <LanguageIcon size={24} fill={Config.theme.text_placeholder} />
      {locales.map(locale => {
        const label = getNormalLanguage(locale).short

        const status = required ? parseValidity(valid[`locale_${locale}_${field}`]) : 'DEFAULT'

        return (
          <RoundedTab
            key={locale}
            small
            label={label}
            status={status}
            active={selected === locale}
            action={() => change(locale)}
            margin={'0 0 0 0.25rem'}
          />
        )
      })}
    </Row>
  )
}

export default LocaleWrapper

export {
  LocaleWrapperHorizontal,
  Controlled,
  findLocaleIndex,
}
