import React from 'react'

import Container from '_shared/components/layout/Container'
import InputCheckBox from '_shared/components/input/InputCheckBox'

const Checkbox = (props) => {
  const {
    area,
    title,
    field,
    placeholder,
    value,
    update,
  } = props

  return (
    <Container area={area}>
      <InputCheckBox
        title={title}
        field={field}
        change={update}
        value={value}
        placeholder={placeholder}
      />
    </Container>
  )
}

export default Checkbox
