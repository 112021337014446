const isString = (value) => {
  return typeof value === 'string' || value instanceof String
}

const dynamicSort = (property) => {
  let sortOrder = 1

  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return (a, b) => {
    a = isString(a[property]) ? a[property].toLowerCase() : a[property]
    b = isString(b[property]) ? b[property].toLowerCase() : b[property]

    let result = (a < b) ? -1 : (a > b) ? 1 : 0
    return result * sortOrder
  }
}

const deepSort = (stack, field, reverse) => {
  let collect = []

  if (typeof stack.length === 'undefined') {
    for (let item in stack) {
      collect.push(stack[item])
    }
  } else {
    collect = stack
  }

  return collect.sort(dynamicSort((reverse) ? '-' + field : field))
}

const deepSortMulti = (stack, fields) => {
  fields.forEach(item => deepSort(stack, item.field, item.reverse))

  return stack
}

export default deepSort

export {
  deepSortMulti,
}
