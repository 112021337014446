const reducerCreator = (reducer) => (state = [], action) => {
  const store = [...state]

  switch (action.type) {
    case `${reducer.toUpperCase()}_LOADED`: {
      return [...action.data]
    }
    case `${reducer.toUpperCase()}_CREATE`: {
      store.push({
        ...action.data,
      })

      return store
    }
    case `${reducer.toUpperCase()}_EDIT`: {
      const index = store.findIndex(item => {
        return item.id === action.id
      })

      const record = store[index]

      store[index] = {
        ...record,
        ...action.data,
      }

      return store
    }
    case `${reducer.toUpperCase()}_DELETE`: {
      const index = store.findIndex(item => {
        return item.id === action.id
      })

      store.splice(index, 1)

      return store
    }
    default: {
      return store
    }
  }
}

export default reducerCreator
