import React from 'react'

import DateRange from '../DateRange'

const YieldDateService = ({
  data,
  change,
}) => {
  return (
    <DateRange
      datePrefix={'service-date'}
      timePrefix={'service-time'}
      label={'Service'}
      data={data}
      change={change}
      checkOptions={['last_30', 'last_7', 'yesterday', 'today', 'tomorrow', 'next_7', 'custom']}
    />
  )
}

export default YieldDateService
