import React, { useState, useRef, useEffect } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import styles from './toasts.scss'

import {
  hideNotification,
  markReadNotification,
} from 'actions/notifications'

import Alert from './Alert'

import deepSort from 'libs/deepSort'

const DURATION = 3000

const copyStatus = (notifications) => {
  return notifications.map(notification => {
    return {
      id: notification.id,
      hidden: notification.hidden,
      persistent: notification.persistent,
    }
  })
}

const filterResults = (notifications) => {
  return notifications.filter(notification => {
    return notification.hidden === false && notification.persistent === false
  })
}

const ToastManager = () => {
  const timer = useRef(null)

  const notifications = useSelector(state => {
    const {
      notifications,
    } = state

    return deepSort(notifications, 'id')
  })

  const dispatch = useDispatch()

  const [
    paused,
    setPaused,
  ] = useState(false)

  useEffect(() => {
    const next = filterResults(copyStatus(notifications))

    if (next.length > 0 && timer.current === null) start()
  }, [paused, notifications])

  const start = () => {
    const first = filterResults(copyStatus(notifications))

    if (!paused && first.length > 0) {
      timer.current = setTimeout(() => {
        destroy(first[0].id)
      }, DURATION)
    }
  }

  const pause = (state) => {
    clearTimeout(timer.current)

    timer.current = null

    if (!state) {
      setPaused(false)
      // start()
    } else {
      setPaused(true)
    }
  }

  const destroy = (id) => {
    clearTimeout(timer.current)

    timer.current = null

    dispatch(markReadNotification(id))

    pause(false)
  }

  return (
    <div className={styles.layout}>
      {notifications
        .filter(notification => !notification.hidden)
        .map((notification, index) => {
          const position = index + 1

          return (
            <Alert
              key={notification.id}
              id={notification.id}
              position={position}
              data={notification.data}
              dismiss={destroy}
              isMounted={!notification.read}
              unMountCallBack={() => dispatch(hideNotification(notification.id))}
              pause={pause}
            />
          )
        }).reverse()
      }
    </div>
  )
}

export default ToastManager
