import React from 'react'
import styles from './button.scss'

const Button = (props) => {
  const {
    onClick,
    content,
    styling,
  } = props

  return (
    <button
      className={[styles.btn, styling].join(' ')}
      onClick={onClick}
    >
      {content}
    </button>
  )
}

export default Button
