import React from 'react'

import styles from './navigator.scss'

import {
  Route,
  NavLink,
} from 'react-router-dom'

import Container from 'containers/Container'

import styleCombine from 'libs/styleCombine'

const LinkContainer = ({ notLink, path, children, collapsed, ...rest }) => {
  if (notLink) {
    return (
      <Route
        path={path}
        children={({ match }) => {
          return (
            <Container styling={styleCombine(styles.link, match && styles.linkActive, collapsed && styles.collapsed)} {...rest}>
              {children}
            </Container>
          )
        }}
      />
    )
  }

  // TODO: Fix active state so that it reflects paths with variables

  return (
    <NavLink
      activeClassName={styleCombine(styles.linkActive, collapsed && styles.collapsed)}
      className={styleCombine(styles.link, collapsed && styles.collapsed)}
      to={path}
    >
      {children}
    </NavLink>
  )
}

export default LinkContainer
