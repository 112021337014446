import React, { useState, Fragment } from 'react'

import useNavigationView from '_shared/hooks/useNavigationView'

import styles from './sidenavigation.scss'

import Container from '_shared/components/layout/Container'
import Area from '_shared/components/layout/Area'

import styleCombine from 'libs/styleCombine'

const SideNavigationChild = ({ children, action, noBorder = false }) => {
  const [
    selected,
    setSelected,
  ] = useState(0)

  const changeTab = (index) => {
    setSelected(index)
  }

  return (
    <Area
      areas={[
        'nav content content content',
      ]}
    >
      <ul className={styleCombine(styles.options, noBorder && styles.noBorder)} area={'nav'}>
        {React.Children.map(children, (child, index) => {
          const active = index === selected

          if (!child || !child.props.label) return null

          return (
            <li
              key={index}
              className={styleCombine(styles.option, active && styles.selected)}
              onClick={() => changeTab(index)}
            >
              {child.props.label}
            </li>
          )
        })}
      </ul>
      <Container area={'content'}>
        {children[selected]}
      </Container>
    </Area>
  )
}

const Pane = ({ children }) => {
  return (
    <Fragment>
      {children}
    </Fragment>
  )
}

/*
  const options = [
    {
      title: 'Zone Areas' - Can be a react element,
      view: ZoneAreas - Must be a react element
      slug: 'zone' - unique slug to match against
    }
  ]
*/

const SideNavigationHistory = ({ options, noBorder = false }) => {
  const {
    selected,
    changeView,
  } = useNavigationView(options)

  const current = options.find(option => option.slug === selected)

  const View = current.view

  return (
    <Area
      areas={[
        'nav content content content',
      ]}
    >
      <Raw
        area={'nav'}
        options={options}
        selected={selected}
        action={changeView}
        noBorder={noBorder}
      />
      <View area={'content'} />
    </Area>
  )
}

const Raw = ({ options, selected, action, noBorder = false }) => {
  return (
    <ul className={styleCombine(styles.options, noBorder && styles.noBorder)}>
      {options.map((option, index) => {
        const {
          title,
          disabled,
          slug,
          spacer = false,
        } = option

        if (spacer) {
          return <li key={`spacer_${index}`} className={styles.spacer} />
        }

        const active = selected === slug

        return (
          <li
            key={slug}
            className={styleCombine(styles.option, active && styles.selected, disabled && styles.disabled)}
            onClick={() => action(slug)}
          >
            {title}
          </li>
        )
      })}
    </ul>
  )
}

export default SideNavigationHistory

export {
  Pane,
  Raw,
  SideNavigationChild,
}
