import React from 'react'

import TextBox from '_shared/components/layout/TextBox'

const Info = ({
  field,
  content,
}) => {
  return (
    <TextBox strong size={1} area={field}>{content}</TextBox>
  )
}

export default Info
