import { useMemo, useCallback } from 'react'

import {
  useSelector,
} from 'react-redux'

import {
  useQueryClient,
  useMutation,
} from 'react-query'

import {
  createNotification,
} from '_shared/components/notification/Toasts/actions'

import { useDispatch } from 'react-redux'

const useActions = (handlers, permissions_key, status, dispatch) => {
  const reduxDispatch = useDispatch()

  const permissions = useSelector((state) => {
    const {
      user,
    } = state.user

    return user.permissions
  })

  const queryClient = useQueryClient()

  const updateRecord = useMutation(
    (payload) => handlers[status](payload),
    {
      onSuccess: (data) => {
        return queryClient.invalidateQueries(handlers.store)
      },
    },
  )

  const removeRecord = useMutation(
    (payload) => handlers.remove(payload),
    {
      onSuccess: (data) => {
        return queryClient.invalidateQueries(handlers.store)
      },
    },
  )

  const notify = useCallback(payload => reduxDispatch(createNotification(payload)), [reduxDispatch])

  const setBusyState = useCallback(busy => {
    reduxDispatch({
      type: busy ? 'UI_BUSY' : 'UI_NOT_BUSY',
    })
  }, [reduxDispatch])

  const load = useCallback(async (...parameters) => {
    let record = {}

    let status = 'new'

    dispatch({
      type: 'SET_STATUS',
      status: 'waiting',
    })

    if (parameters.length > 0) {
      setBusyState(true)

      try {
        record = await queryClient.fetchQuery([handlers.store, ...parameters], () => handlers.get(...parameters))

        status = 'edit'

        setBusyState(false)
      } catch (error) {
        console.error(error)

        status = 'error'
      }
    }

    dispatch({
      type: 'SET_STATUS',
      status,
    })

    return record
  }, [dispatch, handlers, queryClient, setBusyState])

  const save = useCallback(async (payload) => {
    try {
      setBusyState(true)

      await updateRecord.mutateAsync(payload)

      await queryClient.refetchQueries([handlers.store])

      notify({
        type: status,
        message: `Record ${status === 'new' ? 'saved' : 'updated'}`,
      })

      dispatch({
        type: 'SET_STATUS',
        status: 'idle',
      })

      setBusyState(false)

      return updateRecord.data
    } catch (error) {
      console.error(error)

      setBusyState(false)

      notify({
        type: 'error',
        message: `Unable to ${status === 'new' ? 'save' : 'update'} record`,
      })

      dispatch({
        type: 'SET_STATUS',
        status: 'error',
      })
    }
  }, [setBusyState, handlers, status, updateRecord, queryClient, notify, dispatch])

  const remove = useCallback(async (payload) => {
    try {
      await removeRecord.mutateAsync(payload)

      dispatch({
        type: 'SET_STATUS',
        status: 'waiting',
      })

      await queryClient.refetchQueries([handlers.store])

      return removeRecord.data
    } catch (error) {
      console.error(error)

      dispatch({
        type: 'SET_STATUS',
        status: 'error',
      })
    }
  }, [dispatch, handlers.store, queryClient, removeRecord])

  const cancel = useCallback(async () => {
    dispatch({
      type: 'SET_STATUS',
      status: 'idle',
    })

    handlers.cancel && handlers.cancel()
  }, [dispatch, handlers])

  return useMemo(() => {
    return {
      permissions: permissions_key && permissions[permissions_key] ? permissions[permissions_key] : [],
      load,
      save,
      remove,
      cancel,
    }
  }, [cancel, load, permissions, permissions_key, remove, save])
}

export default useActions
