import React from 'react'

import styles from './infobox.scss'

import Row from '_shared/components/layout/Row'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import TickIcon from '_shared/icons/Tick'
import InfoIcon from '_shared/icons/Info'
import WarningIcon from '_shared/icons/Warning'

import styleCombine from 'libs/styleCombine'

import Config from 'libs/Config'

const InfoBox = ({ type = 'note', title, content }) => {
  const typeMap = {
    note: {
      icon: InfoIcon,
      color: Config.theme.text,
      text: 'text',
    },
    warning: {
      icon: WarningIcon,
      color: Config.theme.text_warning,
      text: 'text_warning',
    },
    success: {
      icon: TickIcon,
      color: Config.theme.text_success,
      text: 'text_success',
    },
  }

  const Icon = typeMap[type] ? typeMap[type].icon : typeMap.note.icon
  const color = typeMap[type] ? typeMap[type].color : typeMap.note.color
  const text = typeMap[type] ? typeMap[type].text : typeMap.note.text

  return (
    <div className={styleCombine(styles.layout, (type !== 'note' && typeMap[type]) && styles[type])}>
      <Row>
        <Icon fill={color} size={24} />
        <Heading level={3} title={title} color={text} />
      </Row>
      <TextBox color={text}>{content}</TextBox>
    </div>
  )
}

export default InfoBox
