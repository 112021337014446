import React from 'react'

import NavIcon from './NavIcon'

import View from '_shared/components/layout/View'
import Heading from '_shared/components/layout/Heading'

import SideNavigation from '_shared/components/navigation/SideNavigation'

// import CreateWidget from 'templates/list/Widgets'

import Cache from 'templates/Cache'
import Localisation from 'templates/Localisation'
import Markets from './subviews/Markets/Main'

const Settings = (props) => {
  const options = [
    {
      title: 'Languages & Currency',
      view: Localisation,
      slug: 'localisation',
    },
    {
      title: 'Markets',
      view: Markets,
      slug: 'marketing',
    },
    {
      title: 'Cache',
      view: Cache,
      slug: 'cache',
    },
    // {
    //   title: 'Widgets',
    //   view: CreateWidget,
    //   slug: 'widgets'
    // }
  ]

  return (
    <View>
      <Heading
        level={'section'}
        title={'System administration'}
      />
      <SideNavigation
        options={options}
      />
    </View>
  )
}

export default Settings

export {
  NavIcon,
}