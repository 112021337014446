import React, { Component } from 'react'

import Column from 'containers/Column'
import Heading from 'atoms/Heading'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError (error) {
    console.log('Error caught', error)
    return {
      hasError: true,
    }
  }

  render () {
    if (this.state.hasError) {
      return (
        <Column
          type={'center'}
        >
          <Heading
            level={'section'}
            title={'Something went wrong'}
          />
        </Column>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
