import React from 'react'

import styles from './visibilityindicator.scss'

import VisibleIcon from '_shared/icons/Visible'
import NotVisibleIcon from '_shared/icons/NotVisible'

import StyleWrapper from '_shared/style/StyleWrapper'

import Config from 'libs/Config'

const VisibilityIndicator = ({ active, change, ...style }) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div onClick={change} className={styles.layout} style={styling}>
            {active && (
              <NotVisibleIcon
                size={24}
                fill={Config.theme.text}
              />
            )}
            {!active && (
              <VisibleIcon
                size={24}
                fill={Config.theme.text}
              />
            )}
          </div>
        )
      }}
    />
  )
}

export default VisibilityIndicator
