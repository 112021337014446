import React, { Component, Fragment } from 'react'
import {
  connect,
} from 'react-redux'

import {
  resetProfile,
} from 'actions/profiles'

import {
  createNotification,
} from 'actions/notifications'

import FormConfirm from 'organisms/Form-Confirm'

import ModalWrapper from 'containers/ModalWrapper'

import ActionCTA from '_shared/components/action/ActionCTA'

class FormResetUser extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  open = () => {
    this.setState({
      open: true,
    })
  }

  cancel = () => {
    this.setState({
      open: false,
    })
  }

  reset = () => {
    const {
      user,
      resetProfile,
      cancel,
      notify,
    } = this.props

    resetProfile(user)
      .then(success => {
        if (success) {
          notify({
            type: 'save',
            message: 'Password reset request successful',
          })

          this.cancel()

          cancel && cancel()
        } else {
          notify({
            type: 'remove',
            message: 'Password reset requested failed',
          })

          this.cancel()
        }
      })
  }

  render () {
    const {
      open,
    } = this.state

    return (
      <Fragment>
        <ModalWrapper isMounted={open} style={{ width: '30rem', right: 'calc((100vw - 30rem) / 2)', height: '20rem' }}>
          <FormConfirm
            title={'Reset this users password?'}
            text={'The user will recieve an email with a new temporary password.'}
            confirm={this.reset}
            cancel={this.cancel}
            confirmText={'Confirm reset'}
            cancelText={'Back'}
          />
        </ModalWrapper>
        <ActionCTA
          type={'caution'}
          label={'Reset password for this user'}
          change={this.open}
        />
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    notify: data => dispatch(createNotification(data)),
    resetProfile: (id) => dispatch(resetProfile(id)),
  }
}

export default connect(null, mapDispatchToProps)(FormResetUser)
