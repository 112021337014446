import React from 'react'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'

import SectionWrapper from '_shared/components/layout/SectionWrapper'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import InputCheckBox from '_shared/components/input/InputCheckBox'
import InputISOInterval from '_shared/components/input/InputISOInterval'

const FormRules = ({
  current,
  update,
  updateSingle,
}) => {
  const handleValueSwitch = (field) => () => {
    updateSingle(`combo_limitations.${field}`, !current.combo_limitations[field])
  }

  const handleDatesValueSwitch = (field) => () => {
    update([
      {
        field: `combo_limitations.${field}`,
        value: !current.combo_limitations[field],
      },
      {
        field: 'combo_limitations.max_total_interval',
        value: null,
      },
    ])
  }

  const sameDay = current.combo_limitations.same_day === true
  const differentDays = current.combo_limitations.same_day === false

  return (
    <FormView>
      <Area
        areas={[
          'section_return',
          'return',
          'section_same',
          'same',
        ]}
        rowgap={2}
      >
        <SectionWrapper area={'section_return'}>
          <Heading level={1} title={'Return journey'} />
        </SectionWrapper>
        <Area
          area={'return'}
          areas={[
            'info',
            'type/2 . .',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Define whether the combo is a return journey. A return journey will apply/enforce the same journey is in reverse.'}</TextBox>
          <Row area={'type'}>
            <InputCheckBox
              field={'combo_limitations.return'}
              value={current.combo_limitations.return === true}
              title={'Return journey'}
              change={handleValueSwitch('return')}
            />
            <InputCheckBox
              field={'combo_limitations.return'}
              value={current.combo_limitations.return === false}
              title={'Do NOT enforce a return'}
              change={handleValueSwitch('return')}
            />
          </Row>
        </Area>
        <SectionWrapper area={'section_same'}>
          <Heading level={1} title={'Combo item dates'} />
        </SectionWrapper>
        <Area
          area={'same'}
          areas={[
            'info',
            'type/2 . .',
            'info_2',
            'time/2 . .',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'For the combo to apply, the items in the combo must be booked for the same day.'}</TextBox>
          <Row area={'type'}>
            <InputCheckBox
              field={'combo_limitations.same_day'}
              value={sameDay}
              title={'Must book items for the same day'}
              change={handleDatesValueSwitch('same_day')}
            />
            <InputCheckBox
              field={'combo_limitations.same_day'}
              value={differentDays}
              title={'Can book for different days'}
              change={handleDatesValueSwitch('same_day')}
            />
          </Row>
          {differentDays &&
            <>
              <TextBox strong area={'info_2'}>{'Max time between combo items'}</TextBox>
              <Row area={'time'}>
                <InputISOInterval
                  title={'Quantity of..'}
                  field={'combo_limitations.max_total_interval'}
                  value={current.combo_limitations.max_total_interval}
                  change={updateSingle}
                />
              </Row>
            </>
          }
        </Area>
      </Area>
    </FormView>
  )
}

export default FormRules
