const getDeepLocale = (collection, key, default_locale) => {
  const found = collection.find(({ locale }) => locale.replace('_', '-').toLowerCase().includes(default_locale))

  return found ? found[key] : 'undefined'
}

const getMaxCapacityFromVenue = (venue) => {
  const capacities = venue.capacity_templates
    .reduce((acc, cur) => {
      const capacity = cur.capacity_layout.areas.reduce((acc, cur) => {
        acc += cur.capacity.units
        return acc
      }, 0)

      acc.push(capacity)

      return acc
    }, [])

  return Math.max(...capacities)
}

const getCapacityTypesFromLayout = (areas, capacity_types) => {
  return areas.map(area => {
    const type = capacity_types.find(type => area.capacity.type && type.entity_id === area.capacity.type.entity_id)
    const { units, reserved_units } = area.capacity

    return {
      label: type ? type.title : 'undefined',
      value: area.capacity.units,
      units: {
        total: units ?? 0,
        reserved: reserved_units ?? 0,
        available: (units ?? 0) - (reserved_units ?? 0),
      },
    }
  })
}

export {
  getDeepLocale,
  getMaxCapacityFromVenue,
  getCapacityTypesFromLayout,
}
