import React from 'react'

import InputTagSelector from '_shared/components/input/InputTagSelector'

const options = [
  {
    label: 'Mon',
    value: 1,
  },
  {
    label: 'Tue',
    value: 2,
  },
  {
    label: 'Wed',
    value: 3,
  },
  {
    label: 'Thu',
    value: 4,
  },
  {
    label: 'Fri',
    value: 5,
  },
  {
    label: 'Sat',
    value: 6,
  },
  {
    label: 'Sun',
    value: 0,
  },
]

const DayOfWeek = ({
  field,
  value,
  change,
}) => {
  return (
    <InputTagSelector
      field={field}
      value={value}
      options={options}
      change={change}
      rawStyle={{ flexWrap: 'wrap' }}
    />
  )
}

export default DayOfWeek
