import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText from '_shared/components/input/InputText'

import InputCheckBox from '_shared/components/input/InputCheckBox'

import FormResetUser from 'organisms/Form-ResetUser'
import Permissions from './Permissions'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

const FormUser = ({
  status,
  current,
  valid,
  updateSingle,
}) => {
  return (
    <FormView>
      <Area
        areas={[
          'sectionbasic',
          'basic',
          'sectionpermissions',
          'permissions',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'sectionbasic'}>
          <Heading level={1} title={'Basic user details'} />
        </SectionWrapper>
        <Area
          area={'basic'}
          areas={[
            'name email enable .',
            'department . . .',
            'reset . . .',
          ]}
          columns={4}
        >
          <InputText
            area={'name'}
            title={'Name'}
            field={'name'}
            value={current.name}
            placeholder={'Name'}
            change={updateSingle}
            status={parseValidity(valid.name)}
            required
            requiredText={'Field is required'}
          />
          <InputText
            area={'email'}
            title={'email'}
            field={'email'}
            value={current.email}
            placeholder={'email'}
            change={updateSingle}
            status={parseValidity(valid.email)}
            required
            requiredText={'Field is required'}
          />
          <InputCheckBox
            area={'enable'}
            title={'Enable user'}
            field={'enabled'}
            value={current.enabled}
            change={updateSingle}
            gridAlign={'center'}
          />
          <InputText
            area={'department'}
            title={'Department'}
            field={'department'}
            placeholder={'department'}
            value={current.department}
            change={updateSingle}
          />
          {status !== 'new' && (
            <FormResetUser
              area={'reset'}
              cancel={() => {}}
              user={current.username}
            />
          )}
        </Area>
        <SectionWrapper area={'sectionpermissions'}>
          <Heading level={1} title={'Basic user details'} />
        </SectionWrapper>
        <Permissions
          area={'permissions'}
          roles={current.roles}
          update={updateSingle}
          field={'roles'}
          status={parseValidity(valid.roles)}
        />
      </Area>
    </FormView>
  )
}

export default FormUser
