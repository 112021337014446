import React from 'react'

import styles from './controlledslide.scss'

import Column from 'containers/Column'
import TextBox from 'containers/TextBox'

import LockedBadge from 'atoms/LockedBadge'

import ModalButton from 'atoms/ModalButton'

const Controls = (props) => {
  const {
    saveAction,
    cancelAction,
    removeAction,
    permissions,
    disabled,
    isNew,
  } = props

  const permissionDelete = permissions.includes('delete')
  const permissionSave = isNew ? permissions.includes('create') : permissions.includes('update')

  const readOnly = permissions.length === 1 && permissions[0] === 'read'

  const hasLock = saveAction && ((removeAction && !permissionDelete) || !permissionSave)

  return (
    <Column styling={styles.controls}>
      {hasLock && (
        <Locked readOnly={readOnly} />
      )}
      {saveAction && permissionSave && disabled && (
        <TextBox styling={styles.required} noMargin>{'* Content required'}</TextBox>
      )}
      {saveAction && (
        <ModalButton
          content={'Save'}
          color={disabled ? 'disabled' : 'save'}
          onClick={!disabled ? saveAction : null}
          locked={!permissionSave}
        />
      )}
      <ModalButton
        content={'Back'}
        color={'cancel'}
        onClick={cancelAction}
      />
      {removeAction && (
        <ModalButton
          content={'Delete'}
          color={'delete'}
          onClick={removeAction}
          locked={!permissionDelete}
        />
      )}
    </Column>
  )
}

const Locked = (props) => {
  const {
    readOnly,
  } = props

  const message = readOnly ? 'Your user profile has viewing privileges only' : 'Your user profile has limited privileges'

  return (
    <Column>
      <LockedBadge />
      <TextBox styling={styles.lockMessage}>{message}</TextBox>
    </Column>
  )
}

export default Controls
