import React from 'react'
import styles from './label.scss'
import InlineTextBox from 'containers/InlineTextBox'

import styleCombine from 'libs/styleCombine'

const Label = (props) => {
  const {
    title,
    children,
    styling,
    bold = false,
    ...rest
  } = props

  return (
    <label
      className={styleCombine(styles.label, styling)}
      onClick={event => event.preventDefault()}
      {...rest}
    >
      <InlineTextBox styling={bold ? styles.text_bold : styles.text}>{title}</InlineTextBox>
      {children}
    </label>
  )
}

export default Label
