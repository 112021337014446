import React, { useState, useEffect } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'

import ValidityIndicator from '_shared/components/element/ValidityIndicator'

const validLoc = /^[-+]?\d+\.\d+,{1}[-+]?\d+\.\d+$/

const parse = (value) => {
  const output = value.replace(/[^0-9.,\-+]/g, '')

  let sections = output.split(',')

  if (sections.length > 2) {
    sections = sections.slice(0, 2)
  }

  if (sections.length === 2) {
    const valid = sections.join(',').match(validLoc)

    if (valid) {
      return sections.join(',')
    } else {
      return value
    }
  }
}

const split = (value) => {
  const output = value.replace(/[^0-9.,\-+]/g, '')

  let sections = output.split(',')

  if (sections.length > 2) {
    sections = sections.slice(0, 2)
  }

  if (sections.length === 2) {
    const valid = sections.join(',').match(validLoc)

    if (valid) {
      return sections
    } else {
      return value
    }
  }
}

const InputGeo = ({
  placeholder,
  name,
  field,
  status,
  value = {
    latitude: 0,
    longitude: 0,
  },
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  ...style
}) => {
  const [
    innerValue,
    setValue,
  ] = useState(`${value.latitude},${value.longitude}`)

  useEffect(() => {
    setValue(`${value.latitude},${value.longitude}`)
  }, [value])

  const handleChange = (event) => {
    const output = event.target.value.replace(/[^0-9.,\-+]/g, '')

    let sections = output.split(',')

    if (sections.length > 2) {
      sections = sections.slice(0, 2)
    }

    setValue(sections.join(','))

    if (sections.length === 2) {
      const valid = sections.join(',').match(validLoc)

      if (valid) {
        const [
          latitude,
          longitude,
        ] = sections

        change(field, {
          latitude: Number(latitude),
          longitude: Number(longitude),
        })
      }
    }
  }

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      indicator={state => (<ValidityIndicator status={status} margin={'0 0.25rem'} />)}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={innerValue}
        change={handleChange}
        margin={margin}
        disabled={disabled}
      />
    </StatefulWrapper>
  )
}

const InputGeoWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputGeo {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputGeo {...props} />
  )
}

export default InputGeoWrapped

export {
  Raw,
  parse,
  split,
}
