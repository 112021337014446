import React, { useState } from 'react'

import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'
import Container from '_shared/components/layout/Container'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import { Localised as InputRTELocalised } from '_shared/components/input/InputRTE'

import { Raw as SideNavigation } from '_shared/components/navigation/SideNavigation'

import OutboundIcon from '_shared/icons/Outbound'
import TransferIcon from '_shared/icons/Transfer'

import useDependencies from '_shared/hooks/useDependencies'

import useLocale from '_shared/hooks/useLocale'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import Config from 'libs/Config'

const addHashesToRoutes = (routes) => {
  return routes.map(route => {
    const {
      legs,
    } = route

    const hash = JSON.stringify(legs
      .flatMap(({ departure_location, arrival_location }) => [departure_location.entity_id, arrival_location.entity_id]))

    return {
      hash,
      ...route,
    }
  })
}

const FormInfo = ({
  current,
  valid,
  updateSingle,
}) => {
  const [
    selected,
    setSelected,
  ] = useState('default')

  const {
    default_locale,
  } = useLocale()

  const { data: locations } = useDependencies('locations', data => {
    return data.reduce((list, location) => {
      list[location.entity_id] = {
        ...location,
        title: getDeepLocale(location.locales, 'title', default_locale),
      }

      return list
    }, {})
  })

  const options = [
    {
      title: 'Product default info',
      slug: 'default',
      Component: DefaultForm,
    },
    {
      title: '',
      slug: 'spacer',
      spacer: true,
    },
  ]

  const routes = addHashesToRoutes(current.routes)

  const directRoutes = routes.filter(({ legs }) => legs.length === 1)
  const transferRoutes = routes.filter(({ legs }) => legs.length > 1)

  directRoutes.forEach(route => {
    const {
      arrival_location,
      departure_location,
      legs,
    } = route

    const departure_entity = departure_location ? departure_location.entity_id : legs[0].departure_location.entity_id
    const arrival_entity = arrival_location ? arrival_location.entity_id : legs[0].arrival_location.entity_id

    const depart = locations[departure_entity]?.title
    const arrive = locations[arrival_entity]?.title

    options.push({
      title: (
        <Row noFlex>
          <InlineTextBox rawStyle={{ fontSize: 'inherit', color: 'inherit' }}>{depart}</InlineTextBox>
          <OutboundIcon fill={Config.theme.text} size={20} />
          <InlineTextBox rawStyle={{ fontSize: 'inherit', color: 'inherit' }}>{arrive}</InlineTextBox>
        </Row>
      ),
      slug: route.hash,
      Component: LegForm,
    })
  })

  transferRoutes.forEach(route => {
    const {
      arrival_location,
      departure_location,
      legs,
    } = route

    const departure_entity = departure_location ? departure_location.entity_id : legs[0].departure_location.entity_id
    const arrival_entity = arrival_location ? arrival_location.entity_id : legs[legs.length - 1].arrival_location.entity_id

    const depart = locations[departure_entity]?.title
    const arrive = locations[arrival_entity]?.title

    options.push({
      title: (
        <Row noFlex>
          <InlineTextBox rawStyle={{ fontSize: 'inherit', color: 'inherit' }}>{depart}</InlineTextBox>
          <TransferIcon fill={Config.theme.text} size={20} />
          <InlineTextBox rawStyle={{ fontSize: 'inherit', color: 'inherit' }}>{arrive}</InlineTextBox>
        </Row>
      ),
      slug: route.hash,
      Component: LegForm,
    })
  })

  const index = options.findIndex(({ slug }) => slug === selected)

  const Form = options[index].Component

  return (
    <FormView>
      <Area
        areas={[
          'nav content/3',
        ]}
        columns={4}
        rowgap={1.5}
      >
        <SideNavigation
          area={'nav'}
          options={options}
          selected={selected}
          action={setSelected}
          noBorder
        />
        <Form
          area={'content'}
          current={current}
          option={options[index]}
          routes={routes}
          locations={locations}
          updateSingle={updateSingle}
        />
      </Area>
    </FormView>
  )
}

const DefaultForm = ({
  current,
  updateSingle,
}) => {
  return (
    <Area
      areas={[
        'heading',
        'information .',
      ]}
      columns={2}
      rowgap={1}
    >
      <Container area={'heading'}>
        <Heading title={'Default product travel instructions'} level={2} />
        <TextBox>{'This is the travel instructions preset for this product, all route legs use this by default.'}</TextBox>
      </Container>
      <InputRTELocalised
        area={'information'}
        title={'Important Information'}
        field={'important_information'}
        value={current.locales}
        placeholder={'Important information'}
        change={updateSingle}
      />
    </Area>
  )
}

const LegForm = ({
  current,
  updateSingle,
  option,
  routes,
  locations,
}) => {
  const routeIndex = routes.findIndex(({ hash }) => hash === option.slug)
  const data = routes[routeIndex]

  return (
    <Area
      areas={[
        'heading',
        'default',
        'information',
      ]}
      columns={1}
      rowgap={1}
    >
      <Heading
        area={'heading'}
        title={(
          <Row>
            <InlineTextBox rawStyle={{ fontSize: 'inherit' }}>{'Travel instructions:'}</InlineTextBox>
            {option.title}
          </Row>
        )}
        level={2}
      />
      <Area area={'information'} columns={1}>
        {data.legs.map((leg, index) => {
          const {
            arrival_location,
            departure_location,
          } = leg

          const depart = locations[departure_location.entity_id]?.title
          const arrive = locations[arrival_location.entity_id]?.title

          return (
            <Area
              key={index}
              areas={[
                'stage content',
              ]}
              columns={['4rem', '1fr']}
              colgap={0}
            >
              <InlineTextBox area={'stage'} strong size={1.125}>{`Leg ${index + 1}`}</InlineTextBox>
              <Area
                areas={[
                  'heading',
                  'information .',
                ]}
                columns={2}
                rowgap={1.5}
              >
                <Heading
                  title={(
                    <Row noFlex>
                      <InlineTextBox rawStyle={{ fontSize: 'inherit' }}>{depart}</InlineTextBox>
                      <OutboundIcon fill={Config.theme.text} size={20} />
                      <InlineTextBox rawStyle={{ fontSize: 'inherit' }}>{arrive}</InlineTextBox>
                    </Row>
                  )}
                  level={3}
                  area={'heading'}
                />
                <InputRTELocalised
                  area={'information'}
                  title={'Important Information'}
                  domain={`routes[${routeIndex}]legs[${index}]locales`}
                  field={'important_info'}
                  value={leg.locales}
                  placeholder={'Important information'}
                  change={updateSingle}
                />
              </Area>
            </Area>
          )
        })}
      </Area>
    </Area>
  )
}

export default FormInfo
