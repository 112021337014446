import React, { Component } from 'react'

import styles from './carouselcontroller.scss'

import Container from 'containers/Container'

const carouselController = (Wrapped) => {
  class Controller extends Component {
    constructor (props) {
      super(props)

      this.state = {
        current: 0,
      }
    }

    getChildren = () => {
      const {
        children,
        slides,
        slideStyling,
        styling,
      } = this.props

      const className = styles[`slide_${slides}`]
      const distance = 100 / slides

      // Slides per movement = props.slides

      const cards = React.Children.map(children, (child, index) => {
        const style = {}

        if (index === 0) style.marginLeft = `-${this.state.current * distance}%`

        const renderChild = index >= (this.state.current - slides) && index <= (this.state.current + slides)

        return (
          <Container
            key={index}
            styling={[className, slideStyling].join(' ')}
            style={style}
          >
            {renderChild ? child : null}
          </Container>
        )
      })

      return (
        <Container
          styling={[styles.caddy, styling].join(' ')}
        >
          {cards}
        </Container>
      )
    }

    advance = (direction) => {
      const {
        children,
        slides,
      } = this.props

      const count = React.Children.count(children)

      let current = this.state.current

      if (count > slides) {
        if (direction === 'left' && (current - 1) > -1) {
          current -= 1
        }

        if (direction === 'right' && (current + 1) < ((count + 1) - slides)) {
          current += 1
        }
      }

      this.setState({
        current,
      })
    }

    componentDidUpdate (prevProps) {
      const prevCount = React.Children.count(prevProps.children)
      const currentCount = React.Children.count(this.props.children)

      if (currentCount < prevCount) {
        this.setState({
          current: 0,
        })
      }
    }

    render () {
      const {
        children,
        slides,
        ...rest
      } = this.props

      const {
        current,
      } = this.state

      const count = React.Children.count(children)

      const leftActive = count > slides && (current) !== 0

      const rightActive = count > slides && (current) !== ((count) - slides)

      const position = `${this.state.current + 1} of ${count}`

      return (
        <Wrapped
          advance={this.advance}
          leftActive={leftActive}
          rightActive={rightActive}
          cards={this.getChildren()}
          position={position}
          current={current}
          count={count}
          {...rest}
        />
      )
    }
  }

  return Controller
}

export default carouselController
