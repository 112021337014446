import React, { Fragment, useState } from 'react'

import Area from '_shared/components/layout/Area'

import modules from './modules'

const generateForm = (form, current) => {
  return Object.keys(form.fields).reduce((data, key) => {
    if (form.fields[key].stored) data[key] = current !== null ? current[key] : form.fields[key].default

    return data
  }, {})
}

const Integration = ({
  field,
  form,
  current,
  update,
}) => {
  const [
    data,
    setData,
  ] = useState(generateForm(form, current[field]))

  const updateInternal = (internalField, value) => {
    const output = { ...data }

    output[internalField] = value

    // Need to remove any "SPECIAL" fields here
    setData(output)

    const upstream = Object.keys(output)
      .reduce((data, key) => {
        if (!form.fields[key].hasOwnProperty('affects')) data[key] = output[key] // Oof

        return data
      }, {})

    update && update(field, upstream)
  }

  const handleCancel = () => {
    if (current[field] === null) {
      const data = generateForm(form, current[field])
      update && update(field, data)
    } else {
      update && update(field, null)
    }
  }

  return (
    <Area
      areas={form.layout}
      columns={form.columns}
      rowgap={1}
      colgap={2}
    >
      {Object.keys(form.fields).map(key => {
        const details = form.fields[key]

        const Module = modules[details.type]

        return (
          <Module
            key={key}
            {...details}
            field={key}
            value={data[key]}
            change={updateInternal}
            cancel={handleCancel}
            current={current[field]}
            disabled={current[field] === null}
          />
        )
      })}
    </Area>
  )
}

const Launcher = (props) => {
  const {
    form_integration,
  } = window.TICKNOVATE_CONFIG

  // If I am not an array, I am not set here
  if (!Array.isArray(form_integration)) return null

  const filtered = form_integration
    .filter(({ forms }) => forms[props.config])

  return (
    <Fragment>
      {filtered.map(integration => {
        return (
          <Integration
            key={integration.key}
            {...props}
            form={integration.forms[props.config]}
            field={integration.key}
          />
        )
      })}
    </Fragment>
  )
}

export default Launcher
