const createNotification = (data, persistent = false) => (dispatch, getState) => {
  dispatch({
    type: 'NOTIFICATIONS_CREATE',
    data,
    persistent,
  })
}

const deleteNotification = (id) => (dispatch, getState) => {
  dispatch({
    type: 'NOTIFICATIONS_DELETE',
    id,
  })
}

const hideNotification = (id) => (dispatch, getState) => {
  dispatch({
    type: 'NOTIFICATIONS_HIDDEN',
    id,
  })
}

const markReadNotification = (id) => (dispatch, getState) => {
  dispatch({
    type: 'NOTIFICATIONS_READ',
    id,
  })
}

export {
  createNotification,
  deleteNotification,
  hideNotification,
  markReadNotification,
}
