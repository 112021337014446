import React from 'react'

const VisibleIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size * 0.7}
      viewBox='0 0 30 20.45'
    >
      <path
        fill={fill}
        d={'M15,0A16.12,16.12,0,0,0,0,10.23a16.12,16.12,0,0,0,30,0A16.12,16.12,0,0,0,15,0Zm0,17.05a6.82,6.82,0,1,1,6.82-6.82A6.83,6.83,0,0,1,15,17.05ZM15,6.14a4.09,4.09,0,1,0,4.09,4.09A4.08,4.08,0,0,0,15,6.14Z'}
      />
    </svg>
  )
}

export default VisibleIcon
