import { useState, useCallback } from 'react'

import parentDistance, { viewportDistance } from '_shared/libs/parentDistance'

const unit = 2.5
const base = (3 * unit) - (unit / 2)

const useParentDistance = (containerRef, useWindow = false) => {
  const [
    maxHeight,
    setMaxHeight,
  ] = useState(base)

  const calc = useWindow ? viewportDistance : parentDistance

  const calcMaxHeight = useCallback(() => {
    setMaxHeight(`${calc(containerRef.current, 1.25)}rem`)
  }, [])

  return {
    maxHeight,
    calcMaxHeight,
  }
}

export default useParentDistance
