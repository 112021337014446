import React, { Fragment } from 'react'

import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'
import Row from '_shared/components/layout/Row'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import ActionCTA from '_shared/components/action/ActionCTA'

import ListTable from '_shared/components/navigation/ListTable'

import deepSort from 'libs/deepSort'

import useDependencies from '_shared/hooks/useDependencies'

import useNav from '_shared/hooks/useNav'

import Consumer from './form/Consumer'

import {
  handlers,
} from './form/Context'

const columns = [
  {
    key: 'external',
    label: 'ID',
  },
  {
    key: 'title',
    label: 'title',
  },
]

const MarketsList = () => {
  const {
    open,
  } = useNav('subview')

  const { isFetching, data } = useDependencies(handlers.store, data => data.map(record => ({
    id: record.entity_id,
    external: record.external_id,
    title: record.title,
  })))

  return (
    <Area
      areas={[
        'description',
        'nav',
        'list',
      ]}
      rowgap={1.5}
    >
      <ListTable
        area={'list'}
        columns={columns}
        data={deepSort(data, 'title')}
        edit={open}
        loading={isFetching}
      />
      <Container area={'description'}>
        <Heading level={2} title={'Markets'} />
        <TextBox strong>{'Define markets.'}</TextBox>
      </Container>
      <Row type={'spaced'} area={'nav'}>
        <ActionCTA change={() => open(`new_${handlers.store}`)} label={'Add a New Market'} />
      </Row>
    </Area>
  )
}

const ListWrapper = () => {
  return (
    <Fragment>
      <Consumer />
      <MarketsList />
    </Fragment>
  )
}

export default ListWrapper
