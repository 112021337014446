import React from 'react'

import styles from './formview.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

import styleCombine from '_shared/libs/styleCombine'

const FormView = ({
  noScroll,
  children,
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styleCombine(styles.layout, noScroll && styles.no_scroll)} style={styling} data-type={'form'}>
            <div className={styles.wrapper}>
              {children}
            </div>
          </div>
        )
      }}
    />
  )
}

FormView.styleable = true

export default FormView
