import React, { Component, Fragment } from 'react'

import styles from './chunkedtable.scss'

import List from './modules/List'
import Nav from './modules/Nav'

import Carousel from './modules/Carousel'

import filterByKey from 'libs/filterByKey'

import deepSort from 'libs/deepSort'

// const spec = [
//     {
//       title: 'Title',
//       key: 'title'
//     },
//     {
//       title: 'Type',
//       key: 'type'
//     },
//     {
//       title: 'Usage',
//       key: 'usage'
//     },
//     {
//       title: 'Expires',
//       key: 'expires'
//     }
//   ]

class ChunkedTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      searchTerm: '',
      sorted: 0,
    }
  }

  setSearch = (searchTerm) => {
    this.setState({
      searchTerm,
    })
  }

  setSort = (sorted) => {
    this.setState({
      sorted,
      searchTerm: '',
    })
  }

  filter = () => {
    const {
      data,
      spec,
    } = this.props

    const {
      sorted,
      searchTerm,
    } = this.state

    let output = deepSort([...data], spec[sorted].key)

    if (searchTerm.length > 2) {
      output = filterByKey(output, spec[sorted].key, searchTerm)
    }

    return output
  }

  render () {
    const {
      label,
      edit,
      create,
      spec,
      permissions = [],
    } = this.props

    const rows = this.filter()

    const chunks = []

    while (rows.length) {
      chunks.push(rows.splice(0, 15))
    }

    return (
      <Fragment>
        {create && (
          <Nav
            label={label}
            create={() => create()}
            value={this.state.searchTerm}
            update={this.setSearch}
            sorted={this.state.sorted}
            setSort={this.setSort}
            options={spec}
            canCreate={permissions.includes('create')}
          />
        )}
        <Carousel
          slides={1}
          paged
          styling={styles.layout}
        >
          {chunks.map((chunk, index) => {
            return (
              <List
                key={index}
                edit={edit}
                rows={chunk}
                spec={spec}
                sorted={this.state.sorted}
                sort={this.setSort}
                canEdit={permissions.includes('update')}
              />
            )
          })}
        </Carousel>
      </Fragment>
    )
  }
}

const StandaloneTable = (props) => {
  const {
    edit,
    data,
    spec,
    sorted,
    sort,
    permissions = [],
  } = props

  const rows = [...data]

  const chunks = []

  while (rows.length) {
    chunks.push(rows.splice(0, 15))
  }

  return (
    <Carousel
      slides={1}
      paged
      styling={styles.layout}
    >
      {chunks.map((chunk, index) => {
        return (
          <List
            key={index}
            edit={edit}
            rows={chunk}
            spec={spec}
            sorted={sorted}
            sort={sort}
            canEdit={permissions.includes('update')}
          />
        )
      })}
    </Carousel>
  )
}

export default ChunkedTable

export {
  List,
  StandaloneTable,
}
