import React, { useCallback } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'

const InputInteger = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  min,
  max,
  ...style
}) => {
  const handleChange = useCallback((event) => {
    const changeValue = event.target.value

    if (changeValue === '') {
      change(field, 0)
    } else {
      let value = Number(event.target.value.replace(/[^0-9]/g, ''))

      if (min && value < min) value = Number(min)

      if (max && value > max) value = Number(max)

      change(field, value)
    }
  }, [change, field, max, min])

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={value}
        change={handleChange}
        margin={margin}
        disabled={disabled}
      />
    </StatefulWrapper>
  )
}

const InputIntegerWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputInteger {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputInteger {...props} />
  )
}

export default InputIntegerWrapped

export {
  Raw,
}
