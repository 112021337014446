import React from 'react'

const PlusIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
    >
      <polygon
        fill={fill}
        points='32 12 20 12 20 0 12 0 12 12 0 12 0 20 12 20 12 32 20 32 20 20 32 20 32 12'
      />
    </svg>
  )
}

export default PlusIcon
