import React, { Fragment } from 'react'

import styles from './navigator.scss'

import Container from 'containers/Container'
import InlineTextBox from 'containers/InlineTextBox'

import LockIcon from 'icons/Locked'

import Config from 'libs/Config'

const LinkContent = ({ item, locked, collapsed }) => {
  const {
    icon,
    display,
  } = item

  const Icon = icon

  return (
    <Fragment>
      {!collapsed && (
        <Icon
          fill={locked ? Config.theme.text_disabled : Config.theme.nav_icon}
          size={20}
        />
      )}
      <InlineTextBox
        styling={locked ? styles.titleInactive : styles.title}
      >
        {display}
      </InlineTextBox>
      {locked && (
        <Container styling={styles.locked}>
          <LockIcon
            fill={Config.theme.text_disabled}
            size={16}
          />
        </Container>
      )}
    </Fragment>
  )
}

export default LinkContent
