import React from 'react'

import NavIcon from './NavIcon'

import View from '_shared/components/layout/View'
import Heading from '_shared/components/layout/Heading'

import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'

import ListTable from '_shared/components/navigation/ListTable'

import ActionCTA from '_shared/components/action/ActionCTA'

import useNav from '_shared/hooks/useNav'

import Consumer from './form/Consumer'

import {
  handlers,
} from './form/Context'

import useLocale from '_shared/hooks/useLocale'

import useDependencies from '_shared/hooks/useDependencies'
import usePublish from '_shared/hooks/usePublish'

const columns = [
  {
    key: 'title',
    label: 'Name',
    sortable: true,
  },
  {
    key: 'external',
    label: 'ID',
    sortable: true,
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'live',
    label: 'Live',
  },
  {
    key: 'draft',
    label: 'Draft',
  },
  {
    key: 'frozen',
    label: 'Frozen',
  },
  {
    key: 'cancel',
    label: 'Cancel',
  },
  {
    key: 'publish',
    label: 'Publish',
  },
]

const Products = (props) => {
  const {
    default_locale,
  } = useLocale()

  const {
    open,
  } = useNav('subview')

  const publishEntity = usePublish(handlers.store)

  const { isFetching, data } = useDependencies(handlers.store, data => {
    return data.map(record => {
      const title = record.locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))

      return {
        id: record.entity_id,
        external: record.external_id,
        title: title ? title.title : 'undefined',
        type: record.type,
        live: '-',
        draft: '-',
        frozen: '-',
        cancel: '-',
        publish: (
          <ActionCTA
            label={'Publish'}
            change={() => publishEntity(record.entity_id)}
            minHeight={'1.5rem'}
            disabled={record.published}
          />
        ),
      }
    })
  })

  return (
    <View>
      <Consumer />
      <Area
        areas={[
          'section/2 . .',
          'actions/2 . .',
          'list',
        ]}
        columns={4}
        rowgap={1.5}
      >
        <Heading level={'section'} title={'Products'} area={'section'} />
        <Row type={'start'} area={'actions'}>
          <ActionCTA label={'Add a New Product'} change={() => open(`new_${handlers.store}`)} />
          <ActionCTA label={'Create From Existing Product'} disabled />
        </Row>
        <ListTable
          columns={columns}
          data={data}
          edit={open}
          area={'list'}
          loading={isFetching}
        />
      </Area>
    </View>
  )
}

export default Products

export {
  NavIcon,
}