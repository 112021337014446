import React, { useCallback, useState, useRef } from 'react'

import UtilityDropDownMulti from '_shared/components/element/UtilityDropDownMulti'
import ActionAdd from '_shared/components/action/ActionAdd'

import useOnClickOutside from '_shared/hooks/useOnClickOutside'
import useParentDistance from '_shared/hooks/useParentDistance'

const ActionUtilityMulti = (props) => {
  const {
    field,
    value,
    change,
    options,
  } = props

  const container = useRef(null)

  const [
    open,
    setOpen,
  ] = useState(false)

  const forceClose = useCallback(event => {
    setOpen(false)
  }, [])

  useOnClickOutside(container, forceClose)

  const {
    maxHeight,
    calcMaxHeight,
  } = useParentDistance(container)

  const handleChange = useCallback((value) => {
    change(field, value)
  }, [change, field])

  const handleOpen = useCallback((event) => {
    event.preventDefault()

    calcMaxHeight()

    setOpen(!open)
  }, [open, calcMaxHeight])

  return (
    <div
      ref={container}
    >
      <ActionAdd change={handleOpen} collapsed />
      {open && (
        <UtilityDropDownMulti
          options={options}
          change={handleChange}
          value={value}
          cancel={forceClose}
          maxHeight={maxHeight}
        />
      )}
    </div>
  )
}

export default ActionUtilityMulti
