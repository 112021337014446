import React from 'react'

import {
  getSpec,
  valid as valid_spec,
  handlers,
} from './Context'

import { StateTabbedHeader as TabbedHeader }  from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'

import SlideForm from 'templates/SlideForm'

const options = [
  {
    label: 'Description',
    view: FormDescription,
    slug: 'description',
  },
]

const Consumer = (props) => {
  return (
    <SlideForm
      options={options}
      data_spec={getSpec}
      valid_spec={valid_spec}
      handlers={handlers}
      mountKey={'record'}
      permissionsKey={'capacity-types'}
      render={({
        current,
        valid,
        update,
        updateSingle,
      }) => {
        return (
          <TabbedHeader
            title={'Capacity Area Type'}
            options={options}
            passProps={{
              current,
              valid,
              updateSingle,
            }}
          />
        )
      }}
    />
  )
}

export default Consumer
