import {
  useQuery,
} from 'react-query'

import product from 'api/product'

const useServiceLocations = ({
  service_ids = [],
}) => {
  const {
    isFetching,
    isSuccess,
    // isError,
    data,
  } = useQuery(
    ['service-locations', service_ids],
    product.listServiceLocations,
    {
      retry: 0,
      keepPreviousData: true,
      staleTime: 1000 * 60 * 2,
    },
  )

  let output = {
    allowed: [],
    mapped: [],
  }

  if (isSuccess) {
    const {
      services,
      locations,
    } = data

    const filtered = service_ids.length > 0 ? Object.keys(services).filter(id => service_ids.includes(id)) : []

    // REMEMBER: We only care about the locations and transfers

    /*
      TAKE AN AGGREGATE OF "ALL" POSSIBLE LOCATIONS AND PAIRS FROM THE SERVICES
      [a, b, c]
      =
      [a, b]
      [a, c]
      [b, a]
      [b, c]
      [c, a]
      [c, b]

      DO THE SAME FOR TRANSFERS!! UNPACK THEM AND MARK THEM AS SUCH
    */
    const allowed = [...new Set(filtered.flatMap(id => services[id].location_entity_ids))]
      .filter(entity_id => entity_id !== null)

    const destinations = allowed
      .reduce((list, id, index, entity_ids) => {
        const generate = entity_ids
          .filter(entity_id => entity_id !== id)

        list[id] = {
          title: locations[id].title,
          destinations: [],
        }

        generate
          .forEach(entity_id => {
            list[id].destinations.push({
              entity_id,
              title: locations[entity_id].title,
              isTransfer: false,
            })
          })

        const transfers = Object.keys(locations[id].transfers)

        if (transfers.length > 0) {
          generate
            .forEach(entity_id => {
              list[id].destinations.push({
                entity_id,
                title: locations[entity_id].title,
                isTransfer: true,
              })
            })
        }

        return list
      }, {})

    output = {
      allowed,
      transfers: destinations,
      mapped: allowed
        .reduce((list, id, index, entity_ids) => {
          const generate = entity_ids
            .filter(entity_id => entity_id !== id)

          generate
            .forEach(entity_id => {
              const hash = JSON.stringify([id, entity_id])

              list.push({
                hash, // Use this value for a simple comparison to existing data
                departure_location: {
                  entity_id: id,
                  ...locations[id],
                },
                arrival_location: {
                  entity_id: entity_id,
                  ...locations[entity_id],
                },
              })
            })

          return list
        }, []),
    }
  }

  return {
    isLoaded: isSuccess,
    isFetching,
    data: output,
  }
}

export default useServiceLocations
