import React from 'react'

import Selector from '../Selector'

const ChooserLocation = ({
  data,
  change,
}) => {
  return (
    <Selector
      field={'location_id'}
      label={'Filter departure location'}
      data={data}
      change={change}
      dependency={'locations'}
      valueKey={'entity_id'}
    />
  )
}

export default ChooserLocation
