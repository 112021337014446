import { createSchema } from 'morphism'
import api from 'api/product'

import { mapLocales, mapDefaults } from '_shared/libs/mapToSchema'

import {
  checkValidID,
  isUnique,
} from '_shared/libs/validityChecks'

/*
{
  "external_id": "passenger",
  "locales": [
      {
          "locale": "en_GB",
          "title": "Passenger"
      },
      {
          "locale": "fr_FR",
          "title": "Passager"
      }
  ]
}
*/
const defaults = {
  'external_id': '',
  'capacity.units': 0,
}

const getSpec = (locales) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      icon: 'icon',
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          title: '',
          description: '',
        }, value, locales),
      },
    },
    mapDefaults(defaults),
  )
}

const valid = {
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },
  locales: (record, value) => {
    return value
      .map(locale => locale.title)
      .some(locale => locale !== '')
  },
}

const handlers = {
  store: 'ticket_groups',
  unique_key: 'external_id',
  get: api.getTicketTypeGroup,
  new: api.createTicketTypeGroup,
  edit: (payload) => api.updateTicketTypeGroup(payload.entity_id, payload),
  remove: (payload) => {},
  cancel: null,
}

export {
  getSpec,
  valid,
  handlers,
}
