import React, { useState } from 'react'

import sortBy from 'lodash/sortBy'

import StyleWrapper from '_shared/style/StyleWrapper'

import Container from '_shared/components/layout/Container'
import Table, { makeSpanData } from '_shared/components/element/Table'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import Button from '_shared/components/element/Button'

import ActionEdit from '_shared/components/action/ActionEdit'
import ActionAdd from '_shared/components/action/ActionAdd'
import ActionExpand from '_shared/components/action/ActionExpand'
import ActionRemove from '_shared/components/action/ActionRemove'

import LoadIndicator from '_shared/components/notification/LoadIndicator'

import Config from 'libs/Config'

const getInitialSortable = (columns) => {
  const sortable = columns.filter(column => column.sortable)

  return sortable.length > 0 ? sortable[0].key : null
}

const createSortableHeader = (selected, column, action) => {
  if (column.sortable) {
    const {
      label,
      ...rest
    } = column

    return {
      label: <SortWrapper selected={column.key === selected} label={label} change={() => action(column.key)} />,
      ...rest,
    }
  } else {
    return column
  }
}

/*
  Data shape:

  {
    id: 3,
    title: 'Parent',
    __children: [
      id: 3,
      title: 'Parent'
    ]
  }
*/

const ExpandListTable = ({
  columns,
  data = [],
  edit,
  create,
  remove,
  loading = false,
  ...style
}) => {
  const [
    sorted,
    setSorted,
  ] = useState(getInitialSortable(columns))

  const [
    expand,
    setExpand,
  ] = useState([])

  const handleSort = (key) => {
    setSorted(key)
  }

  const handleExpand = (row) => {
    const state = [...expand]

    const index = state.indexOf(row)

    if (index === -1) {
      state.push(row)
    } else {
      state.splice(index, 1)
    }

    setExpand(state)
  }

  const headRowStyle = {
    backgroundColor: Config.theme.row_header,
  }

  const headStyle = {
    color: Config.theme.text_mid,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
  }

  const bodyRowStyle = {
    height: '2.5rem',
  }

  const expandStyle = {
    position: 'absolute',
    top: 0,
    width: '100%',
    bottom: 0,
  }

  const rowSpans = {
    'list_row_expand': [],
  }

  const sortedData = sorted !== null ? sortBy(data, [sorted]) : data

  let mutatedData = sortedData.reduce((acc, cur, index) => {
    const {
      __children,
      ...rest
    } = cur

    if (expand.includes(rest.id)) {
      rowSpans['list_row_expand'].push({
        row: acc.length,
        span: __children.length + 1,
      })

      acc.push({
        ...rest,
        'list_row_expand': (<ActionExpand active rawStyle={expandStyle} change={() => handleExpand(rest.id)} />),
      }, ...__children)
    } else {
      acc.push({
        ...cur,
        'list_row_expand': (<ActionExpand rawStyle={expandStyle} change={() => handleExpand(rest.id)} />),
      })
    }

    return acc
  }, [])

  let styledColumns = columns
    .map(column => ({
      ...createSortableHeader(sorted, column, handleSort),
      headStyle,
    }))

  if (edit) {
    styledColumns.unshift({
      key: 'list_row_edit',
      label: 'Edit',
      headStyle: {
        ...headStyle,
        width: '3rem',
      },
    })

    mutatedData = mutatedData
      .map(row => ({
        ...row,
        'list_row_edit': (<ActionEdit collapsed label={'edit'} change={() => edit(row.id)} />),
      }))
  }

  if (remove) {
    styledColumns.push({
      key: 'list_row_remove',
      label: '',
      headStyle: {
        ...headStyle,
        width: '3rem',
      },
    })

    mutatedData = mutatedData.map(row => ({
      ...row,
      'list_row_remove': (<ActionRemove collapsed label={'remove'} change={() => remove(row.id)} />),
    }))
  }

  styledColumns.unshift({
    key: 'list_row_expand',
    label: '',
    headStyle: {
      ...headStyle,
      width: '3rem',
    },
    bodyStyle: {
      padding: '0',
    },
  })

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <Container background={'background_module'} style={styling}>
            <Table
              columns={styledColumns}
              {...makeSpanData(rowSpans, mutatedData)}
              shadeAlternateRows
              headRowStyle={headRowStyle}
              bodyRowStyle={bodyRowStyle}
            />
            {mutatedData.length === 0 && (
              <Empty />
            )}
            {create && (
              <AddBar create={create} label={'Add a new record'} />
            )}
            {loading && (
              <LoadIndicator />
            )}
          </Container>
        )
      }}
    />
  )
}

const SortWrapper = ({
  selected,
  label,
  change,
}) => {
  const style = {
    color: selected ? Config.theme.text : Config.theme.text_mid,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: 0,
  }

  return (
    <Button change={change} rawStyle={style}>
      {label}
    </Button>
  )
}

const Empty = () => {
  const style = {
    minHeight: '2.5rem',
    borderBottom: `solid 1px ${Config.theme.border_thin}`,
  }

  return (
    <Container flexCenter rawStyle={style}>
      <InlineTextBox strong>{'No records to display'}</InlineTextBox>
    </Container>
  )
}

const AddBar = ({ create, label }) => {
  const style = {
    minHeight: '2.5rem',
    backgroundColor: Config.theme.row_highlight,
    borderBottom: `solid 1px ${Config.theme.border_boundary}`,
  }

  return (
    <Container flexCenter background={'table_body_highlight'} rawStyle={style}>
      <ActionAdd change={create} label={label} />
    </Container>
  )
}

export default ExpandListTable