import { createSchema } from 'morphism'
import api from 'api/product'

import { mapDefaults } from '_shared/libs/mapToSchema'

import {
  notEmptyOrNull,
  isUnique,
  checkValidID,
} from '_shared/libs/validityChecks'

const defaults = {
  'external_id': '',
  'title': '',
  'description': '',
}

const getSpec = () => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      title: 'title',
      description: 'description',
    },
    mapDefaults(defaults),
  )
}

const valid = {
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },
  title: notEmptyOrNull,
}

const handlers = {
  store: 'capacity_types',
  unique_key: 'external_id',
  get: api.getCapacity,
  new: api.createCapacity,
  edit: (payload) => api.updateCapacity(payload.entity_id, payload),
  remove: (payload) => {},
  cancel: null,
}

export {
  valid,
  handlers,
  getSpec,
}
