import React from 'react'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import Heading from '_shared/components/layout/Heading'
import { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import { Localised as InputRTELocalised } from '_shared/components/input/InputRTE'

const FormInformation = ({
  current,
  updateSingle,
}) => {
  return (
    <FormView>
      <Area
        areas={[
          'section',
          'time .',
          'available .',
        ]}
      >
        <SectionWrapper area={'section'}>
          <Heading level={2} title={'Event Information'} />
        </SectionWrapper>
        <InputTextLocalised
          area={'time'}
          title={'Entry time'}
          field={'boarding_time'}
          value={current.locales}
          placeholder={'Entry time'}
          change={updateSingle}
        />
        <InputRTELocalised
          area={'available'}
          title={'Whats available inside'}
          field={'whats_onboard'}
          value={current.locales}
          placeholder={'Whats available inside'}
          change={updateSingle}
        />
      </Area>
    </FormView>
  )
}

export default FormInformation
