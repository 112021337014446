const parseValidity = (value) => {
  if (typeof value === 'undefined') return 'VALID'
  return value ? 'VALID' : 'INVALID'
}

const checkValidID = (uniqueId) => uniqueId.match('^[A-Za-z0-9_-]+$')

const getUniqueIdError = (uniqueId) => {
  if (uniqueId == null || uniqueId.length === 0)
    return 'You must enter a value' // false

  if (!checkValidID(uniqueId))
    return 'Special characters are not allowed' // false

  if (!isUnique(uniqueId))
    return 'Value must be unique' // false

  return 'Value must be unique' // true
}

const notEmptyOrNull = (record, value) => {
  return value !== null && value !== ''
}

const isUnique = (key) => (record, value, unique = {}) => {
  const hasValue = value !== null && value !== ''

  return unique[key] ? !unique[key].includes(value) && hasValue : hasValue
}

export {
  parseValidity,
  notEmptyOrNull,
  isUnique,
  checkValidID,
  getUniqueIdError,
}
