import React from 'react'

import {
  valid,
  getSpec,
  handlers,
  apiValid,
} from './Context'

import { StateTabbedHeader as TabbedHeader } from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'
import FormRoute from './FormRoute'
import FormVenue from './FormVenue'

import SubSlideForm from 'templates/SubSlideForm'

const options = [
  {
    label: 'Description & Notes',
    view: FormDescription,
    slug: 'description',
  },
  {
    label: 'Route',
    view: FormRoute,
    slug: 'route',
  },
  {
    label: 'Venue, Layout & Capacity',
    view: FormVenue,
    slug: 'venue_layout_capacity',
  },
]

const optionsAPI = [
  {
    label: 'Description & Notes',
    view: FormDescription,
    slug: 'description',
  },
  {
    label: 'Route',
    view: FormRoute,
    slug: 'route',
  },
]

const TemplatesConsumer = ({
  record,
  initial,
  // save,
  close,
  mount,
  type,
  operator_type,
}) => {
  return (
    <SubSlideForm
      data_spec={getSpec}
      valid_spec={operator_type === 'scandlines' ? apiValid : valid}
      handlers={handlers}
      mount={mount}
      permissionsKey={'user'}
      initial={initial}
      record={record}
      close={close}
      render={({
        current,
        valid,
        update,
        updateSingle,
      }) => {
        return (
          <TabbedHeader
            title={'Template'}
            options={operator_type === 'scandlines' ? optionsAPI : options}
            passProps={{
              current,
              update,
              updateSingle,
              type,
            }}
          />
        )
      }}
    />
  )
}

export default TemplatesConsumer
