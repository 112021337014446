import React from 'react'

const RemoveIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
    >
      <path
        d='M32,3.22,28.78,0,16,12.78,3.22,0,0,3.22,12.78,16,0,28.78,3.22,32,16,19.22,28.78,32,32,28.78,19.22,16Z'
        fill={fill}
      />
    </svg>
  )
}

export default RemoveIcon
