import React from 'react'

const TickIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 24'
    >
      <polygon
        fill={fill}
        points='28 0 32 4.08 12 24 0 12 4 8 12 16 28 0'
      />
    </svg>
  )
}

export default TickIcon
