import React, { useCallback, useState, useRef } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import DropDownWrapper from '_shared/components/layout/DropDownWrapper'
import GradientButton from '_shared/components/element/GradientButton'

import DropDown from './modules/DatePicker'

import useOnClickOutside from '_shared/hooks/useOnClickOutside'

const InputDate = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  controlled = false,
  ...style
}) => {
  const container = useRef(null)

  const [
    open,
    setOpen,
  ] = useState(false)

  const handleChange = value => {
    setOpen(false)

    change(field, value)
  }

  const handleOpen = (event) => {
    setOpen(!open)
  }

  const forceClose = useCallback(event => {
    setOpen(false)
  }, [])

  useOnClickOutside(container, forceClose)

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      getRef={container}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <GradientButton
        change={handleOpen}
        disabled={disabled}
        focus={event => event.stopPropagation()}
        blur={event => event.stopPropagation()}
      >
        {value}
      </GradientButton>
      {open && (
        <DropDownWrapper width={'15rem'}>
          <DropDown
            update={handleChange}
            date={value}
            disabled={[]}
          />
        </DropDownWrapper>
      )}
    </StatefulWrapper>
  )
}

const InputDateWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputDate {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputDate {...props} />
  )
}

export default InputDateWrapped

export {
  Raw,
}
