import React from 'react'
import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'
import InputRadio from '_shared/components/input/InputRadio'

const options = [
  {
    label: 'XLSX',
    value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    label: 'CSV',
    value: 'text/csv',
  },
  {
    label: 'JSON',
    value: 'application/json',
  },
]

const ChooserFormat = ({
  data,
  change,
}) => {
  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  return (
    <Area
      columns={1}
      rowgap={1}
    >
      <Heading
        level={3}
        title={'File Format'}
      />
      <InputRadio
        type={'horizontal'}
        field={'format'}
        options={options}
        value={data.format}
        change={handleChange}
      />
    </Area>
  )
}

export default ChooserFormat
