import React from 'react'

import styles from './monthrow.scss'

import Row from 'containers/Row'
import InlineTextBox from 'containers/InlineTextBox'

import CalPrev from 'icons/CalPrev'
import CalNext from 'icons/CalNext'

import {
  getMonthInfo,
} from 'libs/date'

import colors from 'config/colors.json'

const MonthRow = (props) => {
  const {
    date,
    change,
  } = props

  const monthStrings = getMonthInfo(date)

  return (
    <Row
      type={'center'}
      styling={styles.controls}
    >
      <InlineTextBox
        styling={styles.button}
        onMouseDown={(event) => {
          event.preventDefault()

          change(false)
        }}
      >
        <CalPrev
          fill={colors.color_text}
          size={16}
        />
      </InlineTextBox>
      <InlineTextBox
        styling={styles.title}
      >
        {`${monthStrings.short} ${monthStrings.year}`}
      </InlineTextBox>
      <InlineTextBox
        styling={styles.button}
        onMouseDown={(event) => {
          event.preventDefault()

          change(true)
        }}
      >
        <CalNext
          fill={colors.color_text}
          size={16}
        />
      </InlineTextBox>
    </Row>
  )
}

export default MonthRow
