import React, { useEffect } from 'react'

import {
  getSpec,
  valid as valid_spec,
} from './Context'

import { StateTabbedHeader as TabbedHeader } from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'

import Subslide, { Controls } from 'containers/SubSlide'

import useForm from '_shared/hooks/useForm'

const options = [
  {
    label: 'Description and Layout',
    view: FormDescription,
    slug: 'description',
  },
]

const permissions = [
  'read',
  'create',
  'update',
  'delete',
]

const LayoutConsumer = ({
  record,
  save,
  close,
  mount,
}) => {
  const {
    current,
    valid,
    allValid,
    reset,
    update,
  } = useForm(getSpec, valid_spec, record, {})

  useEffect(() => {
    reset(record)
  }, [reset, record])

  const updateSingle = (field, value) => update([{ field, value }])

  const wrapped = () => {
    save(current)
  }

  return (
    <Subslide
      mount={mount}
      controls={() => {
        return (
          <Controls
            saveAction={wrapped}
            cancelAction={close}
            permissions={permissions}
            disabled={!allValid}
          />
        )
      }}
    >
      <TabbedHeader
        title={'Layout Templates'}
        options={options}
        passProps={{
          current,
          valid,
          update,
          updateSingle,
          save,
        }}
      />
    </Subslide>
  )
}

export default LayoutConsumer
