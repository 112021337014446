import React from 'react'

import Chooser from '../Chooser'

const ChooserService = ({
  data,
  change,
}) => {
  return (
    <Chooser
      field={'services'}
      label={'Filter services'}
      data={data}
      change={change}
      dependency={'services'}
    />
  )
}

export default ChooserService
