import React, { Component } from 'react'

import styles from './appstatus.scss'

import {
  withRouter,
} from 'react-router-dom'

import {
  connect,
} from 'react-redux'

import Container from 'containers/Container'

import RequestAuth from './RequestAuth'

const AppStatus = (props) => {
  const {
    children,
    auth,
    busy,
  } = props

  return (
    <Container
      styling={styles.layout}
    >
      {children}
      {auth && <RequestAuth />}
      {busy && <Overlay />}
    </Container>
  )
}

const Overlay = (props) => {
  return (
    <Container
      styling={styles.busy}
    >
      <Container
        styling={styles.loading}
      >
        <WaitingStatus />
      </Container>
    </Container>
  )
}

class WaitingStatus extends Component {
  constructor (props) {
    super(props)

    this.state = {
      flipped: false,
    }

    this.timer = null
  }

  componentDidMount () {
    this.timer = null

    this.timer = setInterval(() => {
      this.setState({
        flipped: !this.state.flipped,
      })
    }, 250)
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  render () {
    const light = 'rgb(125,226,202)'
    const dark = 'rgb(61,191,163)'

    return (
      <svg
        viewBox={'0 0 100 100'}
      >
        <polygon
          fill={this.state.flipped ? light : dark}
          points={'50 0 75 25 50 50 25 25'}
        />
        <polygon
          fill={this.state.flipped ? dark : light}
          points={'75 25 100 50 75 75 50 50'}
        />
        <polygon
          fill={this.state.flipped ? light : dark}
          points={'50 50 75 75 50 100 25 75'}
        />
        <polygon
          fill={this.state.flipped ? dark : light}
          points={'25 25 50 50 25 75 0 50'}
        />
      </svg>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    ui,
  } = state

  return {
    busy: ui.busy,
    auth: ui.requestAuth,
  }
}

export default withRouter(connect(mapStateToProps)(AppStatus))
