import React, { Component } from 'react'
import {
  connect,
} from 'react-redux'

import styles from './notifications.scss'

import {
  deleteNotification,
  hideNotification,
  markReadNotification,
} from 'actions/notifications'

import Container from 'containers/Container'

import Alert from './Alert'

import deepSort from 'libs/deepSort'

const DURATION = 3000

const copyStatus = (notifications) => {
  return notifications.map(notification => {
    return {
      id: notification.id,
      hidden: notification.hidden,
      persistent: notification.persistent,
    }
  })
}

const filterResults = (notifications) => {
  return notifications.filter(notification => {
    return notification.hidden === false && notification.persistent === false
  })
}

class Notifications extends Component {
  constructor (props) {
    super(props)

    this.timer = null
    this.current = null

    this.state = {
      paused: false,
    }
  }

  componentDidMount () {
    const next = filterResults(copyStatus(this.props.notifications))

    if (next.length !== 0) {
      this.start()
    }
  }

  componentDidUpdate () {
    const next = filterResults(copyStatus(this.props.notifications))

    if (next.length > 0 && this.timer === null) {
      this.start()
    }
  }

  start = () => {
    const notifications = filterResults(copyStatus(this.props.notifications))

    if (!this.state.paused) {
      this.timer = setTimeout(() => {
        this.destroy(notifications[0].id)
      }, DURATION)
    }
  }

  pause = (paused) => {
    clearTimeout(this.timer)

    this.timer = null

    if (!paused) {
      this.setState({
        paused: false,
      })
    } else {
      this.setState({
        paused: true,
      })
    }
  }

  destroy = (id) => {
    clearTimeout(this.timer)

    this.timer = null

    this.props.markReadNotification(id)

    this.pause(false)
  }

  render () {
    return (
      <Container
        styling={styles.layout}
      >
        {this.props.notifications.filter(notification => !notification.hidden).map((notification, index) => {
          const position = index + 1

          return (
            <Alert
              key={notification.id}
              id={notification.id}
              position={position}
              data={notification.data}
              dismiss={this.destroy}
              isMounted={!notification.read}
              unMountCallBack={() => this.props.hideNotification(notification.id)}
              pause={this.pause}
            />
          )
        }).reverse()}
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    notifications,
  } = state

  return {
    notifications: deepSort(notifications, 'id'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteNotification: id => dispatch(deleteNotification(id)),
    hideNotification: id => dispatch(hideNotification(id)),
    markReadNotification: id => dispatch(markReadNotification(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
