import React from 'react'

import Container from '_shared/components/layout/Container'
import InputInteger from '_shared/components/input/InputInteger'

const Integer = ({
  title,
  field,
  value,
  placeholder,
  change,
  disabled,
}) => {
  return (
    <Container area={field}>
      <InputInteger
        title={title}
        field={field}
        value={value}
        placeholder={placeholder}
        change={change}
        disabled={disabled}
      />
    </Container>
  )
}

export default Integer
