import React, { Component } from 'react'

import styles from './select.scss'

import ShowHideWrapper from 'containers/ShowHideWrapper'

import StatefulContainer from 'containers/StatefulContainer'
import TextIconRow from 'atoms/TextIconRow'

import UpIcon from 'icons/Up'
import DownIcon from 'icons/Down'

import List from './List'

import shallowEqual from 'libs/shallowEqual'

import deepSort from 'libs/deepSort'

import parentDistance from 'libs/parentDistance'

const compareVar = (value, comparison) => {
  if (value === Object(value) && comparison === Object(comparison)) {
    return shallowEqual(value, comparison)
  } else {
    return value === comparison
  }
}

const unit = 3
const base = (3 * unit) - (unit / 2)

class Select extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      maxHeight: base,
    }

    this.container = React.createRef()
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.forceClose, false)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.forceClose, false)
  }

  forceClose = (event) => {
    if (this.container.current.contains(event.target)) {
      return
    }

    this.setState({
      open: false,
    })
  }

  edit = () => {
    const maxHeight = parentDistance(this.container.current, 1.5)

    this.setState({
      maxHeight,
      open: !this.state.open,
    })
  }

  close = () => {
    this.setState({
      open: false,
    })
  }

  change = (value) => {
    const {
      cancelable,
      stay,
      change,
    } = this.props

    if (cancelable) {
      value === this.props.value ? change(null) : change(value)
    } else {
      change(value)
    }

    !stay && this.close()
  }

  getTitle = () => {
    const {
      options,
      value,
    } = this.props

    const list = options || []

    const index = list.findIndex(item => compareVar(item.value, value))

    return index
  }

  render () {
    const {
      placeholder,
      options,
      styling,
      status,
      showRequiredMessage,
      required,
      requiredText,
      noSort,
      expand = false,
    } = this.props

    const Icon = this.state.active ? UpIcon : DownIcon

    const found = this.getTitle()

    const title = found !== -1 ? options[found].title : placeholder

    const alteredStatus = this.state.open ? 'interacting' : status

    const sorted = noSort ? options : deepSort(options, 'title')

    return (
      <StatefulContainer
        status={alteredStatus}
        styling={[styles.layout, styling].join(' ')}
        getRef={this.container}
        showRequiredMessage={showRequiredMessage}
        required={required}
        requiredText={requiredText}
      >
        <button
          onClick={(event) => {
            event.preventDefault()
            this.edit()
          }}
          className={styles.clickHandle}
          tabIndex={0}
        >
          <TextIconRow
            text={title}
            icon={Icon}
            styling={styles.button}
            iconSize={10}
          />
        </button>
        <ShowHideWrapper
          visible={this.state.open}
        >
          <List
            options={sorted}
            close={this.close}
            value={this.props.value}
            update={this.change}
            expand={expand}
            maxHeight={this.state.maxHeight}
          />
        </ShowHideWrapper>
      </StatefulContainer>
    )
  }
}

export default Select
