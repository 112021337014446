import React from 'react'

import {
  getSpec,
  valid as valid_spec,
  handlers,
} from './Context'

import { StateTabbedHeader as TabbedHeader } from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'

import SlideForm from 'templates/SlideForm'

import { useGetLocalValueFunc } from '_shared/hooks/useGetLocaleValue'

const options = [
  {
    label: 'Description',
    view: FormDescription,
    slug: 'description',
  },
]

const Consumer = (props) => {
  const getTitle = useGetLocalValueFunc('title')

  return (
    <SlideForm
      options={options}
      data_spec={getSpec}
      valid_spec={valid_spec}
      handlers={handlers}
      mountKey={'record'}
      permissionsKey={'terms'}
      render={({
        current,
        valid,
        update,
        updateSingle,
      }) => {
        const title = getTitle(current)

        return (
          <TabbedHeader
            title={`Cancellation & Amendments${title !== null ? `: ${title}` : ''}`}
            options={options}
            passProps={{
              current,
              valid,
              updateSingle,
            }}
          />
        )
      }}
    />
  )
}

export default Consumer
