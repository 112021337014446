const EDITOR = {
  admin: true,
  owner: true,
  editor: true,
  viewer: false,
  billing: false,
}

export default {
  // Further restrictions are applied in the microservice that
  // serves the carts and orders to ensure trade partners can
  // only see their own orders.
  // TODO: Maybe we can have dynamic permissions? e.g. only "cart:read:me", with NO generic "cart:read" for TP users
  // Similar to what we have for the "trade_partner:read:me" permission, it would be good for more intent to
  // be specified here, rather than this being implicit.
  'cart:read': EDITOR,
  'order:create:trade_partner': EDITOR,
  'order:read': EDITOR,
  'order:update': EDITOR,

  'payment:pay:internal_credit': EDITOR,
  'payment:pay:sagepay_card': EDITOR,
  'payment:read': EDITOR,
  'payment:refund:internal_credit': EDITOR,
  'payment:refund:sagepay_card': EDITOR,
  'trade_partner:read:me': EDITOR,
  'voucher:read': EDITOR,
}
