import React from 'react'

import {
  getSchema,
  handlers,
  valid,
} from './Context'

import { StateTabbedHeader as TabbedHeader } from '_shared/components/navigation/TabbedHeader'

import FormInformation from '../../ScheduleTemplate/form/FormInformation'
import FormVenue from './FormVenue'
import FormDuration from './FormDuration'
import FormRoute from './FormRoute'

import Row from '_shared/components/layout/Row'
import ActionCTA from '_shared/components/action/ActionCTA'

import SubSlideForm from 'templates/SubSlideForm'

import format from 'date-fns/format'
import parse from 'date-fns/parse'

import {
  useQueryClient,
} from 'react-query'

const optionsEvent = [
  {
    label: 'Date, time & duration',
    view: FormDuration,
    slug: 'duration',
  },
  {
    label: 'Venue, layout & capacity',
    view: FormVenue,
    slug: 'venue_layout_capacity',
  },
]

const optionsRoute = [
  {
    label: 'Date, time & route',
    view: FormRoute,
    slug: 'duration',
  },
  {
    label: 'Venue, layout & capacity',
    view: FormVenue,
    slug: 'venue_layout_capacity',
  },
]

const optionsAPIEvent = [
  {
    label: 'Date, time & duration',
    view: FormDuration,
    slug: 'duration',
  },
]

const optionsAPIRoute = [
  {
    label: 'Date, time & route',
    view: FormRoute,
    slug: 'duration',
  },
]

const getOptions = (current, operator_type) => {
  if (current.type === 'event') {
    return operator_type === 'scandlines' ? optionsAPIEvent : optionsEvent
  } else {
    return operator_type === 'scandlines' ? optionsAPIRoute : optionsRoute
  }
}

const getActualTime = (stamp) => {
  try {
    const [
      hours,
      minutes,
    ] = stamp.split('T')[1].split(':')

    return `${hours}:${minutes}`
  } catch (error) {
    console.log('Error with time', stamp)
    return '00:00'
  }
}

const InstanceConsumer = ({
  record,
  initial,
  close,
  mount,
  type,
  service,
  operator_type,
}) => {
  const queryClient = useQueryClient()

  const handleSave = async () => {
    await queryClient.refetchQueries([`instance_list_${service}`])
  }

  return (
    <SubSlideForm
      data_spec={getSchema}
      valid_spec={valid}
      handlers={handlers}
      mount={mount}
      permissionsKey={'user'}
      initial={initial}
      record={record}
      close={close}
      handleSave={handleSave}
      render={({
        current,
        update,
        updateSingle,
      }) => {
        // TODO: Fix the record loading logic in sub forms
        if (!current.segments[0] || !current.segments[0].start) return null

        return (
          <TabbedHeader
            title={`Instance: ${current.segments[0] && `${getActualTime(current.segments[0].start)} ${format(parse(current.segments[0].start), 'D MMM')}`}`}
            options={getOptions(current, operator_type)}
            passProps={{
              current,
              update,
              updateSingle,
              type,
            }}
            status={() => {
              return (
                <Status
                  current={current}
                  updateSingle={updateSingle}
                />
              )
            }}
          />
        )
      }}
    />
  )
}

// "active" | "suspended" | "cancelled" | "locked"

const Status = ({
  current,
  updateSingle,
}) => {
  const handleFreeze = () => {
    const status = current.status === 'active' ? 'suspended' : 'active'

    updateSingle('status', status)
  }

  const handleCancel = () => {
    const status = current.status !== 'cancelled' ? 'cancelled' : 'active'

    updateSingle('status', status)
  }

  const not_frozen = current.status === 'active' || current.status === 'cancelled'

  return (
    <Row type={'end'}>
      <ActionCTA
        type={'frozen'}
        label={not_frozen ? 'Freeze Sales' : 'Un-Freeze Sales'}
        change={handleFreeze}
        disabled={current.status === 'cancelled'}
      />
      <ActionCTA
        type={'caution'}
        label={current.status === 'cancelled' ? 'Un-Cancel Departure' : 'Cancel Departure'}
        change={handleCancel}
      />
    </Row>
  )
}

export default InstanceConsumer
