import React from 'react'

import generateAreas from './libs/cleanAreas'

import getUniqueAreas from './libs/getUniqueAreas'

import getTracks from './libs/getTracks'

import stylingHandler from '_shared/style/stylingHandler'

const base = {
  display: 'grid',
  width: '100%',
  alignItems: 'start',
}

/*
const columns = ['2rem', 'auto', '2rem']
*/

/*
const rows = ['2rem', 'auto', '2rem']
*/

/*
const areas = [
  ['icon', 'title', 'dismiss/3'],
  ['icon', message', 'message', 'message']
]
*/

const getCSSColumns = (columns) => {
  if (Array.isArray(columns)) {
    return columns.join(' ')
  } else {
    return new Array(columns).fill('1fr').join(' ')
  }
}

const getCSSAreas = (areas) => {
  return areas.map(row => {
    return `"${row.join(' ')}"`
  }).join(' ')
}

const extractSafeStyleProps = ({
  width,
  minWidth,
  height,
  minHeight,
  maxHeight,
  area,
  gridAlign,
}) => stylingHandler({
  width,
  minWidth,
  height,
  minHeight,
  maxHeight,
  area,
  gridAlign,
})

const AreaLayout = (props) => {
  const {
    colgap = 3,
    rowgap = 3,
    columns = 12, // 12 || ['value', 'value', 'value'] - If is a number then calculate subgrid from length
    baseColUnit = 6,
    rows = [], // Always an array ['value', 'value', 'value']
    areas = [], // Areas always has to be an array
    children,
    ...styling
  } = props

  const columnAmount = Array.isArray(columns) ? columns.length : columns
  let repeater = false
  let parsedAreas = []

  const childLength = React.Children.count(children)

  if (areas.length === 0) { // Auto assign areas
    repeater = true

    const rowLength = columnAmount / baseColUnit
    let remaining = childLength

    const generatedAreas = []

    while (remaining > 0) {
      const currentRow = []

      for (let x = 0; x < rowLength; x++) {
        currentRow.push(`area_${childLength - remaining}`)
        remaining -= 1
      }

      generatedAreas.push(currentRow)
    }

    parsedAreas = generateAreas(generatedAreas, columns)
  } else {
    // Check amount of children here and pad with extra areas if needed to create a hybrid
    // Or fill with empty based on closest divisible row and maximum row
    parsedAreas = generateAreas(areas, columns)
  }

  const tracks = getTracks(parsedAreas, columnAmount)

  const style = {
    ...base,
    ...{
      gridColumnGap: `${colgap}rem`,
      gridRowGap: `${rowgap}rem`,
      gridTemplateColumns: getCSSColumns(columns),
      gridTemplateAreas: getCSSAreas(parsedAreas),
    },
    ...(rows.length > 0 ? { gridTemplateRows: rows.join(' ') } : { gridAutoRows: 'minmax(min-content, max-content)' }),
    ...stylingHandler(styling),
  }

  const repeatableAreas = getUniqueAreas(parsedAreas)

  return (
    <div style={style}>
      {React.Children.map(children, (child, index) => {
        if (!child) return null

        if (repeater) {
          if (child.type === AreaLayout && child.props.subGrid) { // We have a sub grid
            return React.cloneElement(child, {
              area: repeatableAreas[index],
              columns: tracks[repeatableAreas[index]].cols,
            })
          }

          return (
            <div style={{ gridArea: repeatableAreas[index] }}>
              {child}
            </div>
          )
        } else {
          if (child.type === AreaLayout && child.props.subGrid) { // We have a sub grid
            return React.cloneElement(child, {
              columns: child.props.area ? tracks[child.props.area].cols : tracks[repeatableAreas[index]].cols,
            })
          }

          if (child.props.area) {
            if (child.type.styleable) return child

            return (
              <div style={extractSafeStyleProps(child.props)}>
                {child}
              </div>
            )
          }
        }

        return child
      })}
    </div>
  )
}

export default AreaLayout
