import React from 'react'

const DataIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path
        fill={fill}
        d='M13,2.05v3A7,7,0,0,1,19,12a7.11,7.11,0,0,1-.48,2.54l2.6,1.53A9.87,9.87,0,0,0,22,12,10,10,0,0,0,13,2.05ZM12,19A7,7,0,0,1,11,5.08v-3a10,10,0,1,0,9.05,15.86l-2.6-1.53A7,7,0,0,1,12,19Z'
      />
    </svg>
  )
}

export default DataIcon
