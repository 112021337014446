import React from 'react'

import styles from './column.scss'

const allowed = [
  'spaced',
  'start',
  'end',
]

const Column = (props) => {
  const {
    children,
    type,
    styling,
    getRef,
    spread,
    align,
    rawStyle,
    ...rest
  } = props

  const className = type && allowed.includes(type) ? styles[type] : styles.default

  const elementSpace = spread ? styles.spread : null

  return (
    <div
      className={[className, elementSpace, styling].join(' ')}
      ref={getRef}
      style={{ ...rawStyle, alignItems: align || 'none'}}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Column
