import React from 'react'

import { StateTabbedHeader as TabbedHeader } from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'
// import FormRules from './FormRules'
import FormProduct from './FormProduct'
import FormActivation from './FormActivation'
import FormLimits from './FormLimits'

import useGetLocalValue from '_shared/hooks/useGetLocaleValue'

const options = [
  {
    label: 'Description',
    view: FormDescription,
    slug: 'description',
    status: 'DEFAULT',
  },
  // {
  //   label: 'Promo rules',
  //   view: FormRules,
  //   slug: 'rules',
  //   status: 'DEFAULT'
  // },
  {
    label: 'Promo Products',
    view: FormProduct,
    slug: 'products',
    status: 'DEFAULT',
  },
  {
    label: 'Activation',
    view: FormActivation,
    slug: 'activation',
    status: 'DEFAULT',
  },
  {
    label: 'Purchase & channel limits',
    view: FormLimits,
    slug: 'limits',
    status: 'DEFAULT',
  },
]

const Route = ({
  cancel,
  current,
  valid,
  update,
  updateSingle,
}) => {
  const title = useGetLocalValue(current, 'title')

  return (
    <TabbedHeader
      title={`Promotion${title !== null ? `: ${title}` : ''}`}
      cancel={cancel}
      options={options}
      passProps={{
        current,
        valid,
        update,
        updateSingle,
      }}
    />
  )
}

export default Route
