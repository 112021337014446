import React from 'react'

const AnimatedLogo = (props) => {
  const {
    size,
  } = props

  return (
    <svg
      width={size}
      height={size * 0.75}
      viewBox='0 0 125.49 94.11'
    >
      <path fill={'#fff'} d='M78.43,47.05a22.19,22.19,0,0,1,0-31.37L62.74,0,0,62.74,31.37,94.11,62.74,84.3l31.37,9.81,31.38-31.37L109.8,47.05a22.17,22.17,0,0,1-31.35,0Z' />
      <rect fill={'#7de2ca'} x='51.65' y='67.34' width='22.18' height='22.18' transform='translate(-37.08 67.34) rotate(-45)' />
      <polygon fill={'#3dbfa3'} points='78.43 78.42 94.11 94.11 62.74 94.11 78.43 78.42' />
      <polygon fill={'#3dbfa3'} points='47.06 78.42 62.74 94.11 31.37 94.11 47.06 78.42' />
    </svg>
  )
}

export default AnimatedLogo
