import React from 'react'

import Row from '_shared/components/layout/Row'
import ActionUtilityMulti from '_shared/components/action/ActionUtilityMulti'
import Chip from '_shared/components/element/Chip'

import StyleWrapper from '_shared/style/StyleWrapper'

const TemporaryTags = props => {
  const  {
    name,
    field,
    options = [],
    value = [],
    change,
    ...style
  } = props

  const chips = options.filter(o => value.includes(o.value))
  const removeChip = chipValue => () => change(field, value.filter(v => v !== chipValue))

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <Row type={'start'} style={styling}>
            <ActionUtilityMulti
              name={name}
              field={field}
              options={options}
              value={value}
              change={change}
            />
            {chips.map(chip => (
              <Chip
                key={chip.value}
                label={chip.label}
                removable
                change={removeChip(chip.value)}
              />
            ))}
          </Row>
        )
      }}
    />
  )
}

export default TemporaryTags
