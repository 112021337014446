import React from 'react'

const LogOutIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size * 0.82}
      viewBox='0 0 32 26.15'
    >
      <path
        fill={fill}
        d={'M29.09,0H2.91A2.92,2.92,0,0,0,0,2.91v5.8H2.91V2.88H29.09V23.29H2.91V17.44H0v5.83a2.89,2.89,0,0,0,2.91,2.88H29.09A2.89,2.89,0,0,0,32,23.27V2.91A2.91,2.91,0,0,0,29.09,0ZM14.55,18.89l5.81-5.81L14.55,7.26v4.36H0v2.91H14.55Z'}
      />
    </svg>
  )
}

export default LogOutIcon
