import { useParams, useHistory } from 'react-router-dom'

/*
  Requires a standard subview object:
  [
    {
      title: 'A View',
      slug: 'view_a
    },
    ...
  ]
*/

const useNavigationView = (options) => {
  const history = useHistory()

  const {
    view,
  } = useParams()

  const changeView = (slug) => {
    const location = history.location.pathname.replace(/\/$/, '')

    const route = view ? location.replace(view, slug) : `${location}/${slug}`

    history.push(route)
  }

  // Clean up false URLs. TODO: FIND A BETTER WAY OF NOT REPEATING YOURSELF WITH THE PARAM
  const selected = options.some(option => option.slug === view) ? view : options[0].slug

  return {
    selected,
    changeView,
  }
}

export default useNavigationView
