import React from 'react'

import styles from './controlledfull.scss'

import Container from 'containers/Container'

import styleCombine from 'libs/styleCombine'

const SlideContent = (props) => {
  const {
    isMounted,
    onAnimationEnd,
    children,
  } = props

  return (
    <Container
      styling={styleCombine(styles.caddy, !isMounted && styles.out)}
      onAnimationEnd={onAnimationEnd}
    >
      <Container
        styling={styles.content}
      >
        {children}
      </Container>
    </Container>
  )
}

export default SlideContent
