import React from 'react'

import FlexButton from '_shared/components/element/FlexButton'
import BackIcon from '_shared/icons/Back'

import Config from 'libs/Config'

const ActionBack = ({ change, ...style }) => {
  return (
    <FlexButton {...style} change={change} background={'button'}>
      <BackIcon
        size={24}
        fill={Config.theme.text_light}
      />
    </FlexButton>
  )
}

ActionBack.styleable = true

export default ActionBack
