const VIEWER = {
  admin: true,
  owner: true,
  viewer: true,
  editor: true,
  billing: true,
}

const EDITOR = {
  admin: true,
  owner: true,
  editor: true,
  viewer: false,
  billing: false,
}

const ADMIN = {
  admin: true,
  owner: true,
  viewer: false,
  editor: false,
  billing: false,
}

export default {
  // "<RESOURCE>:<ACTION>:<MODIFIER>"
  'cart:override_fees': ADMIN,
  'cart:read': EDITOR,
  'order_email:send': EDITOR,
  'order_history:read': VIEWER,
  'order:create:trade_partner': EDITOR,
  'order:read': VIEWER,
  'order:send_email': EDITOR,
  'order:update': EDITOR,
  'order:update:past': EDITOR,
  'payment:pay:internal_credit': EDITOR,
  'payment:pay:internal_generic': EDITOR,
  'payment:pay:sagepay_card': EDITOR,
  'payment:read': VIEWER,
  'payment:refund:internal_credit': EDITOR,
  'payment:refund:internal_generic': EDITOR,
  'payment:refund:sagepay_card': EDITOR,
  'trade_partner:read': VIEWER,
  'voucher:read': VIEWER,
  'voucher:use': EDITOR,
}
