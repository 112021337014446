import React from 'react'

import Area from '_shared/components/layout/Area'
import Card from '_shared/components/layout/Card'
import Row from '_shared/components/layout/Row'
import Container from '_shared/components/layout/Container'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import InfoIcon from '_shared/icons/Info'
import VenueIcon from '_shared/icons/Venue'
import SeatIcon from '_shared/icons/Seat'
import AncillaryIcon from '_shared/icons/Ancillary'
import DurationIcon from '_shared/icons/Duration'

import Config from 'libs/Config'

const RouteTemplateCard = ({
  value,
  actions,
}) => {
  const {
    title,
    description,
    venue_title,
    venue_information,
    layout_title,
    layout_information,
    information,
    ancillary_items = [],
    segments,
  } = value

  return (
    <Card width={'100%'} padding={'2rem'} margin={'0 0 1rem 0'}>
      <Area
        areas={[
          'controls',
          'details',
        ]}
        width={'100%'}
      >
        <Area
          area={'controls'}
          areas={[
            'info actions',
          ]}
        >
          <Container area={'info'}>
            <Heading level={3} title={`Template: ${title}`} />
            <TextBox>{description}</TextBox>
          </Container>
          <Row area={'actions'} type={'end'} crossAxis={'flex-start'}>
            {actions && actions()}
          </Row>
        </Area>
        <Area
          area={'details'}
          areas={[
            'venue layout info items',
            'other',
          ]}
          rowgap={0}
          colgap={0}
          rawStyle={{ border: `1px solid ${Config.theme.text}` }}
        >
          <InnerSection area={'venue'} icon={VenueIcon} rawStyle={{ borderRight: `1px solid ${Config.theme.text}` }}>
            <Heading level={4} title={`Venue: ${venue_title}`} />
            <TextBox noMargin>{`${venue_information} Max capacity`}</TextBox>
          </InnerSection>
          <InnerSection area={'layout'} icon={SeatIcon} rawStyle={{ borderRight: `1px solid ${Config.theme.text}` }}>
            <Heading level={4} title={`Layout: ${layout_title}`} />
            {layout_information.map((item, index) => {
              return (
                <Row type={'spaced'} key={index}>
                  <InlineTextBox>{item.label}</InlineTextBox>
                  <InlineTextBox>{`x ${item.value}`}</InlineTextBox>
                </Row>
              )
            })}
          </InnerSection>
          <InnerSection area={'info'} icon={InfoIcon} rawStyle={{ borderRight: `1px solid ${Config.theme.text}` }}>
            <Heading level={4} title={'Information'} />
            <TextBox noMargin>{information}</TextBox>
          </InnerSection>
          <InnerSection area={'items'} icon={AncillaryIcon}>
            <Heading level={4} title={'Ancillary items'} />
            {ancillary_items.map((item, index) => (
              <TextBox noMargin key={index}>{item}</TextBox>
            ))}
          </InnerSection>
          <InnerSection area={'other'} icon={DurationIcon} rawStyle={{ borderTop: `1px solid ${Config.theme.text}` }}>
            <Heading level={4} title={'Route'} />
            {segments.map((item, index) => {
              return (
                <Row type={'spaced'} key={index}>
                  <InlineTextBox>{item.time}</InlineTextBox>
                  <InlineTextBox>{item.title}</InlineTextBox>
                </Row>
              )
            })}
          </InnerSection>
        </Area>
      </Area>
    </Card>
  )
}

const InnerSection = ({
  icon: Icon,
  children,
  ...style
}) => {
  return (
    <Container {...style} gridAlign={'stretch'}>
      <Row crossAxis={'flex-start'} padding={'1rem'}>
        <Icon fill={Config.theme.text} size={24} />
        <Container>
          {children}
        </Container>
      </Row>
    </Container>
  )
}

InnerSection.styleable = true

export default RouteTemplateCard
