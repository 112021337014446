import React, { useCallback, useState, useRef } from 'react'

import Label from '_shared/components/layout/Label'
import Row from '_shared/components/layout/Row'
import Input from '_shared/components/element/Input'
import Button from '_shared/components/element/Button'
import Chip from '_shared/components/element/Chip'

import { StatefulContainer } from '_shared/components/layout/StatefulWrapper'

import DropDownMulti from '_shared/components/element/DropDownMulti'

import useOnClickOutside from '_shared/hooks/useOnClickOutside'
import useParentDistance from '_shared/hooks/useParentDistance'

import ListAdd from '_shared/icons/ListAdd'
import RemoveIcon from '_shared/icons/RemoveRound'

import Config from 'libs/Config'

const InputFilter = ({
  field,
  placeholder,
  value,
  change,
  options,
  status,
  required,
  requiredText,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  disabled = false,
  controlled = false,
  lockToWindow = false,
  ...style
}) => {
  const container = useRef(null)

  const [
    filter,
    setFilter,
  ] = useState('')

  const [
    open,
    setOpen,
  ] = useState(false)

  const forceClose = useCallback(event => {
    setOpen(false)
  }, [])

  useOnClickOutside(container, forceClose)

  const {
    maxHeight,
    calcMaxHeight,
  } = useParentDistance(container, lockToWindow)

  const handleChange = useCallback((value) => {
    change(field, value)

    setOpen(false)
  }, [change, field])

  const handleRemove = (option) => () => {
    const index = value.findIndex(item => item === option)
    const output = [...value]

    output.splice(index, 1)

    change(field, output)
  }

  const handleOpen = useCallback((event) => {
    event.preventDefault()

    calcMaxHeight()

    setOpen(true)
  }, [calcMaxHeight])

  const handleClear = useCallback(() => {
    change(field, [])

    setOpen(false)
  }, [change, field])

  const handleFilter = (event) => setFilter(event.target.value)

  const filtered = options.filter(item => item.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1)

  return (
    <div>
      <StatefulContainer
        focused={open}
        status={status}
        required={required}
        requiredText={requiredText}
        getRef={container}
        minHeight={minHeight}
        padding={'0 0.5rem 0 0'}
        disabled={disabled}
        controlled={controlled}
        {...style}
      >
        <Input
          name={field}
          type={'text'}
          placeholder={placeholder}
          value={open ? filter : value.length > 0 ? `${value.length} selected` : ''}
          change={handleFilter}
          margin={margin}
          disabled={disabled}
          focus={handleOpen}
        />
        {value.length === 0 && (
          <ListAdd fill={Config.theme.text} size={24} />
        )}
        {value.length > 0 && (
          <Button change={handleClear} padding={'0'}>
            <RemoveIcon fill={Config.theme.text_warning} size={18} />
          </Button>
        )}
        {open && (
          <DropDownMulti
            options={filtered}
            change={handleChange}
            value={value}
            cancel={forceClose}
            focusOnMount={false}
            maxHeight={maxHeight}
            utility
          />
        )}
      </StatefulContainer>
      <Row type='start' flexWrap>
        {value.map(item => {
          const exists = options.find(option => option.value === item)

          return (
            <Chip
              key={item}
              label={exists.label}
              change={handleRemove(item)}
              removable
            />
          )
        })}
      </Row>
    </div>
  )
}

const InputFilterWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputFilter {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputFilter {...props} />
  )
}

export default InputFilterWrapped

export {
  Raw,
}
