import React, { useCallback, useState, useRef } from 'react'

import { StatefulContainer } from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import DropDownMulti from '_shared/components/element/DropDownMulti'
import GradientButton from '_shared/components/element/GradientButton'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import VerticalArrowIndicator from '_shared/components/element/VerticalArrowIndicator'

import useOnClickOutside from '_shared/hooks/useOnClickOutside'
import useParentDistance from '_shared/hooks/useParentDistance'

const InputSelectMulti = ({
  field,
  placeholder,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  change,
  options = [],
  minHeight = '2.5rem',
  controlled = false,
  ...style
}) => {
  const container = useRef(null)

  const [
    open,
    setOpen,
  ] = useState(false)

  const forceClose = useCallback(() => {
    setOpen(false)
  }, [])

  useOnClickOutside(container, forceClose)

  const {
    maxHeight,
    calcMaxHeight,
  } = useParentDistance(container)

  const handleChange = (value) => {
    change(field, value)
  }

  const handleOpen = useCallback((event) => {
    event.preventDefault()

    calcMaxHeight()

    setOpen(!open)
  }, [open, calcMaxHeight])

  const parseTitle = useCallback(() => {
    const mapped = value.map(item => {
      const found = options.find(option => option.value === item)

      return found ? found.label : 'undefined'
    })

    return mapped.length > 0 ? mapped.join(', ') : placeholder
  }, [options, placeholder, value])

  return (
    <StatefulContainer
      focused={open}
      status={status}
      required={required}
      requiredText={requiredText}
      getRef={container}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <GradientButton
        change={handleOpen}
        disabled={disabled}
        width={'100%'}
        padding={'0 1rem'}
      >
        <InlineTextBox truncate>{parseTitle()}</InlineTextBox>
        <VerticalArrowIndicator open={open} absRight={'0.5rem'} />
      </GradientButton>
      {open && (
        <DropDownMulti
          options={options}
          change={handleChange}
          value={value}
          cancel={forceClose}
          maxHeight={maxHeight}
        />
      )}
    </StatefulContainer>
  )
}

const InputSelectWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputSelectMulti {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputSelectMulti {...props} />
  )
}

export default InputSelectWrapped

export {
  Raw,
}
