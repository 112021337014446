import React, { Fragment } from 'react'

import styles from './multirow.scss'

import Row from 'containers/Row'
import InlineTextBox from 'containers/InlineTextBox'

import Button from 'atoms/TransparentButton'
import Input from 'atoms/Input'
import MaskedInput from 'atoms/MaskedInput'
import StatefulContainer from 'containers/StatefulContainer'
import Select from 'atoms/Select'
import CheckBox from 'atoms/CheckBox'

import CurrencyField from 'molecules/CurrencyField'

import RemoveIcon from 'icons/RemoveNeg'

// import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const RemoveButton = <RemoveIcon size={24} fill={'rgba(193, 44, 9, 1)'} />

const masks = {
  hourMinute: {
    getValue: (value) => value,
    setValue: (value) => value,
    placeholder: '00:15',
    mask: [/\d/, /\d/, ':', /[0-5]/, /[0-9]/],
  },
  time: {
    getValue: (value) => value,
    setValue: (value) => value,
    placeholder: '12:23',
    mask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
  },
  integer: {
    getValue: (value) => value,
    setValue: (value) => Number(value),
    placeholder: '0',
    mask: [],
  },
  date: {
    getValue: (value) => value,
    setValue: (value) => value,
    placeholder: '02/03/2018',
    mask: [/[0-2]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/],
  },
}

const CellLabel = (props) => {
  return (
    <InlineTextBox>
      {props.value}
    </InlineTextBox>
  )
}

const CellRemove = (props) => {
  const {
    action,
  } = props

  return (
    <Button
      styling={styles.removeBtn}
      content={RemoveButton}
      onClick={action}
    />
  )
}

const CellInput = (props) => {
  const {
    value,
    placeholder,
    masked,
    change,
    styling,
  } = props

  let Element

  if (masked && masks[masked]) {
    const maskSet = masks[masked]

    const modifiedValue = maskSet.getValue(value)

    if (masked === 'currency') {
      Element = (
        <CurrencyField
          styling={styles.input}
          value={value}
          change={change}
        />
      )
    } else {
      Element = (
        <MaskedInput
          styling={styles.input}
          value={modifiedValue}
          placeholder={placeholder || maskSet.placeholder}
          onChange={(event) => change(maskSet.setValue(event.target.value))}
          mask={maskSet.mask}
          guide={false}
        />
      )
    }
  } else {
    Element = (
      <Input
        styling={styles.input}
        value={value}
        placeholder={placeholder}
        onChange={(event) => change(event.target.value)}
      />
    )
  }

  return (
    <StatefulContainer
      styling={[styles.field, styling].join(' ')}
    >
      {Element}
    </StatefulContainer>
  )
}

const CellSelect = (props) => {
  const {
    value,
    placeholder,
    options,
    change,
    noSort,
    filter,
    filterSet,
  } = props

  let filtered = options

  if (filter && filterSet) {
    filtered = options.filter(item => {
      return item.value === value || !filterSet.includes(item.value)
    })
  }

  return (
    <Select
      styling={styles.select}
      value={value}
      placeholder={placeholder}
      options={filtered}
      noSort={noSort}
      change={value => change(value)}
    />
  )
}

const CellCheckBox = (props) => {
  const {
    value,
    label,
    change,
  } = props

  return (
    <Row
      type={'start'}
      onClick={() => change(!value)}
    >
      <CheckBox
        toggled={value}
        action={() => {}}
      />
      <span className={styles.checkBoxLabel}>
        {label}
      </span>
    </Row>
  )
}

const CellCustom = (props) => {
  const {
    column,
    value,
    change,
    component,
  } = props

  return (
    <Fragment>
      {React.cloneElement(component, {
        value,
        change,
        column,
      })}
    </Fragment>
  )
}

export {
  CellLabel,
  CellRemove,
  CellInput,
  CellSelect,
  CellCheckBox,
  CellCustom,
}
