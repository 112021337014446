import getTime from 'date-fns/get_time'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfMonth from 'date-fns/start_of_month'
import getDaysInMonth from 'date-fns/get_days_in_month'
import isSameDay from 'date-fns/is_same_day'
import differenceInMonths from 'date-fns/difference_in_months'
import addMonths from 'date-fns/add_months'
import differenceInCalendarISOWeeks from 'date-fns/difference_in_calendar_iso_weeks'
import min from 'date-fns/min'
import max from 'date-fns/max'
import isAfter from 'date-fns/is_after'
import isBefore from 'date-fns/is_before'

const getToday = () => {
  return stampToDateISO(new Date())
}

const dateBefore = (dateBefore, dateAfter) => {
  return isSameDay(dateBefore, dateAfter) || isBefore(dateBefore, dateAfter)
}

const dateAfter = (dateBefore, dateAfter) => {
  return isSameDay(dateBefore, dateAfter) || isAfter(dateAfter, dateBefore)
}

const isBetween = (start, end, date) => {
  return (isSameDay(start, date) || isAfter(date, start)) && (isSameDay(end, date) || isBefore(date, end))
}

const getStamp = (date) => getTime(parse(date))

const stampFromYMD = (year, month, day) => getStamp(new Date(year, month, day))

const stampFromYM = (year, month) => getStamp(new Date(year, month, 1))

const splitDate = (date, iso) => {
  const [
    day,
    month,
    year,
  ] = format(parse(date), 'D/M/YYYY').split('/')

  return {
    day: Number(day),
    month: iso ? Number(month) - 1 : Number(month),
    year: Number(year),
    clean: format(parse(date), 'D/M/YYYY'),
  }
}

const getDistanceWeeks = (baseStamp, direction) => {
  const startRange = addMonths(baseStamp, -1)

  const calc = differenceInCalendarISOWeeks(startOfMonth(baseStamp), startOfMonth(startRange))

  return calc
}

// Zero based offset array for week positions
const getWeeksAsOffsets = (baseStamp, range) => {
  const output = []

  let startRange = startOfMonth(addMonths(baseStamp, (0 - range)))

  for (let x = (0 - range); x <= range; x++) {
    const currentRange = startOfMonth(addMonths(baseStamp, x))

    output.push(differenceInCalendarISOWeeks(currentRange, startRange))
  }

  return output
}

const getMonthRange = (baseStamp, range) => {
  const output = []

  for (let x = (0 - range); x <= range; x++) {
    const currentRange = startOfMonth(addMonths(baseStamp, x))

    output.push(currentRange)
  }

  return output
}

const getMonthInfo = (date) => {
  date = parse(date)

  return {
    firstDay: startOfMonth(date).getDay(),
    length: getDaysInMonth(date),
    long: format(date, 'MMMM'),
    short: format(date, 'MMM'),
    year: format(date, 'YYYY'),
  }
}

const stampToStrings = (date) => {
  date = parse(date)

  return {
    dateShort: format(date, 'D'),
    dateLong: format(date, 'DD'),
    dayOfWeek: Number(format(date, 'd')),
    dayShort: format(date, 'ddd'),
    dayLong: format(date, 'dddd'),
    dayOrd: format(date, 'Do'),
    monthShort: format(date, 'MMM'),
    monthLong: format(date, 'MMMM'),
    year: format(date, 'YYYY'),
  }
}

const stampToDDMMYYYY = (stamp) => {
  stamp = parse(stamp)

  return format(stamp, 'DD/MM/YYYY')
}

const isStampSameDay = (left, right) => {
  return isSameDay(parse(left), parse(right))
}

const distanceBetween = (first, last) => {
  const months = differenceInMonths(parse(last), parse(first))

  return months
}

const getRangeInDates = (dates, parseToString) => {
  if (dates.length === 0) {
    return {
      start: null,
      end: null,
    }
  }

  const start = min(...dates)
  const end = max(...dates)

  if (parseToString) {
    return {
      start: stampToDDMMYYYY(start),
      end: stampToDDMMYYYY(end),
    }
  } else {
    return {
      start,
      end,
    }
  }
}

const stampToDateISO = (stamp) => {
  stamp = parse(stamp)

  return format(stamp, 'YYYY-MM-DD')
}

export {
  getToday,
  getStamp,
  getDistanceWeeks,
  getWeeksAsOffsets,
  stampFromYMD,
  stampFromYM,
  splitDate,
  getMonthRange,
  getMonthInfo,
  stampToStrings,
  isStampSameDay,
  distanceBetween,
  stampToDDMMYYYY,
  getRangeInDates,
  stampToDateISO,
  isBetween,
  dateBefore,
  dateAfter,
}