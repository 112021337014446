import React from 'react'

import styles from './tick.scss'

import TickIcon from 'icons/Tick'

import styleCombine from '_shared/libs/styleCombine'

import StyleWrapper from '_shared/style/StyleWrapper'

import Config from 'libs/Config'

const Tick = (props) => {
  const {
    active,
    change,
    disabled,
    ...style
  } = props

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div
            className={styleCombine(styles.layout, active && styles.selected, disabled && styles.disabled)}
            onClick={change && change}
          >
            {active && (
              <TickIcon size={10} fill={Config.theme.text_light} />
            )}
          </div>
        )
      }}
    />
  )
}

export default Tick
