import React, { useState } from 'react'

import { morphism } from 'morphism'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'
import InputSelect from '_shared/components/input/InputSelect'
import Container from '_shared/components/layout/Container'

import useDependencies from '_shared/hooks/useDependencies'

import cleanTemplate from './cleanTemplate'

import TemplateHolder from '../../Services/form/TemplateHolder'

import { useTemplateFunc, useAPITemplateFunc } from '_shared/hooks/useTemplate'

const FormTemplate = ({
  service,
  type,
  current,
  updateSingle,
  operator_type,
}) => {
  const decorateTemplate = useTemplateFunc()
  const apiDecorateTemplate = useAPITemplateFunc()

  const [
    template,
    setTemplate,
  ] = useState(null)

  const { data: templates } = useDependencies('instance_templates', data => {
    let result = data.filter(item => item.service.entity_id === service)

    if (operator_type !== 'scandlines') {
      result = result.filter(item => item.venue !== null)
    }

    return result.map(template => {
      return {
        label: template.title,
        value: template.entity_id,
        data: template,
      }
    })
  })

  const handleUpdate = (field, value) => {
    const found = templates.find(template => template.value === value)

    if (found) {
      setTemplate(value)
      updateSingle('template', morphism(cleanTemplate, found.data))
    }
  }

  const selected_template = templates.find(item => item.value === template)

  return (
    <FormView>
      <Area
        areas={[
          'select . . .',
          'template',
        ]}
      >
        <InputSelect
          area={'select'}
          title={'Template'}
          field={'template'}
          value={template}
          placeholder={'Choose template'}
          options={templates}
          change={handleUpdate}
        />
        <Container area={'template'}>
          {selected_template && (
            <TemplateHolder
              type={type}
              template={selected_template.data}
              decorate={operator_type === 'scandlines' ? apiDecorateTemplate : decorateTemplate}
            />
          )}
        </Container>
      </Area>
    </FormView>
  )
}

export default FormTemplate
