import React, { useState } from 'react'

import * as duration from 'duration-fns'
import { morphism } from 'morphism'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'
import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import { Raw as InputDuration } from '_shared/components/input/InputDuration'
import { Raw as InputTime } from '_shared/components/input/InputTime'
import { Raw as InputSelect } from '_shared/components/input/InputSelect'

import SortListTable from '_shared/components/navigation/SortListTable'

import useDependencies from '_shared/hooks/useDependencies'
import useLocale from '_shared/hooks/useLocale'
import { apply, parse } from 'duration-fns'

import { route_segment } from './Context'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import {
  update,
} from '_shared/libs/mapToSchema'

import cloneDeep from 'lodash/cloneDeep'

import swap from '_shared/libs/arraySwap'

import differenceInMinutes from 'date-fns/difference_in_minutes'
import format from 'date-fns/format'
import addZero from 'libs/addZero'

const FormRoute = ({
  current,
  updateSingle,
}) => {
  const {
    default_locale,
  } = useLocale()

  const { data: location_options } = useDependencies('locations', data => data
    .map(location => ({
      label: getDeepLocale(location.locales, 'title', default_locale),
      value: location.entity_id,
    })))

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'selector/3 .',
        ]}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Define stops and travel time'} />
        </SectionWrapper>
        <Selector
          area={'selector'}
          field={'segments'}
          options={location_options}
          value={current.segments}
          change={(field, value) => updateSingle(field, value)}
        />
      </Area>
    </FormView>
  )
}

const columns = [
  {
    key: 'stage',
    label: '',
  },
  {
    key: 'start',
    label: 'Depart',
  },
  {
    key: 'end',
    label: 'Travel Time',
  },
  {
    key: 'location',
    label: 'Stop',
  },
]

const fieldStyle = {
  flex: '0 0 auto',
  minHeight: '1.5rem',
}

const addTempID = (item, index) => ({
  ...item,
  temp_id: index + 1,
})

const removeTempID = ({ temp_id, ...rest }) => ({ ...rest })

const getDuration = (start, end) => {
  const difference = differenceInMinutes(end, start)
  const hours = Math.floor(difference / 60)
  const minutes = difference % 60
  return duration.toString({
    hours,
    minutes,
  })
}

const getActualTime = (stamp) => {
  try {
    const [
      hours,
      minutes,
    ] = stamp.split('T')[1].split(':')

    return `${hours}:${minutes}`
  } catch (error) {
    console.log('Error with time', stamp)
    return '00:00'
  }
}

const mapInitial = (data) => {
  return data.map(({ start, end, ...rest }, index) => {
    return addTempID({
      date: start.split('T')[0], // format(parse(start), 'YYYY-MM-DD'),
      start: getActualTime(start), // format(parse(start), 'HH:mm'),
      end: getDuration(start, end),
      ...rest,
    })
  })
}

const sanitizeTime = (time) => {
  let [
    hours = '00',
    minutes = '00',
  ] = time.split(':')

  return `${addZero(hours)}:${addZero(minutes)}`
}

const generateOutput = (data) => {
  return data.map(({ date, start, end, ...rest }, index) => {
    return removeTempID({
      start: `${date}T${start}:00.000Z`, // parse(`${date} ${start}`).toISOString(),
      end: duration.apply(`${date}T${sanitizeTime(start)}:00.000Z`, end).toISOString(),
      ...rest,
    })
  })
}

const Selector = ({
  field: outer_field,
  value: collection,
  options,
  change,
}) => {
  const [
    internal,
    setInternal,
  ] = useState(mapInitial(collection))

  const sync = (state) => {
    setInternal(state)
    change(outer_field, generateOutput(state))
  }

  const create = () => {
    const state = [...internal]
    const lastItem = state[state.length - 1]
    state.push({
      ...lastItem,
      location: { entity_id: null },
      start: lastItem.start,
      end: 'PT0M',
      temp_id: state.length + 1,
    })
    sync(state)
  }
  const remove = (row) => {
    const state = [...internal]

    state.splice(row, 1)

    sync(state)
  }

  // TODO: Write cascading time logic for start and end on each stop
  const handleChange = (row) => (field, value) => {
    const state = [...internal]
    if (field === 'end') update(state, `[${row + 1}]start`, formatStart(`${state[row].date}:${state[row].start}`, value))
    update(state, `[${row}]${field}`, value)
    sync(state)
  }

  const sort = (row, drag) => {
    const over = internal.findIndex(item => item.temp_id === row)
    const dragging = internal.findIndex(item => item.temp_id === drag)

    if (over === -1 || dragging === -1) return

    const mutated = swap(cloneDeep(internal), over, dragging)

    setInternal(mutated)
  }

  const finish = () => {
    change(outer_field, cloneDeep(internal).map(removeTempID))
  }

  const getStage = (index) => {
    let output = index + 1
    if (index === 0) output = 'START'
    if (index === internal.length - 1) output = 'END'

    return (
      <InlineTextBox textAlign={'center'} width={'100%'} block>{output}</InlineTextBox>
    )
  }

  const getTimeField = (index, field, value, change) => {
    return (
      <InputTime
        field={field}
        placeholder={'00:00'}
        value={value}
        change={change}
        {...fieldStyle}
        width={'6rem'}
      />
    )
  }

  const getDurationField = (index, field, value, change) => {
    if (index < internal.length - 1) {
      return (
        <InputDuration
          field={field}
          placeholder={'00h 00m'}
          value={value}
          change={change}
          {...fieldStyle}
          width={'6rem'}
        />
      )
    }
    return ''
  }

  const formatStart = (start, end) => {
    const duration = apply(new Date(start), parse(end))
    return format(duration, 'HH:mm')
  }
  const mutatedData = internal.map(({
    temp_id,
    start,
    end,
    location,
  }, row) => {
    return {
      id: temp_id,
      stage: getStage(row),
      start: getTimeField(row, 'start', start, handleChange(row)),
      end: getDurationField(row, 'end', end, handleChange(row)),
      location: (
        <InputSelect
          field={'location.entity_id'}
          placeholder={'Choose a location'}
          options={options}
          value={location.entity_id}
          change={handleChange(row)}
          {...fieldStyle}
        />
      ),
    }
  })

  return (
    <SortListTable
      columns={columns}
      data={mutatedData}
      create={create}
      remove={remove}
      sort={sort}
      finish={finish}
      locked
    />
  )
}

export default FormRoute
