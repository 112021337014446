import React, { useCallback, useState, useEffect, useRef } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import DropDown from '_shared/components/element/DropDown'

import useParentDistance from '_shared/hooks/useParentDistance'

const shape = /(\d+)([ymwdhmsYMWDHMS])?/

const compactShape = /^(?=.{3,}$)P(\d+Y)?(\d+M)?(\d+W)?(\d+D)?(T(\d+H)?(\d+M)?(\d+S)?)?$/

const timeOptions = [
  {
    label: 'years',
    value: 'y',
  },
  {
    label: 'months',
    value: 'm',
  },
  {
    label: 'weeks',
    value: 'w',
  },
  {
    label: 'days',
    value: 'd',
  },
  {
    label: 'hours',
    value: 'h',
  },
  {
    label: 'minutes',
    value: 'min',
  },
  {
    label: 'seconds',
    value: 's',
  },
]

const generateISOInterVal = (value, quantifier) => {
  const output = {
    y: 0,
    m: 0,
    w: 0,
    d: 0,
    h: 0,
    min: 0,
  }

  output[quantifier] = value || 0

  return `P${output.y}Y${output.m}M${output.w}W${output.d}DT${output.h}H${output.min}M0S`
}

const parseISOInterval = (value, defaultVal) => {
  const response = {
    value: 0,
    quantifier: defaultVal || 'd',
  }

  if (typeof value === 'undefined' || typeof value !== 'string') return response

  const hasShape = value.match(compactShape)

  if (hasShape) {
    const parts = [...hasShape]
      .slice(1)
      .filter(item => item)

    parts.forEach((key, index) => {
      const item = key.match(shape)

      if (item && Number(item[1]) > 0) {
        response.value = item[1]
        response.quantifier = timeOptions.find(option => option.value === item[2].toLowerCase()).value
      }
    })
  }

  return response
}

// PT24H

const InputISOInterval = ({
  placeholder,
  name,
  field,
  options = ['y', 'm', 'w', 'd'],
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  ...style
}) => {
  const container = useRef(null)

  const quantifierOptions = options.length > 0 ? timeOptions.filter(option => options.includes(option.value)) : timeOptions

  const {
    value: internal,
    quantifier,
  } = parseISOInterval(value, quantifierOptions[0].value)

  useEffect(() => {
    if (!options.includes(quantifier)) {
      console.error(`${quantifier} in ${field} is not allowed. Reseting to 0`)
      change(field, 'P0D')
      setHeld('d')
    }
  }, [change, field, options, quantifier])

  const [
    held,
    setHeld,
  ] = useState(quantifier)

  const [
    open,
    setOpen,
  ] = useState(false)

  const {
    maxHeight,
    calcMaxHeight,
  } = useParentDistance(container)

  const handleChange = value => change(field, value)

  const handleOpen = useCallback((event) => {
    calcMaxHeight()

    setOpen(true)
  }, [calcMaxHeight])

  const forceClose = useCallback(event => {
    setOpen(false)
  }, [])

  const displayQuantifier = internal === 0 ? held : quantifier

  const updateQuantifier = (quantifier) => {
    handleChange(generateISOInterVal(internal, quantifier))
    setHeld(quantifier)
  }

  const updateValue = (event) => {
    const value = Number(event.target.value.replace(/[^0-9]/g, ''))

    handleChange(generateISOInterVal(value, displayQuantifier))
  }

  const label = quantifierOptions.find(item => displayQuantifier === item.value)?.label || 'Undefined'

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      getRef={container}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={internal}
        change={updateValue}
        margin={margin}
        disabled={disabled}
        focus={handleOpen}
        blur={forceClose}
      />
      <InlineTextBox margin={'0 0.5rem 0 auto'} color={'text_success'}>{label}</InlineTextBox>
      {open && (
        <DropDown
          options={quantifierOptions}
          change={updateQuantifier}
          value={displayQuantifier}
          cancel={forceClose}
          focusOnMount={false}
          maxHeight={maxHeight}
        />
      )}
    </StatefulWrapper>
  )
}

const InputISOIntervalWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputISOInterval {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputISOInterval {...props} />
  )
}

export default InputISOIntervalWrapped

export {
  Raw,
}
