import React from 'react'

const RemoveRoundIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 28 28'
    >
      <path
        fill={fill}
        d='M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0Zm7,15.75H7v-3.5H21Z'
      />
    </svg>
  )
}

export default RemoveRoundIcon
