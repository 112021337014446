import React from 'react'

import styles from './inlinetextbox.scss'

const InlineTextBox = (props) => {
  const {
    children,
    styling,
    bold,
    noStyle,
    ...rest
  } = props

  const style = noStyle ? [] : [styles.layout]

  if (bold) style.push(styles.bold)

  if (styling) style.push(styling)

  return (
    <span
      className={style.join(' ')}
      {...rest}
    >
      {children}
    </span>
  )
}

export default InlineTextBox
