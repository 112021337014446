import React from 'react'

import EditIcon from 'icons/Edit'
import VisibleIcon from 'icons/Visible'

import IconButton from 'molecules/IconButton'

const EditViewButton = (props) => {
  const {
    styling,
    action = null,
    view = false,
  } = props

  return (
    <IconButton
      icon={view ? VisibleIcon : EditIcon}
      label={view ? 'View' : 'Edit'}
      action={action}
      styling={styling}
    />
  )
}

export default EditViewButton
