import React from 'react'

import Label from '_shared/components/layout/Label'
import InputRadio from '_shared/components/input/InputRadio'

const Radio = ({
  title,
  field,
  value,
  options,
  disabled,
  change,
}) => {
  return (
    <Label title={title} area={field}>
      <InputRadio
        field={field}
        value={value}
        change={change}
        options={options}
        type={'horizontal'}
        margin={'1.25rem 0 0 0'}
        disabled={disabled}
      />
    </Label>
  )
}

export default Radio
