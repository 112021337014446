import { useEffect } from 'react'
import useActions from '_shared/hooks/useActions'
import useForm from '_shared/hooks/useForm'
import useStatus from '_shared/hooks/useStatus'
import useNav from '_shared/hooks/useNav'
import useDependencies from '_shared/hooks/useDependencies'

import { useParams } from 'react-router-dom'

const matchKeys = (match, params) => {
  return Object.keys(match)
    .reduce((acc, cur) => {
      return (params[cur] && params[cur] === match[cur])
    }, false)
}

/*
  unique = {
    external_id: [id, id,...]
  }
*/

const getUniqueValues = (collection, key) => {
  return collection.map(record => record[key] ? record[key] : null)
}

const useFormController = (
  data_spec,
  valid_spec,
  handlers,
  permissions_key,
  mount_key,
  match,
) => {
  const unique = {}

  useDependencies(handlers.store, data => {
    if (handlers.unique_key) {
      unique[handlers.unique_key] = getUniqueValues(data, handlers.unique_key)
    }
  })

  const {
    current,
    valid,
    allValid,
    changed,
    reset,
    update,
    setOverride,
  } = useForm(data_spec, valid_spec, {}, unique)

  const [
    state,
    dispatch,
  ] = useStatus()

  const {
    permissions,
    load,
    save,
    remove,
    cancel,
  } = useActions(handlers, permissions_key, state.status, dispatch, current)

  const {
    close,
  } = useNav(mount_key)

  const params = useParams()

  const wrappedLoad = async (...parameters) => {
    const record = await load(...parameters)

    reset(record)
  }

  useEffect(() => {
    let matched

    if (match) {
      matched = matchKeys(match, params) && params[mount_key[mount_key.length - 1]]
    } else {
      matched = Array.isArray(mount_key) ? params[mount_key[mount_key.length - 1]] : params[mount_key]
    }

    if (matched) {
      if (matched === `new_${handlers.store}`) {
        wrappedLoad()
      } else {
        wrappedLoad(matched)
      }
    }
  }, [params])

  const wrappedSave = async () => {
    const output = handlers.decorateSave ? handlers.decorateSave(current) : current

    await save(output)
    close()
  }

  const wrappedCancel = () => {
    cancel()
    close()
  }

  return {
    current,
    status: state.status,
    valid,
    allValid,
    changed,
    permissions,
    load: wrappedLoad,
    save: wrappedSave,
    cancel: wrappedCancel,
    remove,
    update,
    setOverride,
  }
}

export default useFormController
