import React from 'react'

import TimeRange from '../TimeRange'

const DateService = ({
  data,
  change,
}) => {
  return (
    <TimeRange
      fieldFrom={'service-time-start'}
      fieldTo={'service-time-end'}
      label={'Service'}
      data={data}
      change={change}
    />
  )
}

export default DateService
