import React, { useCallback, useState, useRef } from 'react'

import { StatefulContainer } from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import DropDown from '_shared/components/element/DropDown'
import GradientButton from '_shared/components/element/GradientButton'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import VerticalArrowIndicator from '_shared/components/element/VerticalArrowIndicator'

import useOnClickOutside from '_shared/hooks/useOnClickOutside'
import useParentDistance from '_shared/hooks/useParentDistance'

const InputSelect = ({
  field,
  placeholder,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  change,
  options = [],
  minHeight = '2.5rem',
  controlled = false,
  lockToWindow = false,
  ...style
}) => {
  const container = useRef(null)

  const [
    open,
    setOpen,
  ] = useState(false)

  const forceClose = useCallback(event => {
    setOpen(false)
  }, [])

  useOnClickOutside(container, forceClose)

  const {
    maxHeight,
    calcMaxHeight,
  } = useParentDistance(container, lockToWindow)

  const handleChange = (value) => {
    setOpen(false)

    change(field, value)
  }

  const handleOpen = useCallback((event) => {
    event.preventDefault()

    calcMaxHeight()

    setOpen(!open)
  }, [open, calcMaxHeight])

  const found = options.find(option => option.value === value)

  const title = found ? found.label : placeholder

  return (
    <StatefulContainer
      focused={open}
      status={status}
      required={required}
      requiredText={requiredText}
      getRef={container}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <GradientButton
        focus={event => event.stopPropagation()}
        blur={event => event.stopPropagation()}
        change={handleOpen}
        disabled={disabled}
        width={'100%'}
        padding={'0 1rem'}
      >
        <InlineTextBox truncate>{title}</InlineTextBox>
        <VerticalArrowIndicator open={open} absRight={'0.5rem'} />
      </GradientButton>
      {open && (
        <DropDown
          options={options.filter(option => option.disabled !== true)}
          change={handleChange}
          value={value}
          cancel={forceClose}
          maxHeight={maxHeight}
        />
      )}
    </StatefulContainer>
  )
}

const InputSelectWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputSelect {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputSelect {...props} />
  )
}

export default InputSelectWrapped

export {
  Raw,
}
