import React from 'react'

import Button from '_shared/components/element/Button'
import Tick from '_shared/components/element/Tick'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import StyleWrapper from '_shared/style/StyleWrapper'

const InputCheckBox = ({
  title,
  field,
  value,
  change,
  ...style
}) => {
  const handleChange = () => change(field, !value)

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <Button rawStyle={{ ...styling, justifyContent: 'flex-start' }} change={handleChange}>
            <Tick active={Boolean(value)} />
            <InlineTextBox margin={'0 0 0 0.5rem'}>{title}</InlineTextBox>
          </Button>
        )
      }}
    />
  )
}

InputCheckBox.styleable = true

export default InputCheckBox
