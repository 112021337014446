import React, { useState } from 'react'

import Area from '_shared/components/layout/Area'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import Heading from '_shared/components/layout/Heading'

import modules from './modules'

// Return an area definition for an item
const parseFields = (fields) => {
  console.log('FIELDS', fields)
  let current = 0

  const areas = []

  fields.forEach((field, index) => {
    if (!areas[current]) areas.push([])

    if (field.type === 'block') {
      if (areas[current].length === 0) {
        areas[current] = [`${field.field}${index}`, `${field.field}${index}`]
      } else {
        if (areas[current].length < 2) areas[current].push('.')
        areas.push([`${field.field}${index}`, `${field.field}${index}`])
      }

      current += 1
    } else {
      if (areas[current].length === 2) {
        areas.push([`${field.field}${index}`])

        current += 1
      } else {
        areas[current].push(`${field.field}${index}`)
      }
    }
  })

  console.log('AREAS', areas)

  return areas.map(area => {
    if (area.length === 1) {
      area.push('.')
    }

    return area.join(' ')
  })
}

const Extension = (props) => {
  const {
    config,
    value = {},
    update,
  } = props

  const {
    block_title,
    fields,
  } = config

  const [
    data,
    setData,
  ] = useState(fields.reduce((form, field) => {
    if (field.type !== 'block') {
      form[field.field] = value[field.field] || field.default
    }

    return form
  }, {}))

  const updateInternal = (field, value) => {
    const output = {...data}

    output[field] = value

    setData(output)

    update && update('extensions', output)
  }

  return (
    <Area
      areas={[
        'section',
        'chooser',
      ]}
      columns={1}
      rowgap={1.5}
    >
      <SectionWrapper area={'section'}>
        <Heading level={1} title={block_title} />
      </SectionWrapper>
      <Area
        area={'chooser'}
        areas={parseFields(fields)}
        columns={2}
        rowgap={1.5}
        colgap={1}
      >
        {fields.map(({ field, type, options, ...rest }, index) => {
          const Module = modules[type]

          return (
            <Module key={field} {...rest} area={`${field}${index}`} field={field} value={data[field]} options={options} update={updateInternal} />
          )
        })}
      </Area>
    </Area>
  )
}

const Launcher = (props) => {
  const {
    form_extension,
  } = window.TICKNOVATE_CONFIG

  if (!form_extension[props.config]) return null

  return (
    <Extension {...props} config={form_extension[props.config]} />
  )
}

export default Launcher
