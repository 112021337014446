import React from 'react'

import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'
import Heading from '_shared/components/layout/Heading'
import Tick from '_shared/components/element/Tick'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import ListTable from '_shared/components/navigation/ListTable'

import RouteIcon from '_shared/icons/Route'
import OutboundIcon from '_shared/icons/Outbound'
import ReturnIcon from '_shared/icons/Return'

import { getLegSchema } from './Context'

import useLocale from '_shared/hooks/useLocale'
import mapToSchema from '_shared/libs/mapToSchema'

import Config from 'libs/Config'

const addHashesToRoutes = (routes) => {
  return routes.map(route => {
    const {
      legs,
    } = route

    const hash = JSON.stringify(legs
      .flatMap(({ departure_location, arrival_location }) => [departure_location.entity_id, arrival_location.entity_id]))

    return {
      hash,
      ...legs,
    }
  })
}

const generateDirectRoutes = ({ locales, currencies, locations }, record) => {
  const hashes = record.map(item => item.hash)

  const generateLeg = mapToSchema(locales, currencies, getLegSchema)

  const output = locations
    .reduce((routes, { hash, departure_location, arrival_location }) => {
      const exists = routes.find(route => route.data.return.hash === hash)

      if (!exists) {
        const returnHash = JSON.stringify([arrival_location.entity_id, departure_location.entity_id])

        routes.push({
          meta: {
            departTitle: departure_location.title,
            arriveTitle: arrival_location.title,
          },
          data: {
            outbound: {
              hash,
              sell: hashes.includes(hash), // This value denotes whether it is saved
              legs: [
                generateLeg({
                  departure_location: {
                    entity_id: departure_location.entity_id,
                  },
                  arrival_location: {
                    entity_id: arrival_location.entity_id,
                  },
                  locales: [],
                }),
              ],
            },
            return: {
              hash: returnHash,
              sell: hashes.includes(returnHash), // Same as above
              legs: [
                generateLeg({
                  departure_location: {
                    entity_id: arrival_location.entity_id,
                  },
                  arrival_location: {
                    entity_id: departure_location.entity_id,
                  },
                  locales: [],
                }),
              ],
            },
          },
        })
      }

      return routes
    }, [])

  return output
}

const DirectRouteSelector = ({
  loading,
  data,
  current,
  change,
}) => {
  const {
    locales,
    currencies,
  } = useLocale()

  const routes = addHashesToRoutes(current.routes)
  const options = generateDirectRoutes({
    locales,
    currencies,
    locations: data.mapped,
  }, routes)

  const handleChange = ({ hash, legs }) => {
    const state = [...current.routes]

    const index = routes.findIndex(route => route.hash === hash)

    if (index === -1) {
      state.push({
        legs,
      })
    } else {
      state.splice(index, 1)
    }

    change([
      {
        field: 'routes',
        value: state,
      },
    ])
  }

  const mutatedData = options
    .map((route, row) => {
      return {
        id: row,
        title: (
          <Row noFlex>
            <InlineTextBox>{route.meta.departTitle}</InlineTextBox>
            <RouteIcon fill={Config.theme.text} size={20} />
            <InlineTextBox>{route.meta.arriveTitle}</InlineTextBox>
          </Row>
        ),
        sell_outbound: (
          <ActionSell active={route.data.outbound.sell} change={() => handleChange(route.data.outbound)} />
        ),
        sell_return: (
          <ActionSell outbound={false} active={route.data.return.sell} change={() => handleChange(route.data.return)} />
        ),
      }
    })

  return (
    <Area columns={1} rowgap={1}>
      <Heading title={'Select direct routes to sell'} level={2} />
      <ListTable
        loading={loading}
        columns={[
          {
            key: 'title',
            label: 'Route',
          },
          {
            key: 'sell_outbound',
            label: 'Sell Route',
          },
          {
            key: 'sell_return',
            label: 'Sell Reverse',
          },
        ]}
        data={mutatedData}
      />
    </Area>
  )
}

const ActionSell = ({
  outbound = true,
  active,
  change,
}) => {
  const Icon = outbound  ? OutboundIcon : ReturnIcon

  return (
    <Row noFlex>
      <Icon fill={Config.theme.text} size={20} />
      <Tick active={active} change={change} />
      <InlineTextBox>{'Sell'}</InlineTextBox>
    </Row>
  )
}

export default DirectRouteSelector
