import React from 'react'

import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import ActionCTA from '_shared/components/action/ActionCTA'

const ConfirmForm = ({
  title = 'Confirm deletion',
  text,
  cancel,
  confirm,
  confirmText = 'Confirm deletion',
  cancelText = 'Cancel deletion',
}) => {
  return (
    <Area
      areas={[
        'title title',
        'text text',
        'cancel confirm',
      ]}
      columns={2}
      rowgap={1.5}
      colgap={1}
      padding={'4rem 2rem 2rem 2rem'}
    >
      <Heading level={1} title={title} area={'title'} />
      <TextBox strong noMargin area={'text'}>{text}</TextBox>
      <ActionCTA area={'cancel'} label={cancelText} change={cancel} type={'unimportant'} width={'100%'} />
      <ActionCTA area={'confirm'} label={confirmText} change={confirm} type={'caution'} width={'100%'} />
    </Area>
  )
}

export default ConfirmForm
