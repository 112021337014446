const VIEWER = {
  admin: true,
  owner: true,
  viewer: true,
  editor: true,
  billing: true,
}

const EDITOR = {
  admin: true,
  owner: true,
  editor: true,
  viewer: false,
  billing: false,
}

const ADMIN = {
  admin: true,
  owner: true,
  viewer: false,
  editor: false,
  billing: false,
}

export default {
  // "<RESOURCE>:<ACTION>:<MODIFIER>"
  'cart:override_fees': ADMIN,
  'cart:read': EDITOR,
  'order_email:send': EDITOR,
  'order_history:read': VIEWER,
  'order:read': VIEWER,
  'payment:pay:internal_cash': EDITOR,
  'payment:pay:internal_generic': EDITOR,
  'payment:pay:ocius_card': EDITOR,
  'payment:read': VIEWER,
  'payment:refund:internal_cash': EDITOR,
  'payment:refund:internal_generic': EDITOR,
  'payment:refund:ocius_card': EDITOR,
  'voucher:read': VIEWER,
  'voucher:use': EDITOR,
}
