import React from 'react'

import {
  getSpec,
  valid as valid_spec,
  handlers,
} from './Context'

import { StateTabbedHeader as TabbedHeader }  from '_shared/components/navigation/TabbedHeader'

import FormDescription from './FormDescription'
import FormTransfer from './FormTransfer'
import FormExtension from './FormExtension'

import SlideForm from 'templates/SlideForm'

import { useGetLocalValueFunc } from '_shared/hooks/useGetLocaleValue'

const options = [
  {
    label: 'Description',
    view: FormDescription,
    slug: 'description',
  },
  {
    label: 'Transfer',
    view: FormTransfer,
    slug: 'transfer',
    status: 'DEFAULT',
  },
  {
    label: 'Integrations',
    view: FormExtension,
    slug: 'integrations',
    status: 'DEFAULT',
  },
]

const Consumer = (props) => {
  const getTitle = useGetLocalValueFunc('title')

  return (
    <SlideForm
      options={options}
      data_spec={getSpec}
      valid_spec={valid_spec}
      handlers={handlers}
      mountKey={'record'}
      permissionsKey={'locations'}
      render={({
        current,
        valid,
        update,
        updateSingle,
      }) => {
        const title = getTitle(current)

        options[1].status = current.entity_id !== null ? 'DEFAULT' : 'DISABLED'

        return (
          <TabbedHeader
            title={`Location & Station${title !== null ? `: ${title}` : ''}`}
            options={options}
            passProps={{
              current,
              valid,
              update,
              updateSingle,
            }}
          />
        )
      }}
    />
  )
}

export default Consumer
