import React from 'react'

import styles from './row.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

import styleCombine from '_shared/libs/styleCombine'

const justifyMap = {
  centered: 'center',
  spaced: 'space-between',
  start: 'flex-start',
  end: 'flex-end',
}

const Row = ({ children, type = 'start', spread = true, ...style }) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        const layout = {
          ...styling,
          justifyContent: justifyMap[type],
        }

        return (
          <div className={styleCombine(styles.layout, spread && styles.spread)} style={layout}>
            {children}
          </div>
        )
      }}
    />
  )
}

Row.styleable = true

export default Row
