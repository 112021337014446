const measureElement = 'SPAN'

const clamp = (element, lineClamp, keep) => {
  let measure
  let lineWidth
  let lineStart
  let lineCount
  let wordStart
  let line
  let lineText
  let wasNewLine

  // measurement element is made a child of the clamped element to get it's style
  measure = document.createElement(measureElement)

  ;((style) => {
    style.position = 'absolute' // prevent page reflow
    style.whiteSpace = 'pre' // cross-browser width results
    style.visibility = 'hidden' // prevent drawing
  })(measure.style)

  // reset to safe starting values
  lineStart = wordStart = 0
  lineCount = 1
  wasNewLine = false

  // Updated - Remove the left and right padding values
  lineWidth = parseInt(element.offsetWidth)

  const previousHolder = null // element.getAttribute('data-text')

  let text
  let textHold

  if (previousHolder !== null && previousHolder !== '') {
    text = previousHolder

    textHold = text
  } else {
    text = (element.textContent || element.innerText).replace(/\n/g, ' ')

    element.setAttribute('data-text', text)

    textHold = text
  }
  // get all the text, remove any line changes
  // const text = previousHolder !== null && previousHolder !== '' ? previousHolder : (element.textContent || element.innerText).replace(/\n/g, ' ')

  // const textHold = text

  // if (typeof keep !== 'undefined' && keep && (previousHolder === null || previousHolder === '')) element.setAttribute('data-text', text)

  // remove all content
  while (element.firstChild !== null) element.removeChild(element.firstChild)

  element.appendChild(measure)
  // add measurement element within so it inherits styles
  // http://ejohn.org/blog/search-and-dont-replace/
  text.replace(/ /g, function (m, pos) {
    // ignore any further processing if we have total lines
    if (lineCount === lineClamp) return
    // create a text node and place it in the measurement element
    measure.appendChild(document.createTextNode(text.substr(lineStart, pos - lineStart)))
    // have we exceeded allowed line width?

    if (lineWidth < measure.clientWidth) {
      if (wasNewLine) {
        // we have a long word so it gets a line of it's own
        lineText = text.substr(lineStart, pos + 1 - lineStart)
        // next line start position
        lineStart = pos + 1
      } else {
        // grab the text until this word
        lineText = text.substr(lineStart, wordStart - lineStart)
        // next line start position
        lineStart = wordStart
      }
      // create a line element
      line = document.createElement(measureElement)
      // add text to the line element
      line.appendChild(document.createTextNode(lineText))
      // add the line element to the container
      element.appendChild(line)
      // yes, we created a new line
      wasNewLine = true
      lineCount++
    } else {
      // did not create a new line
      wasNewLine = false
    }
    // remember last word start position
    wordStart = pos + 1
    // clear measurement element
    measure.removeChild(measure.firstChild)
  })
  // remove the measurement element from the container
  element.removeChild(measure)

  if (lineCount < lineClamp) {
    element.textContent = textHold
  } else {
    // create the last line element
    line = document.createElement(measureElement)
    // give styles required for text-overflow to kick in
    ;(function (style) {
      style.display = 'inline-block'
      style.overflow = 'hidden'
      style.textOverflow = 'ellipsis'
      style.whiteSpace = 'nowrap'
      style.width = '100%'
    })(line.style)
    // add all remaining text to the line element
    line.appendChild(document.createTextNode(text.substr(lineStart)))
    // add the line element to the container
    element.appendChild(line)
  }
}

export default clamp
