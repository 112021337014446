import React, { Fragment, useState, useEffect, useRef } from 'react'

import Row from '_shared/components/layout/Row'
import Container from '_shared/components/layout/Container'
import RoundedTab from '_shared/components/element/RoundedTab'
import ListTable from '_shared/components/navigation/ListTable'

import { Raw as InputPriceAdjust, options } from '_shared/components/input/InputPriceAdjust'
import Tick from '_shared/components/element/Tick'
import ActionOptionUtility from '_shared/components/action/ActionOptionUtility'

import useLocale from '_shared/hooks/useLocale'
import useDependencies from '_shared/hooks/useDependencies'

import channel_map from 'config/channels'

import deepSort from 'libs/deepSort'

import { update } from '_shared/libs/mapToSchema'

import sortBy from 'lodash/sortBy'

import {
  generateEffects,
  filterByTicket,
  filterValidEffects,
  hasMarketData,
} from './utils'

import { getDeepLocale } from '_shared/libs/nestedDataHelpers'

const channels = [1, 2, 3, 4, 5, 6, 7]

const fieldStyle = {
  flex: '0 0 auto',
  minHeight: '1.5rem',
}

const PriceAdjustMatrix = ({
  tickets = [],
  markets = [],
  routes = [],
  field,
  change,
  value,
  activeRoute,
  ticketMode = 'per_ticket', // 'all' | 'per_ticket'
}) => {
  const { default_locale } = useLocale()

  const [ market, setMarket ] = useState(null)

  const { data: ticket_groups } = useDependencies('ticket_groups')

  const ticket_list = tickets.map(ticket => ticket.entity_id)

  const ticket_map = tickets.reduce((acc, record) => {
    const title = getDeepLocale(record.locales, 'title', default_locale)

    const ticket_group = ticket_groups.find(group => group.entity_id === record.ticket_type_group.entity_id)

    const group = ticket_group ? `${getDeepLocale(ticket_group.locales, 'title', default_locale)}: ` : ''

    acc[record.entity_id] = `${group}${title}`

    return acc
  }, {})

  const market_options = sortBy(markets.map(market => ({
    value: market.entity_id,
    label: market.title,
  })), ['label'])

  const currency_options = markets
    .reduce((acc, cur) => {
      acc[cur.entity_id] = cur.currencies[0].id

      return acc
    }, {})

  // Generate effects for the matrix
  const effects = generateEffects({
    channels,
    markets: market_options,
    tickets: ticket_list,
    routes,
    data: value,
  })

  // If market options change, reset current selected market
  useEffect(() => {
    if (market_options.length > 0) {
      const exists = market_options.find(({ value }) => value === market)

      if (!exists) {
        setMarket(market_options[0].value)
      }
    }
  }, [markets])

  // Reset all effects that have the current market_id to the new type
  const handleChangeAll = (field, value) => {
    const list = effects
      .filter(effect => effect.market_id === market)

    const state = [...effects]

    list.forEach(({ index }) => {
      update(state, `[${index}]value`, 0)
      update(state, `[${index}]type`, value)
    })

    change(
      field,
      state,
    )
  }

  // Find out which markets have any active values
  const marketsPopulated = hasMarketData({
    markets: markets.map(({ entity_id }) => entity_id),
    activeRoute,
    effects,
  })

  return (
    <Container>
      <Row type={'spaced'}>
        <MarketChooser
          selected={market}
          options={market_options}
          change={setMarket}
          status={marketsPopulated}
        />
        {ticketMode === 'per_ticket' && (
          <ActionOptionUtility
            field={market}
            label={'Make all'}
            options={options}
            value={null}
            change={handleChangeAll}
          />
        )}
      </Row>
      <AdjustTicket
        field={field}
        effects={effects}
        market={market}
        change={change}
        ticket_map={ticket_map}
        currency_options={currency_options}
        activeRoute={activeRoute}
        perTicket={ticketMode === 'per_ticket'}
      />
    </Container>
  )
}

// Placeholder for future row deletion feature
const kill_efffects = true

// Flatten out current selected effects for modification
const getCurrentEffects = (flattened) => {
  return Object.keys(flattened)
    .reduce((acc, cur) => {
      return [...acc, ...flattened[cur]]
    }, [])
}

// On load get the initial state of effects that have a value set for all channels
const getInitialChannelRows = (effects) => {
  const channel_effects = filterValidEffects(effects)
    .filter(({ channel }) => channel === null)
    .map(({
      market_id,
      ticket_type_id,
      location_id,
      end_location_id,
    }) => `${market_id}|${ticket_type_id}|${location_id === null ? 'all' : location_id}|${end_location_id === null ? 'all' : end_location_id}`)

  return channel_effects
}

const AdjustTicket = ({
  field,
  effects,
  market,
  change,
  ticket_map,
  currency_options,
  activeRoute = {
    depart: null,
    arrive: null,
  },
  perTicket = true,
}) => {
  let depart = null
  let arrive = null

  if (activeRoute) {
    depart = activeRoute.depart
    arrive = activeRoute.arrive
  }

  const flattened = filterByTicket({
    effects,
    market,
    depart,
    arrive,
    perTicket,
  })

  const [ channelRows, setChannelRows ] = useState(getInitialChannelRows(effects))

  const [ effectRows, setEffectRows ] = useState([])

  const emptyEffectOnce = useRef(effects.length)

  /*
    If the top level data is resetting, this can result in stale data for channelRows
    Ensure that this only happens once and reset the channel rows
  */
  useEffect(() => {
    if (effects.length !== emptyEffectOnce.current) {
      setChannelRows(getInitialChannelRows(effects))
      emptyEffectOnce.current = effects.length
    }
  }, [effects])

  const updateRecord = (state) => {
    change(
      field,
      state,
    )
  }

  // Update the value or type for an effect
  const handleChange = (field, value) => {
    const [
      index, // Index of effect (from generateEffects)
      field_type, // Type of modification, type || value
    ] = field.split('|')

    const state = [...effects]

    update(state, `[${index}]${field_type}`, value)

    updateRecord(state)
  }

  // Set the selected row to all or per channel
  const handleSetChannel = (row) => {
    const state = [...channelRows]

    const [
      market_id,
      ticket_id,
      depart,
      arrive,
    ] = row.split('|')

    let list = []

    const depart_filter = depart === 'all' ? null : depart
    const arrive_filter = arrive === 'all' ? null : arrive

    const effects_list = getCurrentEffects(flattened)

    if (state.includes(row)) {
      const index = state.findIndex(index => index === row)

      state.splice(index, 1)

      list = effects_list
        .filter(effect => (
          effect.ticket_type_id === ticket_id &&
          effect.market_id === market_id &&
          effect.channel === null &&
          effect.location_id === depart_filter &&
          effect.end_location_id === arrive_filter
        ))
    } else {
      state.push(row)

      list = effects_list
        .filter(effect => (
          effect.ticket_type_id === ticket_id &&
          effect.market_id === market_id &&
          effect.channel !== null &&
          effect.location_id === depart_filter &&
          effect.end_location_id === arrive_filter
        ))
    }

    const reset = [...effects]

    list.forEach(({ index }) => {
      update(reset, `[${index}]value`, 0)
      update(reset, `[${index}]type`, 'increment_percent')
    })

    updateRecord(reset)

    setChannelRows(state)
  }

  // Null out all values in the current row, currently not used
  const handleSetEffect = (row) => {
    const state = [...effectRows]

    if (state.includes(row)) {
      const index = state.findIndex(index => index === row)

      state.splice(index, 1)
    } else {
      const [
        market_id,
        ticket_id,
        depart,
        arrive,
      ] = row.split('|')

      const depart_filter = depart === 'all' ? null : depart
      const arrive_filter = arrive === 'all' ? null : arrive

      state.push(row)

      const effects_list = getCurrentEffects(flattened)

      const list = effects_list
        .filter(effect => (
          effect.ticket_type_id === ticket_id &&
          effect.market_id === market_id &&
          effect.location_id === depart_filter &&
          effect.end_location_id === arrive_filter
        ))

      const reset = [...effects]

      list.forEach(({ index }) => {
        update(reset, `[${index}]value`, null)
        update(reset, `[${index}]type`, 'NA')
      })

      updateRecord(reset)
    }

    setEffectRows(state)
  }

  // Change all types in a row to the same value
  const handleChangeRow = (field, value) => {
    const [
      market_id,
      ticket_id,
    ] = field.split('|')

    const effects_list = getCurrentEffects(flattened)

    const list = effects_list
      .filter(effect => (
        effect.ticket_type_id === ticket_id &&
        effect.market_id === market_id &&
        effect.location_id === depart &&
        effect.end_location_id === arrive &&
        effect.channel !== null
      ))

    const state = [...effects]

    list.forEach(({ index }) => {
      update(state, `[${index}]value`, 0)
      update(state, `[${index}]type`, value)
    })

    updateRecord(state)
  }

  // Create the data for the current matrix view
  const rows = Object.keys(flattened)
    .reduce((acc, ticket_type_id) => {
      const current = flattened[ticket_type_id]

      const channelIndex = `${market}|${ticket_type_id}|${depart === null ? 'all' : depart}|${arrive === null ? 'all' : arrive}`

      const allChannelsField = current.find(({ channel }) => channel === null)

      acc.push({
        effect: (
          <>
            {!perTicket ? 'all tickets' : ticket_map[ticket_type_id]}
            {!kill_efffects && (
              <Tick active={!effectRows.includes(channelIndex)} change={() => handleSetEffect(channelIndex)} />
            )}
          </>
        ),
        switch: effectRows.includes(channelIndex) ? '' : (
          <Tick active={!channelRows.includes(channelIndex)} change={() => handleSetChannel(channelIndex)} />
        ),
        cost: effectRows.includes(channelIndex) ? '' : (
          <InputPriceAdjust
            key={channelIndex}
            value={'0.0'}
            field={`${market}|${ticket_type_id}|cost`}
            type_field={`${market}|${ticket_type_id}|type`}
            type={'set_fixed'}
            change={handleChange}
            {...fieldStyle}
            width={'6rem'}
            disabled
            currency={currency_options[market]}
          />
        ),
        all: effectRows.includes(channelIndex) ? '' : (
          <InputPriceAdjust
            key={channelIndex}
            value={!channelRows.includes(channelIndex) ? null : allChannelsField.value}
            field={`${allChannelsField.index}|value`}
            type_field={`${allChannelsField.index}|type`}
            type={!channelRows.includes(channelIndex) ? 'NA' : allChannelsField.type}
            change={handleChange}
            {...fieldStyle}
            width={'6rem'}
            disabled={!channelRows.includes(channelIndex)}
            currency={currency_options[market]}
          />
        ),
        ...deepSort(current, 'channel')
          .reduce((acc, item) => {
            if (channelRows.includes(channelIndex) || effectRows.includes(channelIndex)) {
              acc[item.channel] = ''
            } else {
              const baseField = `${item.index}`

              acc[item.channel] = (
                <InputPriceAdjust
                  key={baseField}
                  value={item.value}
                  field={`${baseField}|value`}
                  type_field={`${baseField}|type`}
                  type={item.type}
                  change={handleChange}
                  {...fieldStyle}
                  width={'6rem'}
                  disabled={channelRows.includes(channelIndex)}
                  currency={currency_options[market]}
                />
              )
            }

            return acc
          }, {}),
        row: channelRows.includes(channelIndex) || effectRows.includes(channelIndex) ? '' : (
          <ActionOptionUtility
            field={channelIndex}
            label={'Row'}
            options={options}
            value={null}
            change={handleChangeRow}
          />
        ),
      })

      return acc
    }, [])

  return (
    <ListTable
      columns={[
        {
          key: 'effect',
          label: 'Ticket Type',
        },
        {
          key: 'cost',
          label: 'Cost Price',
        },
        {
          key: 'switch',
          label: 'By Channel',
        },
        {
          key: 'all',
          label: 'All Channels',
        },
        ...channels.sort()
          .map(channel => ({
            key: channel,
            label: channel_map[channel],
          })),
        {
          key: 'row',
          label: '',
        },
      ]}
      data={rows}
    />
  )
}

const MarketChooser = ({
  selected = null,
  options,
  change,
  status,
}) => {
  return (
    <Row type={'start'} spread={false}>
      {options.map(option => {
        return (
          <RoundedTab
            key={option.value}
            label={option.label}
            status={status[option.value] ? 'VALID' : 'DEFAULT'}
            active={selected === option.value}
            action={() => change(option.value)}
            margin={'0 0 0 0.25rem'}
          />
        )
      })}
    </Row>
  )
}

export default PriceAdjustMatrix
