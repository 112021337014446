import React from 'react'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import Navigator from '../Navigator'

import AppStatus from '../AppStatus'

import Routes from './Routes'

import ToastManager from '_shared/components/notification/Toasts'

import { ReactQueryDevtools } from 'react-query/devtools'

const Main = () => {
  return (
    <Router>
      <AppStatus>
        <Navigator />
        <Routes />
        <div id={'full'} />
        <div id={'slide'} />
        <div id={'portal'} />
        <ToastManager />
      </AppStatus>
      <ReactQueryDevtools initialIsOpen={false} />
    </Router>
  )
}

export default Main
