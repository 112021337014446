import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import { Localised as InputRTELocalised } from '_shared/components/input/InputRTE'

import InfoBox from '_shared/components/notification/InfoBox'

import CapacityAreas from './CapacityAreas'

const FormDescription = ({
  current,
  save,
  updateSingle,
}) => {
  return (
    <FormView>
      <Area
        areas={[
          'title . info',
          'description/2 info',
          'define',
        ]}
      >
        <InfoBox
          area={'info'}
          title={'Service templates'}
          content={'Editing this will not impact any live departures.'}
        />
        <InputTextLocalised
          area={'title'}
          title={'Layout name'}
          field={'title'}
          domain={'capacity_layout.locales'}
          value={current.capacity_layout.locales}
          placeholder={'E.g Full Capacity'}
          change={updateSingle}
        />
        <InputRTELocalised
          area={'description'}
          title={'Description'}
          field={'description'}
          domain={'capacity_layout.locales'}
          value={current.capacity_layout.locales}
          placeholder={'Description of the layout'}
          change={updateSingle}
        />
      </Area>
      <CapacityAreas
        area={'define'}
        field={'capacity_layout.areas'}
        data={current.capacity_layout.areas}
        change={updateSingle}
      />
    </FormView>
  )
}

export default FormDescription
