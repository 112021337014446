import React, { Fragment } from 'react'

import styles from './modalbutton.scss'

import Button from 'atoms/Button'

import LockedBadge from 'atoms/LockedBadge'

import styleCombine from 'libs/styleCombine'

const allowed = [
  'red',
  'blue',
  'green',
  'dark',
  'disabled',
  'create',
  'save',
  'cancel',
  'delete',
  'warning',
]

/**
 * @description
 * A button component that can be different colors
 *
 * @param {String} [color] One of 5 allowed colors: red, blue, green, disabled
 * @param {Any} [content] Any valid text string or react component for the label of the button
 * @param {Function} [onClick] Callback when button is clicked
*/

const ModalButton = (props) => {
  const {
    color,
    content,
    onClick,
    styling,
    locked = false,
  } = props

  let style = color && allowed.includes(color) ? styles[`button_${color}`] : styles.button_blue

  let Inner = (
    <span>{content}</span>
  )

  if (locked) {
    style = styles.button_disabled

    Inner = (
      <Fragment>
        <span>{content}</span>
        <LockedBadge styling={styles.locked} />
      </Fragment>
    )
  }

  return (
    <Button
      styling={styleCombine(style, styling)}
      content={Inner}
      onClick={onClick}
    />
  )
}

export default ModalButton
