import React from 'react'

import styles from './error.scss'

import TextBox from 'containers/TextBox'

const ErrorMessage = (props) => {
  const {
    message,
    show,
  } = props

  if (!show) return null

  return (
    <TextBox styling={styles.error}>
      {`* ${message}`}
    </TextBox>
  )
}

export default ErrorMessage
