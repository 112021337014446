import storage from 'libs/asyncStorage'

import loginApi from 'api/login'

const refreshToken = () => (dispatch, getState) => {
  return storage.all()
    .then(data => {
      const {
        refreshToken,
        username,
      } = data

      if (refreshToken === null && username !== null) {
        throw new Error('No token available')
      } else {
        return loginApi.login({
          refreshToken,
          username,
        })
          .then(response => {
            return response
          })
          .catch(error => {
            throw error
          })
      }
    })
    .catch(error => {
      throw error
    })
}

const setTokens = (data) => (dispatch, getState) => {
  const {
    accessToken,
    refreshToken,
    idToken,
    expiry,
  } = data

  const lastReset = Date.now() + (expiry * 1000)

  return storage.all()
    .then(store => {
      const data = {
        ...store,
        ...(refreshToken ? {refreshToken} : {}),
        idToken,
        lastReset,
        token: accessToken,
      }

      return data
    })
    .then(data => {
      return storage.fill(data)
    })
    .catch(error => {
      throw error
    })
}

export {
  refreshToken,
  setTokens,
}
