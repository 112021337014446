import React, { Fragment, useRef, useCallback } from 'react'

import styles from './dropdownmulti.scss'

import Tick from '_shared/components/element/Tick'

import StyleWrapper from '_shared/style/StyleWrapper'

import styleCombine from '_shared/libs/styleCombine'

import useListNavigation from '_shared/hooks/useListNavigation'

import AddIcon from '_shared/icons/AddRound'
import RemoveIcon from '_shared/icons/RemoveRound'

import Config from 'libs/Config'

const manageCollection = (collection, value) => {
  const temp = [...collection]

  temp.includes(value) ? temp.splice(temp.indexOf(value), 1) : temp.push(value)

  return temp
}

const DropDownMulti = (props) => {
  const {
    options,
    change,
    value,
    cancel,
    focusOnMount = true,
    utility = false,
    ...style
  } = props

  const container = useRef(null)

  const allSelected = options.map(option => option.value).every(option => value.includes(option))

  const flipAll = useCallback(() => {
    change(allSelected ? [] : options.map(option => option.value))
  }, [change, options, allSelected])

  const keyChange = useCallback((focused) => {
    focused === 0 ? flipAll() : change(manageCollection(value, options[focused - 1].value))
  }, [change, options, flipAll, value])

  const {
    focused,
    handleKey,
  } = useListNavigation(container, 0, options, keyChange, cancel, focusOnMount)

  const Option = utility ? Utility : Normal

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div
            ref={container}
            className={styles.drop}
            style={styling}
            onKeyDown={handleKey}
            tabIndex={0}
          >
            <div
              className={styleCombine(styles.all, (focused === 0) && styles.focused)}
              onMouseDown={flipAll}
            >
              {allSelected ? 'Deselect All' : 'Select All'}
            </div>
            {options.map((option, index) => {
              const isFocused = (index + 1) === focused
              const isSelected = value.includes(option.value)

              const mod = isSelected ? styles.selected : isFocused ? styles.focused : null

              return (
                <div
                  key={option.value}
                  className={styleCombine(styles.row, mod, utility && styles.utility)}
                  onMouseDown={event => {
                    event.stopPropagation()

                    change(manageCollection(value, option.value))
                  }}
                >
                  <Option
                    label={option.label}
                    active={isSelected}
                  />
                </div>
              )
            })}
          </div>
        )
      }}
    />
  )
}

const Normal = ({
  active,
  label,
}) => {
  return (
    <Fragment>
      <Tick active={active} />
      <span className={styles.label}>{label}</span>
    </Fragment>
  )
}

const Utility = ({
  active,
  label,
}) => {
  return (
    <Fragment>
      <span>{label}</span>
      {!active && (
        <AddIcon fill={Config.theme.text_success} size={24} />
      )}
      {active && (
        <RemoveIcon fill={Config.theme.text_warning} size={24} />
      )}
    </Fragment>
  )
}

export default DropDownMulti
