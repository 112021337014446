import React, { Fragment } from 'react'

import styles from './table.scss'

import styleCombine from '_shared/libs/styleCombine'

/**
  * Base Table component
  *
  * Takes columns and data as props, is styleable
  *
  * @param {(Object)[]} columns
  * @param {Object} data
  * @param {Boolean} shadeAlternateRows
  * @param {Object} headRowStyle
  * @param {Object} bodyRowStyle
*/

/*
  columns = [
    {
      key: 'data mapping key',
      label: 'Column label',
      headStyle: {
        color: Config.theme.text_mid,
        fontSize: '0.75rem',
        textTransform: 'uppercase'
      }
    }
  ]
*/

/*
  const rowspans = {
    title: [
      {
        row: 0,
        span: 2
      }
    ]
  }
*/

import Config from 'libs/Config'

const generateIndexes = (spans, length) => {
  return Object.keys(spans)
    .reduce((mod, key) => {
      const current = spans[key]

      const map = [...Array(length).keys()]

      current.forEach(({ row, span }) => {
        const index = map.indexOf(row + 1)

        map.splice(index, span - 1)
      })

      mod[key] = map

      return mod
    }, {})
}

const makeSpanData = (spans, data) => {
  const rowSpans = []

  const indexes = generateIndexes(spans, data.length)

  const output = data.map((item, index) => {
    const line = {}

    Object.keys(item)
      .forEach(key => {
        if (indexes[key]) {
          if (indexes[key].includes(index)) {
            const rowData = spans[key].find(span => span.row === index)

            rowData && rowSpans.push({
              index,
              key,
              span: rowData.span,
            })

            line[key] = item[key]
          }
        } else {
          line[key] = item[key]
        }
      })

    return line
  })

  return {
    data: output,
    rowSpans,
  }
}

/*
  draggable: {
    onDragOver,
    onDragEnd
  }
*/
const Table = ({
  columns,
  data,
  shadeAlternateRows,
  headRowStyle,
  bodyRowStyle,
  rowSpans = [],
  draggable = null,
}) => {
  const RowType = draggable && draggable.active ? TableDragRow : TableRow

  return (
    <table className={styleCombine(styles.layout, shadeAlternateRows && styles.shaded)}>
      <thead>
        <tr style={headRowStyle}>
          {columns.map(({key, label, headStyle}) => {
            return (
              <th key={key} style={headStyle}>{label}</th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          const spans = rowSpans
            .filter(span => span.index === index)
            .reduce((acc, span) => {
              acc[span.key] = span.span
              return acc
            }, {})

          return (
            <RowType
              key={row.id || index}
              row={row}
              columns={columns}
              bodyRowStyle={bodyRowStyle}
              rowSpan={spans}
              draggable={draggable}
            />
          )
        })}
      </tbody>
    </table>
  )
}

const TableRow = ({
  row,
  columns,
  bodyRowStyle,
  rowSpan,
}) => {
  return (
    <Fragment>
      <tr style={bodyRowStyle}>
        {columns.map(({ key, colSpan = 1, bodyStyle }) => {
          if (!row.hasOwnProperty(key)) return null

          return (
            <td
              key={row._key || key}
              style={bodyStyle}
              colSpan={colSpan}
              rowSpan={rowSpan[key] || 1}
            >
              {row[key]}
            </td>
          )
        })}
      </tr>
    </Fragment>
  )
}

const TableDragRow = ({
  row,
  columns,
  bodyRowStyle,
  rowSpan,
  draggable,
}) => {
  const {
    drag,
    onDragOver,
    onDragStart,
    onDragEnd,
  } = draggable

  const styling = drag === row.id ? { ...bodyRowStyle, backgroundColor: Config.theme.row_highlight } : bodyRowStyle

  return (
    <Fragment>
      <tr
        draggable
        style={styling}
        onDragStart={onDragStart(row.id)}
        onDragEnd={onDragEnd(row.id)}
        onDragOver={onDragOver(row.id)}
      >
        {columns.map(({ key, colSpan = 1, bodyStyle }) => {
          if (!row.hasOwnProperty(key)) return null

          return (
            <td
              key={key}
              style={bodyStyle}
              colSpan={colSpan}
              rowSpan={rowSpan[key] || 1}
            >
              {row[key]}
            </td>
          )
        })}
      </tr>
    </Fragment>
  )
}

export default Table

export {
  makeSpanData,
}
