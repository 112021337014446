import React from 'react'

import './advanced.scss'

/**
 * Provides a templated CSS Grid container if set to advanced
 * @param {Number} cols - number of columns (1 - 12)
 * @param {Number} rows - number of rows (1 - 12)
 * @param {String} flow - 'row' or 'column'
 * @param {String} colcell - whether all cols are same size, 'fixed' or 'auto'
 * @param {String} rowcell - whether all rows are same size, 'fixed' or 'auto'
*/

const Grid = (props) => {
  const {
    children,
    cols = 1,
    rows = 1,
    flow = 'row',
    colcell = 'auto',
    rowcell = 'auto',
    colgap = 3,
    rowgap = 3,
    styling,
  } = props

  let classes = ['grid']

  classes.push(`col-${cols}_cell-${colcell}`)

  classes.push(`row-${rows}_cell-${rowcell}`)

  classes.push(`flow-${flow}`)

  classes.push(`col-gap_${colgap}`)

  classes.push(`row-gap_${rowgap}`)

  if (styling) classes.push(styling)

  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  )
}

export default Grid
