import Home from '_/components/views/Home'
import BuildingBlocks, { NavIcon as BlockIcon } from '_/components/views/BuildingBlocks'
import Services, { NavIcon as ServiceIcon } from '_/components/views/Services'
import Products, { NavIcon as ProductIcon } from '_/components/views/Products'
import Combos, { NavIcon as ComboIcon } from '_/components/views/Combo'
import Promos, { NavIcon as PromoIcon } from '_/components/views/Promo'
import Users, { NavIcon as UserAdminIcon } from '_/components/views/Users'
import Settings, { NavIcon as SettingsIcon } from '_/components/views/Settings'
import Reports, { NavIcon as ReportsIcon } from '_/components/views/Reports'

const routes = [
  {
    display: 'Home',
    type: 'nav',
    path: '/home',
    navTo: '/home',
    inNav: false,
    component: Home,
    icon: null,
  },
  {
    display: 'Building Blocks',
    type: 'nav',
    path: '/blocks/:view?:subview(-[a-z]+)?/:record?',
    navTo: '/blocks/tickets',
    inNav: true,
    component: BuildingBlocks,
    icon: BlockIcon,
  },
  {
    display: 'Services',
    type: 'nav',
    path: '/services/:record?/',
    navTo: '/services',
    inNav: true,
    component: Services,
    icon: ServiceIcon,
  },
  {
    display: 'Products',
    type: 'nav',
    path: '/products/:record?/',
    navTo: '/products',
    inNav: true,
    component: Products,
    icon: ProductIcon,
  },
  {
    display: 'Combos',
    type: 'nav',
    path: '/combos/:record?/',
    navTo: '/combos',
    inNav: true,
    component: Combos,
    icon: ComboIcon,
  },
  {
    display: 'Promos',
    type: 'nav',
    path: '/promos/:record?/',
    navTo: '/promos',
    inNav: true,
    component: Promos,
    icon: PromoIcon,
  },
  {
    display: 'User Admin',
    type: 'nav',
    path: '/users/:view?/:record?/',
    navTo: '/users/all',
    inNav: true,
    component: Users,
    icon: UserAdminIcon,
    capability: ['user'],
  },
  {
    display: 'Reports',
    type: 'nav',
    path: '/reports',
    navTo: '/reports',
    inNav: true,
    component: Reports,
    icon: ReportsIcon,
    capability: ['system_setting'],
  },
  {
    display: 'System Settings',
    type: 'nav',
    path: '/settings/:view?:subview(-[a-z]+)?/:record?',
    navTo: '/settings/localisation',
    inNav: true,
    component: Settings,
    icon: SettingsIcon,
    capability: ['system_setting'],
  },
]

export default routes
