import React from 'react'

import IconButton from '_shared/components/element/IconButton'

import RemoveIcon from '_shared/icons/RemoveRound'

const ActionDelete = ({ label, change, collapsed, disabled, ...style }) => {
  return (
    <IconButton
      icon={RemoveIcon}
      change={change}
      label={label}
      collapsed={collapsed}
      color={'text_warning'}
      disabled={disabled}
      {...style}
    />
  )
}

export default ActionDelete
