import React from 'react'

import {
  getSchema,
  valid as valid_spec,
  handlers,
} from './Context'

// import Chooser from '../../../templates/Chooser'

import SlideForm from 'templates/FullForm'

import Route from './Route'

// const text = {
//   title: 'Select a Product Type',
//   create: 'Add a new product',
//   warning: 'This cannot be changed later. Make sure you are selecting the correct one for the type of product you are trying to sell.',
//   journeyTitle: 'Journey Product',
//   journeyInfo: ['This product supports journey services that have a route.'],
//   eventTitle: 'Event Product',
//   eventInfo: ['This product supports event services']
// }

const Consumer = (props) => {
  return (
    <SlideForm
      data_spec={getSchema}
      valid_spec={valid_spec}
      handlers={handlers}
      mountKey={'record'}
      permissionsKey={'combos'}
      render={({
        cancel,
        current,
        valid,
        update,
        updateSingle,
      }) => {
        return (
          <Route
            cancel={cancel}
            current={current}
            valid={valid}
            update={update}
            updateSingle={updateSingle}
          />
        )
      }}
    />
  )
}

export default Consumer
