import React from 'react'

import styles from './iconbutton.scss'

// import Row from 'containers/Row'
import InlineTextBox from 'containers/InlineTextBox'

import colors from 'config/colors'

import styleCombine from 'libs/styleCombine'

import LockedBadge from 'atoms/LockedBadge'

const allowed = {
  brand: colors.color_brand,
  warning: colors.color_warning,
  caution: colors.color_delete,
  disabled: colors.color_disabled,
  negative: colors.color_text_light,
}

const IconButton = (props) => {
  const {
    type = 'brand',
    icon,
    label,
    action = null,
    locked,
    filled = false,
    styling,
  } = props

  let fill = allowed[type] ? allowed[type] : allowed.brand
  let text = locked ? 'label_disabled' : allowed[type] ? `label_${type}` : 'label_brand'
  let block = null

  if (filled) {
    block = locked ? 'negative_disabled' : allowed[type] ? `negative_${type}` : 'negative_brand'
    fill = allowed.negative
    text = 'label_negative'
  }

  const Icon = icon

  return (
    <button className={styleCombine(styles.layout, block && styles[block], styling)} tabIndex={0} onClick={!locked ? action : null}>
      {!locked && (
        <Icon
          fill={fill}
          size={16}
        />
      )}
      {locked && (
        <LockedBadge />
      )}
      <InlineTextBox styling={styles[text]}>{label}</InlineTextBox>
    </button>
  )
}

export default IconButton
