import React from 'react'

const ReturnIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M11.41 7.41L7.83 11L22 11L22 13L7.83 13L11.42 16.59L10 18L4 12L10 6C10 6 11.41 7.41 11.41 7.41ZM3 6L3 18L1 18L1 6L3 6Z' fill={fill} />
    </svg>
  )
}

export default ReturnIcon
