import React, { useState } from 'react'

import sortBy from 'lodash/sortBy'

import StyleWrapper from '_shared/style/StyleWrapper'

import Container from '_shared/components/layout/Container'
import Table from '_shared/components/element/Table'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import Button from '_shared/components/element/Button'

import ActionEdit from '_shared/components/action/ActionEdit'
import ActionAdd from '_shared/components/action/ActionAdd'
import ActionRemove from '_shared/components/action/ActionRemove'

import LoadIndicator from '_shared/components/notification/LoadIndicator'

import Config from 'libs/Config'

const getInitialSortable = (columns) => {
  const sortable = columns.filter(column => column.sortable)

  return sortable.length > 0 ? sortable[0].key : null
}

const createSortableHeader = (selected, column, action) => {
  if (column.sortable) {
    const {
      label,
      ...rest
    } = column

    return {
      label: <SortWrapper selected={column.key === selected} label={label} change={() => action(column.key)} />,
      ...rest,
    }
  } else {
    return column
  }
}

const ListTable = ({
  columns,
  data = [],
  edit,
  create,
  remove,
  loading = false,
  shadeAlternateRows = true,
  ...style
}) => {
  const [
    sorted,
    setSorted,
  ] = useState(getInitialSortable(columns))

  const handleSort = (key) => {
    setSorted(key)
  }

  const headRowStyle = {
    backgroundColor: Config.theme.row_header,
  }

  const headStyle = {
    color: Config.theme.text_mid,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
  }

  const bodyRowStyle = {
    height: '2.5rem',
  }

  let mutatedData

  const styledColumns = edit ? [
    {
      key: 'list_row_edit',
      label: 'Edit',
      headStyle: {
        ...headStyle,
        width: '3rem',
      },
    },
    ...columns
      .map(column => ({
        ...createSortableHeader(sorted, column, handleSort),
        headStyle,
      })),
  ] : columns
    .map(column => ({
      ...createSortableHeader(sorted, column, handleSort),
      headStyle,
    }))

  mutatedData = edit ? data
    .map(row => ({
      ...row,
      'list_row_edit': (<ActionEdit collapsed label={'edit'} change={() => edit(row.id)} />),
    })) : data

  if (remove) {
    styledColumns.push({
      key: 'list_row_remove',
      label: '',
      headStyle: {
        ...headStyle,
        width: '3rem',
      },
    })
    // If an id doesn't exist for a row, just return the index
    mutatedData = mutatedData.map((row, i) => ({
      ...row,
      'list_row_remove': (<ActionRemove collapsed label={'remove'} change={() => remove(row.id || i)} />),
    }))
  }

  const sortedData = sorted !== null ? sortBy(mutatedData, [sorted]) : mutatedData

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <Container background={'background_module'} style={styling}>
            <Table
              columns={styledColumns}
              data={sortedData}
              shadeAlternateRows={shadeAlternateRows}
              headRowStyle={headRowStyle}
              bodyRowStyle={bodyRowStyle}
            />
            {mutatedData.length === 0 && (
              <Empty loading={loading} />
            )}
            {create && (
              <AddBar create={create} label={'Add a new record'} />
            )}
            {loading && (
              <LoadIndicator />
            )}
          </Container>
        )
      }}
    />
  )
}

const SortWrapper = ({
  selected,
  label,
  change,
}) => {
  const style = {
    color: selected ? Config.theme.text : Config.theme.text_mid,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: 0,
  }

  return (
    <Button change={change} rawStyle={style}>
      {label}
    </Button>
  )
}

const Empty = ({ loading }) => {
  const style = {
    minHeight: '2.5rem',
    borderBottom: `solid 1px ${Config.theme.border_thin}`,
  }

  return (
    <Container flexCenter rawStyle={style}>
      {!loading && (
        <InlineTextBox strong>{'No records to display'}</InlineTextBox>
      )}
    </Container>
  )
}

const AddBar = ({ create, label }) => {
  const style = {
    minHeight: '2.5rem',
    backgroundColor: Config.theme.row_highlight,
    borderBottom: `solid 1px ${Config.theme.border_boundary}`,
  }

  return (
    <Container flexCenter background={'table_body_highlight'} rawStyle={style}>
      <ActionAdd change={create} label={label} />
    </Container>
  )
}

export default ListTable