import React, { useEffect } from 'react'

import {
  getPricingRuleSchema,
  // valid as valid_spec,
} from './Context'

import FormRule from './FormRule'

import Subslide, { Controls } from 'containers/SubSlide'

import useForm from '_shared/hooks/useForm'

import { filterOnSave } from 'templates/PriceAdjustMatrix'

const permissions = [
  'read',
  'create',
  'update',
  'delete',
]

const PricingRuleConsumer = ({
  record,
  save,
  close,
  mount,
  routes,
  type,
  activeTickets,
  activeMarkets,
}) => {
  const {
    current,
    reset,
    update,
  } = useForm(getPricingRuleSchema, {}, record, {})

  useEffect(() => {
    reset(record)
  }, [reset, record])

  const updateSingle = (field, value) => update([{ field, value }])

  const wrapped = () => {
    const {
      price_adjustment,
      ...rest
    } = current

    const payload = {
      price_adjustment: filterOnSave(price_adjustment),
      ...rest,
    }

    save(payload)
  }

  return (
    <Subslide
      mount={mount}
      controls={() => {
        return (
          <Controls
            saveAction={wrapped}
            cancelAction={close}
            permissions={permissions}
            disabled={false}
          />
        )
      }}
    >
      <FormRule
        {...{
          current,
          conditions: record.conditions,
          update,
          updateSingle,
          routes,
          type,
          activeTickets,
          activeMarkets,
        }}
      />
    </Subslide>
  )
}

export default PricingRuleConsumer
