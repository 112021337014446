import { createSchema, morphism } from 'morphism'
import api from 'api/product'

import { mapLocales, mapDefaults } from '_shared/libs/mapToSchema'

import {
  checkValidID,
  isUnique,
} from '_shared/libs/validityChecks'

const defaults = {
  'external_id': '',
  'type': '',
  'ticket_types': [],
  'mandatory_companions_max_free_selection': 0,
}

const ticket_type = {
  entity_id: 'ticket_type.entity_id',
}

const getTicketSpec = (locales) => {
  return createSchema(
    {
      ticket_type,
      capacity_type: {
        entity_id: 'capacity_type.entity_id',
      },
      capacity_units: 'capacity_units',
    },
    mapDefaults(defaults),
  )
}

const getSpec = (locales) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      type: 'type',
      ticket_types: {
        path: 'ticket_types',
        fn: (value, source) => {
          if (!value) return []
          return value.map(record => morphism(getTicketSpec(locales), record))
        },
      },
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          title: '',
          description: '',
        }, value, locales),
      },
      operator_type: 'operator_type',
    },
    mapDefaults(defaults),
  )
}

const valid = {
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },
  locales: (record, value) => {
    return value
      .map(locale => locale.title)
      .some(locale => locale !== '')
  },
  type: (record, value) => {
    return value !== ''
  },
  ticket_types: (record, value) => {
    return value.length > 0 && value
      .every(({ ticket_type, capacity_type }) => {
        return ticket_type.entity_id !== null && (capacity_type.entity_id !== null || record.operator_type === 'scandlines')
      })
  },
}

const handlers = {
  store: 'services',
  unique_key: 'external_id',
  get: api.getService,
  new: api.createService,
  edit: (payload) => api.updateService(payload.entity_id, payload),
  remove: (payload) => {},
  cancel: null,
}

export {
  getSpec,
  valid,
  handlers,
}
