import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText, { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import { Localised as InputRTELocalised } from '_shared/components/input/InputRTE'

import FeeBox from './FeeBox'

import InfoBox from '_shared/components/notification/InfoBox'

import mapToSchema from '_shared/libs/mapToSchema'

import { getRuleSpec } from './Context'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

const FormDescription = ({
  current,
  valid,
  updateSingle,
}) => {
  const createRule = (type) => {
    const state = [...current.rules]

    const row = mapToSchema([], [], getRuleSpec)({ type })

    state.push(row)

    updateSingle('rules', state)
  }

  const removeRule = (row) => {
    const state = [...current.rules]

    state.splice(row, 1)

    updateSingle('rules', state)
  }

  return (
    <FormView>
      <Area
        areas={[
          'section/2 warning',
          'info/2 warning',
          'code . code_info',
          'title . code_info',
          'description/2 .',
          'feeblock',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Description'} />
        </SectionWrapper>
        <TextBox strong area={'info'}>{'Give these terms and conditions a customer facing name and description'}</TextBox>
        <InputText
          area={'code'}
          title={'External ID'}
          field={'external_id'}
          value={current.external_id}
          placeholder={'Unique External ID'}
          change={updateSingle}
          status={parseValidity(valid.external_id)}
          required
          requiredText={'Must be unique'}
        />
        <InfoBox
          area={'code_info'}
          type={'info'}
          title={'External ID'}
          content={'Used for external API calls.'}
        />
        <InfoBox
          area={'warning'}
          type={'warning'}
          title={'Warning'}
          content={'Changing details here will change it throughout the products.'}
        />
        <InputTextLocalised
          area={'title'}
          title={'Name'}
          field={'title'}
          value={current.locales}
          placeholder={'Terms name'}
          change={updateSingle}
          valid={valid.locales}
          required
        />
        <InputRTELocalised
          area={'description'}
          title={'Description'}
          field={'description'}
          value={current.locales}
          placeholder={'Description'}
          change={updateSingle}
        />
        <FeeBlock
          area={'feeblock'}
          current={current.rules}
          change={updateSingle}
          create={createRule}
          remove={removeRule}
        />
      </Area>
    </FormView>
  )
}

const filterRecords = (type, collection = []) => collection
  .reduce((acc, cur, index) => {
    if (cur.type === type) acc.push({
      id: index,
      row: cur,
    })

    return acc
  }, [])

const FeeBlock = ({
  current,
  change,
  create,
  remove,
}) => {
  const cancel = filterRecords('cancellation', current)
  const amend = filterRecords('amendment', current)

  return (
    <Area
      areas={[
        'cancel_section/2 .',
        'cancel_info/2 .',
        'cancel_fee',
        'amend_section/2 .',
        'amend_info/2 .',
        'amend_fee',
      ]}
      rowgap={1}
    >
      <SectionWrapper area={'cancel_section'}>
        <Heading level={1} title={'Cancellation Fees'} />
      </SectionWrapper>
      <TextBox strong area={'cancel_info'}>{'Define the fees that customers will be charged when cancelling their booking'}</TextBox>
      <FeeBox
        area={'cancel_fee'}
        field={'cancellations'}
        type={'amendment'}
        value={cancel}
        change={change}
        create={() => create('cancellation')}
        remove={remove}
      />
      <SectionWrapper area={'amend_section'}>
        <Heading level={1} title={'Amendment Fees'} />
      </SectionWrapper>
      <TextBox strong area={'amend_info'}>{'Define the fees that customers will be charged when amending their booking'}</TextBox>
      <FeeBox
        area={'amend_fee'}
        field={'amendments'}
        type={'cancellation'}
        value={amend}
        change={change}
        create={() => create('amendment')}
        remove={remove}
      />
    </Area>
  )
}

export default FormDescription
