import React, { Fragment, useState } from 'react'

import Container from '_shared/components/layout/Container'
import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import ShadowWrapper from '_shared/components/layout/ShadowWrapper'
import Row from '_shared/components/layout/Row'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import Card from '_shared/components/layout/Card'
import CardSelected from '_shared/components/notification/CardSelected'
import Button from '_shared/components/element/Button'

import InfoBox from '_shared/components/notification/InfoBox'

import ActionCTA from '_shared/components/action/ActionCTA'
import ActionBack from '_shared/components/action/ActionBack'

import { Raw as InputSelect } from '_shared/components/input/InputSelect'

import Config from 'libs/Config'

import isEmpty from 'lodash/isEmpty'

const buttonStyle = {
  flexDirection: 'column',
  alignSelf: 'stretch',
  flexGrow: 1,
  justifyContent: 'flex-start',
}

const defaultConfig = {
  title: 'Select a Service Type',
  create: 'Add a new service',
  warning: 'This cannot be changed later. Make sure you are selecting the correct one for the type of service you are trying to sell.',
  journeyTitle: 'Journey Service',
  journeyInfo: ['A service with multiple stops.', 'This is best for services like buses or ferries.'],
  eventTitle: 'Event Service',
  eventInfo: ['A service with a fixed venue.', 'This is best for theatre, museums and parties.'],
  apiJourneyTitle: 'API Journey Service',
  apiEventTitle: 'API Event Service',
  apiEventInfo: ['Connect an external API as a journey service'],
}

const selectStyle = {
  flex: '0 0 auto',
  minHeight: '1.5rem',
}

const Chooser = ({
  config = {},
  cancel,
  current,
  reset,
  update,
}) => {
  const [
    type,
    changeType,
  ] = useState('route')

  const [
    operatorType,
    changeOperatorType,
  ] = useState('internal')

  const changeTypeAndOperator = (type, operator) => {
    changeType(type)
    changeOperatorType(operator)
  }

  const handleSubmit = () => {
    reset({
      type,
      operator_type: operatorType,
    })
  }

  const text = {
    ...defaultConfig,
    ...config,
  }

  return (
    <Container>
      <ShadowWrapper>
        <Area
          areas={[
            'back title/2 create .',
          ]}
          colgap={1.5}
          rowgap={1}
          columns={['2rem', '1fr', '1fr', '10rem', '2rem']}
          rows={['8rem']}
        >
          {cancel && (
            <ActionBack area={'back'} change={cancel} />
          )}
          <Heading level={'section'} title={text.title} area={'title'} noMargin gridAlign={'center'} />
          <ActionCTA label={text.create} change={handleSubmit} area={'create'} gridAlign={'center'} />
        </Area>
      </ShadowWrapper>
      <FormView>
        <Area
          areas={[
            'info .',
            'select',
          ]}
          columns={6}
        >
          <InfoBox type={'warning'} title={'warning'} content={text.warning} area={'info'} />
          <Row type={'start'} area={'select'} spread={false}>
            <Card key='route'>
              <CardSelected selected={type === 'route' && operatorType === 'internal'}>
                <Button change={() => changeTypeAndOperator('route', 'internal')} rawStyle={buttonStyle}>
                  <Heading level={2} title={text.journeyTitle} margin={'1rem 0'} />
                  {text.eventInfo.map((text, index) => {
                    return (
                      <TextBox noMargin key={index}>{text}</TextBox>
                    )
                  })}
                </Button>
              </CardSelected>
            </Card>
            <InlineTextBox color={'text_success'} strong margin={'0 1rem'}>{'OR'}</InlineTextBox>
            <Card key='event'>
              <CardSelected selected={type === 'event' && operatorType === 'internal'}>
                <Button change={() => changeTypeAndOperator('event', 'internal')} rawStyle={buttonStyle}>
                  <Heading level={2} title={text.eventTitle} margin={'1rem 0'} />
                  {text.eventInfo.map((text, index) => {
                    return (
                      <TextBox noMargin key={index}>{text}</TextBox>
                    )
                  })}
                </Button>
              </CardSelected>
            </Card>
            {Config.app['use_scandlines_api_services'] && isEmpty(config) && (
              <Fragment>
                <InlineTextBox color={'text_success'} strong margin={'0 1rem'}>{'OR'}</InlineTextBox>
                <Card key='api_route'>
                  <CardSelected selected={type === 'route' && operatorType === 'scandlines'}>
                    <Button change={() => changeTypeAndOperator('route', 'scandlines')} rawStyle={buttonStyle}>
                      <Heading level={2} title={text.apiJourneyTitle} margin={'1rem 0'} />
                      {text.apiEventInfo.map((text, index) => {
                        return (
                          <TextBox noMargin key={index}>{text}</TextBox>
                        )
                      })}
                      <TextBox>{'Select API'}</TextBox>
                      <InputSelect
                        field={'api_type'}
                        options={[{ label: 'Scandlines', value: 'scandlines' }]}
                        value={'scandlines'}
                        change={() => {}}
                        {...selectStyle}
                      />
                    </Button>
                  </CardSelected>
                </Card>
              </Fragment>
            )}
          </Row>
        </Area>
      </FormView>
    </Container>
  )
}

export default Chooser
