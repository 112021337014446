import React, { useState } from 'react'

import Area from '_shared/components/layout/Area'
import InputRadio from '_shared/components/input/InputRadio'
import InputInteger from '_shared/components/input/InputInteger'
import InputISOInterval from '_shared/components/input/InputISOInterval'

import InlineTextBox from '_shared/components/layout/InlineTextBox'

const options = [
  {
    label: 'Unlimited',
    value: 'unlimited',
    action: (current) => {
      return {
        max_sale_qty: null,
        max_sale_period: null,
      }
    },
  },
  {
    label: 'Set total purchase quantity',
    value: 'quantity',
    action: (current) => {
      return {
        max_sale_qty: current.max_sale_qty !== null ? current.max_sale_qty : 0,
        max_sale_period: null,
      }
    },
  },
  {
    label: 'Set quantity per day/week',
    value: 'all',
    action: (current) => {
      return {
        max_sale_qty: current.max_sale_qty !== null ? current.max_sale_qty : 0,
        max_sale_period: 'P0Y0M0W1DT0H0M',
      }
    },
  },
]

const deriveType = ({ max_sale_qty, max_sale_period }) => {
  if (max_sale_qty === null && max_sale_period === null) return 'unlimited'

  if (max_sale_qty !== null && max_sale_period === null) return 'quantity'

  if (max_sale_qty !== null && max_sale_period !== null) return 'all'
}

const PurchaseLimit = ({
  current,
  change,
}) => {
  const [
    type,
    setType,
  ] = useState(deriveType(current))

  const handleChangeType = (field, type) => {
    const option = options.find(option => option.value === type)

    const {
      max_sale_qty,
      max_sale_period,
    } = option.action(current)

    change([
      {
        field: 'max_sale_qty',
        value: max_sale_qty,
      },
      {
        field: 'max_sale_period',
        value: max_sale_period,
      },
    ])

    setType(type)
  }

  const handleChangeValue = (field, value) => {
    change([
      {
        field,
        value,
      },
    ])
  }

  return (
    <Area
      areas={[
        'chooser',
        'limit text duration .',
      ]}
      columns={['6rem', '4rem', '10rem', '1fr']}
      crossAxis={'center'}
      rowgap={1}
      colgap={1}
    >
      <InputRadio
        area={'chooser'}
        type={'horizontal'}
        field={'type'}
        options={options}
        value={type}
        change={handleChangeType}
      />
      <InputInteger
        area={'limit'}
        field={'max_sale_qty'}
        value={current.max_sale_qty === null ? 0 : current.max_sale_qty}
        disabled={current.max_sale_qty === null}
        change={handleChangeValue}
      />
      <InlineTextBox area={'text'} strong>{'per every'}</InlineTextBox>
      <InputISOInterval
        area={'duration'}
        field={'max_sale_period'}
        value={current.max_sale_period === null ? 0 : current.max_sale_period}
        disabled={current.max_sale_period === null}
        change={handleChangeValue}
      />
    </Area>
  )
}

export default PurchaseLimit
