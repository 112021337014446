const spanMatch = /^([A-Za-z_0-9.]+)(?:\/([0-9]+))?$/

const expand = (areas, baseSpanUnit) => areas.map(row => row.map(item => new Array(baseSpanUnit).fill(item)).flat())

const createSpanned = (row) => {
  return row.reduce((acc, area) => {
    const spanned = area.match(spanMatch)

    try {
      const [
        area,
        span = 1,
      ] = [...spanned]
        .slice(1)

      acc.push(new Array(Number(span)).fill(area))

    } catch (e) {
      console.error('Area definition is invalid:', area)
      acc.push(area)
    }

    return acc
  }, []).flat()
}

const generateAreas = (areas, columns = 12) => {
  areas = areas
    .filter(row => row.length > 0) // Clear empty rows
    .map(row => !Array.isArray(row) ? row.split(' ') : row) // Turn string rows into array
    .map(row => createSpanned(row)) // Expand span notation

  const maxRowLength = areas.reduce((acc, cur) => {
    if (cur.length > acc) acc = cur.length
    return acc
  }, 0)

  // Set all rows to the same length by padding last item
  const cleaned = areas.reduce((acc, row) => {
    const mod = [...row]

    if (mod.length < maxRowLength) { // Pad the area out to normalise
      if (mod.length > 0) {
        // Get last area
        const last = mod.slice(-1)[0]

        for (let x = mod.length; x < maxRowLength; x++) {
          mod.push(last)
        }
      }
    }

    acc.push(mod)

    return acc
  }, [])

  if ((columns % maxRowLength) > 0) {
    console.log('Unable to calculate column spread', columns, maxRowLength, (columns % maxRowLength))

    return cleaned
  }

  if (maxRowLength < columns) {
    return expand(cleaned, columns / maxRowLength)
  }

  return cleaned
}

export default generateAreas
