import React from 'react'

import {
  getSchema,
  valid as valid_spec,
  handlers,
} from './Context'

import SlideForm from 'templates/FullForm'

import Route from './Route'

const Consumer = (props) => {
  return (
    <SlideForm
      data_spec={getSchema}
      valid_spec={valid_spec}
      handlers={handlers}
      mountKey={'record'}
      permissionsKey={'promotions'}
      render={({
        cancel,
        current,
        valid,
        update,
        updateSingle,
      }) => {
        return (
          <Route
            cancel={cancel}
            current={current}
            valid={valid}
            update={update}
            updateSingle={updateSingle}
          />
        )
      }}
    />
  )
}

export default Consumer
