import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'
import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText from '_shared/components/input/InputText'

const FormDescription = ({
  current,
  updateSingle,
}) => {
  return (
    <FormView>
      <Area
        areas={[
          'section',
          'label .',
          'description/2 . .',
        ]}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Internal Name & Description'} />
        </SectionWrapper>
        <InputText
          area={'label'}
          title={'Label'}
          field={'title'}
          value={current.title}
          placeholder={'Label'}
          change={updateSingle}
        />
        <InputText
          area={'description'}
          title={'Description'}
          field={'description'}
          value={current.description}
          placeholder={'Description'}
          change={updateSingle}
        />
      </Area>
    </FormView>
  )
}

export default FormDescription
