import React from 'react'

const LeftIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20.01 32'
    >
      <polygon
        fill={fill}
        points='20 4 16 0 0 16 16 32 20.01 28 8 16 20 4'
      />
    </svg>
  )
}

export default LeftIcon
