import React, { Fragment, useState } from 'react'

import Area from '_shared/components/layout/Area'

import Heading from '_shared/components/layout/Heading'
import InputDate from '_shared/components/input/InputDate'
import InputRadio from '_shared/components/input/InputRadio'
import InputTime from '_shared/components/input/InputTime'

import {
  dateAfter,
  stampToDateISO,
} from 'libs/date'

import addDays from 'date-fns/add_days'

const options = [
  {
    label: 'Last 30 days',
    value: 'last_30',
    shift: () => {
      return {
        dateFrom: stampToDateISO(addDays(new Date(), -30)),
        dateTo: stampToDateISO(new Date()),
      }
    },
  },
  {
    label: 'Last week',
    value: 'last_7',
    shift: () => {
      return {
        dateFrom: stampToDateISO(addDays(new Date(), -7)),
        dateTo: stampToDateISO(new Date()),
      }
    },
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    shift: () => {
      return {
        dateFrom: stampToDateISO(addDays(new Date(), -1)),
        dateTo: stampToDateISO(addDays(new Date(), -1)),
      }
    },
  },
  {
    label: 'Today',
    value: 'today',
    shift: () => {
      return {
        dateFrom: stampToDateISO(new Date()),
        dateTo: stampToDateISO(new Date()),
      }
    },
  },
  {
    label: 'Tomorrow',
    value: 'tomorrow',
    shift: () => {
      return {
        dateFrom: stampToDateISO(addDays(new Date(), 1)),
        dateTo: stampToDateISO(addDays(new Date(), 1)),
      }
    },
  },
  {
    label: 'Next 7 days',
    value: 'next_7',
    shift: () => {
      return {
        dateFrom: stampToDateISO(new Date()),
        dateTo: stampToDateISO(addDays(new Date(), 7)),
      }
    },
  },
  {
    label: 'None',
    value: 'none',
    shift: () => {
      return {
        dateFrom: null,
        dateTo: null,
      }
    },
  },
  {
    label: 'Custom',
    value: 'custom',
    shift: null,
  },
]

const getLayout = (type, showTime = false) => {
  if (type === 'custom') {
    return [
      'heading',
      'chooser',
      'datefrom dateto .',
      ...(showTime ? [ 'timefrom timeto .' ] : []),
    ]
  } else {
    return [
      'heading',
      'chooser',
      ...(showTime ? ['timefrom timeto .'] : []),
    ]
  }
}

const DateRange = ({
  datePrefix,
  timePrefix,
  showTime = false,
  label,
  data,
  change,
  checkOptions,
  defaultOption = 'today',
}) => {
  const [
    type,
    setType,
  ] = useState('today')

  // Set fields
  const dateFieldFrom = `${datePrefix}-start`
  const dateFieldTo = `${datePrefix}-end`
  const timeFieldFrom = `${timePrefix}-start`
  const timeFieldTo = `${timePrefix}-end`

  let radioOptions = options

  if (checkOptions) {
    radioOptions = radioOptions.filter((option) => checkOptions.includes(option.value))
  }

  const handleChangeTime = (field, value) => {
    if (field === timeFieldTo && value < data[timeFieldFrom]) {
      change([
        {
          field: timeFieldFrom,
          value,
        },
        {
          field,
          value,
        },
      ])
    } else {
      change([{
        field,
        value,
      }])
    }
  }

  const handleChangeDate = (field, value) => {
    if (field === dateFieldTo && !dateAfter(data[dateFieldFrom], value)) {
      change([
        {
          field: dateFieldFrom,
          value,
        },
        {
          field,
          value,
        },
      ])
    } else {
      change([{
        field,
        value,
      }])
    }
  }

  const handleChangeType = (field, type) => {
    const option = radioOptions.find(option => option.value === type)

    if (option.shift === null) {
      const dateFrom = data[dateFieldFrom]
      const dateTo = data[dateFieldTo]

      if (dateFrom === null || dateTo === null) {
        change([
          {
            field: dateFieldFrom,
            value: stampToDateISO(new Date()),
          },
          {
            field: dateFieldTo,
            value: stampToDateISO(new Date()),
          },
        ])
      }

      setType(type)
    } else {
      const {
        dateFrom,
        dateTo,
      } = option.shift()

      change([
        {
          field: dateFieldFrom,
          value: dateFrom,
        },
        {
          field: dateFieldTo,
          value: dateTo,
        },
      ])

      setType(type)
    }
  }

  return (
    <Area
      areas={getLayout(type, showTime)}
      columns={3}
      rowgap={1}
    >
      <Heading
        area={'heading'}
        level={3}
        title={`Filter ${label.toLowerCase()} date${showTime ? ' and time ranges' : ''}`}
      />
      <InputRadio
        area={'chooser'}
        type={'horizontal'}
        field={'type'}
        options={radioOptions}
        value={type}
        change={handleChangeType}
        margin={'1rem 0 0 0'}
      />
      {type === 'custom' && (
        <Fragment>
          <InputDate
            area={'datefrom'}
            field={dateFieldFrom}
            title={`${label} Start Date`}
            placeholder={`${label} Start Date`}
            value={data[dateFieldFrom]}
            change={handleChangeDate}
          />
          <InputDate
            area={'dateto'}
            field={dateFieldTo}
            title={`${label} End Date`}
            placeholder={`${label} End Date`}
            value={data[dateFieldTo]}
            change={handleChangeDate}
          />
        </Fragment>
      )}
      {showTime && (
        <Fragment>
          <InputTime
            area={'timefrom'}
            field={timeFieldFrom}
            title={`${label} Time Starts`}
            placeholder={'00:00'}
            value={data[timeFieldFrom]}
            change={handleChangeTime}
          />
          <InputTime
            area={'timeto'}
            field={timeFieldTo}
            title={`${label} Time Ends`}
            placeholder={'00:00'}
            value={data[timeFieldTo]}
            change={handleChangeTime}
          />
        </Fragment>
      )}
    </Area>
  )
}

export default DateRange
