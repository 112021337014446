import React from 'react'

import styles from './carousel.scss'

import Column from 'containers/Column'
import Container from 'containers/Container'
import Row from 'containers/Row'
import TextBox from 'containers/TextBox'

import LeftArrow from 'icons/Left'
import RightArrow from 'icons/Right'

import colors from 'config/colors'

import carouselController from 'HOC/carouselController'

const Action = (props) => {
  const {
    action,
    children,
    active,
    styling,
  } = props

  const style = active ? null : styles.ghosted

  return (
    <Column
      type={'center'}
      styling={[styles.action, styling, style].join(' ')}
      onClick={action}
    >
      {children}
    </Column>
  )
}

const Carousel = (props) => {
  const {
    advance,
    leftActive,
    rightActive,
    cards,
    current,
    count,
  } = props

  const Prev = (
    <Action
      styling={styles.prev}
      action={advance.bind(this, 'left')}
      active={leftActive}
    >
      <LeftArrow
        size={12}
        fill={colors.color_ui}
      />
    </Action>
  )

  const Next = (
    <Action
      styling={styles.next}
      action={advance.bind(this, 'right')}
      active={rightActive}
    >
      <RightArrow
        size={12}
        fill={colors.color_ui}
      />
    </Action>
  )

  const showArrows = count > 1

  return (
    <Container
      styling={styles.layout}
    >
      <Container
        styling={styles.shifter}
      >
        {cards}
      </Container>
      <Row
        type={'center'}
        styling={styles.controller}
        spread
      >
        {showArrows && Prev}
        <TextBox
          styling={styles.title}
        >
          {`${current + 1} of ${count}`}
        </TextBox>
        {showArrows && Next}
      </Row>
    </Container>
  )
}

export default carouselController(Carousel)
