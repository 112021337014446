import React from 'react'

const GiftIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 30.4'
    >
      <path
        fill={fill}
        d='M28.8,6.4H25.31a4.73,4.73,0,0,0,.29-1.6,4.79,4.79,0,0,0-8.8-2.64L16,3.23l-.8-1.09A4.8,4.8,0,0,0,6.4,4.8a4.73,4.73,0,0,0,.29,1.6H3.2A3.17,3.17,0,0,0,0,9.6L0,27.2a3.18,3.18,0,0,0,3.2,3.2H28.8A3.18,3.18,0,0,0,32,27.2V9.6A3.18,3.18,0,0,0,28.8,6.4Zm-8-3.2a1.6,1.6,0,1,1-1.6,1.6A1.6,1.6,0,0,1,20.8,3.2Zm-9.6,0A1.6,1.6,0,1,1,9.6,4.8,1.6,1.6,0,0,1,11.2,3.2Zm17.6,24H3.2V24H28.8Zm0-8H3.2V9.6h8.13L8,14.13,10.59,16l3.81-5.18L16,8.64l1.6,2.18L21.41,16,24,14.13,20.67,9.6H28.8Z'
      />
    </svg>
  )
}

export default GiftIcon
