const fragmentShape = /(\d+)([ymwdhmsYMWDHMS])?/

const durationShape = /^(?=.{3,}$)P(\d+Y)?(\d+M)?(\d+W)?(\d+D)?(T(\d+H)?(\d+M)?(\d+S)?)?$/

const duration_map = {
  year: {
    singular: 'year',
    plural: 'years',
  },
  month: {
    singular: 'month',
    plural: 'months',
  },
  week: {
    singular: 'week',
    plural: 'weeks',
  },
  day: {
    singular: 'day',
    plural: 'days',
  },
  hour: {
    singular: 'hour',
    plural: 'hours',
  },
  minute: {
    singular: 'minute',
    plural: 'minutes',
  },
  second: {
    singular: 'second',
    plural: 'seconds',
  },
}

const duration_index = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second']

const getDurationFragments = (duration) => {
  const value = duration.match(durationShape)

  if (!value) return null

  const date_map = [...value].slice(1, 5)
  const time_map = [...value].slice(6, 9)

  const output = [...date_map, ...time_map]
    .reduce((acc, cur, index) => {
      if (cur === undefined) {
        acc[duration_index[index]] = 0
      } else {
        const [
          amount,
        ] = cur.match(fragmentShape).slice(1)

        acc[duration_index[index]] = Number(amount)
      }

      return acc
    }, {})

  return output
}

const getTranslatedMeasure = (amount, measure) => {
  return amount > 1 ? duration_map[measure].plural : duration_map[measure].singular
}

const translateDuration = (duration) => {
  if (typeof duration !== 'string') return duration
  const value = getDurationFragments(duration)

  if (!value) return duration

  return duration_index
    .reduce((acc, cur) => {
      if (value[cur] > 0) acc.push(`${value[cur]} ${getTranslatedMeasure(value[cur], cur)}`)

      return acc
    }, [])
    .join(', ')
}

const addDuration = (date, duration) => {
  const dateCopy = new Date(date)
  const fragments = getDurationFragments(duration)

  dateCopy.setSeconds(dateCopy.getSeconds() + fragments.second)
  dateCopy.setMinutes(dateCopy.getMinutes() + fragments.minute)
  dateCopy.setHours(dateCopy.getHours() + fragments.hour)
  dateCopy.setDate(dateCopy.getDate() + fragments.day)
  dateCopy.setDate(dateCopy.getDate() + fragments.week * 7)
  dateCopy.setMonth(dateCopy.getMonth() + fragments.month)
  dateCopy.setFullYear(dateCopy.getFullYear() + fragments.year)

  return dateCopy.toISOString()
}

export {
  fragmentShape,
  durationShape,
  getTranslatedMeasure,
  getDurationFragments,
  translateDuration,
  addDuration,
}
