import cacheAPI from 'api/cache'

import {
  setBusyState,
} from 'libs/actionCreator'

const clearCache = () => (dispatch) => {
  dispatch(setBusyState(true))

  return cacheAPI.clear()
    .then(response => {
      console.log('Response from clear cache', response)

      dispatch(setBusyState(false))

      return response
    })
    .catch(err => {
      console.error('Caught error clear cache', err)

      dispatch(setBusyState(false))

      throw err
    })
}

export {
  clearCache,
}
