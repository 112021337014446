import React from 'react'

import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'
import Container from '_shared/components/layout/Container'
import ActionCTA from '_shared/components/action/ActionCTA'

import CapacityCard from './CapacityCard'

import useLocale from '_shared/hooks/useLocale'

import mapToSchema, { update } from '_shared/libs/mapToSchema'

import { getAreaSpec } from './Context'

const CapacityAreas = ({
  field,
  data,
  change,
}) => {
  const {
    locales,
    currencies,
  } = useLocale()

  const create = () => {
    const state = [...data]
    const row = mapToSchema(locales, currencies, getAreaSpec)({})

    state.push(row)

    change(field, state)
  }

  const remove = (row) => {
    const state = [...data]

    state.splice(row, 1)

    change(field, state)
  }

  const handleChange = (row) => (field, value) => {
    const state = [...data]

    update(state[row], `${field}`, value)

    change(`[$row]${field}`, state)
  }

  return (
    <Area
      areas={[
        'section/5 action',
        'list/5 .',
      ]}
      rowgap={1.5}
    >
      <Heading level={2} title={'Define Capacity Areas'} area={'section'} />
      <ActionCTA label={'Add a space'} change={create} area={'action'} />
      <Container area={'list'}>
        <Area
          rowgap={1}
          columns={1}
        >
          {data.map((card, row) => {
            return (
              <CapacityCard
                key={row}
                value={card}
                remove={() => remove(row)}
                change={handleChange(row)}
              />
            )
          })}
        </Area>
      </Container>
    </Area>
  )
}

export default CapacityAreas
