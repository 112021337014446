import React from 'react'

import styles from './validityindicator.scss'

import TickIcon from '_shared/icons/Tick'
import WarningIcon from '_shared/icons/Warning'

import StyleWrapper from '_shared/style/StyleWrapper'

import Config from 'libs/Config'

const ValidityIndicator = ({ status, ...style }) => {
  if (!status) return null

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.layout} style={styling}>
            {status === 'VALID' && (
              <TickIcon
                size={24}
                fill={Config.theme.text_success}
              />
            )}
            {status === 'INVALID' && (
              <WarningIcon
                size={24}
                fill={Config.theme.text_error}
              />
            )}
          </div>
        )
      }}
    />
  )
}

export default ValidityIndicator
