import React, { Component } from 'react'
import {
  connect,
} from 'react-redux'

import {
  reAuth,
  logout,
} from 'actions/user'

import styles from './request.scss'

import Container from 'containers/Container'
import Row from 'containers/Row'
import TextBox from 'containers/TextBox'

import Heading from 'atoms/Heading'
import TextField from 'molecules/TextField'
import TextFieldIcon from 'molecules/TextFieldIcon'
import ModalButton from 'atoms/ModalButton'

import VisibleIcon from 'icons/Visible'
import HiddenIcon from 'icons/Hidden'

import notEmptyOrNull from 'libs/notEmptyOrNull'

import {
  withValidator,
  validate,
  formValid,
} from 'HOC/formValidator'

import colors from 'config/colors'

const Validator = withValidator({
  username: notEmptyOrNull,
  password: notEmptyOrNull,
})

const ValidTextField = validate(TextField)
const ValidPassField = validate(TextFieldIcon)

const Hidden = (
  <HiddenIcon
    size={20}
    fill={colors.color_text}
  />
)

const Visible = (
  <VisibleIcon
    size={20}
    fill={colors.color_text}
  />
)

const ValidSubmit = formValid((props) => {
  const {
    action,
    status,
  } = props

  const color = status ? 'green' : 'disabled'

  return (
    <ModalButton
      color={color}
      content={'Login'}
      onClick={status ? action : null}
      styling={styles.button}
    />
  )
})

class RequestAuth extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showPass: false,
      record: {
        username: '',
        password: '',
      },
    }
  }

  update = (field, value) => {
    const record = { ...this.state.record }

    record[field] = value

    this.setState({
      record,
    })
  }

  showPass = () => {
    this.setState({
      showPass: !this.state.showPass,
    })
  }

  submit = () => {
    this.props.reAuth({ ...this.state.record })
  }

  logout = () => {
    this.props.logout()
  }

  catchSubmit = (event) => {
    event.preventDefault()
  }

  render () {
    const {
      record,
    } = this.state

    return (
      <Validator
        record={record}
      >
        <Container styling={styles.layout}>
          <form onSubmit={this.catchSubmit}>
            <Container styling={styles.content} spread>
              <Heading
                level={1}
                title={'Your current session has expired'}
              />
              <TextBox>{'Please login again so that we can re-authorise you.'}</TextBox>
              <Row
                type={'start'}
                spread
              >
                <ValidTextField
                  field={'username'}
                  placeholder={'Email'}
                  value={record.username}
                  change={this.update}
                  styling={styles.field}
                  requiredText={'User email required'}
                />
                <ValidPassField
                  field={'password'}
                  placeholder={'Password'}
                  type={this.state.showPass ? 'text' : 'password'}
                  value={record.password}
                  change={this.update}
                  styling={styles.field}
                  requiredText={'Password required'}
                  icon={this.state.showPass ? Hidden : Visible}
                  action={this.showPass}
                />
              </Row>
              <Row type={'spaced'}>
                <ValidSubmit
                  action={this.submit}
                />
                <ModalButton
                  color={'delete'}
                  content={'Logout'}
                  onClick={this.logout}
                  styling={styles.button}
                />
              </Row>
            </Container>
          </form>
        </Container>
      </Validator>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reAuth: (data) => dispatch(reAuth(data)),
    logout: () => dispatch(logout()),
  }
}

export default connect(null, mapDispatchToProps)(RequestAuth)
