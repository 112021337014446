import React, { Component } from 'react'

import styles from './select.scss'

import Container from 'containers/Container'
import Row from 'containers/Row'

const getIndex = (options, value) => {
  return value ? options.findIndex(item => item.value === value) : 0
}

const moveDown = (options, current) => {
  const length = options.length

  const next = current + 1 < length ? current + 1 : current

  return next
}

const moveUp = (options, current) => {
  const next = current - 1 > -1 ? current - 1 : current

  return next
}

const alphaKeys = Array.from('abcdefghijklmnopqrstuvwxyz')

class List extends Component {
  constructor (props) {
    super(props)

    this.input = React.createRef()

    const index = getIndex(props.options, props.value)

    this.state = {
      focused: index === -1 ? 0 : index,
    }
  }

  componentDidMount () {
    this.input.current.focus()

    this.moveTo()
  }

  componentDidUpdate (prevProps) {
    this.moveTo()
  }

  changeFocused = (focused) => {
    this.setState({
      focused,
    })
  }

  handleKey = (event) => {
    const key = event.key

    const {
      options,
    } = this.props

    if (alphaKeys.includes(key)) {
      const result = options
        .map(option => option.title)
        .filter(item => item.charAt(0).toLowerCase() === key)

      if (result.length > 0) {
        const focused = options.findIndex(option => result[0] === option.title)

        focused !== -1 && this.changeFocused(focused)
      }

      return
    }

    switch (key) {
      case 'Down':
        event.preventDefault()

        this.changeFocused(moveDown(options, this.state.focused))

        break
      case 'ArrowDown':
        event.preventDefault()

        this.changeFocused(moveDown(options, this.state.focused))

        break
      case 'Up':
        event.preventDefault()

        this.changeFocused(moveUp(options, this.state.focused))

        break
      case 'ArrowUp':
        event.preventDefault()

        this.changeFocused(moveUp(options, this.state.focused))

        break
      case 'Enter':
        event.preventDefault()

        this.update(options[this.state.focused].value)
        break
      case 'SpaceBar':
        event.preventDefault()

        this.update(options[this.state.focused].value)
        break
      case 'Escape':
        this.input.current.blur()

        break
      case ' ':
        event.preventDefault()

        this.update(options[this.state.focused].value)
        break
      default:
        break
    }
  }

  onBlur = () => {
    this.props.close()
  }

  moveTo = () => {
    const node = this.input.current

    if (node.scrollHeight > node.clientHeight) {
      const element = node.childNodes[this.state.focused]

      const scrollBottom = node.clientHeight + node.scrollTop
      const elementBottom = element.offsetTop + element.offsetHeight

      if (elementBottom > scrollBottom) {
        node.scrollTop = elementBottom - node.clientHeight
      } else if (element.offsetTop < node.scrollTop) {
        node.scrollTop = element.offsetTop
      }
    }
  }

  update = (value) => {
    setTimeout(() => {
      this.input.current && this.input.current.blur()
      this.props.update(value)
    }, 100)
  }

  expand = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  render () {
    const {
      options,
      value,
      expand,
      maxHeight,
    } = this.props

    return (
      <Container
        styling={[styles.drop, (this.state.expanded ? styles.expanded : null)].join(' ')}
        getRef={this.input}
        onKeyDown={this.handleKey}
        onBlur={this.onBlur}
        style={{maxHeight: `${maxHeight}rem`}}
        tabIndex={0}
      >
        {options.map((option, index) => {
          const focused = index === this.state.focused
          const selected = option.value === value

          const styling = selected ? styles.selected : focused ? styles.focused : null

          return (
            <Row
              key={index}
              type={'start'}
              styling={[styles.row, styling].join(' ')}
              onMouseDown={(event) => {
                event.stopPropagation()

                this.update(option.value)
              }}
            >
              {option.title}
            </Row>
          )
        })}
        {expand && (
          <Container
            styling={styles.expand}
            onClick={this.expand}
          />
        )}
      </Container>
    )
  }
}

export default List
