import React from 'react'

import SectionWrapper from '_shared/components/layout/SectionWrapper'
import Heading from '_shared/components/layout/Heading'

const Section = ({
  field,
  title,
}) => {
  return (
    <SectionWrapper area={field}>
      <Heading level={1} title={title} />
    </SectionWrapper>
  )
}

export default Section
