import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputText, { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import { Localised as InputRTELocalised } from '_shared/components/input/InputRTE'

import InfoBox from '_shared/components/notification/InfoBox'

import {
  parseValidity,
  getUniqueIdError,
} from '_shared/libs/validityChecks'

const FormDescription = ({
  current,
  valid,
  updateSingle,
}) => {
  return (
    <FormView>
      <Area
        areas={[
          'section',
          'code category . code_info',
          'title short_title . .',
          'short_description . .',
          'description/2 . info',
          'information/2 . .',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={`Product: ${current.type}`} />
        </SectionWrapper>
        <InfoBox
          area={'code_info'}
          type={'info'}
          title={'External ID'}
          content={'Used for external API calls.'}
        />
        <InputText
          area={'code'}
          title={'External ID'}
          field={'external_id'}
          value={current.external_id}
          placeholder={'Unique External ID'}
          change={updateSingle}
          status={parseValidity(valid.external_id)}
          required
          requiredText={getUniqueIdError(current.external_id)}
        />
        <InputTextLocalised
          area={'category'}
          title={'Product category'}
          field={'category'}
          value={current.locales}
          placeholder={'Category'}
          change={updateSingle}
          valid={valid.locales}
        />
        <InputTextLocalised
          area={'title'}
          title={'Product name'}
          field={'title'}
          value={current.locales}
          placeholder={'Label'}
          change={updateSingle}
          valid={valid.locales}
          required
        />
        <InputTextLocalised
          area={'short_title'}
          title={'Short name'}
          field={'short_title'}
          value={current.locales}
          placeholder={'Label'}
          change={updateSingle}
        />
        <InputRTELocalised
          area={'description'}
          title={'Service Description'}
          field={'description'}
          value={current.locales}
          placeholder={'Description'}
          change={updateSingle}
        />
        <InputTextLocalised
          area={'short_description'}
          title={'Short description'}
          field={'short_description'}
          value={current.locales}
          placeholder={'Short description'}
          change={updateSingle}
          valid={valid.locales}
        />
        {current.type !== 'route' && (
          <InputRTELocalised
            area={'information'}
            title={'Important Information'}
            field={'important_information'}
            value={current.locales}
            placeholder={'Important information'}
            change={updateSingle}
          />
        )}
      </Area>
    </FormView>
  )
}

export default FormDescription
