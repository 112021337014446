import React from 'react'

import styles from './heading.scss'

const Heading = (props) => {
  const {
    level,
    title,
    styling,
    ...rest
  } = props

  const Heading = level === 'section' ? 'h1' : `h${level}`

  const className = level === 'section' ? styles.section : styles.heading

  return (
    <Heading
      className={[className, styling].join(' ')}
      {...rest}
    >
      {title}
    </Heading>
  )
}

export default Heading
