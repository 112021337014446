const addToStore = (store, data) => ({
  type: `${store.toUpperCase()}_CREATE`,
  data: data,
})

const editStore = (store, data) => ({
  type: `${store.toUpperCase()}_EDIT`,
  id: data.id,
  data: data,
})

const deleteStore = (store, id) => ({
  type: `${store.toUpperCase()}_DELETE`,
  id,
})

const refreshStore = (store, data) => ({
  type: `${store.toUpperCase()}_LOADED`,
  data: data,
})

const setBusyState = (busy) => {
  if (busy) {
    return ({
      type: 'UI_BUSY',
    })
  } else {
    return ({
      type: 'UI_NOT_BUSY',
    })
  }
}

const createActionCreate = (store, call, postAction) => (data) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return call(data)
    .then(data => {
      dispatch(setBusyState(false))

      dispatch(addToStore(store, data))

      if (postAction) dispatch(postAction())

      return data
    })
    .catch(err => {
      console.error('Caught error in create', err)

      dispatch(setBusyState(false))

      if (postAction) dispatch(postAction())

      throw err
    })
}

const createActionEdit = (store, call, postAction) => (data) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return call(data, data.id)
    .then(data => {
      dispatch(editStore(store, data))

      dispatch(setBusyState(false))

      if (postAction) dispatch(postAction())

      return data
    })
    .catch(err => {
      console.error('Caught error in edit', err)

      dispatch(setBusyState(false))

      if (postAction) dispatch(postAction())

      throw err
    })
}

const createActionDelete = (store, call, postAction) => (id) => (dispatch, getState) => {
  dispatch(setBusyState(true))

  return call(id)
    .then(data => {
      dispatch(deleteStore(store, id))

      dispatch(setBusyState(false))

      if (postAction) dispatch(postAction())

      return data
    })
    .catch(err => {
      console.error('Caught error in delete', err)

      dispatch(setBusyState(false))

      if (postAction) dispatch(postAction())

      throw err
    })
}

const createActionGet = (store, call, postAction) => () => (dispatch, getState) => {
  dispatch(setBusyState(true))

  const locked = getState().ui.lockGroup

  return call()
    .then(data => {
      let response = data

      if (locked !== null) {
        response = data.filter(item => item.hasOwnProperty('service_group') && item.service_group === locked)
      }

      dispatch(refreshStore(store, response))

      dispatch(setBusyState(false))

      if (postAction) dispatch(postAction())

      return response
    })
    .catch(err => {
      console.error('Caught error in get', err)

      dispatch(setBusyState(false))

      if (postAction) dispatch(postAction())

      throw err
    })
}

export {
  createActionCreate,
  createActionEdit,
  createActionDelete,
  createActionGet,
  addToStore,
  editStore,
  deleteStore,
  refreshStore,
  setBusyState,
}
